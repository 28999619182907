import React from 'react';
import {Store, AnyAction} from 'redux';
import Lottie from 'react-lottie';
import {IFlowNotification} from 'spekit-types';
import {MdOutlineWarningAmber} from 'react-icons/md';
import {RiCheckboxCircleLine, RiCloseLine, RiErrorWarningLine} from 'react-icons/ri';
import checkmark from './lottieAnimations/animation-w600-h600.json';
import {Flex} from './design-system/Layout/Flex';
import {Icon} from './design-system/Icon/Icon';
import {colors} from './design-system/theme/Foundations/color';

type NotificationActionType = ((notification: IFlowNotification) => AnyAction) | null;

interface Actions {
  addNotification: NotificationActionType;
  removeNotification: NotificationActionType;
}

interface NotificationsProps {
  removeNotification: (msg: IFlowNotification) => void;
  notifications: IFlowNotification[];
}

interface NotificationProps {
  timeout?: number;
  requestClose: () => void;
  error?: boolean;
  warning?: boolean;
  onClick?: React.MouseEventHandler;
  animation?: boolean;
  text: string;
}

interface NotificationState {
  firstTimeout: number | null;
  secondTimeout: number | null;
  show: boolean;
}

let addNotification: NotificationActionType = null;
let _store: Store | null = null;
let _commsSendTobackground: any = null;

class Notification extends React.Component<NotificationProps, NotificationState> {
  constructor(props: NotificationProps) {
    super(props);
    this.state = {
      show: false,
      firstTimeout: null,
      secondTimeout: null,
    };
  }
  componentDidMount() {
    window.setTimeout(() => {
      this.setState({show: true});
    }, 50);
    this.setState({
      firstTimeout: window.setTimeout(
        () => {
          this.setState({show: false});
        },
        this.props.timeout ? this.props.timeout - 300 : 2700
      ),
      secondTimeout: window.setTimeout(
        () => {
          this.props.requestClose();
        },
        this.props.timeout ? this.props.timeout : 3000
      ),
    });
  }

  getStylesToHandleSidebar() {
    const sidebar = document.getElementById('spekit-sidebar');
    return sidebar && sidebar.style.display !== 'none'
      ? {position: 'relative' as 'relative', right: '420px'}
      : {};
  }

  removeNotification = (e: React.MouseEvent): any => {
    e.stopPropagation();
    if (this.state.firstTimeout) {
      window.clearTimeout(this.state.firstTimeout);
    }
    if (this.state.secondTimeout) {
      window.clearTimeout(this.state.secondTimeout);
    }
    window.setTimeout(() => {
      this.props.requestClose();
    }, 300);
  };

  getColorStyles() {
    const {error, warning} = this.props;
    if (error) {
      return {
        backgroundColor: colors.error[25],
        color: colors.error[700],
        border: `1px solid ${colors.error[600]}`,
      };
    }
    if (warning) {
      return {
        backgroundColor: colors.warning[25],
        color: colors.warning[700],
        border: `1px solid ${colors.warning[600]}`,
      };
    }
    return {
      backgroundColor: colors.success[25],
      color: colors.success[700],
      border: `1px solid ${colors.success[600]}`,
    };
  }

  getAlertIcons() {
    if (this.props.error) {
      return RiErrorWarningLine;
    }
    return MdOutlineWarningAmber;
  }
  render() {
    const {error, warning} = this.props;
    let lottieStyles = {
      display: 'inline-block',
      verticalAlign: 'middle',
    };
    return (
      <Flex
        onClick={this.props.onClick}
        justifyContent='space-between'
        alignItems='center'
        w={300}
        borderRadius={8}
        padding={16}
        boxShadow='0px 0px 20px 2px rgba(0,0,0,0.2)'
        transition='all 300ms ease-in-out'
        opacity={this.state.show ? '1' : '0'}
        mb={10}
        data-testid='notification-container'
        {...this.getStylesToHandleSidebar()}
        {...this.getColorStyles()}
      >
        {this.props.animation ? (
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: this.props.animation || checkmark,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            style={lottieStyles}
            width={64}
            height={64}
          />
        ) : null}
        <Flex alignItems='center'>
          <Icon
            fontSize='1.25rem'
            mr='0.75rem'
            as={!error && !warning ? RiCheckboxCircleLine : this.getAlertIcons()}
          />
          {this.props.text}
        </Flex>
        <Icon
          fontSize='1.25rem'
          as={RiCloseLine}
          onClick={this.removeNotification}
          cursor='pointer'
        />
      </Flex>
    );
  }
}

class Notifications extends React.Component<NotificationsProps> {
  removeNotification(msg: IFlowNotification) {
    this.props.removeNotification(msg);
  }

  render() {
    let styles = {
      main: {
        position: 'fixed' as 'fixed',
        bottom: '10px',
        right: '20px',
        zIndex: 2147483647,
      },
    };
    return (
      <div style={styles.main}>
        {this.props.notifications.map((msg, index) => {
          return (
            <Notification
              error={msg.error}
              warning={msg.warning}
              requestClose={this.removeNotification.bind(this, msg)}
              animation={msg.animation}
              text={msg.text}
              timeout={msg.timeout}
              key={`${index} - ${msg.text}`}
            />
          );
        })}
      </div>
    );
  }
}

const onNotify = function (actions: Actions, store: Store, commsSendTobackground: any) {
  addNotification = actions.addNotification;
  _store = store;
  _commsSendTobackground = commsSendTobackground;
};

const notify = function (notification: IFlowNotification, fromCurrentTab?: boolean) {
  if (_store !== null && addNotification !== null) {
    if (!notification.text) {
      console.error('notification must have text at least.');
    } else {
      if (!!_commsSendTobackground) {
        if (fromCurrentTab) {
          _store.dispatch(addNotification(notification));
        } else {
          _commsSendTobackground.showNotification(notification);
        }
      } else {
        _store.dispatch(addNotification(notification));
      }
    }
  }
};

export default {notify, Notifications, onNotify};
