import {capture} from '../logging';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';

const checkTaskStatus = (jobId) => {
  let poll = function () {
    return fetch('/api/tasks/' + jobId + '/', {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    }).then((response) => response.json());
  };
  let test = function (result) {
    return new Promise((res, rej) => {
      if (
        result.status &&
        (result.status.toLowerCase() === 'queued' ||
          result.status.toLowerCase() === 'processing')
      ) {
        res(false);
      } else if (result.status && result.status.toLowerCase() === 'completed') {
        res(true);
      } else {
        rej('sync failed');
      }
    });
  };
  return new Promise((res, rej) => {
    let pollAndTest = function () {
      poll()
        .then((response) => test(response))
        .then((jobDone) => {
          if (jobDone) {
            res();
          } else {
            setTimeout(() => {
              pollAndTest();
            }, 3000);
          }
        })
        .catch((err) => {
          capture(err);
          rej(err);
        });
    };
    pollAndTest();
  });
};

export default checkTaskStatus;
