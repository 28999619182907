import LoginLayout from '../../components/LoginLayout';
import {Link} from 'react-router-dom';
import {Flex, Heading, Text, Link as DSLink} from 'spekit-ui';

const PasswordExpiredLink = () => {
  return (
    <LoginLayout hideQuote hideImage>
      <Flex direction='column' px={144} maxW={450} pt={176} gap={20}>
        <Heading as='h3' fontWeight='semibold' fontSize='24px' textAlign='center'>
          Invalid Reset Password Link
        </Heading>
        <Text variant='body2' fontWeight='normal' textAlign='center'>
          This reset password link has already been used or the link is invalid. Please
          generate a new reset password link from{' '}
          <DSLink data-testid='forgot-password-link' as={Link} to='/reset-password'>
            forgot password link.
          </DSLink>
        </Text>
      </Flex>
    </LoginLayout>
  );
};
export default PasswordExpiredLink;
