import React from 'react';

import Select from 'react-select';

import Dropdown from '../Dropdown/Dropdown';

const EnhancedDropDown: any = Dropdown(Select);

export interface Props {
  teamOptions: Array<string>;
  selectedTeams: Array<string>;
  onTeamsSelect: CallableFunction;
}

const AnalyticsTeamSelectorDropdown: React.FC<Props> = ({
  teamOptions,
  selectedTeams,
  onTeamsSelect,
}) => {
  const getOptions = () =>
    teamOptions.map((team) => {
      return {label: team, value: team};
    });

  const getValues = () =>
    selectedTeams.map((team) => {
      return {label: team, value: team};
    });

  const handleTeamChange = (newValue: any, actionMeta: any) => {
    let {action, option} = actionMeta;

    if (action === 'select-option') {
      let temp = [option.label, ...selectedTeams];
      onTeamsSelect(temp);
    }

    if (action === 'deselect-option') {
      let temp = [...selectedTeams].filter((team) => team !== option.label);
      onTeamsSelect(temp);
    }
  };

  return (
    <EnhancedDropDown
      isClearable={false}
      hideSelectedOptions={false}
      defaultOptions
      isMulti
      filterLabel='Team'
      noOptionMessage='No teams found'
      placeholder='Select a team'
      teamStyles
      handleChange={handleTeamChange}
      options={getOptions()}
      value={getValues()}
    />
  );
};

export default AnalyticsTeamSelectorDropdown;
