import React from 'react';
import {Box} from '../Layout';
import {EncapsulatedInput} from '../Components';
import {EncapsulatedInputProps} from '../Components/EncapsulatedInput/EncapsulatedInput';

export interface IColorInputProps {
  value: string;
  setValue: (v: string) => void;
  error?: string;
}

export const getColorPalette = (color: string) => {
  return {
    // !important is applied to override styles being injected from
    // extension that mess up with updated colors
    25: `color-mix(in srgb, ${color}, #fff 95%) !important`,
    50: `color-mix(in srgb, ${color},  #fff 90%) !important`,
    100: `color-mix(in srgb, ${color}, #fff 80%) !important`,
    200: `color-mix(in srgb, ${color}, #fff 70%) !important`,
    300: `color-mix(in srgb, ${color}, #fff 60%) !important`,
    400: `color-mix(in srgb, ${color}, #fff 50%) !important`,
    500: `${color} !important`,
    600: `color-mix(in srgb, ${color}, #000 10%) !important`,
    700: `color-mix(in srgb, ${color}, #000 20%) !important`,
    800: `color-mix(in srgb, ${color}, #000 30%) !important`,
    900: `color-mix(in srgb, ${color}, #000 40%) !important`,
  };
};

export const ColorInput = ({
  errorMessage,
  value,
  onChange,
  ...rest
}: EncapsulatedInputProps) => {
  const colorDisplay = !errorMessage ? (
    <Box w={5} h={5} borderRadius='full' bgColor={value} />
  ) : undefined;

  const handleColorInputChange = (inputValue: string) => {
    const sanitizedColorValue = `#${inputValue.replace(/#/g, '')}`;
    if (sanitizedColorValue === value) return;
    onChange(sanitizedColorValue);
  };

  return (
    <EncapsulatedInput
      value={value}
      testId='color-input'
      inputControl={colorDisplay}
      isInvalid={!!errorMessage}
      placeholder='Enter hex code'
      errorMessage={errorMessage}
      onChange={handleColorInputChange}
      {...rest}
    />
  );
};
