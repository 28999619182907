import React from 'react';
import Select from 'react-select';
import {Dropdown} from 'spekit-ui';
import {RuleSelectorSelectProps} from '../types';
const EnhancedDropDown: any = Dropdown(Select);

interface Props {
  onSelect: (value: RuleSelectorSelectProps) => void;
  monitorScrollElement: any;
  value: string | null;
  disabled: boolean;
}

const options = [
  {
    value: 'url-contains',
    label: 'URL contains',
    type: 'url',
  },
  {
    value: 'any-url',
    label: 'Any URL',
    type: 'anywhere',
  },
  // {
  //   value: 'salesforce',
  //   label: 'Salesforce',
  //   type: 'salesforce',
  // },
];

const RuleSelectorDropdown: React.FC<Props> = ({
  onSelect,
  monitorScrollElement,
  value,
  disabled,
}) => {
  const handleChange = (newValue: any, actionMeta: any) => {
    let {action} = actionMeta;

    if (action === 'select-option') {
      onSelect({label: newValue.label, value: newValue.value, type: newValue.type});
    }
  };
  return (
    <EnhancedDropDown
      isClearable={false}
      defaultOptions
      isSearchable={false}
      noOptionMessage='No rules found'
      placeholder='Select'
      centeredLabelStyles
      menuPosition='fixed'
      options={options}
      isDisabled={disabled}
      menuPortalTarget={document.querySelector('body')}
      closeMenuOnScroll={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (
          monitorScrollElement &&
          monitorScrollElement.current &&
          event.target.isSameNode(monitorScrollElement.current)
        ) {
          return true;
        }
        return false;
      }}
      handleChange={handleChange}
      value={options.filter((option) => option.type === value)[0]}
    />
  );
};

export default RuleSelectorDropdown;
