type TActionType = 'SHOW_CREATE_SPEK_MODAL' | 'HIDE_CREATE_SPEK_MODAL';

interface IAction {
  type: TActionType;
}

const initialState = {
  isShowing: false,
};

function spekModalReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case 'SHOW_CREATE_SPEK_MODAL':
      return {
        ...state,
        isShowing: true,
      };
    case 'HIDE_CREATE_SPEK_MODAL':
      return {
        ...state,
        isShowing: false,
      };
    default:
      return state;
  }
}

export default spekModalReducer;
