/* eslint no-underscore-dangle: 0 */
const APP_TOKEN_KEY = 'app-token';
const EXT_TOKEN_KEY = 'token';
export const OUTLOOK_TOKEN_KEY = 'outlook-token';

/**
 * Helper method to get the HTTP-X-Client name.
 *
 * @param xClient {string}
 * @returns {string}
 */
export const getTokenKey = (xClient: string): string => {
  if (xClient === 'outlook') {
    return OUTLOOK_TOKEN_KEY;
  } else if (xClient === 'ext') {
    return EXT_TOKEN_KEY;
  }
  return APP_TOKEN_KEY;
};

/**
 * Helper method to decode jwt token string.
 *
 * @param token {string}
 * @returns {object | null}
 */
export function jwtDecodeToken(token: string): Record<string, string> | null {
  try {
    if (token.split('.').length !== 3 || typeof token !== 'string') {
      return null;
    }
    const payload: string = token.split('.')[1];
    const padding: string = '='.repeat((4 - (payload.length % 4)) % 4);
    const base64: string = payload.replace('-', '+').replace('_', '/') + padding;
    const decoded = JSON.parse(atob(base64)) as Record<string, string>;
    return decoded;
  } catch (error) {
    // Return null if something goes wrong
    return null;
  }
}

// helper with Chrome storage

export const setItem = (key: string, value: string) =>
  new Promise<void>((res, rej) => {
    try {
      if (key === OUTLOOK_TOKEN_KEY) {
        localStorage.setItem(key, value);
        res();
      } else {
        chrome.storage.local.set({[key]: value}, () => {
          res();
        });
      }
    } catch (error) {
      rej(error);
    }
  });

export const getItem = (itemKey: string) =>
  new Promise<string | null>((res, rej) => {
    try {
      if (itemKey === OUTLOOK_TOKEN_KEY) {
        const value = localStorage.getItem(itemKey);
        res(value);
      } else {
        chrome.storage.local.get(itemKey, (item: {[key: string]: string}) => {
          res(item[itemKey]);
        });
      }
    } catch (error) {
      rej(error);
    }
  });

export const removeItem = (keys: string) =>
  new Promise<void>((res, rej) => {
    try {
      if (keys === OUTLOOK_TOKEN_KEY) {
        localStorage.removeItem(keys);
        res();
      } else {
        chrome.storage.local.remove(keys, () => {
          res();
        });
      }
    } catch (error) {
      rej(error);
    }
  });

interface ITokenStorage {
  _token: string | null;
  setToken(key: string, token: string): Promise<void>;
  getToken(key: string): Promise<string | undefined>;
  deleteToken(key: string): Promise<void>;
  _isExtTokenOrOutlook(key: string): boolean;
}

export const tokenStorage = <ITokenStorage>{
  _token: null,

  _isExtTokenOrOutlook(key) {
    return key === EXT_TOKEN_KEY || key === OUTLOOK_TOKEN_KEY;
  },

  async setToken(key, token) {
    if (this._isExtTokenOrOutlook(key)) {
      await setItem(key, token);
    } else {
      this._token = token;
    }
  },

  async getToken(key) {
    if (this._isExtTokenOrOutlook(key)) {
      const token = await getItem(key);
      return token;
    }
    return this._token;
  },

  async deleteToken(key) {
    if (this._isExtTokenOrOutlook(key)) {
      await removeItem(key);
    }
    this._token = null;
  },
};
