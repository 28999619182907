import React from 'react';
import {Table as ChakraTable, TableProps} from '@chakra-ui/react';
import {FONT_FAMILY} from '../Typography';

export type TTableProps = TableProps;

export const TableConfig = {
  parts: ['th', 'td', 'tr'],
  baseStyle: {
    th: {
      fontFamily: FONT_FAMILY,
    },
    td: {
      fontFamily: FONT_FAMILY,
    },
    tr: {
      fontFamily: FONT_FAMILY,
    },
  },
  variants: {
    simple: {
      tr: {
        borderColor: 'neutral.200',
      },
      th: {
        fontWeight: 'normal',
        color: 'neutral.800',
      },
      td: {
        color: 'neutral.900',
      },
    },
  },
  sizes: {
    md: {
      th: {
        px: 24,
        py: 12,
        fontSize: 'tiny',
      },
      td: {
        p: 24,
        fontSize: 'small',
      },
    },
  },
  defaultProps: {},
};
export const Table = (props: TTableProps) => <ChakraTable {...props} />;
