import React from 'react';
import {Icon, TIconProps} from '../design-system/Icon';

export const SpreadsheetIcon = (props: TIconProps) => (
  <Icon
    {...props}
    viewBox='0 0 67 86'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='spreadsheet-icon'
  >
    <path
      d='M56.5252 19.6088C54.3485 19.6067 52.2615 18.741 50.7223 17.2018C49.1831 15.6626 48.3175 13.5757 48.3154 11.3989V0H11.63C8.78843 0 6.06324 1.12884 4.05394 3.13814C2.04464 5.14743 0.915833 7.87256 0.915833 10.7141V75.3017C0.920052 78.1405 2.05073 80.8616 4.05957 82.8675C6.0684 84.8734 8.79118 86 11.63 86H55.6652C57.0725 86.0005 58.4662 85.7238 59.7665 85.1856C61.0669 84.6474 62.2485 83.8583 63.2438 82.8633C64.2391 81.8684 65.0287 80.6871 65.5674 79.3869C66.1061 78.0867 66.3833 76.6932 66.3833 75.2859V19.5929L56.5252 19.6088Z'
      fill='#ECFDF3'
    />
    <path
      d='M66.3833 19.6088H56.5252C54.3484 19.6067 52.2615 18.741 50.7223 17.2018C49.1831 15.6626 48.3174 13.5757 48.3153 11.3989V0L66.3833 19.6088Z'
      fill='#039855'
    />
    <path
      d='M48.2517 64.0222H19.0475C16.5193 64.0222 14.4648 61.5059 14.4648 58.4083V37.2308C14.4648 34.1372 16.5193 31.6209 19.0475 31.6209H48.2517C50.7799 31.6209 52.8343 34.1372 52.8343 37.2308V58.4083C52.8343 61.5059 50.7799 64.0222 48.2517 64.0222ZM19.0475 34.4876C17.8132 34.4876 16.8059 35.7179 16.8059 37.2308V58.4083C16.8059 59.9213 17.8132 61.1516 19.0475 61.1516H48.2517C49.4859 61.1516 50.4893 59.9213 50.4893 58.4083V37.2308C50.4893 35.7179 49.4859 34.4876 48.2517 34.4876H19.0475Z'
      fill='#039855'
    />
    <path d='M51.6638 51.2695H15.6353V54.1402H51.6638V51.2695Z' fill='#039855' />
    <path d='M51.6638 41.4273H15.6353V44.298H51.6638V41.4273Z' fill='#039855' />
    <path d='M42.4387 33.0543H39.5681V62.5849H42.4387V33.0543Z' fill='#039855' />
    <path d='M27.7312 33.0543H24.8605V62.5849H27.7312V33.0543Z' fill='#039855' />
  </Icon>
);
