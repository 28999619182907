import React from 'react';
import {
  PopoverCloseButton as ChakraPopoverCloseButton,
  PopoverCloseButtonProps,
} from '@chakra-ui/react';
import {MdClose} from 'react-icons/md';

export type TPopoverCloseButtonProps = PopoverCloseButtonProps;

export const PopoverCloseButton = (props: TPopoverCloseButtonProps) => (
  <ChakraPopoverCloseButton {...props} as={MdClose} />
);
