import {lazy, Suspense, useEffect} from 'react';
import {IFlagState, ISession} from 'spekit-types';
import {useSelector} from 'react-redux';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';

import {
  isComponentVisible,
  TElementWithVisibility,
} from 'spekit-shared-components/src/utils/liveAssertUtils';

const ListPage = lazy(() => import('./pages/List/ListPage'));
const View = lazy(() => import('./pages/View'));

interface IState {
  flag: IFlagState;
  layout: {
    me: ISession;
  };
}

const KnowledgeCheckRoutes = () => {
  const flags = useSelector((state: IState) => state.flag);
  const role = useSelector((state: IState) => state.layout.me.role);
  const {hasQuizFlag} = flags;
  const location = useLocation();
  const isViewer = role === 'Viewer';

  useEffect(() => {
    setTimeout(() => {
      if (
        location.pathname === '/app/knowledge-checks/create' &&
        hasQuizFlag &&
        !isViewer
      ) {
        const el = document.getElementById(
          'chakra-modal-Question-create'
        ) as TElementWithVisibility;
        isComponentVisible({
          element: el,
          message: 'KC create modal is not visible',
          tag: 'KC-create-modal',
        });
      }
    }, 2000);
  }, [location.pathname, isViewer, hasQuizFlag]);

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route path='/app/knowledge-checks/view/:knowledgeCheckId' exact>
          {hasQuizFlag ? <View /> : <Redirect to='/app/wiki/topics' />}
        </Route>
        <Route path='/app/knowledge-checks'>
          {hasQuizFlag && !isViewer ? <ListPage /> : <Redirect to='/app/wiki/topics' />}
        </Route>
      </Switch>
    </Suspense>
  );
};

export default KnowledgeCheckRoutes;
