import React from 'react';
import {PropagateLoader, PulseLoader} from 'react-spinners';
import {TColors, colors} from '../theme/Foundations/color';

export interface ILoaderProps {
  color?: keyof TColors;
  colorKey?: number;
  size?: number | string;
  loading?: boolean;
  speedMultiplier?: number;
  height?: number | string;
  width?: number | string;
  radius?: number | string;
  margin?: number | string;
  variant?: 'propagate' | 'pulse';
}

export const Loader: React.FC<ILoaderProps> = ({
  color = 'primary',
  colorKey = 500,
  size = 24,
  loading = true,
  speedMultiplier = 1,
  variant = 'propagate',
  ...rest
}) => {
  let colorHex = colors[color][colorKey] as string;

  if (!colorHex) colorHex = colors.primary['500'];

  const args = {
    color: colorHex,
    size,
    loading,
    speedMultiplier,
  };

  if (variant === 'propagate') {
    return <PropagateLoader {...args} {...rest} />;
  }
  if (variant === 'pulse') {
    return <PulseLoader {...args} {...rest} />;
  }

  return null;
};
