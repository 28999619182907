import React from 'react';
import {Flex, Icon, Text} from 'spekit-ui';
import {FaCircle} from 'react-icons/fa';

export interface ISpotlightStatusIndicatorProps {
  status: 'saving' | 'published' | 'draft' | 'expired';
}

const SpotlightStatusIndicator = ({status}: ISpotlightStatusIndicatorProps) => {
  const getStatusLabel = () => {
    const statusLabelMap = {
      published: 'Live',
      saving: 'Saving draft...',
      draft: 'Draft saved',
      expired: 'Expired',
    };
    return statusLabelMap[status];
  };
  const getStatusColor = () => {
    const colorMapper = {
      published: 'success.400',
      expired: 'error.400',
      draft: 'neutral.600',
      saving: 'neutral.600',
    };

    return colorMapper[status];
  };

  return (
    <Flex alignItems='center' mr='50px' ml='auto'>
      <Icon
        color={getStatusColor()}
        as={FaCircle}
        mr={8}
        ml={8}
        w='8px'
        h='8px'
        data-testid={status === 'expired' ? 'expired-indicator' : 'status-indicator'}
      />
      <Text variant='caption1' letterSpacing={0}>
        {getStatusLabel()}
      </Text>
    </Flex>
  );
};

export default SpotlightStatusIndicator;
