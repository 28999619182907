import React from 'react';
import Fontawesome from './fontawesome.tsx';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

class CheckBox extends React.Component {
  handleCheck = (evt) => {
    if (!this.props.allowPropagation) {
      evt.stopPropagation();
    }
    if (typeof this.props.onToggle === 'function' && !this.props.disabled) {
      this.props.onToggle(!this.props.value);
    }
  };
  render() {
    let styles = {
      main: {
        display: 'inline-block',
        verticalAlign: 'middle',
        color: '#FFF',
        fontSize: '14px',
        cursor: this.props.disabled ? 'not-allowed' : 'pointer',
      },
      checkmark: {
        display: 'inline-block',
        verticalAlign: 'middle',
        border: this.props.disabled ? '1px solid #C4C3D0' : '2px solid #3576E8',
        borderRadius: this.props.square ? '2px' : '50%',
        marginRight: '0.5em',
        width: this.props.small ? '18px' : '24px',
        height: this.props.small ? '18px' : '24px',
        fontSize: this.props.small ? '12px' : '16px',
        lineHeight: this.props.small ? '20px' : '24px',
        textAlign: 'center',
        overflow: 'hidden',
        cursor: this.props.disabled ? 'not-allowed' : 'pointer',
        color: this.props.disabled ? '#C4C3D0' : '#FFF',
        backgroundColor:
          !this.props.disabled && this.props.value ? '#3576E8' : 'rgba(0,0,0,0)',
        transition: 'all 300ms ease-in-out',
      },
    };
    return (
      <div style={{...styles.main, ...this.props.style}}>
        <div
          style={{...styles.checkmark, ...this.props.checkboxStyle}}
          onClick={this.handleCheck}
          data-testid={this.props['data-testid']}
        >
          {this.props.value ? <Fontawesome name={faCheck} /> : null}
        </div>
        <span onClick={this.handleCheck} style={this.props.labelStyle}>
          {this.props.label}
        </span>
      </div>
    );
  }
}

export default CheckBox;
