import React from 'react';
import notifications from './notifications';

const {notify} = notifications;

const prodExtensionID = 'nbphjjmibhopfgkkbpoggemjnihnchhk';
const alphaExtensionID = 'jonpflfloggpaeijlegambamlljamkbb';
const betaExtensionID = 'nhjcjjjkifikpfpkelkibjaldjapodmh';
const extensionIds = [prodExtensionID, alphaExtensionID, betaExtensionID];

export const getExtIdBySpekitElement = () => {
  const isInstalled = window.document.getElementById('spekit_extension');
  if (isInstalled) {
    const extId = isInstalled.getAttribute('data-extension-id');
    return extId;
  }
  return null;
};

export const getExtIdByImageUrl = (id) => {
  return fetch('chrome-extension://' + id + '/images/logo.png', {method: 'HEAD'})
    .then((r) => {
      return id;
    })
    .catch((e) => {
      return false;
    });
};

const getExtensionId = async () => {
  const extId = getExtIdBySpekitElement();
  if (extId) {
    return extId;
  }

  if (navigator.userAgent.toLowerCase().indexOf('chrome') < 0) {
    return null;
  }

  for (const id of extensionIds) {
    const extIdByImage = await getExtIdByImageUrl(id);
    if (extIdByImage) {
      return extIdByImage;
    }
  }
  return null;
};

const extensionInstalled = () => getExtensionId();

class GetExtensionBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extensionInstalled: true,
    };
    this.checkChromeExtension = this.checkChromeExtension.bind(this);
  }
  componentDidMount() {
    this.checkChromeExtension();
  }
  async checkChromeExtension() {
    const extId = await getExtensionId();
    if (!extId) {
      this.setState({extensionInstalled: false});
    }
  }
  render() {
    if (this.state.extensionInstalled) {
      if (this.props.alt) {
        return this.props.alt;
      }
      return null;
    }
    let styles = {
      main: {
        display: 'inline-block',
        verticalAlign: 'top',
        cursor: 'pointer',
        fontSize: '14px',
        padding: '13px',
        color: '#FFF',
        backgroundColor: '#3576E8',
        margin: '18px 5px 0px',
        borderRadius: '23px',
      },
    };
    return (
      <a
        href='https://chrome.google.com/webstore/detail/spekit/nbphjjmibhopfgkkbpoggemjnihnchhk?hl=en-US'
        target='_blank'
        rel='noopener noreferrer'
      >
        <div style={styles.main}>Download chrome extension</div>
      </a>
    );
  }
}

export {extensionInstalled, getExtensionId};
export default GetExtensionBtn;
