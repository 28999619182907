import {retrieve, create} from '../utils/APIHelpers';
import {LicenseStatus} from './license.type';

export const getLicenseStatus = async (): Promise<LicenseStatus> => {
  return await retrieve(`/api/v1/companies/license_status/`);
};

export const updateMessageStatus = async (): Promise<unknown> => {
  return await create(`/api/companies/shown_license_invalidity_message/`);
};
