import {fetchMiddleWare as fetch} from './fetchMiddleWare';
import {capture} from './logging';

export interface FileResponse {
  id: string;
  url: {
    view: string;
  };
}

export const upload = function (file: File): Promise<FileResponse> {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('file', file);
    fetch('/api/media/handle_upload/', {
      credentials: 'include',
      method: 'post',
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    })
      .then((res) => {
        if (res.status === 413) {
          throw new Error(JSON.stringify(413));
        } else if (res.status === 403) {
          throw new Error(JSON.stringify(403));
        } else {
          return res.json();
        }
      })
      .then((res) => {
        if (res.url && res.url.view) {
          resolve(res);
        } else {
          reject(JSON.stringify(res.success));
        }
      })
      .catch((err) => {
        reject(err);
        capture(err);
      });
  });
};
