import {logging} from 'spekit-datalayer';

export const sendMsgToSW = async (type: string, data: object) => {
  // Post a message to the service worker
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready;
      registration.active?.postMessage({type: type, data: data});
    } catch (err) {
      logging.capture(err);
      throw err;
    }
  }
};
