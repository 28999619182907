import React, {forwardRef} from 'react';

export interface SimpleAnchorProps {
  children: React.ReactNode;
  href: string;
  openInSidebar?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    href: string
  ) => void;
  className?: string; // For CKEditor
  target?: string;
  tabIndex?: number;
}
const spekitLinks = ['spekit.co', 'spekit.dev', 'app.spektacular.ninja'];

// function that prepends https:// on Spekit links
// the wiki api returns spek links with http, so we need to make sure they're https
export const prependHttps = (link: string) => {
  // allow localhost to function normally
  if (!link || link.includes('localhost')) return link;

  const isSpekitLink = spekitLinks.some((spekitLink) => link.includes(spekitLink));
  const hasHttpProtocol = /^http:\/\//i.test(link);

  // make sure spekit links are https
  if (isSpekitLink && hasHttpProtocol) {
    return link.replace(/^http:\/\//i, 'https://');
  }

  // add https if the link doesn't start with http or https
  // some customers need http links, so we only prepend https if the link is spekit.co
  if (!link.startsWith('http') && !link.startsWith('mailto')) {
    return `https://${link}`;
  }

  return link;
};

// SimpleAnchor component
export const SimpleAnchor = forwardRef<HTMLAnchorElement, SimpleAnchorProps>(
  (props, ref) => {
    const {children, href, openInSidebar, className, target, tabIndex} = props;
    const safeUrl = prependHttps(href);

    // There are rare cases where an href is missing after a Spek is migrated.
    // In these cases we just return the children without an anchor tag
    if (!safeUrl) return <>{children}</>;

    // return an anchor tag with the safe url
    return (
      <a
        tabIndex={tabIndex}
        className={className}
        href={safeUrl}
        onClick={(e) => openInSidebar && openInSidebar(e, safeUrl)}
        rel='noopener noreferrer'
        target={target || '_blank'}
        ref={ref}
      >
        {children}
      </a>
    );
  }
);
