import {sendMsgToSW} from './swBrowserMsgHandlers';
import {
  companyListener,
  userListener,
  startRefreshInterval,
  stopRefreshInterval,
} from '../ablyListeners';
import {
  logging,
  isJwtTokenExpired,
  authTokenRefreshCall,
  LocalStorage,
  SW_USER_DATA_MSG_TYPE,
  ABLY_COMPANY_MSG_TYPE,
  ABLY_USER_MSG_TYPE,
  SW_START_REFRESH_INTERVAL_MSG_TYPE,
  SW_STOP_REFRESH_INTERVAL_MSG_TYPE,
  SW_STOP_ABLY_MSG_TYPE,
} from 'spekit-datalayer';

const {getItem, setItem} = LocalStorage;
const EXT_TOKEN_KEY = 'extToken'; // this key is being used in webapp only

export const handleSwMsg = (event: MessageEvent) => {
  const {data} = event;
  switch (data.type) {
    case ABLY_COMPANY_MSG_TYPE:
      return companyListener(data.msg);
    case ABLY_USER_MSG_TYPE:
      return userListener(data.msg);
    case SW_START_REFRESH_INTERVAL_MSG_TYPE:
      return startRefreshInterval();
    case SW_STOP_REFRESH_INTERVAL_MSG_TYPE:
      return stopRefreshInterval();
    default:
      return false;
  }
};

interface IUserData {
  id: string;
  company: {id: string};
}

export const sendUserDataToSW = async (userData: IUserData) => {
  // Check if the service worker is supported
  let extToken = getItem(EXT_TOKEN_KEY);
  if (!extToken || isJwtTokenExpired(extToken)) {
    const res = await authTokenRefreshCall('', 'ext', '/api/v1/ably/auth');
    const responseData = await res.json();
    extToken = responseData['token'];
    setItem(EXT_TOKEN_KEY, extToken);
  }
  const data = {
    token: extToken,
    companyId: userData.company.id,
    userId: userData.id,
  };
  await sendMsgToSW(SW_USER_DATA_MSG_TYPE, data);
};

export const attachSwMsgListener = async () => {
  // attaching listener on service worker messages
  let isSwMsgListenerAttached = false;
  try {
    await navigator.serviceWorker.ready;
    navigator.serviceWorker.addEventListener('message', handleSwMsg);
    isSwMsgListenerAttached = true;
  } catch (err) {
    logging.capture(err);
    throw err;
  }
  return isSwMsgListenerAttached;
};

export const stopAbly = () => {
  sendMsgToSW(SW_STOP_ABLY_MSG_TYPE, {});
};
