import React from 'react';
import Select from 'react-select';
import {Dropdown} from 'spekit-ui';
import {Select as SelectProps} from '../types';
const EnhancedDropDown: any = Dropdown(Select);

interface Props {
  onSelect: (value: SelectProps) => void;
  monitorScrollElement: any;
  value: string;
  disabled: boolean;
}

const options = [
  {
    label: 'Path is',
    value: 'exactly',
  },
  {
    label: 'Path contains',
    value: 'contains',
  },
  {
    label: 'Path does NOT contain',
    value: 'not-contains',
  },
  {
    label: 'Path is NOT exactly',
    value: 'not-exactly',
  },
];

const PathDropdown: React.FC<Props> = ({
  onSelect,
  monitorScrollElement,
  value,
  disabled,
}) => {
  const handleChange = (newValue: any, actionMeta: any) => {
    let {action} = actionMeta;

    if (action === 'select-option') {
      onSelect({label: newValue.label, value: newValue.value});
    }
  };

  return (
    <EnhancedDropDown
      isClearable={false}
      defaultOptions
      isSearchable={false}
      noOptionMessage='No rules found'
      placeholder='Select'
      centeredLabelStyles
      menuPosition='fixed'
      options={options}
      isDisabled={disabled}
      menuPortalTarget={document.querySelector('body')}
      value={options.filter((option) => option.value === value)[0]}
      closeMenuOnScroll={(event: React.ChangeEvent<HTMLInputElement>) => {
        if (
          monitorScrollElement &&
          monitorScrollElement.current &&
          event.target.isSameNode(monitorScrollElement.current)
        ) {
          return true;
        }
        return false;
      }}
      handleChange={handleChange}
    />
  );
};

export default PathDropdown;
