import React from 'react';
import {
  Modal as ChakraModal,
  ModalProps,
  ModalOverlay,
  ModalContent,
  HTMLChakraProps,
  ModalOverlayProps,
} from '@chakra-ui/react';
import {FONT_FAMILY} from '../Typography';

export type TModalProps = ModalProps & {
  containerProps?: HTMLChakraProps<'div'>;
  shouldWrapOverSidebar?: boolean;
  overlayProps?: ModalOverlayProps;
};

export const ModalConfig = {
  parts: ['overlay', 'dialog', 'header', 'body', 'footer', 'closeButton'],
  baseStyle: {
    overlay: {
      bg: 'alpha.100',
    },
    dialog: {
      p: 24,
    },
    header: {
      color: 'neutral.800',
      fontWeight: 'semibold',
      fontFamily: FONT_FAMILY,
      fontSize: 'large',
      p: 0,
    },
    body: {
      fontSize: 'small',
      color: 'neutral.800',
      fontWeight: 'normal',
      fontFamily: FONT_FAMILY,
      p: 0,
      mt: 16,
    },
    footer: {
      mt: 24,
      fontFamily: FONT_FAMILY,
    },
    closeButton: {
      cursor: 'pointer',
      color: 'neutral.500',
      top: 22,
      right: 20,
      _hover: {
        background: 'neutral.25',
      },
    },
  },
  variants: {
    carousel: {
      dialog: {
        p: 0,
        m: 0,
      },
      body: {
        mt: 0,
      },
    },
    file: {
      dialog: {
        p: 0,
      },
      body: {
        mt: 16,
        p: '0px 24px 24px 24px',
      },
    },
  },
  sizes: {
    xsm: {
      dialog: {
        maxW: '224px',
      },
      header: {
        maxW: '168px',
      },
    },
    sm: {
      dialog: {
        maxW: '448px',
      },
      header: {
        maxW: '392px',
      },
    },
    md: {
      dialog: {
        maxW: '672px',
      },
      header: {
        maxW: '616px',
      },
    },
    lg: {
      dialog: {
        maxW: '896px',
      },
      header: {
        maxW: '832px',
      },
    },
    xl: {
      dialog: {
        maxW: '1120px',
      },
      header: {
        maxW: '1064px',
      },
    },
  },
  defaultProps: {},
};

export const Modal = ({
  shouldWrapOverSidebar,
  containerProps,
  overlayProps,
  ...props
}: TModalProps) => {
  const styles = shouldWrapOverSidebar
    ? {zIndex: 2147483647, ...containerProps}
    : containerProps;

  return (
    <ChakraModal
      scrollBehavior='inside'
      isCentered
      closeOnOverlayClick={false}
      {...props}
    >
      <ModalOverlay
        zIndex={shouldWrapOverSidebar ? 2147483647 : undefined}
        {...overlayProps}
      />
      <ModalContent containerProps={styles} justifyContent='center'>
        {props.children}
      </ModalContent>
    </ChakraModal>
  );
};
