import React from 'react';
import {TCustomField} from 'spekit-types';
import {DSModal as Modal, ModalBody, ModalCloseButton, ModalHeader} from 'spekit-ui';
import {CustomFieldsForm} from '../CustomFieldsForm';

interface ICreateFieldModal {
  field?: TCustomField;
  handleClose: () => void;
  onSave: () => void;
}

export const EditFieldModal = ({onSave, handleClose, field}: ICreateFieldModal) => {
  return (
    <Modal
      size='md'
      isOpen={!!field}
      onClose={handleClose}
      scrollBehavior='outside'
      containerProps={{alignItems: 'baseline'}}
    >
      <ModalHeader>Edit custom field</ModalHeader>
      <ModalCloseButton />
      <ModalBody p={1}>
        <CustomFieldsForm field={field} onSave={onSave} handleModalClose={handleClose} />
      </ModalBody>
    </Modal>
  );
};
