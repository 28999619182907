import React from 'react';
import {Skeleton as ChakraSkeleton, SkeletonProps} from '@chakra-ui/react';

export type TSkeletonProps = SkeletonProps;

export const SkeletonConfig = {
  parts: [],
  baseStyle: {},
  variants: {},
  sizes: {},
  defaultProps: {},
};
export const Skeleton = (props: TSkeletonProps) => (
  <ChakraSkeleton startColor='neutral.50' endColor='neutral.100' speed={1.0} {...props} />
);
