import React from 'react';
import {ControlledSelect} from 'spekit-ui';
import {salesforceObjectsAPI} from 'spekit-datalayer';

export const SalesforceObjectPicker = () => {
  const getSalesforceObjects = (inputValue: string) => {
    return salesforceObjectsAPI
      .getSalesforceObjects(inputValue)
      .then((objects) =>
        objects.map(({label, id}) => ({
          label: label,
          value: id,
        }))
      )
      .catch(() => {
        return [];
      });
  };

  return (
    <ControlledSelect
      testId='salesforce-object-picker'
      menuTestId='salesforce-object-picker-menu'
      name='salesforceObjects'
      id='salesforceObjects'
      label='Salesforce Objects'
      labelTooltip='Speks will show up in the Spekit sidebar when opened on a Salesforce record'
      placeholder='Select Salesforce Objects'
      isAsync
      loadOptions={async (searchText: string) => getSalesforceObjects(searchText)}
      cacheOptions
      defaultOptions
      isMulti
    />
  );
};
