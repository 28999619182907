import React from 'react';
import {Breadcrumb as ChakraBreadcrumb, BreadcrumbProps} from '@chakra-ui/react';
import {FONT_FAMILY} from '../Typography';

export type TBreadcrumbProps = BreadcrumbProps;

export const BreadcrumbConfig = {
  parts: ['link', 'item', 'container', 'separator'],
  baseStyle: {
    container: {
      fontFamily: FONT_FAMILY,
    },
    separator: {
      fontWeight: 'normal',
      color: 'primary.700',
      pt: 6,
      pl: 4,
      pr: 4,
    },
    link: {
      fontWeight: 'normal',
      color: 'primary.700',
      fontSize: 'small',
    },
  },
  variants: {},
  sizes: {},
  defaultProps: {},
};
export const Breadcrumb = (props: TBreadcrumbProps) => (
  <ChakraBreadcrumb {...props} id='spekit-breadcrumb' />
);
