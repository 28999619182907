import {
  logos,
  documentIcons,
  faIcons,
  generalIcons,
  graphIcons,
  learningIcons,
  miscIcons,
  moneyIcons,
  processIcons,
  salesIcons,
} from '../TopicIcons';

const iconsInMenu = {
  ...faIcons,
  ...documentIcons,
  ...logos,
  ...generalIcons,
  ...graphIcons,
  ...learningIcons,
  ...miscIcons,
  ...moneyIcons,
  ...processIcons,
  ...salesIcons,
};

const iconMapper = (font?: string | null) => {
  if (font && font in iconsInMenu) {
    return iconsInMenu[font];
  } else {
    return generalIcons['generalBiz_bookmark'];
  }
};

export {iconMapper};
