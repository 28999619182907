import React, {useState} from 'react';

import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import {DSTooltip as Tooltip, Flex} from '../../design-system';
import Fontawesome from '../../fontawesome';
import styles from './multimedia.module.css';
import MultimediaPreview from './multimediaPreview';
import MultimediaActions from './multimediaActions';

export interface Props {
  readOnly: boolean;
  image: null | string;
  imageLoading: boolean;
  embed: string;
  systemImage: string;
  host?: string;
  editMode: boolean;
  handleDeletePreview: () => void;
  handleEmbedVideo: (href: string) => void;
  handleImage: (image: string) => void;
  handleImageLoading: (isLoading: boolean) => void;
  handleSystemImage: (payload: string) => void;
  notify: any;
  track: any;
  screenName: string;
  label?: string;
}

const Multimedia: React.FC<Props> = ({
  readOnly,
  editMode,
  image,
  imageLoading,
  embed,
  systemImage,
  host,
  handleDeletePreview,
  handleEmbedVideo,
  handleImage,
  handleImageLoading,
  handleSystemImage,
  notify,
  screenName,
  track,
  label,
}) => {
  const [embedPopover, setEmbedPopover] = useState(false);
  const [spekitLibrary, setSpekitLibrary] = useState(false);

  const handleLibrary = (isOpen: boolean) => {
    setSpekitLibrary(isOpen);
  };

  const handleEmbed = (isOpen: boolean) => {
    setEmbedPopover(isOpen);
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        {label !== undefined ? label : 'Image or video'}
        <Tooltip
          label={
            <Flex justify='center' direction='column'>
              <span>Max file size 20 MB.</span>
              <span>Image/video must have a 16:9 aspect ratio.</span>
              <span>Recommended image size: 800 x 450px</span>
            </Flex>
          }
          placement='top'
          shouldWrapChildren
        >
          <Fontawesome name={faInfoCircle} />
        </Tooltip>
      </div>
      <div className={styles.multimediaContainer}>
        <div className={readOnly ? styles.multimediaReadable : styles.multimedia}>
          <MultimediaPreview
            image={image}
            imageLoading={imageLoading}
            embed={embed}
            systemImage={systemImage}
            host={host}
            handleEmbed={handleEmbed}
            handleLibrary={handleLibrary}
          />
          {!readOnly ? (
            <MultimediaActions
              editMode={editMode}
              embedPopover={embedPopover}
              spekitLibrary={spekitLibrary}
              handleEmbed={handleEmbed}
              handleLibrary={handleLibrary}
              handleDeletePreview={handleDeletePreview}
              handleEmbedVideo={handleEmbedVideo}
              handleImage={handleImage}
              handleImageLoading={handleImageLoading}
              handleSystemImage={handleSystemImage}
              notify={notify}
              screenName={screenName}
              track={track}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Multimedia;
