import React from 'react';
import {PopoverFooter as ChakraPopoverFooter, PopoverFooterProps} from '@chakra-ui/react';
import {Flex} from '../Layout';

export type TPopoverFooterProps = PopoverFooterProps;

export const PopoverFooter = (props: TPopoverFooterProps) => (
  <ChakraPopoverFooter {...props}>
    <Flex gap={10} justify='flex-end'>
      {props.children}
    </Flex>
  </ChakraPopoverFooter>
);
