import React, {createContext, useContext} from 'react';
import {utils} from 'spekit-datalayer';
import {TWebappTrack, TExtensionTrack} from './types';

interface IAnalyticsProviderProps {
  children: React.ReactNode;
  track: TWebappTrack | TExtensionTrack;
}

interface IAnalyticsContextValue {
  track: (eventName: string, properties?: Record<string, any>) => void;
}

const AnalyticsContext = createContext<IAnalyticsContextValue | undefined>(undefined);

export function AnalyticsProvider({children, track}: IAnalyticsProviderProps) {
  const handleTrack = (eventName: string, properties?: Record<string, any>) => {
    const environment = utils.getEnvironment();

    if (environment === 'webapp') return (track as TWebappTrack)(eventName, properties);

    return (track as TExtensionTrack)(
      eventName,
      properties?.screenName || null,
      properties
    );
  };

  return (
    <AnalyticsContext.Provider value={{track: handleTrack}}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};
