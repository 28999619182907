import {RiMailUnreadLine} from 'react-icons/ri';
import {Flex, Icon, Heading, Text, Link as DSLink} from 'spekit-ui';
import {getExpirationTime} from '../../helpers';
interface IResetPasswordEmailProps {
  expiryTime?: number;
}

const ResetPasswordEmailConfirmation = ({expiryTime}: IResetPasswordEmailProps) => {
  const linkExpiry = expiryTime ? getExpirationTime(expiryTime) : `30 minutes`;
  return (
    <>
      <Heading as='h3' fontWeight='semibold' fontSize='20px' textAlign='center' mt={20}>
        <Flex direction='row' justifyContent='center' alignItems='center' gap='5px'>
          <Icon as={RiMailUnreadLine} />
          Check your email!
        </Flex>
      </Heading>
      <Text variant='body2' fontWeight='normal' textAlign='center' mt={16}>
        If your username is associated with a Spekit account you’ll receive an email with
        a link to reset your password.
      </Text>
      <Text variant='body2' fontWeight='normal' textAlign='center' mt={12} mb={32}>
        The reset password link will expire after {linkExpiry}.
      </Text>
      <Text variant='caption1' fontWeight='normal' textAlign='center' mt={16}>
        If you do not receive an email within {linkExpiry}, please contact your
        Administrator for further assistance, or email us at
        <DSLink href='mailto:support@spekit.co'> support@spekit.co</DSLink>
      </Text>
    </>
  );
};

export default ResetPasswordEmailConfirmation;
