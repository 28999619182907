import React, {useEffect} from 'react';
import {
  Box,
  Divider,
  Flex,
  DSButton as Button,
  Text,
  Icon,
  DSTooltip as Tooltip,
} from 'spekit-ui';
import {
  RuleSelectorSelectProps,
  State,
  Targeting,
  Select as SelectProps,
  Dispatch,
} from '../types';
import DisplaySelector from './DisplaySelector';
import URLInput from './URLInput';
import PathRules from './PathRules';
import {RiAddLine, RiDeleteBin6Line} from 'react-icons/ri';
import SpotlightExpirationRadio from './SpotlightExpirationRadio';
interface IPublishSettingsProps {
  state: State;
  containerRef: any;
  container: any;
  id: string;
  dispatch: Dispatch;
}

const PublishSettings = (props: IPublishSettingsProps) => {
  const {state, containerRef, container, id, dispatch} = props;
  const {domainEdit, domainCreate, domainDelete} = container;

  useEffect(() => {
    if (state.targeting.length === 1 && !state.edit_mode && !state.read_mode) {
      dispatch({
        type: 'SET_TARGETING_RULES',
        payload: [{type: 'url', domain_comparison: 'contains', path_rules: []}],
      });
    }
  }, []);

  const onRuleSelect = async (rule: RuleSelectorSelectProps, index: number) => {
    let targets = [...state.targeting];
    targets[index].type = rule.type;
    targets[index].domain_comparison = 'contains';
    targets[index].css_selector = '';
    targets[index].position = [];
    targets[index].path_rules = [];
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
  };

  const onDomainChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let targets = [...state.targeting];
    targets[index].domain_value = e.target.value;
    if (e.target.value) {
      targets[index].error = false;
      targets[index].errorText = '';
    }
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
  };

  const handleDomainBlur = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let urlRegexExpression =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    let urlRegex = new RegExp(urlRegexExpression);
    let targets = [...state.targeting];
    try {
      if (!targets[index].domain_value) {
        targets[index].error = true;
        targets[index].errorText = 'Enter a valid URL.';
        dispatch({type: 'SET_TARGETING_RULES', payload: targets});
        return;
      }
      let domainProperties = new URL(targets[index].domain_value!);
      let domainOrigin = domainProperties.origin;
      let domainPathname = domainProperties.pathname;
      let domainSearchQuery = domainProperties.search;
      let domainHash = domainProperties.hash;
      if (
        targets[index].domain_value &&
        targets[index].domain_value!.match(urlRegex) &&
        targets[index].path_rules!.length === 0
      ) {
        targets[index].domain_value = domainOrigin;
        targets[index].path_rules!.push({
          comparison: 'contains',
          value: domainPathname + domainSearchQuery + domainHash,
        });
        dispatch({type: 'SET_TARGETING_RULES', payload: targets});
      }
    } catch (e) {
      targets[index].error = true;
      const errorText = 'Must be a valid URL (https://www.example.com)';
      targets[index].errorText = errorText ? errorText : e.message;
      dispatch({type: 'SET_TARGETING_RULES', payload: targets});
    }
  };

  const removeDomainHandler = async (index: number) => {
    let targets = [...state.targeting];
    let deletedTarget = targets.splice(index, 1);

    if (targets.length === 0) {
      dispatch({
        type: 'SET_TARGETING_RULES',
        payload: [{type: 'url', domain_comparison: 'contains', path_rules: []}],
      });
    } else {
      dispatch({type: 'SET_TARGETING_RULES', payload: targets});
    }
  };
  const onAddNewPath = (e: React.MouseEvent, index: number) => {
    let targets = [...state.targeting];
    targets[index].path_rules!.push({comparison: 'exactly', value: ''});
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
  };

  const removePathHandler = async (
    e: React.MouseEvent,
    index: number,
    pathIndex: number
  ) => {
    let targets = [...state.targeting];
    let newTargets = targets.map((target: Targeting, tIndex: number) => {
      if (index === tIndex) {
        target.path_rules!.splice(pathIndex, 1);
      }
      return target;
    });

    dispatch({type: 'SET_TARGETING_RULES', payload: newTargets});
  };

  const onPathSelect = (path: SelectProps, index: number, pathIndex: number) => {
    let targets = [...state.targeting];
    targets[index].path_rules![pathIndex].comparison! = path.value;
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
  };

  const onDomainPathChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    pathIndex: number
  ) => {
    let targets = JSON.parse(JSON.stringify(state.targeting));
    targets[index].path_rules![pathIndex].value = e.target.value;
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
  };

  const addNewRule = () => {
    let targets = [...state.targeting];
    targets.push({type: 'url', domain_comparison: 'contains', path_rules: []});
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
  };
  const filteredTargetLength = state.targeting.filter(
    (target: Targeting) => target.type
  ).length;

  return (
    <Flex data-testid='publish-settings' direction='column'>
      {state.targeting.map((target: Targeting, index: number) => (
        <>
          <Flex direction='column' width='100%'>
            {state.targeting.length > 1 && (
              <Divider orientation='horizontal' mt={24} ml={20} w='94%' />
            )}
            <Flex alignItems='center'>
              <DisplaySelector
                onSelect={(rule: RuleSelectorSelectProps) => onRuleSelect(rule, index)}
                value={target.type}
                disabled={state.read_mode || Boolean(state.edit_mode && target.id)}
                error={state.publishError}
                dispatch={dispatch}
              />
              {!state.read_mode && (
                <Tooltip label='Delete display rule' placement='top'>
                  <Button
                    mt={24}
                    icon={<Icon as={RiDeleteBin6Line} />}
                    data-testid='delete-display-rule-button'
                    aria-label={'Delete Display Rule Button'}
                    colorScheme='white'
                    variant='icon'
                    // @ts-ignore
                    size='large'
                    onClick={(e: React.MouseEvent) => removeDomainHandler(index)}
                  />
                </Tooltip>
              )}
            </Flex>
            {target.type === 'url' && (
              <>
                <Box mx={42} data-testid='url-input-div'>
                  <URLInput
                    target={target}
                    onDomainChange={onDomainChange}
                    handleDomainBlur={handleDomainBlur}
                    index={index}
                    removeDomainHandler={removeDomainHandler}
                    disabled={state.read_mode}
                  />
                </Box>

                <PathRules
                  containerRef={containerRef}
                  target={target}
                  index={index}
                  onPathSelect={onPathSelect}
                  onAddNewPath={onAddNewPath}
                  onDomainPathChange={onDomainPathChange}
                  removePathHandler={removePathHandler}
                  disabled={state.read_mode}
                />
              </>
            )}
          </Flex>
        </>
      ))}
      {filteredTargetLength > 0 && filteredTargetLength < 8 && (
        <Box ml={20} mt={24}>
          <Button
            colorScheme=''
            leftIcon={<Icon as={RiAddLine} />}
            size='medium'
            variant='outlined'
            isDisabled={state.read_mode}
            onClick={addNewRule}
            data-testid='add-new-diplay-rule-button'
          >
            Add new display rule
          </Button>
        </Box>
      )}
      <Flex ml={24} mt={24} mb={16} gap={16} direction='column'>
        <Text variant='body2' fontWeight='semibold'>
          Expiration date
        </Text>

        <SpotlightExpirationRadio state={state} dispatch={dispatch} />
      </Flex>
    </Flex>
  );
};

export default PublishSettings;
