/* istanbul ignore file */
// CKEditor TS support is coming https://github.com/ckeditor/ckeditor5/issues/11704

import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {toWidget} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import InsertIframeCommand from './insertIframeCommand';

export default class IframeEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    // register embed command
    this.editor.commands.add('embedIframe', new InsertIframeCommand(this.editor));
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    // define schema for upload progress
    schema.register('embeddedIframe', {
      // Behaves like a self-contained object (e.g. an image).
      isObject: true,

      // Allow in places where other blocks are allowed (e.g. directly in the root).
      allowWhere: '$block',

      // only allow data-file attribute
      allowAttributes: ['data-src', 'data-options'],
    });

    // define schema for embedded file
  }

  _defineConverters() {
    const editor = this.editor;
    const conversion = editor.conversion;
    const renderIframe = editor.config.get('reactEmbed').iframeRenderer;

    // converters ((data) view → model)
    conversion.for('upcast').elementToElement({
      view: {
        name: 'section',
        classes: ['iframe-embed'],
      },
      model: (viewElement, {writer: modelWriter}) => {
        // Read the "data-file" attribute from the view and set it as the data-file in the model.
        return modelWriter.createElement('embeddedIframe', {
          'data-src': viewElement.getAttribute('data-src'),
          'data-options': viewElement.getAttribute('data-options'),
        });
      },
    });

    // converters (model → data view)
    conversion.for('dataDowncast').elementToElement({
      model: 'embeddedIframe',
      view: (modelElement, {writer: viewWriter}) => {
        return viewWriter.createEmptyElement('section', {
          class: 'iframe-embed',
          'data-src': modelElement.getAttribute('data-src'),
          'data-options': modelElement.getAttribute('data-options'),
        });
      },
    });

    // converters (model → editing view)
    conversion.for('editingDowncast').elementToElement({
      model: 'embeddedIframe',
      view: (modelElement, {writer: viewWriter}) => {
        const src = modelElement.getAttribute('data-src');
        const options = modelElement.getAttribute('data-options');

        // The outermost <section class="product" data-id="..."></section> element.
        const section = viewWriter.createContainerElement('section', {
          class: 'iframe-embed',
          'data-src': src,
          'data-options': options,
        });

        const reactWrapper = viewWriter.createRawElement(
          'div',
          {
            class: 'ck-editor__react-wrapper',
          },
          function (domElement) {
            renderIframe(src, JSON.parse(options || {}), domElement);
          }
        );

        // we use this custom property to apply a matching toolbar later
        viewWriter.setCustomProperty('iframe-embed', true, section);
        viewWriter.insert(viewWriter.createPositionAt(section, 0), reactWrapper);

        return toWidget(section, viewWriter, {label: 'Iframe embed widget'});
      },
    });
  }
}
