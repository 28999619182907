import React from 'react';
import styles from './modalTitlebar.module.css';
import Fontawesome from '../fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
export interface Props {
  onClose?: (evt: React.MouseEvent) => void;
}

const ModalTitlebar: React.FC<Props> = ({children, onClose}) => {
  return (
    <div className={styles.main}>
      {children}
      {onClose && (
        <Fontawesome
          name={faTimes}
          className={styles.icon}
          onClick={(evt: React.MouseEvent) => onClose(evt)}
        />
      )}
    </div>
  );
};

export default ModalTitlebar;
