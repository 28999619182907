import {RiAlertLine} from 'react-icons/ri';
import {v4 as uuidv4} from 'uuid';

const CANS = [
  'Update definitions and custom fields data',
  'Add new Speks as rows',
  'Assign an expert by copying over their ID from the “Users” tab',
  'Hide terms by marking them as “Hidden” true',
  'Bulk apply Topics to a Spek. In order to add multiple tags, separate them by using commas.',
  'Bulk relate objects to a Spek. In order to add multiple objects, separate their IDs by using commas.',
  'Upload images and YouTube videos via BBCode',
];

const CANTS = [
  'Update any Salesforce data in the grey columns',
  'Create new custom fields via upload',
  'Create new users',
];

const IMPORTANT_NOTES = [
  'Make sure to delete any rows that you do not want to override',
  'Uploading is non-reversible: it will override your data.',
  "Don't change the ID in the far left column.",
];

export const STEP_2 = [
  {
    label: 'You can',
    bullets: CANS,
    id: uuidv4(),
  },
  {
    label: 'You cannot',
    bullets: CANTS,
    id: uuidv4(),
  },
  {
    icon: RiAlertLine,
    label: 'Important things to note',
    bullets: IMPORTANT_NOTES,
    id: uuidv4(),
  },
];
