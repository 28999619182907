import {Targeting} from './types';

interface Params {
  teams: Array<any>;
  targeting: Array<Targeting>;
}

export const isError = ({teams, targeting}: Params) => {
  let targetingError = targeting.filter(
    (t) => (t.error || !t.domain_value) && t.type === 'url'
  );
  if (!teams.length) {
    return {error: true, text: 'Please select some teams.'};
  }
  if (!targeting.filter((t) => t.type).length) {
    return {error: true, text: 'Please select a publish criteria.'};
  }
  if (targetingError.length) {
    return {error: true, text: 'Please enter a url for the target.'};
  }

  return {error: false, text: ''};
};
