import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

// we support pasting iframe code OR sharing links from some "supported" providers
// this map helps us detect when the user has pasted a supported provider link
// and then we can return the correct iframe src and aspect ratio
// we default to a 4:5 aspect ratio if we don't provide one
const supportedProviderRegexMap = {
  // Scribe
  '^https?:\\/\\/(?:www\\.)?scribehow\\.com\\/(?:shared|embed)\\/(\\w+)$': {
    iframeSrc: 'https://scribehow.com/embed/<REPLACEMENT_1>',
    options: {aspectRatio: 1},
  },
  // Vimeo
  '^https?:\\/\\/(?:www\\.)?vimeo\\.com\\/(\\d+)(?:$|\\/|\\?)': {
    iframeSrc: 'https://player.vimeo.com/video/<REPLACEMENT_1>',
    options: {aspectRatio: 16 / 9},
  },
  '^https?:\\/\\/player\\.vimeo\\.com\\/video\\/(\\d+)(?:$|\\/|\\?)': {
    iframeSrc: 'https://player.vimeo.com/video/<REPLACEMENT_1>',
    options: {aspectRatio: 16 / 9},
  },
  // YouTube
  '^https?:\\/\\/(?:www\\.)?youtube\\.com\\/watch\\?v=([a-zA-Z0-9_-]{11})$': {
    iframeSrc: 'https://www.youtube.com/embed/<REPLACEMENT_1>',
    options: {aspectRatio: 16 / 9},
  },
  '^https?:\\/\\/(?:www\\.)?youtube\\.com\\/embed\\/([a-zA-Z0-9_-]{11})$': {
    iframeSrc: 'https://www.youtube.com/embed/<REPLACEMENT_1>',
    options: {aspectRatio: 16 / 9},
  },
  '^https:\\/\\/youtu.be/([a-zA-Z0-9_-]{11})$': {
    iframeSrc: 'https://www.youtube.com/embed/<REPLACEMENT_1>',
    options: {aspectRatio: 16 / 9},
  },
  // GSuite - docs, slides, forms, sheets MUST USE PUBLISH TO WEB OPTIONS
  /**
   * potential do match all G Suite embeds in one regex but some of the params are unique to each type. will revisit
   * "^https?:\\/\\/docs\\.google\\.com\\/(.*?)\\/d\\/e\\/(.+?)\\/(.*)$": {iframeSrc: "https://docs.google.com/<REPLACEMENT_1>/d/e/<REPLACEMENT_2>/<REPLACEMENT_3>", options: {}},
   */
  '^https?:\\/\\/docs\\.google\\.com\\/document\\/d\\/e\\/(.+?)\\/': {
    iframeSrc: 'https://docs.google.com/document/d/e/<REPLACEMENT_1>/pub?embedded=true',
    options: {},
  },
  '^https?:\\/\\/docs\\.google\\.com\\/presentation\\/d\\/e\\/(.+?)\\/(?:pub|embed)\\\\?(.*)$':
    {
      iframeSrc:
        'https://docs.google.com/presentation/d/e/<REPLACEMENT_1>/embed?<REPLACEMENT_2>',
      options: {aspectRatio: 16 / 9},
    },
  '^https?:\\/\\/docs\\.google\\.com\\/forms\\/d\\/e\\/(.+?)\\/': {
    iframeSrc: 'https://docs.google.com/forms/d/e/<REPLACEMENT_1>/viewform?embedded=true',
    options: {},
  },
  '^https?:\\/\\/docs\\.google\\.com\\/spreadsheets\\/d\\/e\\/(.+?)\\/pubhtml.*$': {
    iframeSrc: 'https://docs.google.com/spreadsheets/d/e/<REPLACEMENT_1>/pubhtml',
    options: {aspectRatio: 16 / 9},
  },
  // Loom
  // https://www.loom.com/share/193b05a91af94f1d94ab1f6c75010e99?sid=b726c6ef-5b71-4de3-adf9-0379a11924d9
  '^https?://(?:www.)loom.com/(?:share|embed)/([a-z0-9]+)': {
    iframeSrc: 'https://www.loom.com/embed/<REPLACEMENT_1>',
    options: {aspectRatio: 16 / 9},
  },
  // Spotify
  '^https?:\\/\\/open\\.spotify\\.com\\/(playlist|track|album|artist)\\/(.*?)\\?(.*?)$': {
    iframeSrc:
      'https://open.spotify.com/embed/<REPLACEMENT_1>/<REPLACEMENT_2>?<REPLACEMENT_3>',
    options: {aspectRatio: 16 / 9},
  },
  '^https?:\\/\\/open\\.spotify\\.com\\/embed\\/(playlist|track|album|artist)\\/(.*?)\\?(.*?)$':
    {
      iframeSrc:
        'https://open.spotify.com/embed/<REPLACEMENT_1>/<REPLACEMENT_2>?<REPLACEMENT_3>',
      options: {aspectRatio: 16 / 9},
    },
  // Vidyard
  '^https?:\\/\\/share\\.vidyard\\.com\\/watch\\/([a-zA-Z0-9]+)': {
    iframeSrc: 'https://play.vidyard.com/<REPLACEMENT_1>',
    options: {aspectRatio: 16 / 9},
  },
  // Box
  '^https?:\\/\\/app\\.box\\.com\\/s\\/([a-zA-Z0-9]+)': {
    iframeSrc: 'https://app.box.com/embed/s/<REPLACEMENT_1>',
    options: {},
  },
};

// function that returns true if the supplied string matches any key of the supportedProviderRegexMap
export const isSupportedProvider = (url: string) => {
  return Object.keys(supportedProviderRegexMap).some((regex) => {
    const regexObj = new RegExp(regex);
    return regexObj.test(url);
  });
};

// function that returns the replacement string for the supplied string after replacing all <REPLACEMENT> strings with the corresponding capture group
// also returns the options object for the iframe
export const getIframeDetails = (url: string) => {
  const iframeDetails = {
    src: '',
    options: {},
  };
  Object.keys(supportedProviderRegexMap).forEach((regex) => {
    const regexObj = new RegExp(regex);
    if (regexObj.test(url)) {
      iframeDetails.options = supportedProviderRegexMap[regex].options;
      iframeDetails.src = supportedProviderRegexMap[regex].iframeSrc;
      const matches = url.match(regexObj);
      if (matches) {
        for (let i = 1; i < matches.length; i++) {
          iframeDetails.src = iframeDetails.src.replace(`<REPLACEMENT_${i}>`, matches[i]);
        }
      }
    }
  });
  return iframeDetails;
};

export default class InsertIframe extends Plugin {
  init() {
    const editor = this.editor;

    // handle paste
    editor.editing.view.document.on(
      'paste',
      async (event, data) => {
        const textContent = data.dataTransfer.getData('text/plain').trim();

        let iframeSrc;
        let iframeOptions = {};

        if (isSupportedProvider(textContent)) {
          event.stop();
          data.preventDefault();

          const {src, options} = getIframeDetails(textContent);
          iframeSrc = src;
          iframeOptions = options;
        } else {
          const container = new window.DOMParser().parseFromString(
            textContent,
            'text/html'
          );
          iframeSrc = container.querySelector('iframe')?.getAttribute('src');

          if (iframeSrc && isSupportedProvider(iframeSrc)) {
            const {options} = getIframeDetails(iframeSrc);
            iframeOptions = options;
          }
        }

        if (iframeSrc) {
          event.stop();
          data.preventDefault();

          editor.execute('embedIframe', iframeSrc, null, JSON.stringify(iframeOptions));
        }
      },
      {priority: 'high'}
    );
  }
}
