export interface IPagination {
  totalPages: number;
  currentPage: number;
}

export const getItems = ({totalPages, currentPage}: IPagination) => {
  let paginationItems = [];
  if (totalPages <= 9) {
    paginationItems = Array.from({length: totalPages}, (_, i) => i + 1);
  } else if (currentPage <= 2 || currentPage >= totalPages - 1) {
    paginationItems = [1, 2, 3, '...', totalPages - 2, totalPages - 1, totalPages];
  } else if (currentPage - 1 === 2) {
    paginationItems = [
      1,
      2,
      '...',
      currentPage,
      currentPage + 1,
      currentPage + 2,
      '...',
      totalPages - 1,
      totalPages,
    ];
  } else if (currentPage + 1 === totalPages - 1) {
    paginationItems = [
      1,
      2,
      '...',
      currentPage - 2,
      currentPage - 1,
      currentPage,
      '...',
      totalPages - 1,
      totalPages,
    ];
  } else {
    paginationItems = [
      1,
      2,
      '...',
      currentPage - 1,
      currentPage,
      currentPage + 1,
      '...',
      totalPages - 1,
      totalPages,
    ];
  }
  return paginationItems;
};

export const getItemsSmall = ({totalPages, currentPage}: IPagination) => {
  let paginationItems = [];
  if (totalPages <= 5) {
    paginationItems = Array.from({length: totalPages}, (_, i) => i + 1);
  } else if (currentPage > 2 && currentPage < totalPages - 2) {
    paginationItems = [1, '...', currentPage - 1, currentPage, '...', totalPages];
  } else if (currentPage === totalPages - 2) {
    paginationItems = [1, '...', totalPages - 2, totalPages - 1, totalPages];
  } else {
    paginationItems = [1, 2, 3, '...', totalPages];
  }
  return paginationItems;
};
