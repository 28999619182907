import React, {useCallback, useEffect, useMemo} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {RiAddCircleLine, RiDeleteBin6Line} from 'react-icons/ri';
import {ICustomFieldsFormState} from 'spekit-types';
import {Box, DSButton as Button, EncapsulatedInput, Icon, Text, Flex} from 'spekit-ui';

export default function Options() {
  const {control, watch, trigger, setValue} = useFormContext<ICustomFieldsFormState>();

  const fieldType = watch('data_type');
  const options = watch('options');

  const addOption = () => {
    setValue('options', [...options, {id: '', name: ''}]);
    trigger('options');
  };

  const removeOption = useCallback(
    (index: number) => {
      setValue(
        'options',
        options.filter((_, i) => i !== index)
      );
      trigger('options');
    },
    [options, setValue, trigger]
  );

  useEffect(() => {
    if (!options.length)
      setValue('options', [
        {id: '', name: ''},
        {id: '', name: ''},
      ]);
  }, [options, setValue]);

  const optionsJSX = useMemo(() => {
    return options.map((option, index) => {
      return (
        <Flex key={`${option.name}-${index}`}>
          <Controller
            name={`options.${index}.name`}
            control={control}
            render={({field, fieldState}) => (
              <EncapsulatedInput
                {...field}
                isRequired
                placeholder={`Enter option ${index + 1}`}
                isInvalid={!!fieldState.error}
                errorMessage={fieldState.error?.message}
                testId={`field-option-${index + 1}`}
              />
            )}
          />
          {options.length > 2 && (
            <Button
              ms={12}
              mt={4}
              size='medium'
              variant='icon'
              colorScheme='transparent'
              aria-label='Delete option'
              tooltipLabel='Delete option'
              icon={<Icon as={RiDeleteBin6Line} />}
              data-testid='remove-option-btn'
              onClick={() => removeOption(index)}
            />
          )}
        </Flex>
      );
    });
  }, [options, control, removeOption]);

  return fieldType?.value === 'multi-option' ? (
    <Flex ps={24} gap={10} alignSelf='stretch' direction='column'>
      <Text variant='body2' fontWeight='normal'>
        Options
        <Box as='span' ms={4} color='error.500'>
          *
        </Box>
      </Text>
      {optionsJSX}
      <Button
        size='medium'
        variant='ghost'
        colorScheme='primary'
        alignSelf='flex-start'
        leftIcon={<Icon as={RiAddCircleLine} />}
        onClick={addOption}
        data-testid='add-option-btn'
      >
        Add option
      </Button>
    </Flex>
  ) : (
    <></>
  );
}
