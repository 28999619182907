import React from 'react';
import {forwardRef, Tooltip as ChakraTooltip, TooltipProps} from '@chakra-ui/react';
import {FONT_FAMILY} from '../Typography';

export type TTooltipProps = TooltipProps;

export const TooltipConfig = {
  baseStyle: {
    fontFamily: FONT_FAMILY,
  },
  variants: {
    compact: {
      bg: 'neutral.800',
      borderRadius: '3px',
      color: 'white',
      fontSize: 'tiny',
      p: 4,
      lineHeight: 1,
      maxWidth: '213px',
      gutter: 5,
      zIndex: 21474836460,
    },
  },
  defaultProps: {
    variant: 'compact',
  },
};

export const Tooltip = forwardRef((props: TTooltipProps, ref) =>
  props.label ? <ChakraTooltip ref={ref} {...props} /> : <>{props.children}</>
);
