const initialState = {
  teams: [],
};
function teamsReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_TEAMS':
      return {
        ...state,
        teams: action.teams,
      };
    default:
      return state;
  }
}

export default teamsReducer;
