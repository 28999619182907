import React from 'react';

const Overlay = (props) => {
  let styles = {
    main: {
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      zIndex: '2147483640',
      left: '0',
      top: '0',
      backgroundColor: 'rgba(3,9,18,0.7)',
    },
  };
  return <div style={{...styles.main, ...props.style}}>{props.children}</div>;
};

export default Overlay;
