import {retrieve} from '../utils/APIHelpers';
import {stringifyQs} from '../utils/commonUtils';

interface IFileLookupResponse {
  results: {
    hidden: boolean;
    label: string;
    term_type: 'asset';
    value: string;
  }[];
}
interface IFileLookup {
  q: string;
  teams: string[];
}

export const fileLookup = async (payload: IFileLookup) => {
  const query = stringifyQs(payload);
  const {results} = await retrieve<IFileLookupResponse>(`/api/lookups/files?${query}`);
  return results;
};
