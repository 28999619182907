import {
  IResetPasswordRequest,
  IRetrieveUsernameResponse,
  IPasswordTokenResponse,
  IResetPasswordResponse,
} from 'spekit-types';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {retrieve} from '../utils/APIHelpers';
export const retrieveUsername = async (
  email: string
): Promise<IRetrieveUsernameResponse> => {
  try {
    let response = await fetch(`/api/v1/auth/forgot_username`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...(email && {body: JSON.stringify({email})}),
    });
    const result = await response.json();
    return result;
  } catch (e) {
    throw new Error('Something went wrong. Please try again later.');
  }
};

export const resetPassword = async (
  username: string
): Promise<IResetPasswordResponse> => {
  try {
    let response = await fetch(`/api/auth/forgot`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...(username && {body: JSON.stringify({username})}),
    });
    const result = await response.json();
    return result;
  } catch (e) {
    throw new Error('Something went wrong. Please try again later.');
  }
};

export const updatePassword = async (request: IResetPasswordRequest) => {
  try {
    let response = await fetch(`/api/auth/reset`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...(request && {body: JSON.stringify(request)}),
    });
    const result = await response.json();
    return result;
  } catch (e) {
    throw new Error('Something went wrong. Please try again later.');
  }
};

export const checkValidPasswordToken = async (
  token: string
): Promise<IPasswordTokenResponse> => {
  return retrieve(`/api/auth/verify_token/${token}`);
};
