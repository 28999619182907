import React, {useState} from 'react';
import {Flex, DSButton as Button, useToast, Text, Divider, Heading} from 'spekit-ui';
import {FormProvider, useForm} from 'react-hook-form';
import {ColorInput} from 'spekit-ui/src/design-system/ColorInput/ColorInput';
import {IBrandConfig, ICustomBrandingFormState} from 'spekit-types';
import {brandConfigAPI, logging} from 'spekit-datalayer';
import {LogoUploader} from './LogoUploader';
import {resolver, getDefaultValues} from './form';
import {ColorGuider} from './ColorGuider';
import {ResetButton} from './ResetButton';
import {DEFAULTS, setValueOptions} from './constants';

interface ICustomBranding {
  config: IBrandConfig;
}

const reloadWindow = () => setTimeout(() => window.location.reload(), 500);

export const CustomBranding = ({config}: ICustomBranding) => {
  const methods = useForm<ICustomBrandingFormState>({
    resolver,
    defaultValues: getDefaultValues(config),
  });
  const {
    setValue,
    watch,
    formState: {errors, isDirty},
    handleSubmit,
  } = methods;
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const primaryColorInHex = watch('primaryColorInHex');

  const handleUpdate = async (state: ICustomBrandingFormState) => {
    try {
      setIsSaving(true);
      await brandConfigAPI.updateBrandConfig(state);
      toast({
        variant: 'success',
        description: 'Changes saved and custom branding applied!',
      });
      reloadWindow();
    } catch (e) {
      logging.capture(e);
      toast({variant: 'error', description: e.message});
    } finally {
      setIsSaving(false);
    }
  };

  const handleReset = async () => {
    try {
      setIsSaving(true);
      await brandConfigAPI.updateBrandConfig({
        brandImage: null,
        iconImage: null,
        whiteBrandImage: null,
        whiteIconImage: null,
        primaryColorInHex: DEFAULTS.primaryColor,
      });
      toast({
        variant: 'success',
        description: 'Custom branding reset to default selections',
      });
      reloadWindow();
    } catch (e) {
      logging.capture(e);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <Flex direction='column' alignItems='flex-start'>
        {/* Logo Instructions */}
        <Flex direction='column' mb={32} gap={16}>
          <Heading as='h4' fontWeight={600}>
            Logos
          </Heading>
          <Text variant='body2' color='neutral.500' maxW='600px'>
            Supported formats for logos are SVG, PNG and JPEG.
          </Text>
        </Flex>
        {/* Full color logos */}
        <Flex direction='column' gap={16} mb={32}>
          <Text color='neutral.1000' fontWeight={600} variant='body1'>
            Full color logos
          </Text>
          <Flex gap={52}>
            <LogoUploader
              variant='light'
              testIdPrefix='logo-type'
              fieldName='brandImage'
              label='Logotype'
              tooltipLabel='New logotypes that are uploaded will appear in the primary navigation within the Spekit web app and Chrome extension'
              deleteTooltipLabel='Delete and reset to default logotype'
              defaultLogo={DEFAULTS.brandLogo}
            />
            <LogoUploader
              variant='light'
              testIdPrefix='logo-mark'
              fieldName='iconImage'
              label='Logomark'
              tooltipLabel='New logomarks that are uploaded will appear for embedded Speks on all pages and in the header of the embedded Spek view within Spekit.'
              deleteTooltipLabel='Delete and reset to default logomark'
              defaultLogo={DEFAULTS.iconLogo}
            />
          </Flex>
        </Flex>
        {/* White logos */}
        <Flex direction='column' gap={16}>
          <Text color='neutral.1000' fontWeight={600} variant='body1'>
            White logos
          </Text>
          <Flex gap={52}>
            <LogoUploader
              variant='dark'
              testIdPrefix='white-logo-type'
              fieldName='whiteBrandImage'
              label='Logotype'
              tooltipLabel='New logotypes that are uploaded will appear in the expanded version of the dock in the Spekit Chrome extension.'
              deleteTooltipLabel='Delete and reset to default logotype'
              defaultLogo={DEFAULTS.whiteBrandLogo}
            />
            <LogoUploader
              variant='dark'
              testIdPrefix='white-logo-mark'
              fieldName='whiteIconImage'
              label='Logomark'
              tooltipLabel='New logomarks that are uploaded will appear in the minimized version of the dock in the Spekit Chrome extension.'
              deleteTooltipLabel='Delete and reset to default logomark'
              defaultLogo={DEFAULTS.whiteIconLogo}
            />
          </Flex>
        </Flex>

        {/* Divider */}
        <Divider mt={42} mb={42} />
        {/* Hex Color */}
        <Flex direction='column' gap={10}>
          <Heading as='h4' fontWeight={600}>
            Colors
          </Heading>
          <Flex w='249px' direction='column' gap={6}>
            <ColorInput
              testId='color-input'
              value={primaryColorInHex}
              onChange={(value) => setValue('primaryColorInHex', value, setValueOptions)}
              errorMessage={errors.primaryColorInHex?.message}
              label='Primary color hex code'
              isRequired
            />
            <ColorGuider />
          </Flex>
        </Flex>

        {/* Divider */}
        <Divider mt={42} mb={0} />
        {/* Actions */}
        <Flex gap={12} alignItems='flex-start' mt={52}>
          <ResetButton handleReset={handleReset} disabled={isSaving} />
          <Button
            variant='contained'
            colorScheme='primary'
            size='medium'
            onClick={handleSubmit(handleUpdate)}
            disabled={isSaving || !isDirty}
            data-testid='save-btn'
          >
            Save changes
          </Button>
        </Flex>
      </Flex>
    </FormProvider>
  );
};
