import * as React from 'react';

import styles from './SettingsNav.module.css';

const SettingsNav: React.FC = ({children}) => {
  const contentClass = styles.contentBeta;
  return (
    <div className={styles.main}>
      <div className={contentClass}>{children}</div>
    </div>
  );
};

export default SettingsNav;
