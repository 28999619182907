type ActionType = 'SET_SHARING_PRIVACY';

interface Action {
  type: ActionType;
  payload: {
    sharingAllowed: boolean;
  };
}
const initialState = {
  sharingAllowed: false,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case 'SET_SHARING_PRIVACY':
      return {...state, sharingAllowed: action.payload};

    default:
      return state;
  }
};
