import React from 'react';
import {MenuList as ChakraMenuList, MenuListProps, Portal} from '@chakra-ui/react';

export type TMenuListProps = MenuListProps & {
  containerRef?: React.MutableRefObject<HTMLElement | null>;
};

export const MenuList = ({containerRef, ...props}: TMenuListProps) => (
  <Portal containerRef={containerRef}>
    <ChakraMenuList {...props} />
  </Portal>
);
