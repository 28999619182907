import React from 'react';
import {SingleDatepicker, SingleDatepickerProps} from 'chakra-dayzed-datepicker';

import {endOfYesterday} from 'date-fns';

export type TSingleDatepickerProps = SingleDatepickerProps & {
  placeholder: string;
  idPrefix?: string;
};

export const Datepicker = (props: TSingleDatepickerProps) => {
  const dateConfigs = {
    dateFormat: 'MMM d, yyyy',
  };

  const currentDate = endOfYesterday();

  const idPrefix = props.idPrefix ? `${props.idPrefix}-` : '';

  const propsConfigs: SingleDatepickerProps['propsConfigs'] = {
    dateHeadingProps: {
      id: `${idPrefix}datepicker-date-heading`,
      fontWeight: 'semibold',
    },
    dateNavBtnProps: {
      colorScheme: 'neutral.800',
      variant: 'ghost',
      fontWeight: 'semibold',
    },

    weekdayLabelProps: {
      fontWeight: 'semibold',
      color: 'neutral.800',
    },
    dayOfMonthBtnProps: {
      defaultBtnProps: {
        color: 'neutral.800',
        _hover: {
          background: 'primary.25',
          color: 'primary.700',
          outline: 'transparent solid 2px',
          outlineOffset: '2px',
        },
        _active: {
          border: '1px solid #653FE6',
          color: 'neutral.800',
          boxShadow: `0px 0px 0px 2px #E0D8FA`,
        },
        _focusVisible: {
          fontWeght: 'normal',
          background: 'transparent',
          color: 'neutral.400',
          boxShadow: 'none',
          border: 'none',
        },
        _disabled: {
          fontWeght: 'normal',
          background: 'transparent',
          color: 'neutral.400',
          cursor: 'not-allowed',
          boxShadow: 'none',
          outline: 'none',
          pointerEvents: 'none',
        },
      },
      isInRangeBtnProps: {
        color: 'yellow',
      },
      selectedBtnProps: {
        id: `${idPrefix}datepicker-selected-date-button`,
        background: 'primary.500',
        color: 'neutral.0',
        fontWeight: 'semibold',
      },
      todayBtnProps: {
        id: `${idPrefix}datepicker-today-date-button`,
        color: 'neutral.800',
        fontWeight: 'normal',
        background: 'neutral.100',
        border: 'none',
      },
    },
    inputProps: {
      size: 'sm',
      placeholder: props.placeholder,
    },
    popoverCompProps: {
      popoverContentProps: {
        background: 'white',
        color: 'neutral.800',
        borderRadius: '8px',
        boxShadow: 'xl',
        padding: '8px',
      },
      popoverBodyProps: {
        marginTop: '0px',
      },
    },
  };

  return (
    <SingleDatepicker
      propsConfigs={propsConfigs}
      configs={dateConfigs}
      minDate={currentDate}
      {...props}
    />
  );
};
