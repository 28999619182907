import {retrieve} from './utils/APIHelpers';

export const getSpekVersionHistory = async <T>(
  spekId: string,
  offset: string
): Promise<T> => {
  return retrieve(
    `/api/spek_versions/${spekId}/?offset=${offset}&limit=25&sort=-version_number`
  );
};
