import React from 'react';
import Fontawesome from './fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';

class Pagination extends React.Component {
  render() {
    const self = this;
    let styles = {
      main: {
        fontSize: '15px',
        color: '#ABB6BE',
      },
      btn: {
        display: 'inline-block',
        border: '1px solid #C0C5D2',
        color: '#C0C5D2',
        backgroundColor: '#FFF',
        cursor: 'pointer',
        borderRadius: '50%',
        fontSize: '16px',
        lineHeight: '40px',
        width: '40px',
        textAlign: 'center',
        margin: '0px 7.5px',
      },
      activeBtn: {
        display: 'inline-block',
        border: '1px solid #3576E8',
        color: '#3576E8',
        backgroundColor: 'rgba(53,118,232,0.05)',
        cursor: 'pointer',
        borderRadius: '50%',
        fontSize: '15px',
        lineHeight: '40px',
        width: '40px',
        textAlign: 'center',
        margin: '0px 7.5px',
      },
      edgeBtn: {
        display: 'inline-block',
        border: '1px solid #3576E8',
        color: '#FFF',
        backgroundColor: '#3576E8',
        cursor: 'pointer',
        borderRadius: '50%',
        fontSize: '16px',
        lineHeight: '40px',
        width: '40px',
        textAlign: 'center',
        margin: '0px 7.5px',
      },
      edgeBtnDisabled: {
        display: 'inline-block',
        border: '1px solid #CFD4DC',
        color: '#9FA9BA',
        backgroundColor: 'rgba(159,169,186,0.5)',
        cursor: 'not-allowed',
        borderRadius: '50%',
        fontSize: '16px',
        lineHeight: '40px',
        width: '40px',
        textAlign: 'center',
        margin: '0px 7.5px',
      },
    };
    let pageNumbersToRender = [];
    if (this.props.totalPages <= 9) {
      pageNumbersToRender = Array.apply(null, {length: this.props.totalPages}).map(
        (foo, n) => n + 1
      );
    } else {
      if (
        this.props.currentPage <= 2 ||
        this.props.currentPage >= this.props.totalPages - 1
      ) {
        pageNumbersToRender = [
          1,
          2,
          3,
          '...',
          this.props.totalPages - 2,
          this.props.totalPages - 1,
          this.props.totalPages,
        ];
      } else if (this.props.currentPage - 1 === 2) {
        pageNumbersToRender = [
          1,
          2,
          '...',
          this.props.currentPage,
          this.props.currentPage + 1,
          this.props.currentPage + 2,
          '...',
          this.props.totalPages - 1,
          this.props.totalPages,
        ];
      } else if (this.props.currentPage + 1 === this.props.totalPages - 1) {
        pageNumbersToRender = [
          1,
          2,
          '...',
          this.props.currentPage - 2,
          this.props.currentPage - 1,
          this.props.currentPage,
          '...',
          this.props.totalPages - 1,
          this.props.totalPages,
        ];
      } else {
        pageNumbersToRender = [
          1,
          2,
          '...',
          this.props.currentPage - 1,
          this.props.currentPage,
          this.props.currentPage + 1,
          '...',
          this.props.totalPages - 1,
          this.props.totalPages,
        ];
      }
    }

    return (
      <div style={styles.main} data-testid='pagination'>
        <div
          style={self.props.currentPage <= 1 ? styles.edgeBtnDisabled : styles.edgeBtn}
          onClick={() => {
            if (
              typeof self.props.onPageSelect === 'function' &&
              self.props.currentPage > 1
            ) {
              self.props.onPageSelect(this.props.currentPage - 1);
            }
          }}
        >
          <Fontawesome name={faChevronLeft} />
        </div>
        {pageNumbersToRender.map(function (n) {
          if (n === '...') {
            return '...';
          }
          return (
            <div
              key={n}
              onClick={() => {
                if (
                  typeof self.props.onPageSelect === 'function' &&
                  n !== self.props.currentPage
                ) {
                  self.props.onPageSelect(n);
                }
              }}
              style={n === self.props.currentPage ? styles.activeBtn : styles.btn}
              data-testid='pagination-page-item'
            >
              {n}
            </div>
          );
        })}
        <div
          style={
            self.props.currentPage >= self.props.totalPages
              ? styles.edgeBtnDisabled
              : styles.edgeBtn
          }
          onClick={() => {
            if (
              typeof self.props.onPageSelect === 'function' &&
              self.props.currentPage < self.props.totalPages
            ) {
              self.props.onPageSelect(self.props.currentPage + 1);
            }
          }}
        >
          <Fontawesome name={faChevronRight} />
        </div>
      </div>
    );
  }
}

export default Pagination;
