import React from 'react';
import {DSButton as Button, Flex, Heading, Loader, Fade} from 'spekit-ui';
import {UseFormReturn} from 'react-hook-form';
import {IPasswordFormState} from 'spekit-types';
import Password from '../../components/ResetPassword/Password';

interface IProps {
  isLoading: boolean;
  heading: string;
  submitButtonText: string;
  passwordLabel: string;
  confirmPasswordLabel: string;
  passwordHandler: (data: IPasswordFormState) => void;
  methods: UseFormReturn<IPasswordFormState, any>;
}

const ResetPasswordLayout = (props: IProps) => {
  const {
    methods,
    isLoading,
    heading,
    submitButtonText,
    passwordLabel,
    confirmPasswordLabel,
    passwordHandler,
  } = props;
  const password = methods.getValues('password');

  const isPasswordValidated =
    password && !methods.formState.errors?.password ? true : false;

  return (
    <Flex direction='column' px={144} maxW={450} pt={112}>
      <Heading as='h3' fontWeight='semibold' fontSize='20px' textAlign='center'>
        {heading}
      </Heading>

      <Flex direction='column' mt={28} gap={32}>
        <form onSubmit={methods.handleSubmit(passwordHandler)}>
          <Password
            passwordLabel={passwordLabel}
            confirmPasswordLabel={confirmPasswordLabel}
            methods={methods}
            resetPasswordHandler={passwordHandler}
          />
        </form>

        {isLoading ? (
          <Flex justifyContent='center' alignItems='center' py={20}>
            <Loader size={8} />
          </Flex>
        ) : (
          <Fade in={isPasswordValidated}>
            <Button
              margin='auto'
              data-testid='reset-password-button'
              size='large'
              variant='contained'
              colorScheme='primary'
              onClick={methods.handleSubmit(passwordHandler)}
            >
              {submitButtonText}
            </Button>
          </Fade>
        )}
      </Flex>
    </Flex>
  );
};

export default ResetPasswordLayout;
