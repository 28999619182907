import React from 'react';
import {
  Drawer as ChakraDrawer,
  DrawerProps,
  DrawerOverlay as ChakraDrawerOverlay,
  DrawerContent as ChakraDrawerContent,
} from '@chakra-ui/react';
import {StyleConfig} from '../types';

export const DrawerConfig: StyleConfig = {
  parts: ['overlay', 'dialogContainer', 'dialog'],
  baseStyle: {},
  sizes: {},
  variants: {},
};

export type TDrawerProps = DrawerProps;

export const Drawer = (props: TDrawerProps) => (
  <ChakraDrawer {...props}>
    <ChakraDrawerOverlay />
    <ChakraDrawerContent>{props.children}</ChakraDrawerContent>
  </ChakraDrawer>
);
