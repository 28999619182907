import React from 'react';
import {
  Fade as ChakraFade,
  ScaleFade as ChakraScaleFade,
  Slide as ChakraSlide,
  SlideFade as ChakraSlideFade,
  FadeProps,
  ScaleFadeProps,
  SlideProps,
  SlideFadeProps,
} from '@chakra-ui/react';

export type TFade = FadeProps;
export type TSlide = SlideProps;
export type TScaleFade = ScaleFadeProps;
export type TSlideFade = SlideFadeProps;

export const Fade = (props: TFade) => {
  return <ChakraFade {...props} />;
};

export const ScaleFade = (props: TScaleFade) => {
  return <ChakraScaleFade {...props} />;
};

export const Slide = (props: TSlide) => {
  return <ChakraSlide {...props} />;
};

export const SlideFade = (props: TSlideFade) => {
  return <ChakraSlideFade {...props} />;
};
