import React from 'react';
import {topics} from 'spekit-datalayer';
import {ISpekFromState, TContentType} from 'spekit-types';
import {ControlledSelect} from 'spekit-ui';
import {useFormContext} from 'react-hook-form';

interface ITopicPicker {
  type: TContentType;
}

export const TopicPicker = ({type}: ITopicPicker) => {
  const {
    formState: {errors},
  } = useFormContext<ISpekFromState>();

  const getTopics = async (searchText: string) => {
    try {
      const topics_ = await topics.get(searchText, {allowedOnly: true});
      return topics_.map((topic) => ({
        value: topic.value,
        label: topic.label,
      }));
    } catch (err) {
      return [];
    }
  };

  return (
    <ControlledSelect
      testId='topic-picker'
      menuTestId='topic-picker-menu'
      name='topics'
      id='topics'
      label='Topics'
      isRequired={type === 'business_term' || type === 'asset'}
      isAsync
      isMulti
      cacheOptions
      isInvalid={!!errors.topics}
      defaultOptions
      placeholder='Select Topics'
      loadOptions={async (searchText: string) => getTopics(searchText)}
      labelTooltip='Topics determine who can access this Spek and on which domains it appears'
    />
  );
};
