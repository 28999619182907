import {IVerifyPinResponse, IRegeneratePinResponse} from 'spekit-types';

export const verifyPin = async (pin: string): Promise<IVerifyPinResponse> => {
  let response = await fetch(`/api/auth/verify_pin`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...(pin && {body: JSON.stringify({pin})}),
  });
  const result: IVerifyPinResponse = await response.json();
  return result;
};

export const regeneratePin = async (): Promise<IRegeneratePinResponse> => {
  let response = await fetch(`/api/auth/regenerate_pin`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  });
  const result: IRegeneratePinResponse = await response.json();
  return result;
};
