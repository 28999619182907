import React from 'react';
import {RiQuestionMark} from 'react-icons/ri';
import {DSButton as Button, Icon} from 'spekit-ui';

export interface IAskAnExpertProps {
  onAskExpert: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isSupportEmailEnabled?: boolean;
  size?: 'small' | 'medium';
  variant?: 'contained' | 'icon';
}

export const AskAnExpert = (props: IAskAnExpertProps) => {
  const {
    onAskExpert,
    isSupportEmailEnabled,
    size = 'medium',
    variant = 'contained',
  } = props;

  if (!isSupportEmailEnabled) return null;

  return (
    <Button
      size={size}
      onClick={onAskExpert}
      variant={variant}
      colorScheme='primary'
      role='link'
      aria-label='Ask an expert'
      data-testid='ask-an-expert-button'
      icon={
        variant === 'icon' ? (
          <Icon height='16px' width='16px' as={RiQuestionMark} />
        ) : undefined
      }
      tooltipLabel={variant === 'icon' ? 'Ask an expert' : undefined}
    >
      {variant === 'contained' ? 'Ask an expert' : null}
    </Button>
  );
};
