import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import {toWidget} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import EmbedCommand from './embedCommand';

// config for the custom embed plugin
interface EmbedConfig {
  embedRenderer: (file: any, domElement: any) => void;
}

export default class EmbedEditing extends Plugin {
  static get requires() {
    return [Widget];
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    // register embed command
    this.editor.commands.add('embedContent', new EmbedCommand(this.editor));
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    // define schema for upload progress
    schema.register('embeddedContent', {
      // Behaves like a self-contained object (e.g. an image).
      // isObject: true,

      // Allow in places where other blocks are allowed (e.g. directly in the root).
      // allowWhere: '$block',
      // allowWhere: '$inlineObject',
      inheritAllFrom: '$inlineObject',

      // only allow data-file attribute
      allowAttributes: ['data-file'],
    });
  }

  _defineConverters() {
    const editor = this.editor;
    const conversion = editor.conversion;
    const {embedRenderer} = editor.config.get('reactEmbed') as EmbedConfig;

    // converters ((data) view → model)
    conversion.for('upcast').elementToElement({
      view: {
        name: 'section',
        classes: 'upload-embed',
      },
      model: (viewElement, {writer: modelWriter}) => {
        // Read the "data-file" attribute from the view and set it as the data-file in the model.
        return modelWriter.createElement('embeddedContent', {
          'data-file': viewElement.getAttribute('data-file'),
        });
      },
    });

    // converters (model → data view)
    conversion.for('dataDowncast').elementToElement({
      model: 'embeddedContent',
      view: (modelElement, {writer: viewWriter}) => {
        return viewWriter.createEmptyElement('section', {
          class: 'upload-embed',
          'data-file': modelElement.getAttribute('data-file'),
        });
      },
    });

    // converters (model → editing view)
    conversion.for('editingDowncast').elementToElement({
      model: 'embeddedContent',
      view: (modelElement, {writer: viewWriter}) => {
        const file = (modelElement.getAttribute('data-file') as string) || undefined;

        // Embed is represented in output as a <section> element with its "data-file" attribute
        const section = viewWriter.createContainerElement('section', {
          class: 'upload-embed',
          'data-file': file,
        });

        const reactWrapper = viewWriter.createRawElement(
          'div',
          {
            class: 'ck-editor__react-wrapper',
          },
          function (domElement) {
            embedRenderer(file === undefined ? file : JSON.parse(file), domElement);
          }
        );

        viewWriter.insert(viewWriter.createPositionAt(section, 0), reactWrapper);

        return toWidget(section, viewWriter, {label: 'React embed widget'});
      },
    });
  }
}
