import {useQuery} from '@tanstack/react-query';
import {logging, QUERY_KEYS} from 'spekit-datalayer';
import {getRecentlyViewed} from 'spekit-datalayer/src/search';

export const useRecentlyViewed = () =>
  useQuery({
    cacheTime: 0,
    refetchOnMount: 'always',
    queryKey: [QUERY_KEYS.RecentlyViewedContent],
    queryFn: getRecentlyViewed,
    onError: (e) => logging.capture(e),
  });
