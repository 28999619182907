import React from 'react';

interface DescriptionWithLinksProps {
  description: string;
}

const DescriptionWithLinks: React.FC<DescriptionWithLinksProps> = ({description}) => {
  const parts = description.split(/(<a href='[^']+' target='_blank'>[^<]+<\/a>)/);

  return (
    <>
      {parts.map((part, index) => {
        if (part.startsWith("<a href='")) {
          const linkInfo = part.match(/<a href='([^']+)' target='_blank'>([^<]+)<\/a>/);

          if (linkInfo && linkInfo.length >= 3) {
            const url = linkInfo[1];
            const linkText = linkInfo[2];
            return (
              <a key={index} href={url} target='_blank' rel='noopener noreferrer'>
                {linkText}
              </a>
            );
          }
        } else if (part.trim() !== '') {
          return <span key={index}>{part}</span>;
        }

        return null;
      })}
    </>
  );
};

export default DescriptionWithLinks;
