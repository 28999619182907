import React from 'react';
import {FormControl as ChakraFormControl, FormControlProps} from '@chakra-ui/react';

export type TFormControlProps = FormControlProps;

export const FormControlConfig = {
  parts: ['container', 'requiredIndicator', 'helperText'],
  baseStyle: {},
  variants: {},
  sizes: {},
  defaultProps: {},
};
export const FormControl = (props: TFormControlProps) => <ChakraFormControl {...props} />;
