import {IBrandConfig, IBrandConfigResponse, ICustomBrandingFormState} from 'spekit-types';

export const apiToState = ({
  images: {brand_logo_url, icon_logo_url, white_brand_logo_url, white_icon_logo_url},
  colors: {primary},
}: IBrandConfigResponse): IBrandConfig => ({
  images: {
    brandLogoUrl: brand_logo_url,
    iconLogoUrl: icon_logo_url,
    whiteBrandLogoUrl: white_brand_logo_url,
    whiteIconLogoUrl: white_icon_logo_url,
  },
  colors: {
    primary,
  },
});

export const stateToApi = ({
  brandImage,
  iconImage,
  primaryColorInHex,
  whiteBrandImage,
  whiteIconImage,
}: ICustomBrandingFormState) => {
  const formData = new FormData();
  const appendImageData = (key: string, image: File | string | null) => {
    if (image) formData.append(`${key}_image`, image);
  };
  appendImageData('brand_logo', brandImage);
  appendImageData('icon_logo', iconImage);
  appendImageData('white_brand_logo', whiteBrandImage);
  appendImageData('white_icon_logo', whiteIconImage);
  if (primaryColorInHex) formData.append('primary_color', primaryColorInHex);
  return formData;
};
