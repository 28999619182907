import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  adminToolsAPI,
  checkTaskStatus,
  logging,
  PASSWORD_MISMATCH,
  PERMISSION_DENIED,
  utils,
} from 'spekit-datalayer';
import {
  Flex,
  Heading,
  Text,
  DSButton as Button,
  UnorderedList,
  ListItem,
  Icon,
  useToast,
  Box,
} from 'spekit-ui';
import {setExportJobId, setImportJobId} from '../../../../redux/actions';
import {RootState} from '../../../../reduxStore';
import {STEP_2} from '../../constants';
import {ProtectedAction} from '../ProtectedAction/ProtectedAction';
const {isThirdPartyUser} = utils;

interface IProtectedActionConfig {
  label: 'Download .XLSX file' | 'Upload .XLSX file';
  handler: (password?: string) => Promise<void>;
  type: 'password' | 'text';
  description: string;
  header?: string;
}

export const BulkUpload = () => {
  const [protectedActionConfig, setProtectedActionConfig] =
    useState<IProtectedActionConfig | null>(null);
  const {exportJobId, importJobId} = useSelector((state: RootState) => state.bulkUpload);
  const me = useSelector((state: RootState) => state.layout.me);
  const dispatch = useDispatch();
  const toast = useToast();
  const [isExporting, setIsExporting] = useState(false);

  const updateExportJobId = (exportJobId: string | null) =>
    dispatch(setExportJobId(exportJobId));
  const updateImportJobId = (importJobId: string | null) =>
    dispatch(setImportJobId(importJobId));

  const handleDownload = async () => {
    const initiateDownload = async (password?: string) => {
      try {
        const response = await adminToolsAPI.initiateExportWiki(
          me.company.salesforceorganizations_set[0].id,
          password
        );
        if (response.success) {
          updateExportJobId(response.task_id);
          await checkTaskStatus(response.task_id);
          window.open('/api/wiki/export/' + response.task_id + '/retrieve');
        }
      } catch (e) {
        if (e.message === PERMISSION_DENIED) {
          throw new Error(PASSWORD_MISMATCH);
        } else {
          logging.capture(e);
          toast({description: 'Could not prepare file for download.', variant: 'error'});
        }
      } finally {
        updateExportJobId(null);
        setIsExporting(false);
      }
    };
    if (isThirdPartyUser(me)) {
      setIsExporting(true);
      initiateDownload();
    } else {
      setProtectedActionConfig({
        label: 'Download .XLSX file',
        handler: initiateDownload,
        type: 'password',
        description:
          'Please type in your password to authorize the request to download this file.',
      });
    }
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files === null || files?.length === 0) return;
    const file = files[0];
    const initiateUpload = async (password?: string) => {
      try {
        const formData = new FormData();
        formData.append('file', file, e.target.value);
        if (password) formData.append('password', password);

        const response = await adminToolsAPI.initiateImportWiki(
          me.company.salesforceorganizations_set[0].id,
          formData
        );
        if (response.success) {
          updateImportJobId(response.task_id);
          await checkTaskStatus(response.task_id);
          toast({description: 'File imported into wiki.', variant: 'success'});
        }
      } catch (e) {
        if (e.message === PERMISSION_DENIED) {
          throw new Error(PASSWORD_MISMATCH);
        } else {
          logging.capture(e);
          toast({variant: 'error', description: 'Could not import data.'});
        }
      } finally {
        updateImportJobId(null);
      }
    };
    const config: Pick<IProtectedActionConfig, 'type' | 'description' | 'header'> =
      isThirdPartyUser(me)
        ? {
            type: 'text',
            description:
              "All rows left in the uploaded document will override existing data in the Wiki. Make sure you've removed any rows you don't want to alter.",
            header: 'Confirm bulk upload',
          }
        : {
            type: 'password',
            description:
              'Please type in your password to authorize the request to upload this file.',
          };
    setProtectedActionConfig({
      label: 'Upload .XLSX file',
      handler: initiateUpload,
      ...config,
    });
  };
  const showExportingLoaderOnBackgroundButton =
    (!!exportJobId && protectedActionConfig === null) || isExporting;
  const showImportingLoaderOnBackgroundButton =
    !!importJobId && protectedActionConfig === null;

  return (
    <Flex direction='column' gap={24}>
      {protectedActionConfig && (
        <ProtectedAction
          action={protectedActionConfig.handler}
          isOpen={!!protectedActionConfig}
          onClose={() => setProtectedActionConfig(null)}
          confirmLabel={protectedActionConfig.label}
          type={protectedActionConfig.type}
          description={protectedActionConfig.description}
          header={protectedActionConfig.header}
        />
      )}
      <Heading as='h4' fontWeight='semibold'>
        Bulk upload
      </Heading>
      {/* STEP 1 */}
      <Flex direction='column' gap={16} alignItems='flex-start'>
        <Flex direction='column' gap={10} alignItems='flex-start'>
          <Text fontWeight='semibold'>Step 1</Text>
          <Text variant='body2'>
            Download the .XLXS file of your wiki below and open it using an .XLXS tool
            such as Microsoft Excel, or the iOS Numbers app.
          </Text>
        </Flex>
        <Button
          variant='contained'
          size='medium'
          colorScheme='primary'
          onClick={handleDownload}
          isLoading={showExportingLoaderOnBackgroundButton}
          data-testid='download-xlsx'
        >
          Download .XLXS file
        </Button>
      </Flex>
      {/* STEP 2 */}
      <Flex direction='column' gap={16}>
        <Flex direction='column' gap={10}>
          <Text fontWeight='semibold'>Step 2</Text>
          <Text variant='body2'>Add or change information in your file as needed.</Text>
        </Flex>
        {STEP_2.map(({id, bullets, label, icon}) => (
          <Flex key={id} direction='column' gap={16}>
            <Flex alignItems='center' gap={6}>
              {icon && <Icon as={icon} />}
              <Text fontWeight='semibold' fontSize='sm'>
                {label}:
              </Text>
            </Flex>
            <UnorderedList m={0} p={0} ml={20}>
              {bullets.map((bullet) => (
                <ListItem key={bullet}>
                  <Text fontSize='small'>{bullet}</Text>
                </ListItem>
              ))}
            </UnorderedList>
          </Flex>
        ))}
      </Flex>
      {/* STEP 3 */}
      <Flex gap={16} direction='column' alignItems='flex-start'>
        <Flex direction='column' gap={10}>
          <Text fontWeight='semibold'>Step 3</Text>
          <Text variant='body2'>
            Save your .XLSX file and upload to Spekit below. Ensure that your file has the
            .xlsx extension when saving.
          </Text>
        </Flex>
        <Box>
          <input
            type='file'
            id='upload-xlsx'
            accept='.xls,.xlsx'
            onChange={handleUpload}
            multiple={false}
            style={{display: 'none'}}
            data-testid='file-upload-input'
          />
          <Button
            as='label'
            variant='contained'
            size='large'
            colorScheme='primary'
            isLoading={showImportingLoaderOnBackgroundButton}
            htmlFor='upload-xlsx'
            data-testid='upload-xlsx'
          >
            Upload .XLSX file
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};
