import React from 'react';
import {
  DSPopover as Popover,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  DSButton as Button,
  PopoverAnchor,
  Box,
} from 'spekit-ui';
import {NotificationForm} from './NotificationForm';
import {FormProvider, useForm} from 'react-hook-form';
import {defaultValues, resolver} from './form';
import {
  INotificationState,
  IExtendedNotificationState,
  TNotificationContentType,
  TNotificationButtonMode,
} from 'spekit-types';
import {notification} from 'spekit-datalayer';

const {safeNotificationProperties} = notification;

interface INotificationsContainer {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onSave: (notification: IExtendedNotificationState) => void;
  topics: string[];
  isSubmitting?: boolean;
  notificationAction: string;
  contentType: TNotificationContentType;
  mode: TNotificationButtonMode;
}

export const NotificationsContainer = ({
  children,
  isOpen,
  onClose,
  onSave,
  topics,
  isSubmitting,
  notificationAction,
  contentType,
  mode,
}: INotificationsContainer) => {
  const methods = useForm<INotificationState>({
    defaultValues,
    resolver,
    mode: 'onChange',
  });
  const {handleSubmit, watch} = methods;

  const [notifyInApp, notifyByEmail, createSpotlight] = watch([
    'notifyInApp',
    'notifyByEmail',
    'createSpotlight',
  ]);
  const hasAnyNotificationSelected = notifyInApp || notifyByEmail || createSpotlight;

  const handleSave = (data: INotificationState) =>
    onSave(safeNotificationProperties(data));

  return (
    <FormProvider {...methods}>
      <Popover
        isOpen={isOpen}
        variant='noSpacing'
        placement='bottom-end'
        autoFocus={false}
      >
        <PopoverAnchor>
          <Box>{children}</Box>
        </PopoverAnchor>
        <PopoverContent>
          <PopoverBody>
            <NotificationForm topics={topics} contentType={contentType} mode={mode} />
          </PopoverBody>
          <PopoverFooter>
            <Button
              size='medium'
              variant='ghost'
              colorScheme='white'
              onClick={onClose}
              data-testid='close-notification-form-btn'
            >
              Cancel
            </Button>
            <Button
              size='medium'
              variant='contained'
              colorScheme='primary'
              onClick={handleSubmit(handleSave)}
              disabled={!hasAnyNotificationSelected}
              data-testid='save-and-notify-users-btn'
              isLoading={isSubmitting}
            >
              {notificationAction} and notify users
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </FormProvider>
  );
};
