import {Component} from 'react';
import logo from './images/Logo.svg';
import BG from './images/logo_bg.svg';

class PublicLayout extends Component {
  render() {
    let styles = {
      main: {
        fontFamily: 'Open Sans, sans-serif',
        background: 'url(' + BG + ')',
        backgroundSize: 'cover',
        minHeight: '100vh',
      },
      navbar: {
        height: '70px',
        borderBottom: '1px solid #EBEDF8',
      },
      logoContainer: {
        display: 'inline-block',
        marginLeft: '30px',
        position: 'absolute',
        top: '0px',
      },
      logo: {
        height: '35px',
        marginTop: '15px',
      },
    };
    return (
      <div style={styles.main}>
        <div style={styles.logoContainer}>
          <img src={logo} style={styles.logo} alt='Spekit logo' />
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default PublicLayout;
