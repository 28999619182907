export type TToken = string | null;

interface IStorageType {
  setToken: (token: string) => Promise<void> | void;
  getToken: () => Promise<TToken> | TToken;
  removeToken: () => Promise<void> | void;
}

export class ExtensionTokenStorage implements IStorageType {
  private _storageKey = 'token';

  setToken(token: string) {
    chrome.storage.local.set({[this._storageKey]: token});
  }

  async getToken() {
    const item = await chrome.storage.local.get(this._storageKey);
    return item[this._storageKey] as TToken;
  }

  removeToken() {
    chrome.storage.local.remove(this._storageKey);
  }
}

export class WebappTokenStorage implements IStorageType {
  private _token: TToken = null;

  setToken(token: string) {
    this._token = token;
  }

  getToken() {
    return this._token;
  }

  removeToken() {
    this._token = null;
  }
}
