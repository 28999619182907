import {
  init,
  captureException,
  captureMessage as sentryMessage,
  startTransaction as sentryStartTransaction,
  withScope as sentryWithScope,
  Event,
  EventHint,
  setContext as setSentryContext,
  setUser as setSentryUser,
  setExtras,
  addBreadcrumb,
} from '@sentry/browser';

import {
  BrowserTracing,
  startIdleTransaction as sentryStartIdleTransaction,
} from '@sentry/tracing';

import {SamplingContext, Extras, Context, User} from '@sentry/types';
import {ISession} from 'spekit-types';
import {IGNORED_SENTRY_ERRORS} from '../constants';

// our default sample rate, will be overwritten with custom sampling rate
let spekitErrorSampleRate = 0.001;

function isBeta(): boolean {
  if (typeof chrome !== 'undefined' && chrome.runtime) {
    return !!chrome.runtime.getManifest()?.version_name?.includes('beta');
  }
  return false;
}

function isRunningInServiceWorker() {
  return typeof window === 'undefined';
}

function isIgnoredError(error: Error) {
  return IGNORED_SENTRY_ERRORS.some((ignoredError) =>
    error.message.includes(ignoredError)
  );
}

/**
 * Event filtering to weed out errors we're not interested in.
 * Returning null throws out the error.
 *
 * See https://docs.sentry.io/platforms/javascript/configuration/filtering/
 */
function beforeSend(event: Event, hint: EventHint) {
  // Allow callers to bypass our custom filtering.
  if (event.tags?.force === 'true' || event.extra?.force) {
    return event;
  }

  const error = hint.originalException;
  if (error instanceof Error && isIgnoredError(error)) {
    return null;
  }

  // Apply Sample rate for errors here.
  if (Math.random() > spekitErrorSampleRate) {
    return null;
  }

  return event;
}

/**
 * Gets the Traces and Sample Rate for chrome extension.
 */
function getTracesAndSampleRateForChrome(session?: ISession) {
  let tracesSampleRate = 0.001; // performance traces
  /* 
    Error Sample Rate 
    This is the default sample rate for errors. We keep it at 1
    and do our sampling ourselves in beforeSend. This allows us
    to sample specific errors/messages at 100% rather than at the
    configured error sample rate.
  */
  const sampleRate = 1.0;
  /*
    Configuration driven sampling rates -- settings are provided
    in session for company and user. User overrides company
    if user's rate is higher.
  */
  const companySettings = session?.company?.companysettings;
  const userSettings = session?.user_settings;

  // Traces sample rate -- NOTE: we set this to zero below for all
  if (companySettings?.sentry_traces_sample_rate) {
    tracesSampleRate = companySettings.sentry_traces_sample_rate;
  }
  if (userSettings?.sentry_traces_sample_rate) {
    tracesSampleRate = Math.max(tracesSampleRate, userSettings.sentry_traces_sample_rate);
  }

  // Error sample rate
  if (companySettings?.sentry_error_sample_rate) {
    spekitErrorSampleRate = companySettings.sentry_error_sample_rate;
  }
  if (userSettings?.sentry_error_sample_rate) {
    spekitErrorSampleRate = Math.max(sampleRate, userSettings.sentry_error_sample_rate);
  }

  // By setting process.env.SENTRY_CHROME_CAPTURE_ALL, we can
  // capture everything even for non-Beta builds. This is useful
  // for testing in Staging. It overwrites everything.
  if (isBeta() || process.env.SENTRY_CHROME_CAPTURE_ALL?.toLowerCase() === 'true') {
    tracesSampleRate = 1.0;
    spekitErrorSampleRate = 1.0;
  }

  return {tracesSampleRate, sampleRate};
}

/**
 * Sentry needs each build to be its
 * own release. We use what's in the manifest by default,
 * but allow it to be overwritten by an env variable.
 */
function getReleaseForChrome() {
  if (typeof chrome !== 'undefined' && chrome.runtime) {
    return chrome.runtime.getManifest().version_name;
  }
  if (process.env.SENTRY_CHROME_RELEASE) {
    return process.env.SENTRY_CHROME_RELEASE;
  }
  return undefined;
}

function initializeSentryForChrome(session?: ISession, source?: string) {
  const {tracesSampleRate, sampleRate} = getTracesAndSampleRateForChrome(session);
  const integrations = !isRunningInServiceWorker() ? [new BrowserTracing()] : [];
  const release = getReleaseForChrome();

  // Overwrite traces sampling rate for things we always want to get
  // from all users.
  const tracesSampler = (context: SamplingContext) => {
    const txName = context?.transactionContext?.name || 'unknown';
    if (['system-metrics-v2', 'user-icon-blacklist-action'].includes(txName)) {
      return 1.0;
    }
    // EXPLICITLY SET SAMPLE RATE TO ZERO FOR OTHER THINGS
    return 0.0;
  };

  init({
    dsn: process.env.SENTRY_CHROME_DSN,
    release,
    integrations,
    environment: process.env.SENTRY_ENVIRONMENT,
    beforeSend, // filtering
    attachStacktrace: true,
    tracesSampleRate,
    tracesSampler,
    sampleRate,
    initialScope: {
      tags: {
        source: source || 'unknown',
        companyId: session?.company?.id || 'unknown',
        tracesSampleRate: tracesSampleRate.toFixed(5),
        sampleRate: spekitErrorSampleRate.toFixed(5),
      },
      user: {
        email: session?.email ?? '',
        username: session?.username ?? '',
        id: session?.id ?? '',
        ip_address: '{{auto}}',
      },
    },
  });
}

function initializeSentryForWebapp(session?: ISession) {
  init({
    dsn: process.env.REACT_APP_SENTRY_WEBAPP_DSN,
    release: process.env.REACT_APP_SENTRY_WEBAPP_RELEASE,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_WEBAPP_ENVIRONMENT,
    beforeSend, // filtering
    attachStacktrace: true,
    initialScope: {
      tags: {
        source: 'Webapp',
        companyId: session?.company?.id || 'unknown',
      },
      user: {
        email: session?.email ?? '',
        username: session?.username ?? '',
        id: session?.id ?? '',
        ip_address: '{{auto}}',
      },
    },
  });
}

export function capture(exception: any, extras?: Extras) {
  if (extras) {
    setExtras(extras);
  }
  captureException(exception);
}

export function setContext(contextName: string, context: Context) {
  setSentryContext(contextName, context);
}

export function setUser(user: User) {
  setSentryUser(user);
}

// we need this for testing
export function setErrorSampleRate(rate: number) {
  spekitErrorSampleRate = rate;
}

export {
  sentryStartIdleTransaction,
  sentryStartTransaction,
  sentryWithScope,
  initializeSentryForChrome,
  beforeSend,
  addBreadcrumb,
  sentryMessage,
  initializeSentryForWebapp,
  spekitErrorSampleRate,
};
