import {logging, adminToolsAPI, QUERY_KEYS} from 'spekit-datalayer';
import {useQuery} from '@tanstack/react-query';

export const useCustomFields = () => {
  return useQuery({
    staleTime: Infinity,
    queryKey: [QUERY_KEYS.CustomFields],
    queryFn: adminToolsAPI.getCustomFields,
    onError: (e) => logging.capture(e),
  });
};
