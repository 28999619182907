import React, {useEffect} from 'react';
import {FormProvider} from 'react-hook-form';
import {Link, useHistory} from 'react-router-dom';
import {Flex, Heading, Text, Loader, DSButton as Button, Link as DSLink} from 'spekit-ui';
import LoginLayout from '../../components/LoginLayout';
import PasswordInput from '../../components/LoginInputFields/PasswordInput';
import UsernameInput from '../../components/LoginInputFields/UsernameInput';
import useLogin from '../../hooks/useLogin';
import SFError from '../../components/SFError/SFError';
import {getItem} from 'spekit-datalayer/src/utils/localStorage';

const LoginPage = () => {
  const {
    isLoading,
    isPageLoading,
    isSpekitLogin,
    loginMethod,
    usernameSubmitHandler,
    loginHandler,
    methods,
  } = useLogin();
  const history = useHistory();

  useEffect(() => {
    const username = getItem('username');
    if (username) methods.setValue('username', username);
  }, [methods]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      methods.handleSubmit(isSpekitLogin ? loginHandler : usernameSubmitHandler)();
    }
  };

  return (
    <LoginLayout isPageLoading={isPageLoading}>
      <SFError />
      <Flex direction='column' px={144} maxW={384} pt={176}>
        <Heading as='h3' fontWeight='semibold' fontSize='20px' textAlign='center' mt={44}>
          Login to Spekit
        </Heading>
        <Text variant='body2' fontWeight='normal' textAlign='center' mt={16} mb={32}>
          Enter your email address or username below and we’ll direct you to your
          organization’s login method. You can also choose to{' '}
          <DSLink
            data-testid='view-all-login-options'
            as={Link}
            to={{
              pathname: '/login-all-options',
              search: history.location.search,
              state: {loginFlow: true},
            }}
          >
            view all login options.
          </DSLink>
        </Text>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(
              isSpekitLogin ? loginHandler : usernameSubmitHandler
            )}
          >
            <Flex gap={20} direction='column'>
              <UsernameInput onKeyDown={handleKeyDown} autoFocus />
              {isSpekitLogin && <PasswordInput autoFocus onKeyDown={handleKeyDown} />}

              {isLoading ? (
                <Flex justifyContent='center' alignItems='center' py={20}>
                  <Loader size={8} />
                </Flex>
              ) : (
                <Button
                  data-testid='login-btn'
                  size='large'
                  variant='contained'
                  colorScheme='primary'
                  onClick={methods.handleSubmit(
                    isSpekitLogin ? loginHandler : usernameSubmitHandler
                  )}
                >
                  {isSpekitLogin ? 'Login' : 'Next'}
                </Button>
              )}
              {loginMethod && (
                <Text
                  data-testid='redirecting-text'
                  variant='caption1'
                  fontWeight='semibold'
                  textAlign='center'
                >
                  Redirecting you to {loginMethod}...
                </Text>
              )}
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    </LoginLayout>
  );
};

export default LoginPage;
