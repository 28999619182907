import React from 'react';
import 'react-dates/initialize';
import {SingleDatePicker, DateRangePicker} from 'react-dates';
import './datepicker.css';
import {SHORTENED_DATE_FORMAT} from 'spekit-datalayer';

class DateInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      focusedInput: null,
    };
    this.handleFocusChange = this.handleFocusChange.bind(this);
    this.handleRangeFocusChange = this.handleRangeFocusChange.bind(this);
    this.handleOutsideRange = this.handleOutsideRange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOutsideRange = this.handleOutsideRange.bind(this);
  }
  handleFocusChange(focus) {
    this.setState({focused: focus.focused});
  }
  handleRangeFocusChange(focusedInput) {
    this.setState({focusedInput});
  }
  handleChange(value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value);
    }
  }
  handleOutsideRange(date) {
    if (!this.props.limitRange) return false;

    if (this.props.beforeTodayInvalid) {
      return date.isBefore(new Date(), 'day');
    }
    return date.isAfter(new Date(), 'day');
  }

  render() {
    return (
      <div style={{zIndex: 99}}>
        {this.props.range ? (
          <DateRangePicker
            disabled={this.props.disabled}
            startDate={this.props.value.startDate}
            endDate={this.props.value.endDate}
            startDateId='foo'
            endDateId='bar'
            numberOfMonths={1}
            block
            readOnly
            noBorder
            hideKeyboardShortcutsPanel
            daySize={32}
            isOutsideRange={this.handleOutsideRange}
            openDirection={this.props.openUp ? 'up' : null}
            onDatesChange={this.handleChange}
            focusedInput={this.state.focusedInput}
            onFocusChange={this.handleRangeFocusChange}
            displayFormat={SHORTENED_DATE_FORMAT.toUpperCase()}
            dayAriaLabelFormat={SHORTENED_DATE_FORMAT.toUpperCase()}
          />
        ) : (
          <SingleDatePicker
            disabled={this.props.disabled}
            date={this.props.value}
            numberOfMonths={1}
            block
            readOnly
            noBorder
            hideKeyboardShortcutsPanel
            daySize={32}
            isOutsideRange={this.handleOutsideRange}
            openDirection={this.props.openUp ? 'up' : null}
            focused={this.state.focused}
            onFocusChange={this.handleFocusChange}
            onDateChange={this.handleChange}
            displayFormat={SHORTENED_DATE_FORMAT.toUpperCase()}
            dayAriaLabelFormat={SHORTENED_DATE_FORMAT.toUpperCase()}
          />
        )}
      </div>
    );
  }
}

export default DateInput;
