import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import LoginLayout from '../../components/LoginLayout';
import ResetPasswordLayout from '../../components/ResetPasswordLayout/ResetPasswordLayout';
import {useForm} from 'react-hook-form';
import {IPasswordFormState, IResetPasswordRequest} from 'spekit-types';
import {retrieveUsernamePassword, utils} from 'spekit-datalayer';
import PasswordConfirmation from './PasswordConfirmation';

const ResetPasswordConfirmationPage = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const methods = useForm<IPasswordFormState>({mode: 'onChange', criteriaMode: 'all'});

  const [isValidPasswordLink, setIsValidPasswordLink] = useState(false);

  useEffect(() => {
    const validatePasswordLink = async () => {
      const params = utils.parseQs(history.location.search);
      const {token} = params;

      try {
        const result = await retrieveUsernamePassword.checkValidPasswordToken(
          token as string
        );
        if (result.success) {
          setIsValidPasswordLink(true);
        } else {
          history.push('/reset-password-error');
        }
      } catch (e) {
        history.push('/reset-password-error');
      }
    };
    validatePasswordLink();
  }, [history]);

  const resetPasswordHandler = async (data: IPasswordFormState) => {
    setIsLoading(true);
    try {
      const values = utils.parseQs(history.location.search);
      const token = values['token'];
      const passwordRequest: IResetPasswordRequest = {
        request: token,
        password: data.password,
        verify_password: data.confirmPassword,
      };
      const result = await retrieveUsernamePassword.updatePassword(passwordRequest);
      if (result.success) {
        setIsSuccessful(true);
      } else {
        if (result.logged_in) {
          const {redir} = utils.parseQs(history.location.search);
          history.push(redir ? decodeURIComponent(redir as string) : '/app');
        }
        methods.setError('confirmPassword', {
          type: 'general',
          message: result.message,
        });
      }
    } catch (e) {
      methods.setError('confirmPassword', {
        type: 'general',
        message: e.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginLayout hideQuote hideImage>
      {isSuccessful ? (
        <PasswordConfirmation />
      ) : isValidPasswordLink ? (
        <ResetPasswordLayout
          methods={methods}
          isLoading={isLoading}
          passwordLabel='New password'
          confirmPasswordLabel='Re-enter new password'
          heading={'Reset Spekit Password'}
          submitButtonText={'Reset password'}
          passwordHandler={resetPasswordHandler}
        />
      ) : null}
    </LoginLayout>
  );
};

export default ResetPasswordConfirmationPage;
