import {ISearchResult, ISearchDocumentTypeCount} from 'spekit-types';

interface ISearchState {
  queryId: string;
  results: ISearchResult[];
  totalCount: number;
  loading: boolean;
  contentTypeCounts: ISearchDocumentTypeCount;
  query: string;
  prevPage: number | null;
  page: number;
  undocumented: string;
  nonce: string;
  lastPageReached: boolean;
}

interface IUpdateSavedResultHidden {
  type: 'UPDATE_SAVED_RESULT_HIDDEN';
  payload: {
    id: string;
    hidden: boolean;
  };
}
interface ISetSearchResults {
  type: 'SET_SEARCH_RESULTS';
  payload: ISearchState;
}
interface ISetSearchResultsLoader {
  type: 'SET_SEARCH_RESULTS_LOADER';
  payload: {
    loading: boolean;
  };
}

type TAction = ISetSearchResults | ISetSearchResultsLoader | IUpdateSavedResultHidden;

const initialState: ISearchState = {
  results: [],
  totalCount: 0,
  queryId: '',
  contentTypeCounts: {
    spek: 0,
    flow: 0,
    topic: 0,
    spotlight: 0,
    quiz: 0,
    asset: 0,
  },
  loading: false,
  query: '',
  page: 1,
  prevPage: null,
  undocumented: '',
  nonce: '',
  lastPageReached: false,
};

// eslint-disable-next-line default-param-last
function searchResultsReducer(state = initialState, action: TAction) {
  switch (action.type) {
    case 'SET_SEARCH_RESULTS':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_SEARCH_RESULTS_LOADER':
      return {...state, ...action.payload};
    case 'UPDATE_SAVED_RESULT_HIDDEN':
      const {hidden, id} = action.payload;
      return {
        ...state,
        results: state.results.map((term) =>
          term.id === id ? {...term, hidden} : {...term}
        ),
      };
    default:
      return state;
  }
}

export default searchResultsReducer;
