import React from 'react';
import FocusLock from 'react-focus-lock';

import {
  PopoverBody as ChakraPopoverBody,
  PopoverBodyProps,
  forwardRef,
} from '@chakra-ui/react';

export type TPopoverBodyProps = PopoverBodyProps;

export const PopoverBody = (props: TPopoverBodyProps) => (
  <ChakraPopoverBody>
    <FocusLock>{props.children}</FocusLock>
  </ChakraPopoverBody>
);
