export const getBrowser = function (): 'chrome' | 'old-edge' | 'edge' | 'firefox' {
  const {userAgent} = window.navigator;
  if (/Edge\/([0-9._]+)/.test(userAgent)) {
    return 'old-edge';
  }
  if (/Edg\/([0-9.]+)/.test(userAgent)) {
    return 'edge';
  }
  if (/Firefox\/([0-9.]+)(?:\s|$)/.test(userAgent)) {
    return 'firefox';
  }
  if (/(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/.test(userAgent)) {
    return 'chrome';
  }
  return 'chrome';
};
