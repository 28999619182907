import {IOptionType} from 'spekit-types';

interface Action {
  type: string;
  payload: any;
  message?: string;
  teams?: IOptionType[];
}

const initialState = {
  isOpen: false,
  spotlight: null,
  isNew: false,
  knowledgeCheck: null,
  flow: null,
  message: '',
  teams: [],
};

function spotlightModalReducer(state = initialState, action: Action) {
  switch (action.type) {
    case 'SHOW_SPOTLIGHT':
      return {
        ...state,
        message: action.message,
        teams: action.teams,
        isOpen: true,
        isNew: true,
        spotlight: null,
      };
    case 'CREATE_SPOTLIGHT_WITH_KNOWLEDGE_CHECK':
      return {
        ...state,
        isOpen: true,
        isNew: true,
        spotlight: null,
        knowledgeCheck: action.payload,
      };
    case 'CREATE_SPOTLIGHT_WITH_FLOW':
      return {
        ...state,
        isOpen: true,
        isNew: true,
        spotlight: null,
        flow: action.payload,
      };
    case 'SHOW_SPOTLIGHT_ID':
      return {
        ...state,
        isOpen: true,
        isNew: false,
        spotlight: action.payload,
      };
    case 'CREATE_SPOTLIGHT_WITH_FILE':
      return {
        ...state,
        isOpen: true,
        isNew: true,
        spotlight: null,
        message: action.payload.message,
        teams: action.payload.teams,
        fileDetails: action.payload.fileDetails,
      };
    case 'CLOSE_SPOTLIGHT':
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default spotlightModalReducer;
