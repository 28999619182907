import React, {forwardRef} from 'react';
import {Button, ButtonProps} from '@chakra-ui/react';
import {IconType} from 'react-icons';
import {Icon} from '../../Icon/Icon';

interface IExtraNavigationButtonProps {
  icon?: IconType;
}

export type INavigationButtonProps = IExtraNavigationButtonProps & ButtonProps;

export const NavigationButton = forwardRef((props: INavigationButtonProps, ref: any) => {
  const {children, icon, ...rest} = props;

  return (
    <Button
      ref={ref}
      variant='unstyled'
      pl={12}
      pr={12}
      py={8}
      borderRadius={6}
      leftIcon={icon ? <Icon as={icon} /> : undefined}
      display='flex'
      alignItems='center'
      outline='none'
      borderWidth='1px'
      borderColor='transparent'
      borderStyle='solid'
      fontSize='small'
      fontWeight={400}
      color='neutral.800'
      iconSpacing={6}
      lineHeight='20px'
      _hover={{
        bg: 'primary.25',
      }}
      _active={{
        color: 'primary.700',
        bg: 'primary.25',
      }}
      _focusVisible={{
        borderWidth: '1px',
        borderColor: 'primary.500',
        borderStyle: 'solid',
      }}
      {...rest}
    >
      {children}
    </Button>
  );
});
