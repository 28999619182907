import {ablyActionType} from '../redux/action_types';

interface Action {
  type: typeof ablyActionType;
  payload: [];
}

const initialState = {
  channels: [],
};

// eslint-disable-next-line default-param-last
function ablyChannelReducer(state = initialState, action: Action) {
  switch (action.type) {
    case ablyActionType:
      return {
        ...state,
        channels: [...action.payload],
      };
    default:
      return state;
  }
}

export default ablyChannelReducer;
