import React from 'react';
import {MenuItem as ChakraMenuItem, MenuItemProps} from '@chakra-ui/react';

import {Flex, VStack} from '../Layout';
import {Text} from '../Typography';

interface ComplexMenuItemProps {
  text: string;
  description: string;
  tag?: React.ReactNode;
  icon: React.ReactNode;
}

export type TComplexMenuItemProps = MenuItemProps & ComplexMenuItemProps;

export const ComplexMenuItem = (props: TComplexMenuItemProps) => {
  const {text, description, tag, icon, ...rest} = props;
  return (
    <ChakraMenuItem {...rest}>
      <Flex gap={10} align='flex-start'>
        {icon}
        <VStack mt={4} align='flex-start' spacing={4}>
          <Flex align='baseline' gap={8}>
            <Text variant='body1' fontWeight='semibold'>
              {text}
            </Text>
            {tag}
          </Flex>
          <Text variant='caption1' fontWeight='normal'>
            {description}
          </Text>
        </VStack>
      </Flex>
    </ChakraMenuItem>
  );
};
