import {IAuthLoginResponse, ISession} from 'spekit-types';
import {fetchMiddleWare as fetch} from './fetchMiddleWare';

export const login = async (
  username: string,
  password: string,
  fingerprint: string,
  companyType?: number
): Promise<IAuthLoginResponse> => {
  let response: any = await fetch('/api/auth/login', {
    method: 'post',
    credentials: 'include',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    body:
      encodeURIComponent('username') +
      '=' +
      encodeURIComponent(username) +
      '&' +
      encodeURIComponent('password') +
      '=' +
      encodeURIComponent(password) +
      '&' +
      encodeURIComponent('browser_fingerprint') +
      '=' +
      encodeURIComponent(fingerprint) +
      (companyType !== null && companyType !== undefined
        ? `&company_type=${encodeURIComponent(companyType)}`
        : ''),
  });
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 400 || response.status === 403) {
    const result = await response.json();
    if (!result.multiple_users && !result.logged_in) {
      if (result.message === 'A login session is already in progress!') {
        return {
          ...result,
          login_in_progress: true,
        };
      } else {
        throw new Error(
          result.message ||
            'The username or password you entered is incorrect. Try again or login with SSO or Salesforce.'
        );
      }
    } else {
      return result;
    }
  } else if (response.status === 429) {
    throw new Error(
      'You’ve made too many login attempts, please wait 10 minutes before trying again'
    );
  } else if (response.status === 401) {
    throw new Error('Locked out');
  } else {
    throw new Error('Something went wrong. Please try again later.');
  }
};

export const getSession = async () => {
  const response = await fetch('/api/auth/session', {
    credentials: 'include',
    redirect: 'manual',
  });
  if (!response.ok) throw new Error('not logged in.');
  const session: ISession = await response.json();
  return session;
};
