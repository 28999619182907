import React, {useRef} from 'react';
import {IOptionType} from 'spekit-types';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  AsyncSelect,
  Select,
  TSelectInstanceRef,
  Box,
  DSTooltip as Tooltip,
  Flex,
  Icon,
} from 'spekit-ui';

import debounce from 'debounce-promise';
import {topics as TopicsAPI} from 'spekit-datalayer';
import {RiInformationLine} from 'react-icons/ri';

interface ITopicSelectorProps {
  isInvalid?: boolean;
  defaultValues: IOptionType[] | null;
  handleTopicsChange: (newValue: IOptionType[]) => void;
  label: string;
  placeholder?: string;
  dataTestId?: string;
  errorTestId?: string;
  isAsync?: boolean;
  allowedOnlyTopics?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  labelTooltip?: string;
  blurInputOnSelect?: boolean;
}

export function TopicSelector({
  isInvalid,
  handleTopicsChange,
  defaultValues,
  label,
  placeholder = 'Select Topics',
  dataTestId,
  errorTestId,
  isAsync = true,
  allowedOnlyTopics,
  isDisabled = false,
  isRequired = true,
  labelTooltip,
  blurInputOnSelect = false,
}: ITopicSelectorProps) {
  const getTopics = async (searchText: string) => {
    const topicsConfig = allowedOnlyTopics ? {allowedOnly: true} : {assignableOnly: true};

    try {
      const fetchedTopics = await TopicsAPI.get(searchText, topicsConfig);
      return fetchedTopics.map((topic) => ({
        value: topic.value,
        label: topic.label,
      }));
    } catch (err) {
      return [];
    }
  };

  const getDebouncedTopics = debounce(getTopics, 200, {leading: false});
  const selectRef = useRef<TSelectInstanceRef>(null);
  return (
    <FormControl isInvalid={isInvalid} id='topics' isRequired={isRequired}>
      <Flex alignItems='center'>
        <FormLabel data-testid={dataTestId} me={4}>
          {label}
        </FormLabel>
        {labelTooltip && (
          <Tooltip label={labelTooltip} placement='top'>
            <Flex alignItems='center' data-testid='label-tooltip'>
              <Icon as={RiInformationLine} color='neutral.800' h='16px' w='16px' />
            </Flex>
          </Tooltip>
        )}
      </Flex>
      {isAsync ? (
        <AsyncSelect
          ref={selectRef}
          isDisabled={isDisabled}
          name='topics'
          isMulti
          placeholder={placeholder}
          aria-live='off'
          menuPlacement='auto'
          defaultOptions
          cacheOptions
          loadOptions={getDebouncedTopics}
          defaultValue={defaultValues}
          onChange={(value) => {
            handleTopicsChange(value);
          }}
          blurInputOnSelect={blurInputOnSelect}
        />
      ) : (
        <Select
          name='topics'
          isDisabled={isDisabled}
          ref={selectRef}
          isMulti
          placeholder={placeholder}
          aria-live='off'
          menuPlacement='auto'
          options={defaultValues ?? undefined}
          //Weird typings for React chakra select
          onChange={(value) => {
            handleTopicsChange(value as any);
          }}
          blurInputOnSelect={blurInputOnSelect}
        />
      )}
      {isInvalid && (
        <FormErrorMessage data-testid={errorTestId}>
          Select at least one Topic
        </FormErrorMessage>
      )}
    </FormControl>
  );
}
