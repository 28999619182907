import React, {useState} from 'react';
import {createIntelligenceCompletion, logging} from 'spekit-datalayer';
import {ICreateIntelligenceCompletionResponse} from 'spekit-types';

/**
 * To add new AI features, add a new function to this hook, and update the AIFeatureName type:
 * mono/services/spekit-frontend/spekit-types/src/ai.ts
 *
 * The intelligence endpoint and all AI features are defined here:
 * mono/services/spekit-backend/spekit/api/views/generic.py
 *
 * You must add a matching prompt on the back end for new features.
 *
 * This implementation will mature as we enter beta and iterate based on feedback and demand.
 */

export const useAI = () => {
  // loading state
  const [processing, setProcessing] = useState(false);

  // request handler to return appropriate result as per the chat completion response
  const requestHandler = async (
    fn: () => Promise<ICreateIntelligenceCompletionResponse>
  ) => {
    setProcessing(true);

    try {
      const {content} = await fn();
      setProcessing(false);
      if (content) return content;
    } catch (e) {
      logging.capture(e);
    }

    setProcessing(false);
    return null;
  };

  const generateTitle = async (content: string, spekID: string) =>
    requestHandler(() =>
      createIntelligenceCompletion({
        aiFeatureName: 'title',
        userContent: content,
        spekID,
      })
    );

  // clean functionality
  const cleanContent = async (content: string, spekID: string) =>
    requestHandler(() =>
      createIntelligenceCompletion({
        aiFeatureName: 'clean',
        userContent: content,
        spekID,
      })
    );

  // summary functionality
  const summarize = async (content: string, spekID: string) =>
    requestHandler(() =>
      createIntelligenceCompletion({
        aiFeatureName: 'summarize',
        userContent: content,
        spekID,
      })
    );

  // stylize functionality
  const stylize = async (content: string, spekID: string) =>
    requestHandler(() =>
      createIntelligenceCompletion({
        aiFeatureName: 'stylize',
        userContent: content,
        spekID,
      })
    );

  // translate functionality
  const translate = async (
    content: string,
    languageFrom: string,
    languageTo: string,
    spekID: string
  ) => {
    return requestHandler(() =>
      createIntelligenceCompletion({
        aiFeatureName: 'translate',
        userContent: `[Content: ${content}][Original Language: ${languageFrom} Target Language: ${languageTo}]]`,
        spekID,
      })
    );
  };

  // request functionality
  const request = async (content: string, prompt: string, spekID: string) =>
    requestHandler(() =>
      createIntelligenceCompletion({
        aiFeatureName: 'request',
        userContent: content,
        userPrompt: prompt,
        spekID,
      })
    );

  return {
    cleanContent,
    summarize,
    stylize,
    request,
    processing,
    translate,
    generateTitle,
  };
};
