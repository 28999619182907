import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Box, notifications, Loader, useToast, Text} from 'spekit-ui';
import {forwardRef} from '@chakra-ui/react';

// @ts-ignore
import {CKEditor, EditorConfig} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';

import {spekAPI} from 'spekit-datalayer';
import {getEditorConfiguration} from '../config/editorConfig';
import {IFramer, IFramerOptions} from './IFramer';
import {EmbedCard, EmbeddedFile} from './EmbedCard';

import {useAI} from '../../hooks/useAI';

import {customMentionRenderer} from '../plugins/mentions';
import {Speki} from '../../Speki/Speki';
import {getSelectedContent} from '../plugins/util';

const {notify} = notifications;
const genericErrorMessage = 'Request unsuccessful. Please try again.';

// this function is used to add a custom class to the root element of the editor
function classMaster(editor: ClassicEditor, newClassName: string) {
  // we add a custom class to the root element of the editor to scope it
  // this is necessary because CKEditor styles are global and will affect other elements on apps that also display CKEditor (Catalyst)
  editor.editing.view.change((writer) => {
    // Get the editing root element.
    const editingRoot = editor.editing.view.document.getRoot();
    if (editingRoot) {
      // Add the custom class to the root element.
      writer.addClass(newClassName, editingRoot);
    }
  });
}

const updateContent = (
  editor: ClassicEditor,
  selectedContent: string,
  updatedContent: string | null
) => {
  if (!updatedContent) return false;

  const viewFragment = editor.data.processor.toView(updatedContent);
  const modelFragment = editor.data.toModel(viewFragment);

  editor.model.change((writer) => {
    if (selectedContent) {
      // If the user made a selection, replace that with the updatedContent.
      editor.model.insertContent(modelFragment);
    } else {
      // If no selection was made, replace the entire editor content.
      const root = editor.model.document.getRoot();
      if (root) {
        const range = writer.createRangeIn(root);
        writer.setSelection(range);
        editor.model.insertContent(modelFragment);
      } else {
        console.error('Editor root not found.');
        return;
      }
    }
  });

  // The following line clears the browser's native selection, if any.
  window.getSelection()?.removeAllRanges();
  return true;
};

export interface EditingViewProps {
  config?: any;
  placeholder?: string;
  value: string;
  host?: string | null;
  onChange: (value: string) => void;
  enhanced?: boolean;
  spekID?: string;
  isError?: boolean;
  errorMessage?: string;
  onCharactersCountChange?: (value: number) => void;
  readOnly?: boolean;

  // redux actions - we use these to disable/enable the save button when uploading images and files
  setUploadBusy: () => void;
  unSetUploadBusy: () => void;
}

export const EditingView = forwardRef(
  (
    {
      placeholder = '',
      value,
      host,
      onChange,
      onCharactersCountChange,
      setUploadBusy,
      unSetUploadBusy,
      enhanced,
      spekID,
      isError,
      errorMessage,
      readOnly,
      config,
    }: EditingViewProps,
    ref
  ) => {
    const {cleanContent, summarize, stylize, request, processing, translate} = useAI();
    const [showSpeki, setShowSpeki] = React.useState(false);
    const [isEditorReady, setIsEditorReady] = React.useState(false);
    const editorRef = React.useRef<ClassicEditor | null>(null);
    const toast = useToast();

    const showError = (message: string) => {
      toast({
        variant: 'error',
        description: message,
      });
    };

    useEffect(() => {
      if (isEditorReady) {
        if (readOnly) {
          // @ts-ignore
          editorRef.current.enableReadOnlyMode?.('editing-view');
        } else {
          // @ts-ignore
          editorRef.current.disableReadOnlyMode?.('editing-view');
        }
      }
    }, [readOnly, isEditorReady]);

    return (
      <Box w='100%'>
        <Box
          width='100%'
          data-testid='ck-editor-wrapper'
          onKeyDown={(event) => {
            // prevent enter and tab events to bubble up to the parent
            // CKE will still allow tabbing out of the editor if user is not editing a tabbable element
            if (event.key === 'Tab' || event.key === 'Enter') {
              event.stopPropagation();
              if (event.key === 'Tab') {
                event.preventDefault();
              }
            }
          }}
          pos={'relative'}
          sx={
            showSpeki
              ? {
                  '.spekit-ck-content.ck-content': {
                    // 54px top padding to make room for input
                    paddingTop: '54px !important',
                  },
                }
              : undefined
          }
          borderRadius={2}
          outline='1px solid'
          outlineColor={isError ? 'error.500' : 'transparent'}
        >
          {/* loading indicator for AI generated summary */}
          {processing && (
            <Box
              position={'absolute'}
              zIndex={100}
              height={'100%'}
              width={'100%'}
              top={0}
              left={0}
              backgroundColor={'rgba(255,255,255,0.5)'}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <Loader variant='pulse' size={14} />
            </Box>
          )}
          {showSpeki && enhanced && (
            <Box
              width={'100%'}
              padding={'10px'}
              zIndex={20}
              top={'38px'}
              right={'0px'}
              pos={'absolute'}
              boxSizing={'border-box'}
            >
              <Speki
                onClose={() => setShowSpeki(false)}
                makeRequest={async (requestText: string) => {
                  const response = await request(
                    value,
                    requestText,
                    spekID || 'new spek'
                  );
                  if (response) {
                    // onChange(response);
                    editorRef.current && updateContent(editorRef.current, '', response);
                  } else {
                    showError(genericErrorMessage);
                  }
                }}
              />
            </Box>
          )}
          <CKEditor
            editor={ClassicEditor}
            config={{
              ...({
                // get the base configuration
                ...getEditorConfiguration(enhanced),

                // experimental AI features
                aiConfig: {
                  summarize: async (editorContent: string, editor: ClassicEditor) => {
                    if (!editorContent.trim()) {
                      showError('Please write something to summarize!');
                      return;
                    }

                    const selectedContent = getSelectedContent(editor);
                    const summarizedContent = await summarize(
                      selectedContent || editorContent,
                      spekID || 'new spek'
                    );

                    !updateContent(editor, selectedContent, summarizedContent) &&
                      showError(genericErrorMessage);
                  },

                  clean: async (editorContent: string, editor: ClassicEditor) => {
                    if (!editorContent.trim()) {
                      showError('Please write something to proofread!');
                      return;
                    }

                    const selectedContent = getSelectedContent(editor);
                    const cleanedContent = await cleanContent(
                      selectedContent || editorContent,
                      spekID || 'new spek'
                    );

                    !updateContent(editor, selectedContent, cleanedContent) &&
                      showError(genericErrorMessage);
                  },

                  stylize: async (editorContent: string, editor: ClassicEditor) => {
                    if (!editorContent.trim()) {
                      showError('Please write something to stylize!');
                      return;
                    }

                    const selectedContent = getSelectedContent(editor);
                    const stylizedContent = await stylize(
                      selectedContent || editorContent,
                      spekID || 'new spek'
                    );

                    !updateContent(editor, selectedContent, stylizedContent) &&
                      showError(genericErrorMessage);
                  },

                  translate: async (
                    editorContent: string,
                    editor: ClassicEditor,
                    languageFrom: string,
                    languageTo: string
                  ) => {
                    if (!editorContent.trim()) {
                      showError('Please write something to translate!');
                      return;
                    }

                    const selectedContent = getSelectedContent(editor);
                    const translatedContent = await translate(
                      selectedContent || editorContent,
                      languageFrom,
                      languageTo,
                      spekID || 'new spek'
                    );

                    !updateContent(editor, selectedContent, translatedContent) &&
                      showError(genericErrorMessage);
                  },
                  revert: (previousVersion: string) => {
                    onChange(previousVersion);
                  },
                  toggleSpeki: () => setShowSpeki((prev) => !prev),
                },

                // configure mention plugin - we do it here so we can use props
                // https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html
                mention: {
                  dropdownLimit: 6,
                  feeds: [
                    {
                      marker: '#',
                      feed: async (query: string) => spekAPI.getMentions(query),
                      itemRenderer: customMentionRenderer,
                      minimumCharacters: 3,
                    },
                  ],
                },
                // placeholder text - comes from a prop
                placeholder,
                // used for image and file uploads
                uploadConfig: {
                  host,
                  onError: (text: string) => notify({error: true, text}),
                  onUploadStart: setUploadBusy,
                  onUploadEnd: unSetUploadBusy,
                },
                // used when embedding react components
                reactEmbed: {
                  embedRenderer: (
                    file: EmbeddedFile | undefined,
                    domElement: HTMLElement
                  ) => {
                    ReactDOM.render(
                      <EmbedCard enabled={false} file={file} host={host} />,
                      domElement
                    );
                  },
                  iframeRenderer: (
                    src: string,
                    options: IFramerOptions,
                    domElement: HTMLElement
                  ) => {
                    ReactDOM.render(<IFramer src={src} options={options} />, domElement);
                  },
                },
              } as unknown as EditorConfig),
              ...config,
            }}
            data={value}
            onChange={(_, editor) => {
              const editorData = editor.getData();
              onChange(editorData);

              if (onCharactersCountChange) {
                // @ts-ignore:next-line
                onCharactersCountChange(editor.plugins.get('WordCount').characters);
              }
            }}
            onReady={(editor) => {
              classMaster(editor, 'spekit-ck-content');
              editorRef.current = editor;
              setIsEditorReady(true);
            }}
          />
        </Box>
        {isError && (
          <Text mt={6} color='error.600' fontSize='tiny' whiteSpace='initial'>
            {errorMessage}
          </Text>
        )}
      </Box>
    );
  }
);
