import React from 'react';
import {PopoverAnchor as ChakraPopoverAnchor} from '@chakra-ui/react';

export interface IPopoverAnchorProps {
  children: React.ReactNode;
}

export const PopoverAnchor = ({children}: IPopoverAnchorProps) => (
  <ChakraPopoverAnchor>{children}</ChakraPopoverAnchor>
);
