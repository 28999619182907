export const FONT_FAMILY = 'Open Sans, sans-serif';

export const fontSizes = {
  massive: '32px',
  jumbo: '28px',
  xl: '24px',
  large: '20px',
  medium: '16px',
  small: '14px',
  tiny: '12px',
  micro: '8px',
};
