import styles from './ErrorPage.module.css';
import {Fontawesome} from 'spekit-ui';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {faKey, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import SpekitIcon from '../../../../images/spekit_new_icon.svg';

function ErrorPage() {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.iconsContainer}>
        <img src={SpekitIcon} alt='Spekit' className={styles.spekitIcon} />
        <div className={styles.errorContainer}>
          <div className={styles.rectangle}></div>
          <Fontawesome name={faTimesCircle} svgClassname={styles.errorIcon} />
          <div className={styles.rectangle}></div>
        </div>

        <div className={styles.ssoIcon}>
          <div className={styles.ssoIconContainer}>
            <Fontawesome name={faKey} svgClassname={styles.iconkey} />
          </div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.statusContainer}>
          <Fontawesome name={faExclamationCircle} svgClassname={styles.exclamationIcon} />{' '}
          <div className={styles.errorText}>Error 500</div>
        </div>
        <div className={styles.errorDescription}>Failed to connect.</div>
        <div className={styles.contactDescription}>
          <div>Try again or contact our support team at </div>
          <div className={styles.email}> support@spekit.co.</div>
        </div>
      </div>
    </div>
  );
}
export default ErrorPage;
