import {TSearchContentTypes} from 'spekit-types';
import {stringifyQs} from './commonUtils';

interface ISearchParams {
  search: string;
  page: number;
  contentType: TSearchContentTypes;
  undocumented: boolean;
}
export const contentTypeOrdering = [
  'All',
  'Speks',
  'Files',
  'Topics',
  'Spotlights',
  'Flows',
  'Knowledge Checks',
  'Seismic',
] as const;
export const getContentTypeIndex = (type: TSearchContentTypes) =>
  contentTypeOrdering.indexOf(type);

export function buildSearchQuery({
  search = '',
  contentType = 'All',
  ...params
}: Partial<ISearchParams>) {
  return stringifyQs({
    query: encodeURIComponent(search),
    page: 1,
    undocumented: false,
    contentType,
    contentTypeIndex: getContentTypeIndex(contentType),
    ...params,
  });
}
