import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {IBrandConfig, ICustomBrandingFormState} from 'spekit-types';
import {SUPPORTED_FORMATS, DEFAULTS, ERROR_MESSAGES} from './constants';

export const getDefaultValues = ({
  colors: {primary},
  images: {brandLogoUrl, iconLogoUrl, whiteIconLogoUrl, whiteBrandLogoUrl},
}: IBrandConfig): ICustomBrandingFormState => ({
  primaryColorInHex: primary || DEFAULTS.primaryColor,
  brandImage: brandLogoUrl,
  iconImage: iconLogoUrl,
  whiteBrandImage: whiteBrandLogoUrl,
  whiteIconImage: whiteIconLogoUrl,
});

const shapeLogo = () =>
  yup
    .mixed()
    .test('fileType', ERROR_MESSAGES.supportedFormat, (file: string | File | null) => {
      if (!file || typeof file === 'string') return true;
      return SUPPORTED_FORMATS.includes(file?.type);
    });

export const schema = yup.object().shape({
  brandImage: shapeLogo(),
  iconImage: shapeLogo(),
  whiteBrandImage: shapeLogo(),
  whiteIconImage: shapeLogo(),
  primaryColorInHex: yup
    .string()
    .test('isRequired', 'Enter primary color hex code', (value) => value?.length !== 1)
    .matches(/^#([0-9A-Fa-f]{3}){1,2}$/, ERROR_MESSAGES.hexColor),
});

export const resolver = yupResolver(schema);
