import React from 'react';
import styles from './spekInput.module.css';
import Select from 'react-select/async';
import {components} from 'react-select';
import {Fontawesome, Dropdown, AsyncSelect} from 'spekit-ui';
import {faSitemap} from '@fortawesome/free-solid-svg-icons';

import {SpekLookup, logging} from 'spekit-datalayer';
import debounce from 'debounce-promise';
import {Speks} from './spekInput.types';

import {State, Team, Term} from '../types';
const {capture} = logging;
const {spekLookup} = SpekLookup;

const EnhancedDropDown: any = Dropdown(Select);

const SingleValue = (props: any) => {
  if (props.selectProps.spekInput) {
    return (
      <>
        <components.SingleValue {...props}>
          <span style={{fontSize: '10px'}}>
            <span
              style={{
                display: 'inline-block',
                padding: '2px',
                marginRight: '0.3em',
                borderRadius: '3px',
                backgroundColor: '#DDDDDD',
                color: '#637280',
              }}
            >
              <Fontawesome name={faSitemap} style={{marginRight: '0.2em'}} />
              <span>
                {
                  {
                    object: 'object',
                    field: 'field',
                    field_value: 'picklist value',
                    business_term: 'spek',
                  }[props.data.term_type]
                }
              </span>
            </span>
            {props.children}
          </span>
        </components.SingleValue>
      </>
    );
  } else {
    return <components.SingleValue {...props}>{props.children}</components.SingleValue>;
  }
};

const Option = (props: any) => {
  return (
    <React.Fragment>
      {props.selectProps.spekInput ? (
        <span>
          <components.Option {...props}>
            <span className={styles.optionWrapper}>
              <span className={styles.option}>
                <Fontawesome name={faSitemap} style={{marginRight: '0.2em'}} />
                <span>
                  {
                    {
                      object: 'object',
                      field: 'field',
                      field_value: 'picklist value',
                      business_term: 'spek',
                    }[props.data.term_type]
                  }
                </span>
              </span>
              <span className={styles.titleWrapper}>
                <span className={styles.title}>{props.children}</span>
              </span>
            </span>
          </components.Option>
        </span>
      ) : (
        <components.Option {...props}>{props.children}</components.Option>
      )}
    </React.Fragment>
  );
};

interface ISpekInputProps {
  monitorScrollElement?: React.MutableRefObject<Element>;
  value: Term;
  onSelect: (spek: Term) => void;
  state: State;
  teams: Array<Team>;
}

const SpekInput = ({
  monitorScrollElement,
  value,
  onSelect,
  state,
  teams,
}: ISpekInputProps) => {
  const hasSpotlightsRevampFlag = state.flag?.hasSpotlightsRevampFlag;
  const getSpeks = async (searchTerm: string) => {
    try {
      let speks: Speks = await spekLookup({
        q: searchTerm,
        teams: teams.reduce(
          (accumulator: any, currentValue: any) => accumulator.concat(currentValue.value),
          []
        ),
      });
      let mappedSpeks = speks.results.map((spek: Term) => ({
        value: spek.value,
        label: spek.label,
        term_type: spek.term_type,
      }));
      return mappedSpeks;
    } catch (err) {
      capture(err);
    }
    return;
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    let {action} = actionMeta;
    if (action === 'select-option') {
      onSelect({
        value: newValue.value,
        label: newValue.label,
        term_type: newValue.term_type,
      });
    }
  };
  const getDebouncedSpeks = debounce(getSpeks, 200, {leading: false});
  return (
    <>
      {hasSpotlightsRevampFlag ? (
        <AsyncSelect
          value={value.value ? value : undefined}
          loadOptions={getDebouncedSpeks}
          onChange={handleChange}
          cacheOptions
          defaultOptions
          placeholder='Select Spek'
          blurInputOnSelect
          isDisabled={state.read_mode}
          key={JSON.stringify(teams)}
        />
      ) : (
        <EnhancedDropDown
          isClearable={false}
          hideSelectedOptions={false}
          defaultOptions
          filterLabel='Spek'
          noOptionMessage='No speks found'
          placeholder='Search for a Spek'
          spekInput
          isDisabled={state.read_mode}
          menuPortalTarget={document.querySelector('body')}
          closeMenuOnScroll={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (
              monitorScrollElement &&
              monitorScrollElement.current &&
              event.target.isSameNode(monitorScrollElement.current)
            ) {
              return true;
            }
            return false;
          }}
          handleChange={handleChange}
          loadOptions={getDebouncedSpeks}
          components={{Option, SingleValue}}
          value={value}
          key={JSON.stringify(teams)}
        />
      )}
    </>
  );
};

export default SpekInput;
