import React from 'react';
import {Tab as ChakraTab, TabProps} from '@chakra-ui/react';
import {TColors} from '../theme/Foundations/color';

export type TTabProps = TabProps;

export interface ITabProps extends TabProps {
  colorScheme?: keyof TColors;
}

export const Tab = (props: ITabProps) => {
  const {colorScheme = 'primary', ...rest} = props;

  const styles = {
    _focus: {
      boxShadow: 'none',
    },
    _focusVisible: {
      border: '1px solid',
      borderBottom: '2px solid',
      borderBottomColor: `${colorScheme}.500`,
    },
    _hover: {
      background: `${colorScheme}.25`,
      borderBottom: '2px solid',
      borderBottomColor: `${colorScheme}.700`,
      color: `${colorScheme}.700`,
    },
    _active: {
      background: `${colorScheme}.50`,
      color: `${colorScheme}.700`,
    },
    _selected: {
      color: `${colorScheme}.700`,
      borderBottomColor: 'currentColor',
    },
  };

  return <ChakraTab tabIndex={0} {...styles} {...rest} />;
};
