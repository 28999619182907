import {Component} from 'react';
import {connect} from 'react-redux';
import {logging, fetchMiddleWare} from 'spekit-datalayer';
import {updateWikiStoredState} from './redux/actions.js';

const fetch = fetchMiddleWare.fetchMiddleWare;

class StateManager extends Component {
  componentDidMount() {
    if (!Object.getOwnPropertyNames(this.props.wikiStoredState).length) {
      this.getWikiState();
    }
  }

  componentDidUpdate(oldProps, oldState) {
    if (JSON.stringify(oldProps.stateUpdate) !== JSON.stringify(this.props.stateUpdate)) {
      this.pushState(this.props.stateUpdate);
    }
  }

  getWikiState = () => {
    fetch('/api/wiki/state', {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((result) => {
        let savedState = {};
        if (result.state) {
          savedState = JSON.parse(result.state.replace(/'/g, '"'));
        }
        this.props.updateWikiStoredState(savedState);
      })
      .catch((err) => {
        logging.capture(err);
        this.props.updateWikiStoredState(this.props.wikiStoredState);
      });
  };

  pushState = (stateUpdate) => {
    let lastState = {...this.props.wikiStoredState};
    if (!lastState.columns) {
      lastState.columns = [];
    }
    if (stateUpdate.type) {
      lastState.type = stateUpdate.type;
    }
    if (stateUpdate.columnName) {
      lastState.columns = lastState.columns.filter((col) => !col.sortBy);
      lastState.columns.push({
        sortBy: stateUpdate.columnName,
      });
    }
    if (stateUpdate.colName) {
      let existingCol = lastState.columns.findIndex(
        (col) => col.label === stateUpdate.colName
      );
      if (existingCol !== -1) {
        lastState.columns[existingCol] = {
          label: stateUpdate.colName,
          active: stateUpdate.active,
        };
      } else {
        lastState.columns.push({label: stateUpdate.colName});
      }
    }
    if (stateUpdate.colId) {
      let existingCol = lastState.columns.findIndex(
        (col) => col.id === stateUpdate.colId
      );
      if (existingCol !== -1) {
        lastState.columns[existingCol] = {
          id: stateUpdate.colId,
          active: stateUpdate.active,
        };
      } else {
        lastState.columns.push({id: stateUpdate.colId});
      }
    }
    if (stateUpdate.pageSize) {
      lastState.pageSize = stateUpdate.pageSize;
    }
    if (stateUpdate.uiMode) {
      lastState.uiMode = stateUpdate.uiMode;
    }
    fetch('/api/wiki/state', {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify({
        state: JSON.stringify(lastState),
        timestamp: new Date().getTime(),
      }),
    }).then(() => {
      this.props.updateWikiStoredState(lastState);
    });
  };

  render() {
    return null;
  }
}

export default connect(
  (state, ownProps) => {
    return {
      wikiStoredState: state.wikiStoredState.storedState,
    };
  },
  (dispatch) => {
    return {
      updateWikiStoredState: (stateUpdate) =>
        dispatch(updateWikiStoredState(stateUpdate)),
    };
  }
)(StateManager);
