import {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import {page} from './utils/analytics';

class AnalyticsWrapper extends Component {
  state = {
    location: null,
  };
  UNSAFE_componentWillMount() {
    this.props.history.listen((location) => {
      if (
        location.pathname !== this.state.location &&
        location.pathname !== '/notfound' &&
        location.pathname !== '/empty'
      ) {
        page();
        this.setState({location: location.pathname});
      }
    });
  }
  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

export default withRouter(AnalyticsWrapper);
