import React, {useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {QUERY_KEYS} from 'spekit-datalayer';
import {
  DSModal as Modal,
  ModalBody,
  DSButton as Button,
  ModalCloseButton,
  ModalHeader,
} from 'spekit-ui';
import {CustomFieldsForm} from '../CustomFieldsForm';

export const CreateFieldModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const onSave = () => {
    queryClient.invalidateQueries({queryKey: [QUERY_KEYS.CustomFields], exact: true});
    setIsOpen(false);
  };

  return (
    <>
      <Button
        size='medium'
        variant='contained'
        colorScheme='primary'
        onClick={() => setIsOpen(true)}
        data-testid='custom-field-create-btn'
      >
        Create custom field
      </Button>
      <Modal
        size='md'
        isOpen={isOpen}
        scrollBehavior='outside'
        onClose={() => setIsOpen(false)}
        containerProps={{alignItems: 'baseline'}}
      >
        <ModalHeader>Create custom field</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={1}>
          <CustomFieldsForm onSave={onSave} handleModalClose={() => setIsOpen(false)} />
        </ModalBody>
      </Modal>
    </>
  );
};
