import {useEffect} from 'react';

interface IWindowFocusProps {
  hasFocusRef: React.MutableRefObject<boolean>;
}

export const useWindowFocus = ({hasFocusRef}: IWindowFocusProps) => {
  useEffect(() => {
    const onFocus = () => {
      hasFocusRef.current = true;
    };

    const onBlur = () => {
      hasFocusRef.current = false;
    };

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    onFocus();

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [hasFocusRef]);
};
