import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Editor from '@ckeditor/ckeditor5-core/src/editor/editor';
import {Element} from '@ckeditor/ckeditor5-engine';

interface EventData {
  dataTransfer: DataTransfer;
  preventDefault: () => void;
}

export default class SpotlightHeader extends Plugin {
  init() {
    const editor = this.editor;

    // handle paste
    editor.editing.view.document.on('paste', (event, data: EventData) => {
      const textContent = data.dataTransfer.getData('text/plain');
      const getSelectedElementPosition =
        editor.model.document.selection.getSelectedElement();

      data.preventDefault();
      event.stop();

      this.updateContent(editor, textContent);
      this.setFocus(editor, getSelectedElementPosition);
    });

    // handle enter
    editor.editing.view.document.on('enter', (evt, data) => {
      data.preventDefault();
      evt.stop();
    });
  }
  updateContent(editor: Editor, updatedText: string) {
    editor.model.change((_) => {
      const viewFragment = editor.data.processor.toView(updatedText);
      const modelFragment = editor.data.toModel(viewFragment);
      editor.model.insertContent(modelFragment);
    });
  }

  setFocus(editor: Editor, insertPosition: Element | null) {
    editor.model.change((writer) => {
      if (insertPosition) {
        writer.setSelection(writer.createPositionAfter(insertPosition));
      }
    });
  }
}
