const initialState = {
  topic: null,
  topicId: null,
};

function createSpekWithTopicReducer(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_SPEK_WITH_TOPIC':
      return {
        topic: action.topic,
        topicId: action.topicId,
      };
    default:
      return state;
  }
}

export default createSpekWithTopicReducer;
