import React from 'react';
import {Popover as ChakraPopover, PopoverProps} from '@chakra-ui/react';
import {FONT_FAMILY} from '../Typography';

export type TPopoverProps = Omit<PopoverProps, 'variants'> & {
  variants?: PopoverProps['variant'] | 'default';
};

export const PopoverConfig = {
  parts: ['closeButton', 'content', 'header', 'footer', 'body', 'popper'],
  baseStyle: {
    closeButton: {
      cursor: 'pointer',
      color: 'neutral.500',
      top: 6,
      right: 6,
      _hover: {
        background: 'neutral.25',
      },
    },
    content: {
      maxW: '320px',
      p: 16,
    },
    header: {
      fontWeight: 'semibold',
      fontFamily: FONT_FAMILY,
      fontSize: 'medium',
      color: 'neutral.800',
      p: 0,
    },
    body: {
      fontSize: 'small',
      fontFamily: FONT_FAMILY,
      color: 'neutral.800',
      fontWeight: 'normal',
      p: 0,
      mt: 12,
    },
    footer: {
      fontFamily: FONT_FAMILY,
      mt: 16,
      px: 0,
    },
  },
  variants: {
    icon: {
      popper: {
        borderRadius: 6,
        zIndex: 2147483647,
      },
      content: {
        p: 0,
        maxW: 'none',
        width: '372px',
        boxSizing: 'border-box',
        height: '515px',
        border: '1px solid',
        borderColor: 'neutral.300',
        borderRadius: 6,
        _focus: {
          boxShadow: 'none',
        },
      },
    },
    noSpacing: {
      body: {
        m: 0,
      },
    },
    default: {
      popper: {
        boxShadow: 'lg',
      },
      content: {
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
  sizes: {},
  defaultProps: {},
};
export const Popover = (props: TPopoverProps) => (
  <ChakraPopover placement='bottom-start' {...props}>
    {props.children}
  </ChakraPopover>
);
