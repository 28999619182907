import React from 'react';
import {Icon, TIconProps} from '../design-system/Icon';

export const DocumentIcon = (props: TIconProps) => (
  <Icon {...props} viewBox='0 0 66 86' fill='none' data-testid='document-icon'>
    <path
      d='M55.6094 19.6088C53.4326 19.6067 51.3456 18.741 49.8065 17.2018C48.2673 15.6626 47.4017 13.5757 47.3995 11.3989V0H10.7142C7.87258 0 5.14741 1.12884 3.13811 3.13814C1.12882 5.14743 0 7.87256 0 10.7141V75.3017C0.00421972 78.1405 1.13489 80.8616 3.14373 82.8675C5.15257 84.8734 7.87534 86 10.7142 86H54.7534C57.5922 86 60.3149 84.8734 62.3238 82.8675C64.3326 80.8616 65.4633 78.1405 65.4675 75.3017V19.6088H55.6094Z'
      fill='#F5F9FF'
    />
    <path
      d='M65.4674 19.6088H55.6092C53.4325 19.6067 51.3455 18.741 49.8063 17.2018C48.2671 15.6626 47.4015 13.5757 47.3994 11.3989V0L65.4674 19.6088Z'
      fill='#0B6CFF'
    />
    <path
      d='M51.998 65.2167H10.8932C10.4972 65.2167 10.1175 65.0594 9.83746 64.7794C9.55746 64.4994 9.40016 64.1196 9.40016 63.7236C9.40122 63.3283 9.55898 62.9496 9.83887 62.6704C10.1188 62.3913 10.4979 62.2346 10.8932 62.2346H51.998C52.393 62.2346 52.7717 62.3914 53.051 62.6706C53.3302 62.9499 53.4871 63.3287 53.4871 63.7236C53.4876 63.9195 53.4495 64.1136 53.3749 64.2947C53.3003 64.4758 53.1907 64.6404 53.0524 64.7791C52.9141 64.9178 52.7497 65.0279 52.5688 65.103C52.3879 65.178 52.1939 65.2167 51.998 65.2167Z'
      fill='#0B6CFF'
    />
    <path
      d='M51.998 48.3989H10.8932C10.4979 48.3989 10.1188 48.2421 9.83887 47.963C9.55898 47.6838 9.40122 47.3052 9.40016 46.9099C9.40016 46.5139 9.55746 46.134 9.83746 45.854C10.1175 45.574 10.4972 45.4168 10.8932 45.4168H51.998C52.1939 45.4168 52.3879 45.4555 52.5688 45.5305C52.7497 45.6056 52.9141 45.7156 53.0524 45.8543C53.1907 45.993 53.3003 46.1577 53.3749 46.3388C53.4495 46.5199 53.4876 46.714 53.4871 46.9099C53.4871 47.3048 53.3302 47.6835 53.051 47.9628C52.7717 48.242 52.393 48.3989 51.998 48.3989Z'
      fill='#0B6CFF'
    />
    <path
      d='M51.998 56.8078H10.8932C10.4972 56.8078 10.1175 56.6505 9.83746 56.3705C9.55746 56.0905 9.40016 55.7107 9.40016 55.3147C9.40122 54.9194 9.55898 54.5407 9.83887 54.2615C10.1188 53.9824 10.4979 53.8257 10.8932 53.8257H51.998C52.393 53.8257 52.7717 53.9826 53.051 54.2618C53.3302 54.5411 53.4871 54.9198 53.4871 55.3147C53.4876 55.5106 53.4495 55.7047 53.3749 55.8858C53.3003 56.0669 53.1907 56.2316 53.0524 56.3703C52.9141 56.509 52.7497 56.619 52.5688 56.6941C52.3879 56.7691 52.1939 56.8078 51.998 56.8078Z'
      fill='#0B6CFF'
    />
    <path
      d='M35.7854 39.99H10.8932C10.4979 39.99 10.1188 39.8333 9.83887 39.5541C9.55898 39.275 9.40122 38.8962 9.40016 38.5009C9.40016 38.105 9.55746 37.7252 9.83746 37.4452C10.1175 37.1652 10.4972 37.0079 10.8932 37.0079H35.7854C36.1807 37.0089 36.5595 37.1667 36.8386 37.4466C37.1178 37.7265 37.2745 38.1056 37.2745 38.5009C37.2735 38.8955 37.1163 39.2737 36.8372 39.5527C36.5582 39.8317 36.1801 39.989 35.7854 39.99Z'
      fill='#0B6CFF'
    />
    <path
      d='M35.7854 31.5811H10.8932C10.4979 31.5811 10.1188 31.4243 9.83887 31.1451C9.55898 30.866 9.40122 30.4873 9.40016 30.092C9.40016 29.696 9.55746 29.3162 9.83746 29.0362C10.1175 28.7562 10.4972 28.599 10.8932 28.599H35.7854C36.1807 28.6 36.5595 28.7578 36.8386 29.0377C37.1178 29.3175 37.2745 29.6967 37.2745 30.092C37.2735 30.4866 37.1163 30.8648 36.8372 31.1438C36.5582 31.4228 36.1801 31.5801 35.7854 31.5811Z'
      fill='#0B6CFF'
    />
    <path
      d='M51.2695 28.599H44.2302C43.0032 28.599 42.0085 29.5936 42.0085 30.8206V37.8599C42.0085 39.0869 43.0032 40.0816 44.2302 40.0816H51.2695C52.4965 40.0816 53.4911 39.0869 53.4911 37.8599V30.8206C53.4911 29.5936 52.4965 28.599 51.2695 28.599Z'
      fill='#0B6CFF'
    />
  </Icon>
);
