import React, {Fragment} from 'react';
import styles from './publish.module.css';
import {Fontawesome} from 'spekit-ui';
import {faInfoCircle, faPlusCircle, faTrash} from '@fortawesome/free-solid-svg-icons';
import Pill from '../../spotlightModal/pill/pill';
import Line from '../../spotlightModal/line/line';
import RuleSelector from '../../spotlightModal/ruleSelectorDropdown/ruleSelectorDropdown';
import PathDropdown from '../../spotlightModal/pathDropdown/pathDropdown';
import URLInput from '../../spotlightModal/urlInput/urlInput';
import PathInput from '../../spotlightModal/pathInput/pathInput';
import {DSTooltip as Tooltip, DateInput} from 'spekit-ui';
import {
  Dispatch,
  State,
  Targeting,
  RuleSelectorSelectProps,
  URLRulesProps,
  Select as SelectProps,
  PathRules as TargetingPathRule,
  PathRulesProps,
} from '../../spotlightModal/types';
import moment from 'moment';

interface IMeta {
  name: string;
  marketingLink: React.FC;
}

interface Props {
  state: State;
  dispatch: Dispatch;
  containerRef: any;
  container: any;
  meta: IMeta;
  id: string;
}

const PathRules: React.FC<PathRulesProps> = ({
  target,
  index,
  containerRef,
  onPathSelect,
  onAddNewPath,
  onDomainPathChange,
  removePathHandler,
  handleUpdatePath,
  disabled,
}) => (
  <>
    {target.type || index > 0 ? (
      <div className={styles.subRulesContainer}>
        <div className={styles.flexColumnRules}>
          <Line orientation='vertical' />
        </div>
        {target.type === 'url' ? (
          <div className={styles.pathContainer}>
            {target.path_rules!.map((path: TargetingPathRule, pathIndex: number) => (
              <div key={pathIndex} className={styles.pathSelector}>
                <div className={styles.vline} />
                <div className={styles.pathInsideContainer}>
                  <Line length={12} orientation='horizontal' />
                  <Pill>{pathIndex === 0 ? 'and' : 'or'}</Pill>
                  <Line length={12} orientation='horizontal' />
                  <span style={{width: '200px'}}>
                    <PathDropdown
                      monitorScrollElement={containerRef}
                      onSelect={(path: SelectProps) => {
                        onPathSelect(path, index, pathIndex);
                        if (typeof handleUpdatePath === 'function') {
                          handleUpdatePath(index);
                        }
                      }}
                      value={path.comparison}
                      disabled={disabled}
                    />
                  </span>
                  <Line length={12} orientation='horizontal' />
                  <PathInput
                    value={path.value || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onDomainPathChange(e, index, pathIndex)
                    }
                    onBlur={() => handleUpdatePath && handleUpdatePath(index)}
                    disabled={disabled}
                  />
                  {!disabled ? (
                    <Fontawesome
                      name={faTrash}
                      className={styles.trashIcon}
                      onClick={(e: React.MouseEvent) =>
                        removePathHandler(e, index, pathIndex)
                      }
                    />
                  ) : null}
                </div>
              </div>
            ))}
            <div className={styles.pathSelector}>
              <div className={styles.vline} style={{height: '42px'}} />
              <div className={styles.pathInsideContainer}>
                <Line length={12} orientation='horizontal' />
                <Pill>
                  {target.path_rules && target.path_rules.length === 0 ? 'and' : 'or'}
                </Pill>
                <Line length={12} orientation='horizontal' />
                <span style={{width: '250px'}}>
                  <div
                    className={disabled ? styles.addRuleBtnDisabled : styles.addRuleBtn}
                    onClick={(e: React.MouseEvent) => onAddNewPath(e, index)}
                  >
                    <Fontawesome name={faPlusCircle} className={styles.plusCircle} />
                    Add a path
                  </div>
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>
);

const UrlRules: React.FC<URLRulesProps> = ({
  target,
  onDomainChange,
  index,
  removeDomainHandler,
  handleDomainBlur,
  disabled,
}) => (
  <>
    {target.type === 'url' ? (
      <>
        <Line length={12} orientation='horizontal' />
        <URLInput
          value={target.domain_value || ''}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleDomainBlur(e, index)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onDomainChange(e, index)}
          disabled={disabled}
          placeholder='Enter URL'
          error={target.error}
          errorText={target.errorText}
        />
      </>
    ) : null}
    {!disabled && target.type ? (
      <Fontawesome
        name={faTrash}
        className={styles.trashIcon}
        onMouseDown={() => removeDomainHandler(index)}
      />
    ) : null}
  </>
);

const Publish: React.FC<Props> = ({
  state,
  dispatch,
  containerRef,
  container,
  meta,
  id,
}) => {
  const {domainEdit, domainCreate, domainDelete} = container;

  const onRuleSelect = async (rule: RuleSelectorSelectProps, index: number) => {
    let targets = [...state.targeting];
    targets[index].type = rule.type;
    targets[index].domain_comparison = 'contains';
    targets[index].css_selector = '';
    targets[index].position = [];
    targets[index].path_rules = [];
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
    if (targets[index].type === 'anywhere' && state.edit_mode) {
      dispatch({type: 'SET_SAVING', payload: true});
      let response = await domainCreate({...targets[index], step: id});
      targets[index].id = response.id;
      dispatch({type: 'SET_SAVING', payload: false});
      dispatch({type: 'SET_TARGETING_RULES', payload: targets});
    }
  };

  const onTogglePathFlow = async () => {
    dispatch({type: 'SET_AUTO_LAUNCH', payload: !state.autolaunch});
  };

  const onPathSelect = (path: SelectProps, index: number, pathIndex: number) => {
    let targets = [...state.targeting];
    targets[index].path_rules![pathIndex].comparison! = path.value;
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
  };

  const onDomainChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let targets = [...state.targeting];
    targets[index].domain_value = e.target.value;
    if (e.target.value) {
      targets[index].error = false;
      targets[index].errorText = '';
    }
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
  };

  const handleDomainBlur = async (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let urlRegexExpression =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    let urlRegex = new RegExp(urlRegexExpression);
    let targets = [...state.targeting];
    const errorDictionary = {
      "Failed to construct 'URL': Invalid URL": 'Please enter a valid URL',
      'Enter a valid URL': 'Please enter a valid URL',
    };
    try {
      if (!targets[index].domain_value) {
        targets[index].error = true;
        targets[index].errorText = 'Enter URL';
        dispatch({type: 'SET_TARGETING_RULES', payload: targets});
        return;
      }
      let domainProperties = new URL(targets[index].domain_value!);
      let domainOrigin = domainProperties.origin;
      let domainPathname = domainProperties.pathname;
      let domainSearchQuery = domainProperties.search;
      let domainHash = domainProperties.hash;
      if (
        targets[index].domain_value &&
        targets[index].domain_value!.match(urlRegex) &&
        targets[index].path_rules!.length === 0
      ) {
        targets[index].domain_value = domainOrigin;
        targets[index].path_rules!.push({
          comparison: 'contains',
          value: domainPathname + domainSearchQuery + domainHash,
        });
        dispatch({type: 'SET_TARGETING_RULES', payload: targets});
      }
      if (state.edit_mode && targets[index].id && e.target.value) {
        dispatch({type: 'SET_SAVING', payload: true});
        await domainEdit(targets[index]);
        dispatch({type: 'SET_SAVING', payload: false});
      } else if (state.edit_mode && e.target.value) {
        dispatch({type: 'SET_SAVING', payload: true});
        let response = await domainCreate({...targets[index], step: id});
        targets[index].id = response.id;
        dispatch({type: 'SET_SAVING', payload: false});
        dispatch({type: 'SET_TARGETING_RULES', payload: targets});
      }
    } catch (e) {
      targets[index].error = true;
      const errorText = 'Must be a valid URL (https://www.example.com)';
      targets[index].errorText = errorText ? errorText : e.message;
      dispatch({type: 'SET_TARGETING_RULES', payload: targets});
    }
  };

  const onDomainPathChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    pathIndex: number
  ) => {
    let targets = [...state.targeting];
    targets[index].path_rules![pathIndex].value = e.target.value;
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
  };

  const addNewRule = () => {
    let targets = [...state.targeting];
    targets.push({type: null});
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
  };

  const onAddNewPath = (e: React.MouseEvent, index: number) => {
    let targets = [...state.targeting];
    targets[index].path_rules!.push({comparison: 'exactly', value: ''});
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
  };

  const removeDomainHandler = async (index: number) => {
    let targets = [...state.targeting];
    let deletedTarget = targets.splice(index, 1);

    if (targets.length === 0) {
      targets.push({type: null});
    }
    dispatch({type: 'SET_TARGETING_RULES', payload: targets});
    if (state.edit_mode && deletedTarget[0].id) {
      dispatch({type: 'SET_SAVING', payload: true});
      await domainDelete(deletedTarget[0].id);
      dispatch({type: 'SET_SAVING', payload: false});
    }
  };

  const removePathHandler = async (
    e: React.MouseEvent,
    index: number,
    pathIndex: number
  ) => {
    let targets = [...state.targeting];
    let newTargets = targets.map((target: Targeting, tIndex: number) => {
      if (index === tIndex) {
        target.path_rules!.splice(pathIndex, 1);
      }
      return target;
    });

    dispatch({type: 'SET_TARGETING_RULES', payload: newTargets});
    if (state.edit_mode) {
      dispatch({type: 'SET_SAVING', payload: true});
      await domainEdit(newTargets[index]);
      dispatch({type: 'SET_SAVING', payload: false});
    }
  };

  const handleUpdatePath = async (index: number) => {
    let targets = [...state.targeting];
    if (targets[index].id && state.edit_mode) {
      dispatch({type: 'SET_SAVING', payload: true});
      await domainEdit(targets[index]);
      dispatch({type: 'SET_SAVING', payload: false});
    }
  };

  const handleChangeExpiryRadioGroup = (value: boolean) => () => {
    dispatch({type: 'SET_NEVER_EXPIRES', payload: value});
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        Note: A {meta.name} is shown each time the rules are met, until the person
        engages.{` `}
        {meta.marketingLink && <meta.marketingLink />}
      </div>
      <div className={styles.headingContainer}>
        <div className={styles.heading}>Appears</div>
        <Tooltip
          shouldWrapChildren
          label={`Determines which conditions must be met for the ${
            meta.name
          } to be displayed. ${
            meta.name === 'Flow'
              ? `Editing this may affect a user's ability to view a Flow.`
              : ''
          }`}
        >
          <Fontawesome name={faInfoCircle} className={styles.infoCircle} />
        </Tooltip>
      </div>
      {meta.name === 'Flow' && (
        <div className={styles.checkboxLabelWrapper}>
          <input
            data-testid='autolaunch_checkbox'
            type='checkbox'
            id='showPathCondition'
            name='showPathCondition'
            checked={state.autolaunch}
            onChange={onTogglePathFlow}
            disabled={state.read_mode}
          />
          <label htmlFor='showPathCondition'>
            Automatically launch flows on these pages.
          </label>
        </div>
      )}
      {state.autolaunch && (
        <div className={styles.transition} data-testid='targeting-ui'>
          <div className={styles.rulesContainer}>
            {state.targeting.map((target: Targeting, index: number) => (
              <Fragment key={index}>
                <div className={index === 0 ? styles.flexRow : styles.flexRowRules}>
                  <Pill>{index === 0 ? 'When' : 'or'}</Pill>
                  <Line length={12} orientation='horizontal' />
                  <div className={styles.ruleSelector}>
                    <RuleSelector
                      onSelect={(rule: RuleSelectorSelectProps) =>
                        onRuleSelect(rule, index)
                      }
                      monitorScrollElement={containerRef}
                      value={target.type}
                      disabled={state.read_mode || Boolean(state.edit_mode && target.id)}
                    />
                  </div>
                  <UrlRules
                    target={target}
                    onDomainChange={onDomainChange}
                    handleDomainBlur={handleDomainBlur}
                    index={index}
                    removeDomainHandler={removeDomainHandler}
                    disabled={state.read_mode}
                  />
                </div>
                <PathRules
                  target={target}
                  index={index}
                  containerRef={containerRef}
                  onPathSelect={onPathSelect}
                  onAddNewPath={onAddNewPath}
                  onDomainPathChange={onDomainPathChange}
                  removePathHandler={removePathHandler}
                  handleUpdatePath={handleUpdatePath}
                  disabled={state.read_mode}
                />
              </Fragment>
            ))}
            {state.targeting.filter((target: Targeting) => target.type).length ? (
              <>
                <div className={styles.flexRowOr}>
                  <Pill>or</Pill>
                  <Line length={12} orientation='horizontal' />
                  <div
                    className={
                      state.read_mode ? styles.addRuleBtnDisabled : styles.addRuleBtn
                    }
                    onClick={addNewRule}
                  >
                    <Fontawesome name={faPlusCircle} className={styles.plusCircle} />
                    Add another rule
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      )}

      {meta.name === 'Spotlight' && (
        <>
          <div className={styles.headingContainer}>
            <div className={styles.heading}>Expiry</div>
            <Tooltip
              placement='top'
              shouldWrapChildren
              label='Set an end date on how long your spotlight will  be viewable. This
                  will automatically unpublish  the spotlight after a certain date.'
            >
              <Fontawesome name={faInfoCircle} className={styles.infoCircle} />
            </Tooltip>
          </div>

          <div>
            <div className={styles.radioContainer}>
              <input
                onChange={handleChangeExpiryRadioGroup(false)}
                checked={!state.isNeverExpires}
                id='expires_on'
                name='expiry'
                type='radio'
                disabled={state.read_mode}
              />
              <label htmlFor='expires_on'>Expires on</label>
              <DateInput
                disabled={state.read_mode}
                value={moment(state.end_on)} // remove this when refactoring dateinput
                openUp
                limitRange
                beforeTodayInvalid
                onChange={(value: Date) => {
                  dispatch({type: 'SET_EXPIRES_ON', payload: value});
                }}
              />
            </div>
            <div className={styles.radioContainer}>
              <input
                onChange={handleChangeExpiryRadioGroup(true)}
                checked={state.isNeverExpires}
                id='expires_never'
                name='expiry'
                type='radio'
                disabled={state.read_mode}
              />
              <label htmlFor='expires_never'>Never Expires</label>
              <Tooltip
                placement='top'
                shouldWrapChildren
                label='This spotlight will remain published and viewable by new/existing users who have not seen it yet.'
              >
                <Fontawesome name={faInfoCircle} className={styles.infoCircle} />
              </Tooltip>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Publish;
