import React, {useState} from 'react';
import {Menu, SimpleMenuItem, MenuButton, MenuList, Box} from 'spekit-ui';
import {RiNotificationOffLine, RiNotification4Line} from 'react-icons/ri';
import {MAX_ZINDEX, notification} from 'spekit-datalayer';
import {UseFormHandleSubmit} from 'react-hook-form';
import {
  ISpekFromState,
  IExtendedNotificationState,
  TNotificationContentType,
} from 'spekit-types';
import {NotificationsContainer} from '../NotificationsContainer/NotificationsContainer';
const {safeNotificationProperties} = notification;
interface INotificationButtonCommon {
  onSave: (notification: IExtendedNotificationState) => void;
  topics: string[];
  disabled?: boolean;
  isSubmitting?: boolean;
  testId?: string;
  contentType?: TNotificationContentType;
}

interface INotificationButtonEdit extends INotificationButtonCommon {
  mode: 'edit';
  handleSubmit?: UseFormHandleSubmit<ISpekFromState>;
}

interface INotificationButtonUpload extends INotificationButtonCommon {
  mode: 'upload';
}

interface INotificationButtonCreate extends INotificationButtonCommon {
  mode: 'create';
  handleSubmit?: UseFormHandleSubmit<ISpekFromState>;
}

export type TNotificationButton =
  | INotificationButtonEdit
  | INotificationButtonCreate
  | INotificationButtonUpload;

const helperText = {
  edit: {
    action: 'Save changes',
    notificationAction: 'Save',
    saveWithNotification: 'Save and notify users',
    saveWithoutNotification: 'Save without notifying users',
  },
  create: {
    action: 'Publish',
    notificationAction: 'Publish',
    saveWithNotification: 'Publish and notify users',
    saveWithoutNotification: 'Publish without notifying users',
  },
  upload: {
    action: 'Upload files',
    notificationAction: 'Upload',
    saveWithNotification: 'Upload and notify users',
    saveWithoutNotification: 'Upload without notifying users',
  },
} as const;

export const NotificationButton = (props: TNotificationButton) => {
  const {
    onSave,
    topics,
    mode,
    disabled,
    isSubmitting,
    testId = 'save-btn',
    contentType = 'Spek',
  } = props;
  const [showNotificationsForm, setShowNotificationsForm] = useState(false);
  const {action, saveWithNotification, saveWithoutNotification, notificationAction} =
    helperText[mode];
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    // we can remove edit check later when we integerate spek form in webapp.
    if (mode === 'upload' || (mode === 'edit' && !props.handleSubmit)) {
      setIsMenuOpen(true);
      return;
    }
    const {handleSubmit} = props;
    handleSubmit &&
      handleSubmit(() => {
        setIsMenuOpen(true);
      })();
  };

  return (
    <Box>
      <Menu
        placement='bottom-end'
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <NotificationsContainer
          contentType={contentType}
          isOpen={showNotificationsForm}
          onClose={() => setShowNotificationsForm(false)}
          onSave={onSave}
          topics={topics}
          notificationAction={notificationAction}
          isSubmitting={isSubmitting}
          mode={mode}
        >
          <MenuButton
            colorScheme='primary'
            size='medium'
            variant='contained'
            data-testid={testId}
            onClick={handleMenuOpen}
            isLoading={isSubmitting}
            disabled={disabled}
            mr={mode === 'create' ? 12 : 0}
          >
            {action}
          </MenuButton>
        </NotificationsContainer>
        <MenuList
          zIndex={MAX_ZINDEX}
          data-testid={isMenuOpen ? 'menu-open' : 'menu-close'}
        >
          <SimpleMenuItem
            icon={RiNotificationOffLine}
            onClick={() => onSave(safeNotificationProperties())}
            testId='save-without-notify-btn'
          >
            {saveWithoutNotification}
          </SimpleMenuItem>
          <SimpleMenuItem
            icon={RiNotification4Line}
            onClick={() => setShowNotificationsForm(true)}
            testId='save-with-notify-btn'
          >
            {saveWithNotification}
          </SimpleMenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
