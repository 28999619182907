import {USER_GROUP_ACCESS_TOKEN_API} from '../api';

const explo_access_token = process.env.REACT_APP_EXPLO_ACCESS_TOKEN;

export interface ExploResponse {
  user_group: {
    token: string;
  };
}

export const getUserGroupAccessToken = async (orgId: string, orgName: string) => {
  const customHeaders = {
    'Explo-Authorization': `Token ${explo_access_token}`,
    Accept: '*/*',
    'Content-Type': 'application/json',
  };
  const body = {
    user_group_id: orgId,
    name: orgName,
  };

  const response = await fetch(USER_GROUP_ACCESS_TOKEN_API, {
    method: 'POST',
    headers: {
      ...customHeaders,
    },
    body: JSON.stringify(body),
  });

  const result: ExploResponse = (await response.json()) as ExploResponse;
  return result.user_group.token;
};
