import React, {forwardRef} from 'react';
import {RiCloseLine} from 'react-icons/ri';
import {Button} from '../../Button';
import {Icon} from '../../Icon';
import {TButtonProps, TIconButton} from '../../types';

type TCloseButton = Omit<
  TIconButton,
  'variant' | 'size' | 'colorScheme' | 'aria-label'
> & {
  variant: 'info' | 'warning' | 'success' | 'error' | 'neutral' | 'default';
  size: TButtonProps['size'];
  testId?: string;
  iconSize?: {
    h: string;
    w: string;
  };
  'aria-label'?: string;
};

const closeButton = {
  info: {
    color: 'primary.700',
    _hover: {
      backgroundColor: 'primary.50',
    },
    _active: {
      color: 'inherit',
      backgroundColor: 'primary.100',
    },
  },
  success: {
    color: 'success.700',
    _hover: {
      backgroundColor: 'success.50',
    },
    _active: {
      color: 'inherit',
      backgroundColor: 'success.100',
    },
  },
  warning: {
    color: 'warning.700',
    _hover: {
      backgroundColor: 'warning.50',
    },
    _active: {
      color: 'inherit',
      backgroundColor: 'warning.100',
    },
  },
  error: {
    color: 'error.700',
    _hover: {
      backgroundColor: 'error.50',
    },
    _active: {
      color: 'inherit',
      backgroundColor: 'error.100',
    },
  },
  default: {
    color: 'neutral.700',
    _hover: {
      backgroundColor: 'neutral.100',
    },
    _active: {
      color: 'inherit',
      backgroundColor: 'neutral.500',
    },
  },
};

export const CloseButton = forwardRef((props: TCloseButton, ref) => {
  const {
    onClick,
    variant,
    size,
    testId,
    iconSize = {h: '20px', w: '20px'},
    ...rest
  } = props;
  return (
    <Button
      aria-label='close alert'
      icon={<Icon {...iconSize} as={RiCloseLine} />}
      onClick={onClick}
      variant='icon'
      size={size}
      bgColor='inherit'
      colorScheme='white'
      data-testid={testId}
      ref={ref}
      {...closeButton[variant]}
      {...rest}
    />
  );
});
