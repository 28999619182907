import React from 'react';
import Select from 'react-select/async';
import {components} from 'react-select';
import {Icon, Dropdown, Box, AsyncSelect} from 'spekit-ui';
import {RiCheckDoubleFill} from 'react-icons/ri';
import {quizzes, logging} from 'spekit-datalayer';
import debounce from 'debounce-promise';
import {State, Team, Select as ISelect} from '../types';
import {IQuizLookupResponse} from 'spekit-types';

const {quizLookup} = quizzes;

const {capture} = logging;

export interface IProps {
  monitorScrollElement: any;
  value: ISelect;
  onSelect: (value: ISelect) => void;
  state: State;
  teams: Array<Team>;
}

const EnhancedDropDown: any = Dropdown(Select);

const SingleValue = (props: any) => {
  if (props.selectProps.spekInput) {
    return (
      <components.SingleValue {...props}>
        <Box as='span' fontSize='10px'>
          <Box
            as='span'
            d='inline-block'
            p='2'
            mr='0.3em'
            borderRadius='3px'
            bg='#DDDDDD'
            color='#637280'
          >
            <Icon as={RiCheckDoubleFill} />
            <Box as='span'>Knowledge Check</Box>
          </Box>
          {props.children}
        </Box>
      </components.SingleValue>
    );
  } else {
    return <components.SingleValue {...props}>{props.children}</components.SingleValue>;
  }
};

const Option = (props: any) => {
  return (
    <React.Fragment>
      {props.selectProps.spekInput ? (
        <Box as='span'>
          <components.Option {...props}>
            <Box as='span' fontSize='10px'>
              <Box
                d='inline-block'
                p='2'
                mr='0.3em'
                borderRadius='3px'
                bg='#DDDDDD'
                color='#637280'
              >
                <Icon as={RiCheckDoubleFill} />
                <Box as='span'>Knowledge Check</Box>
              </Box>
              {props.children}
            </Box>
          </components.Option>
        </Box>
      ) : (
        <components.Option {...props}>{props.children}</components.Option>
      )}
    </React.Fragment>
  );
};

const QuizInput = (props: IProps) => {
  const {monitorScrollElement, value, onSelect, state, teams} = props;
  const hasSpotlightsRevampFlag = state.flag?.hasSpotlightsRevampFlag;

  const getQuizzes = async (searchTerm: string) => {
    try {
      let quiz: IQuizLookupResponse = await quizLookup({
        q: searchTerm,
        status: 'published',
        teams: teams.reduce(
          (accumulator: any, currentValue: any) => accumulator.concat(currentValue.value),
          []
        ),
      });
      let mappedQuiz = quiz.results.map((quiz) => ({
        value: quiz.value,
        label: quiz.label,
      }));
      return mappedQuiz;
    } catch (err) {
      capture(err);
    }
    return;
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    let {action} = actionMeta;
    if (action === 'select-option') {
      onSelect({
        value: newValue.value,
        label: newValue.label,
      });
    }
  };

  const getDebouncedQuizzes = debounce(getQuizzes, 200, {leading: false});

  return (
    <>
      {hasSpotlightsRevampFlag ? (
        <AsyncSelect
          value={value}
          loadOptions={getDebouncedQuizzes}
          onChange={handleChange}
          cacheOptions
          defaultOptions
          placeholder='Select Knowledge Check'
          blurInputOnSelect
          isDisabled={state.read_mode}
          key={JSON.stringify(teams)}
        />
      ) : (
        <EnhancedDropDown
          isClearable={false}
          hideSelectedOptions={false}
          defaultOptions
          filterLabel='Knowledge Check'
          noOptionMessage='No Knowledge Check found'
          placeholder='Search for a Knowledge Check'
          spekInput
          isDisabled={state.read_mode}
          menuPortalTarget={document.querySelector('body')}
          closeMenuOnScroll={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (
              monitorScrollElement &&
              monitorScrollElement.current &&
              event.target.isSameNode(monitorScrollElement.current)
            ) {
              return true;
            }
            return false;
          }}
          handleChange={handleChange}
          loadOptions={getDebouncedQuizzes}
          components={{Option, SingleValue}}
          value={value}
          key={JSON.stringify(teams)}
        />
      )}
    </>
  );
};

export default QuizInput;
