const initialState = {
  notifications: [],
};
function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case 'ADDNOTIFICATION':
      return {
        notifications: [...state.notifications, action.notification],
      };
    case 'REMOVENOTIFICATION':
      let index = state.notifications.findIndex(
        (msgg) => msgg.text === action.notification.text
      );
      if (index !== -1) {
        return {
          notifications: [
            ...state.notifications.slice(0, index),
            ...state.notifications.slice(index + 1),
          ],
        };
      } else {
        return {
          ...state,
        };
      }
    default:
      return state;
  }
}

export default notificationReducer;
