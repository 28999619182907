import React from 'react';
import {
  Progress as ChakraProgress,
  ProgressProps,
  ComponentStyleConfig,
} from '@chakra-ui/react';

export interface IProgressProps extends ProgressProps {
  colorScheme?: 'success' | 'warning' | 'error' | 'primary' | 'pink';
}

export const ProgressConfig: ComponentStyleConfig = {
  parts: ['filledTrack', 'track'],
  baseStyle: {
    track: {
      bg: 'neutral.50',
      borderRadius: 8,
    },
    filledTrack: {
      borderRadius: 8,
      _indeterminate: {
        minW: '150px',
        animationDuration: '800ms',
        animationTimingFunction: 'linear',
        bg: 'neutral.200',
      },
    },
  },
  sizes: {
    md: {
      track: {
        height: '12px',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};

export const Progress = (props: IProgressProps) => {
  const {colorScheme = 'pink', ...rest} = props;
  return <ChakraProgress colorScheme={colorScheme} {...rest} />;
};
