import {FlowDetails, MappedFlowResponse} from './flows.types';

export const mapAPIToFlow = (flow: FlowDetails): MappedFlowResponse => {
  let {steps, status, teams, id, published_on, label, autolaunch} = flow;
  let {
    image: launcherImage,
    id: launcherId,
    description: launcherDescription,
    embed_url: launcherEmbed,
    system_image_id: launcherSystemImage,
    locations: launcherLocations,
    label: launcherLabel,
  } = steps[0];
  let {
    image: terminalImage,
    id: terminalId,
    description: terminalDescription,
    embed_url: terminalEmbed,
    system_image_id: terminalSystemImage,
    label: terminalLabel,
  } = steps[steps.length - 1];

  let stepsContent = steps
    .map((step) => {
      return step.description;
    })
    .slice(1, -1);

  return {
    label: label,
    autolaunch,
    launcher: {
      headline: launcherLabel,
      message: launcherDescription,
      image: launcherImage as string,
      image_loading: false,
      embed: launcherEmbed,
      system_image: launcherSystemImage,
      id: launcherId,
    },
    terminal: {
      headline: terminalLabel,
      message: terminalDescription,
      image: terminalImage as string,
      image_loading: false,
      embed: terminalEmbed,
      system_image: terminalSystemImage,
      id: terminalId,
    },
    steps: stepsContent,
    is_published: status === 'published' ? true : false,
    published_on: published_on,
    teams: teams.map((team: any) => ({
      label: team.name,
      value: team.id,
      topics: team.topics ? team.topics : [],
    })),
    id: id,
    targeting: launcherLocations.length
      ? launcherLocations.map((location: any) => ({
          id: location.id,
          type: location.type,
          css_selector: location.css_selector,
          position: location.position,
          path_rules: location.path_rules,
          domain_value: location.domain_value,
          instance_url: location.instance_url,
        }))
      : [{type: null}],
  };
};

export const getMediaUrl = (id: string) => `/api/v1/media/${id}/view/`;
