import React from 'react';
import {Icon, TIconProps} from '../design-system/Icon';

export const MiscIcon = (props: TIconProps) => (
  <Icon
    {...props}
    viewBox='0 0 67 86'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='misc-icon'
  >
    <path
      d='M56.4639 19.6088C54.2862 19.6067 52.1982 18.7406 50.6582 17.2007C49.1183 15.6608 48.2523 13.5728 48.2501 11.395V0H11.5528C8.71124 0 5.98607 1.12879 3.97677 3.13809C1.96748 5.14739 0.838684 7.87261 0.838684 10.7142V75.3018C0.842904 78.1406 1.97357 80.8617 3.98241 82.8675C5.99125 84.8734 8.714 86 11.5528 86H55.588C58.4296 86 61.1547 84.8712 63.164 82.8619C65.1733 80.8526 66.3022 78.1274 66.3022 75.2858V19.5929L56.4639 19.6088Z'
      fill='#EAECEF'
    />
    <path
      d='M66.3022 19.6088H56.464C54.2862 19.6067 52.1982 18.7406 50.6583 17.2007C49.1183 15.6608 48.2523 13.5728 48.2502 11.395V0L66.3022 19.6088Z'
      fill='#495057'
    />
    <path
      d='M33.5704 61.3904C33.1163 61.3904 32.6809 61.2101 32.3598 60.889C32.0387 60.5679 31.8583 60.1325 31.8583 59.6784V57.0467C31.8583 56.822 31.9027 56.5996 31.9887 56.3921C32.0748 56.1846 32.201 55.9961 32.3601 55.8375C32.5191 55.6788 32.7078 55.5531 32.9155 55.4675C33.1232 55.3819 33.3458 55.3381 33.5704 55.3386C34.0234 55.3386 34.4578 55.5185 34.7782 55.8389C35.0985 56.1592 35.2785 56.5937 35.2785 57.0467V59.6784C35.2785 60.1318 35.0986 60.5666 34.7784 60.8876C34.4582 61.2086 34.0238 61.3894 33.5704 61.3904Z'
      fill='#495057'
    />
    <path
      d='M33.5704 40.1731C33.1163 40.1731 32.6809 39.9927 32.3598 39.6717C32.0387 39.3506 31.8583 38.9151 31.8583 38.4611V35.3993C31.8583 34.9453 32.0387 34.5098 32.3598 34.1887C32.6809 33.8676 33.1163 33.6873 33.5704 33.6873C34.0238 33.6883 34.4582 33.8692 34.7784 34.1901C35.0986 34.5111 35.2785 34.946 35.2785 35.3993V38.4611C35.2785 38.9144 35.0986 39.3493 34.7784 39.6702C34.4582 39.9912 34.0238 40.1721 33.5704 40.1731Z'
      fill='#495057'
    />
    <path
      d='M39.6422 59.766C39.3413 59.7665 39.0456 59.6876 38.7849 59.5373C38.5241 59.387 38.3076 59.1706 38.1572 58.91L36.8831 56.6963C36.6566 56.304 36.5952 55.8379 36.7125 55.4003C36.8297 54.9628 37.1159 54.5897 37.5082 54.3632C37.7026 54.2504 37.9174 54.1771 38.1402 54.1475C38.3629 54.1179 38.5894 54.1325 38.8065 54.1906C39.0236 54.2487 39.2271 54.349 39.4054 54.4859C39.5836 54.6228 39.7331 54.7935 39.8453 54.9883L41.1194 57.198C41.346 57.5909 41.4075 58.0577 41.2903 58.4959C41.1731 58.9341 40.8868 59.3079 40.4943 59.5351C40.2353 59.6851 39.9415 59.7647 39.6422 59.766Z'
      fill='#495057'
    />
    <path
      d='M29.0713 41.4512C28.7703 41.4516 28.4746 41.3727 28.2139 41.2224C27.9531 41.0722 27.7366 40.8558 27.5862 40.5952L26.0175 37.8838C25.7911 37.4904 25.7302 37.0233 25.8482 36.585C25.9662 36.1468 26.2533 35.7733 26.6465 35.5467C27.0394 35.32 27.5062 35.2585 27.9444 35.3757C28.3826 35.4929 28.7564 35.7792 28.9837 36.1718L30.5484 38.8871C30.7751 39.28 30.8365 39.7468 30.7193 40.185C30.6021 40.6232 30.3158 40.997 29.9233 41.2243C29.664 41.3732 29.3702 41.4514 29.0713 41.4512Z'
      fill='#495057'
    />
    <path
      d='M44.0815 55.3187C43.7826 55.3189 43.4888 55.2407 43.2295 55.0918L41.0158 53.8137C40.6232 53.5864 40.337 53.2127 40.2198 52.7745C40.1026 52.3363 40.164 51.8695 40.3907 51.4766C40.6173 51.0834 40.9908 50.7962 41.429 50.6783C41.8673 50.5603 42.3344 50.6212 42.7278 50.8475L44.9416 52.1255C45.3341 52.3528 45.6204 52.7266 45.7376 53.1648C45.8548 53.603 45.7933 54.0698 45.5666 54.4627C45.4162 54.7233 45.1997 54.9396 44.9389 55.0899C44.6782 55.2402 44.3825 55.3191 44.0815 55.3187Z'
      fill='#495057'
    />
    <path
      d='M25.7667 44.7479C25.4673 44.7471 25.1735 44.6674 24.9147 44.5169L22.1993 42.9523C21.8067 42.725 21.5205 42.3512 21.4033 41.913C21.2861 41.4748 21.3475 41.008 21.5742 40.6151C21.6864 40.4204 21.8359 40.2497 22.0141 40.1128C22.1924 39.9759 22.3959 39.8755 22.613 39.8175C22.8301 39.7594 23.0566 39.7448 23.2794 39.7744C23.5021 39.804 23.7169 39.8772 23.9113 39.99L26.6267 41.5548C27.0185 41.7828 27.3041 42.1565 27.4212 42.5944C27.5383 43.0323 27.4774 43.4987 27.2518 43.8919C27.1013 44.1525 26.8848 44.3689 26.6241 44.5191C26.3634 44.6694 26.0676 44.7483 25.7667 44.7479Z'
      fill='#495057'
    />
    <path
      d='M45.7139 49.2509H43.0782C42.6241 49.2509 42.1887 49.0706 41.8676 48.7495C41.5466 48.4284 41.3661 47.993 41.3661 47.5389C41.3672 47.0855 41.5481 46.6511 41.869 46.3309C42.19 46.0106 42.6248 45.8308 43.0782 45.8308H45.7139C46.1673 45.8308 46.6022 46.0106 46.9231 46.3309C47.2441 46.6511 47.4249 47.0855 47.426 47.5389C47.426 47.993 47.2456 48.4284 46.9245 48.7495C46.6035 49.0706 46.168 49.2509 45.7139 49.2509Z'
      fill='#495057'
    />
    <path
      d='M24.4847 49.2509H21.4269C20.9728 49.2509 20.5374 49.0706 20.2163 48.7495C19.8953 48.4284 19.7148 47.993 19.7148 47.5389C19.7159 47.0855 19.8968 46.6511 20.2177 46.3309C20.5387 46.0106 20.9735 45.8308 21.4269 45.8308H24.4807C24.7054 45.8303 24.9279 45.8741 25.1356 45.9597C25.3433 46.0453 25.532 46.171 25.6911 46.3297C25.8501 46.4883 25.9762 46.6768 26.0623 46.8843C26.1484 47.0918 26.1927 47.3143 26.1927 47.5389C26.1932 47.7635 26.1495 47.9861 26.0639 48.1938C25.9783 48.4015 25.8525 48.5902 25.6939 48.7492C25.5352 48.9083 25.3467 49.0344 25.1392 49.1205C24.9317 49.2066 24.7093 49.2509 24.4847 49.2509Z'
      fill='#495057'
    />
    <path
      d='M41.6846 44.5647C41.3841 44.5651 41.0888 44.4867 40.8282 44.3371C40.5676 44.1876 40.3508 43.9723 40.1995 43.7127C39.9732 43.3193 39.9123 42.8522 40.0302 42.4139C40.1482 41.9757 40.4354 41.6022 40.8286 41.3755L43.2175 39.99C43.6104 39.7633 44.0771 39.7019 44.5153 39.819C44.9535 39.9362 45.3273 40.2226 45.5546 40.6151C45.7813 41.008 45.8428 41.4748 45.7256 41.913C45.6084 42.3512 45.3221 42.7249 44.9295 42.9522L42.5406 44.3378C42.2804 44.4877 41.985 44.566 41.6846 44.5647Z'
      fill='#495057'
    />
    <path
      d='M23.0553 55.3187C22.7549 55.3192 22.4597 55.2403 22.1996 55.09C21.9395 54.9397 21.7237 54.7233 21.5742 54.4627C21.3475 54.0698 21.2861 53.603 21.4033 53.1648C21.5205 52.7266 21.8067 52.3528 22.1993 52.1255L24.7315 50.6644C25.1247 50.4388 25.5911 50.3779 26.029 50.495C26.4669 50.6121 26.8406 50.8977 27.0687 51.2894C27.295 51.6828 27.3559 52.15 27.2379 52.5882C27.1199 53.0265 26.8328 53.3999 26.4396 53.6266L23.9113 55.0878C23.6519 55.2402 23.3562 55.3199 23.0553 55.3187Z'
      fill='#495057'
    />
    <path
      d='M33.7695 58.7627H33.4948C31.5379 58.7169 29.6293 58.1462 27.9685 57.1104C26.3272 56.0928 24.9817 54.6622 24.0666 52.9617C23.2113 51.3604 22.7628 49.5733 22.7607 47.7579C22.7607 47.6663 22.7607 47.5747 22.7607 47.4832C22.8078 45.6122 23.3313 43.7841 24.2816 42.1718C25.2307 40.5617 26.5733 39.2191 28.1835 38.27C29.8025 37.3189 31.6375 36.7968 33.5147 36.753H33.7655C35.5832 36.7511 37.3728 37.2013 38.9733 38.063C40.6725 38.9749 42.1019 40.3178 43.118 41.9569C44.1551 43.6257 44.7245 45.5426 44.7663 47.507C44.7663 47.5747 44.7663 47.6663 44.7663 47.7579C44.7667 49.6586 44.2743 51.527 43.337 53.1806C42.3572 54.9162 40.9229 56.3519 39.1883 57.3333C37.5357 58.2696 35.6689 58.762 33.7695 58.7627ZM33.7257 40.1692H33.6182C32.3174 40.1974 31.0455 40.5579 29.9234 41.2163C28.8138 41.8707 27.8879 42.7952 27.2319 43.9038C26.5824 45.0185 26.2288 46.2808 26.2047 47.5707C26.2047 47.6424 26.2047 47.6941 26.2047 47.7459C26.2038 48.9964 26.513 50.2275 27.1045 51.3292C27.7345 52.5045 28.6628 53.4932 29.796 54.1959C30.9457 54.9092 32.2656 55.301 33.6182 55.3306H33.7934C35.1037 55.3322 36.392 54.9933 37.532 54.3472C38.7291 53.6697 39.7188 52.6786 40.3947 51.4805C41.0401 50.3417 41.3789 49.0549 41.3781 47.7459V47.5947C41.3486 46.234 40.9554 44.906 40.2394 43.7485C39.5389 42.6183 38.5529 41.6927 37.3807 41.065C36.2602 40.4667 35.0078 40.1587 33.7377 40.1692H33.7257Z'
      fill='#495057'
    />
    <path
      d='M38.173 41.2721C37.8739 41.2732 37.5798 41.1949 37.321 41.0451C37.1262 40.9329 36.9555 40.7835 36.8186 40.6052C36.6817 40.4269 36.5814 40.2234 36.5233 40.0063C36.4652 39.7892 36.4506 39.5627 36.4802 39.3399C36.5098 39.1172 36.5831 38.9024 36.6958 38.708L38.1571 36.1718C38.2692 35.977 38.4187 35.8063 38.597 35.6694C38.7752 35.5325 38.9788 35.4322 39.1959 35.3741C39.413 35.316 39.6394 35.3014 39.8622 35.331C40.085 35.3606 40.2998 35.4339 40.4942 35.5467C40.6889 35.6589 40.8597 35.8083 40.9965 35.9866C41.1334 36.1649 41.2337 36.3684 41.2918 36.5855C41.3499 36.8026 41.3646 37.0291 41.335 37.2519C41.3053 37.4746 41.232 37.6894 41.1193 37.8838L39.6581 40.416C39.5076 40.6767 39.2911 40.893 39.0304 41.0433C38.7697 41.1936 38.4739 41.2725 38.173 41.2721Z'
      fill='#495057'
    />
    <path
      d='M27.4986 59.766C27.1991 59.7658 26.9051 59.6861 26.6465 59.5351C26.2533 59.3085 25.9662 58.935 25.8482 58.4968C25.7302 58.0585 25.7911 57.5914 26.0175 57.198L27.403 54.8091C27.6303 54.4166 28.0041 54.1303 28.4423 54.0131C28.8805 53.8959 29.3473 53.9573 29.7402 54.184C30.1327 54.4113 30.419 54.7851 30.5362 55.2233C30.6534 55.6614 30.5919 56.1282 30.3652 56.5211L28.9837 58.91C28.8332 59.1706 28.6167 59.387 28.356 59.5373C28.0952 59.6876 27.7995 59.7665 27.4986 59.766Z'
      fill='#495057'
    />
  </Icon>
);
