import {ISession} from 'spekit-types';
import store from '../reduxStore';

/**
 * Checks if at least one the permissions provided are present in the users session
 * @param permissions array of permissions to check from the session
 * @returns boolean
 */
export const hasSomeOf = (permissions: string[]) => {
  const session: ISession = store.getState().layout?.me;
  return permissions.some((permission) => session?.permissions?.includes(permission));
};

/**
 * Checks if all the permissions provided are present in the users session
 * @param permissions array of permissions to check from the session
 * @returns boolean
 */
export const hasAllOf = (permissions: string[]) => {
  const session: ISession = store.getState().layout?.me;
  return permissions.every((permission) => session?.permissions?.includes(permission));
};
