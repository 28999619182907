import React from 'react';
import {ControlledSelect} from 'spekit-ui';
import {team} from 'spekit-datalayer';
import {TeamDetail} from 'spekit-datalayer/src/team/team.types';
import {useFormContext} from 'react-hook-form';
import {IOptionType} from 'spekit-types';

interface SelectOption {
  value: string;
  label: string;
}
interface TeamPickerProps {
  selectedTeams?: Array<SelectOption>;
  topics?: Array<string>;
}

interface ITeamPickerState {
  teams: Array<IOptionType>;
}

export const TeamPicker = ({selectedTeams = [], topics = []}: TeamPickerProps) => {
  const {
    formState: {errors},
  } = useFormContext<ITeamPickerState>();

  const getTeamOptions = (searchTerm = '') =>
    team
      .getTeamListBySuggestions({
        q: searchTerm,
        team_admin: true,
        ordering: 'name',
        permissions: 'businessterms.assign_data_expert_own',
        topics,
      })
      .then((res: Array<TeamDetail>) => {
        // selected teams that are not in the response
        const missingTeams = selectedTeams.filter(
          (selectedTeam) =>
            !res.find((teamOption) => teamOption.value === selectedTeam.value)
        );

        // return the response with any selected teams that were not in the response
        return [
          ...res.map((item) => ({value: item.value, label: item.label})),
          ...missingTeams,
        ];
      });

  return (
    <ControlledSelect
      key={JSON.stringify(topics)}
      testId='team-picker'
      menuTestId='team-picker-menu'
      isMulti
      isAsync
      loadOptions={async (inputValue: string) => getTeamOptions(inputValue)}
      cacheOptions
      isInvalid={!!errors.teams}
      defaultOptions
      label='Teams'
      name='teams'
      id='teams'
      placeholder='Select Teams'
      isRequired
    />
  );
};
