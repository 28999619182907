/* istanbul ignore file */
// The readonly and editing view are now being imported separately and this file may soon be deleted. No testing for now since it is not being used.
/**
 * The RichTextEditor component is a wrapper around the spek viewer (read only) and spek editor (CKEditor).
 * The ReadOnlyView parses the raw output from CKEditor.
 * The EditingView renders CKEditor.
 */

import React from 'react';

/**
 * Import base CKEditor styles and variables for rendering read only view
 * Import custom styles for overriding CKEditor styles to match Spekit styles
 * These styles apply to both the read-only and editing views
 *
 * More info regarding CKEditor styles and customizing them:
 * https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 * https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/ui/theme-customization.html
 * https://ckeditor.com/docs/ckeditor5/latest/features/style.html
 */

import './styles/editorStyles.css'; // all default CKEditor color variables with some overrides

// import components
import {ReadOnlyView, EditingView} from './components';
import {EditorCommonProps} from 'spekit-types';

export interface RichTextEditorProps
  extends Omit<EditorCommonProps, 'setUploadBusy' | 'unSetUploadBusy'> {
  host?: string | null; // used in extension to render embedded content
  onImageZoom?: (url: string) => void; // function called when image is clicked in read only view
  isPreview?: boolean; // used to control what content loads in spek previews
  enhanced?: boolean; // used to gate access to upcoming features

  // redux actions - we use these to disable/enable the save button when uploading images and files
  setUploadBusy: () => void;
  unSetUploadBusy: () => void;

  config?: any;
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  host,
  onChange,
  onCharactersCountChange,
  placeholder,
  readOnly,
  singleLine,
  value,
  onImageZoom,
  isPreview,
  setUploadBusy,
  unSetUploadBusy,
  enhanced,
  spekID,
  isError,
  errorMessage,
  editingViewReadOnly,
  config,
}) => {
  // render read only view if readOnly is true, otherwise render editing view
  if (readOnly) {
    return (
      <ReadOnlyView
        singleLine={singleLine}
        host={host}
        value={value}
        onImageZoom={onImageZoom}
        isPreview={isPreview}
      />
    );
  } else {
    return (
      <EditingView
        spekID={spekID}
        enhanced={enhanced}
        placeholder={placeholder}
        value={value}
        host={host}
        isError={isError}
        errorMessage={errorMessage}
        onChange={onChange}
        onCharactersCountChange={onCharactersCountChange}
        setUploadBusy={setUploadBusy}
        unSetUploadBusy={unSetUploadBusy}
        readOnly={editingViewReadOnly}
        config={config}
      />
    );
  }
};
