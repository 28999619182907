import React from 'react';
import {Avatar as ChakraAvatar, AvatarProps} from '@chakra-ui/react';
import {FONT_FAMILY} from '../Typography';

export type TAvatarProps = AvatarProps;

export const AvatarConfig = {
  parts: ['label', 'badge', 'container', 'excessLabel', 'group'],
  baseStyle: {
    excessLabel: {
      fontFamily: FONT_FAMILY,
    },
    label: {
      fontFamily: FONT_FAMILY,
    },
  },
  variants: {},
  sizes: {},
  defaultProps: {},
};
export const Avatar = (props: TAvatarProps) => <ChakraAvatar {...props} />;
