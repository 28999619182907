import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import LoginLayout from '../../components/LoginLayout';
import ResetPasswordLayout from '../../components/ResetPasswordLayout/ResetPasswordLayout';
import {useForm} from 'react-hook-form';
import {IPasswordFormState} from 'spekit-types';
import {logging, inviteUser} from 'spekit-datalayer';
import {Flex, Heading, Text} from 'spekit-ui';

type TSalseforce = '0';
type TNonSalesforce = '1';

interface IAcceptInviteParams {
  inviteType: TSalseforce | TNonSalesforce;
  id: string;
}

const AcceptInvite = () => {
  const salesforceInviteType = '0';
  const history = useHistory();
  const {inviteType, id} = useParams<IAcceptInviteParams>();
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [loaderText, setLoaderText] = useState('');
  const methods = useForm<IPasswordFormState>({mode: 'onChange', criteriaMode: 'all'});

  const [isValidPasswordLink, setIsValidPasswordLink] = useState(false);

  useEffect(() => {
    const checkInvite = async () => {
      try {
        const result = await inviteUser.checkInvite(id);
        setIsValidPasswordLink(result.success);
        setIsPageLoading(result.success && inviteType === salesforceInviteType);
        if (result.success && inviteType === salesforceInviteType) {
          const sandboxFlag =
            result.organization_type !== 'production' ? 'sandbox=true&' : '';
          setLoaderText(`Redirecting you to Salesforce ${sandboxFlag && 'sandbox'}...`);
          window.location.href = `/api/auth/login/salesforce?${sandboxFlag}invite=${id}`;
        }
      } catch (e) {
        logging.capture(e);
      }
    };
    checkInvite();
  }, [id, inviteType]);

  const login = async (data: IPasswordFormState) => {
    setIsLoading(true);
    try {
      const result = await inviteUser.acceptInvite(id, data.password);
      if (result.success) {
        history.push('/app/generate');
      } else if (!result.success && result.accepted) {
        throw new Error('Invite already accepted!');
      } else {
        throw new Error(result.message);
      }
    } catch (e) {
      methods.setError('confirmPassword', {
        type: 'general',
        message: e.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginLayout
      hideQuote
      hideImage
      isPageLoading={isPageLoading}
      loaderText={loaderText}
    >
      {isValidPasswordLink && inviteType !== salesforceInviteType ? (
        <ResetPasswordLayout
          methods={methods}
          isLoading={isLoading}
          passwordLabel='Create password'
          confirmPasswordLabel='Re-enter password'
          heading='Get started with Spekit'
          submitButtonText='Create account'
          passwordHandler={login}
        />
      ) : (
        !isValidPasswordLink && (
          <Flex direction='column' px={144} maxW={450} pt={176} gap={20}>
            <Heading as='h3' fontWeight='semibold' fontSize='24px' textAlign='center'>
              Invalid invitation link
            </Heading>
            <Text variant='body2' fontWeight='normal' textAlign='center'>
              This invitation link has already been used or the link is invalid. Please
              reach out to your admin and request a re-invite.
            </Text>
          </Flex>
        )
      )}
    </LoginLayout>
  );
};

export default AcceptInvite;
