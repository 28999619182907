import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {stringifyQs} from '../utils/commonUtils';
export interface Query {
  q: string;
  teams: string[];
}

interface Spek {
  label: string;
  value: string;
  term_type: string;
}
export interface Response {
  results: Array<Spek>;
}

export interface SpekDetailResponse {
  id: string;
  label: string;
  sfdc_api_name: null;
  definition: string;
  business_rule: null;
  data_expert: null;
  parent: null;
  tags: any[];
  created_on: string;
  last_edited: string;
  type: string;
  related_objects: any[];
}

export const spekLookup = async (query: Query): Promise<Response> => {
  try {
    let querystring = stringifyQs(query, {arrayFormat: 'comma'});
    const response = await fetch(`/api/lookups/speks?defined=false&${querystring}`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    });
    let result = await response.json();
    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const spekDetailLookup = async (
  spekId: string,
  spekType: 'object' | 'field' | 'field_value' | 'business_term'
): Promise<SpekDetailResponse> => {
  try {
    let response = await fetch(`/api/wiki/${spekType}s/${spekId}`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    });
    let result = await response.json();
    return result;
  } catch (err) {
    throw new Error(err);
  }
};
