import {getExtensionButton} from 'spekit-ui';
import {RouteComponentProps} from 'react-router-dom';

const {getExtensionId} = getExtensionButton;

type TSpekView = {
  termType: string;
  termId: string;
};
type TKnowledgeCheckView = {
  knowledgeCheckId: string;
  isPreview?: boolean;
};
type TTopicView = {
  topicId: string;
  topicName: string;
};

type TFlowData = {
  flowId: string;
  sessionId: string | null;
};

type TAction =
  | 'viewSpek'
  | 'viewKnowledgeCheck'
  | 'viewTopic'
  | 'viewFlow'
  | 'record'
  | 'previewFlow';
interface IViewData {
  action: TAction;
  data?: TSpekView | TKnowledgeCheckView | TTopicView | TFlowData;
  history: RouteComponentProps['history'];
}

/**
 * Sends an external message to chrome extension with an action of
 * `viewKnowledgeCheck` | `viewSpek` | `viewTopic` | `viewFlow` | `previewFlow`.
 *  On receiving this message, a user is able to view the item in extension.
 *  Each action has their own data parametres described above.
 */
export const handleView = async (params: IViewData) => {
  const {history, action, data} = params;
  const handler = {...data, action};

  const extensionId = await getExtensionId();
  if (extensionId) {
    chrome.runtime.sendMessage(extensionId, handler);
  } else {
    history.push('/app/error/extension-not-installed');
  }
};

export default {
  handleView,
};
