import React from 'react';
import {Spinner as ChakraSpinner, SpinnerProps} from '@chakra-ui/react';

export type TSpinnerProps = SpinnerProps;

export const SpinnerConfig = {
  baseStyle: {},
  variants: {},
  sizes: {},
  defaultProps: {},
};
export const Spinner = (props: TSpinnerProps) => <ChakraSpinner {...props} />;
