/*global chrome*/
import React from 'react';
import ReactDOM from 'react-dom';
import Fontawesome from './fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import Draggable from 'react-draggable';
class Modal extends React.Component {
  render() {
    if (!this.props.open) return null;
    let styles = {
      main: {
        position: 'fixed',
        top: '20px',
        left: '0px',
        right: '0px',
        height: '0px',
        textAlign: 'center',
        display: this.props.open ? 'block' : 'none',
        zIndex: '2147483645',
        cursor: this.props.draggable ? 'move' : 'initial',
      },
      hCenter: {
        margin: 'auto',
        padding: this.props.noBorder ? '0px' : this.props.draggable ? '40px' : '20px',
        backgroundColor: '#FFF',
        borderRadius: '6px',
        boxShadow: '0px 0px 22px 1px rgba(0,0,0,0.3)',
        display: 'inline-block',
        position: 'relative',
      },
      closeBtn: {
        fontSize: '20px',
        color: '#9FA9BA',
        position: 'absolute',
        top: this.props.noBorder ? '13px' : '20px',
        right: '20px',
        cursor: 'pointer',
        zIndex: '2147483647',
      },
      move: {
        height: '20px',
        position: 'absolute',
        top: '20px',
        left: '20px',
        pointerEvents: 'none',
      },
    };
    styles.hCenter = {
      ...styles.hCenter,
      ...this.props.style,
    };

    let thePortal = document.getElementById('modal_portal');
    if (!thePortal) {
      thePortal = document.createElement('div');
      thePortal.id = 'modal_portal';
      thePortal.style.display = 'initial';
      document.body.appendChild(thePortal);
    }

    return ReactDOM.createPortal(
      <React.Fragment>
        {this.props.draggable ? (
          <Draggable>
            <div style={styles.main}>
              <div style={styles.hCenter}>
                <img
                  src={chrome.runtime.getURL('/images/move.svg')}
                  alt='move'
                  style={styles.move}
                />
                {typeof this.props.onClose === 'function' ? (
                  <Fontawesome
                    name={faTimes}
                    style={styles.closeBtn}
                    onClick={() => this.props.onClose()}
                  />
                ) : null}
                {this.props.children}
              </div>
            </div>
          </Draggable>
        ) : (
          <div style={styles.main}>
            <div style={styles.hCenter} className={this.props.className}>
              {typeof this.props.onClose === 'function' ? (
                <Fontawesome
                  name={faTimes}
                  className={this.props.closeBtnClassName}
                  style={styles.closeBtn}
                  onClick={() => this.props.onClose()}
                />
              ) : null}
              {this.props.children}
            </div>
          </div>
        )}
      </React.Fragment>,
      thePortal
    );
  }
}

export default Modal;
