import {
  IOptionType,
  TComputedValues,
  ICustomFieldOption,
  TCustomField,
  TCustomFieldValue,
} from 'spekit-types';
import {assertExhaustive} from '../utils/commonUtils';

const createFieldsMap = (customFields: TCustomField[]): Map<string, TCustomField> =>
  new Map(customFields.map((field) => [field.id, field]));

const createOptionsMap = (
  customFields: TCustomField[]
): Map<string, ICustomFieldOption> =>
  new Map(
    customFields.flatMap((f) =>
      f.data_type === 'multi-option' ? f.options.map((o) => [o.id, o]) : []
    )
  );

export const transformFieldValuesToComputedValues = (
  values: Record<string, string | string[]>,
  customFields: TCustomField[]
) => {
  const computedValues: TComputedValues = {};
  const fieldsMap = createFieldsMap(customFields);
  const optionsMap = createOptionsMap(customFields);

  const getMultiOptions = (optionIds: string[]): IOptionType[] =>
    optionIds.reduce((acc, optionId) => {
      const option = optionsMap.get(optionId);
      if (option !== undefined) {
        acc.push({value: option.id, label: option.name});
      }
      return acc;
    }, [] as IOptionType[]);

  Object.keys(values).forEach((key) => {
    const value = values[key];
    const field = fieldsMap.get(key);
    if (field !== undefined) {
      computedValues[key] = Array.isArray(value) ? getMultiOptions(value) : value;
    }
  });
  return computedValues;
};

export function getReadableValue(
  value: TCustomFieldValue = '',
  customField?: TCustomField
) {
  if (!customField) return '';
  const {data_type} = customField;
  switch (data_type) {
    case 'string':
      return String(value);
    case 'multi-option':
      if (Array.isArray(value)) {
        const optionsMap = createOptionsMap([customField]);
        const optionNames = [];
        for (const id of value) {
          const optionName = optionsMap.get(id)?.name;
          if (optionName) optionNames.push(optionName);
        }
        return optionNames.join(', ');
      }
      return '';
    default:
      return assertExhaustive(data_type);
  }
}
