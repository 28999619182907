import React from 'react';
import {useStyleConfig} from '@chakra-ui/react';
import {FaChevronLeft, FaChevronRight, FaEllipsisH} from 'react-icons/fa';
import {Button} from '../Button';
import {Icon} from '../Icon';
import {Box, TBoxProps} from '../Layout';
import {IPagination, getItemsSmall, getItems} from './helpers';
import {useBreakpointValue} from '../hooks';

export type TPaginationProps = IPagination &
  TBoxProps & {
    onPageSelect: (x: number) => void;
    size?: 'small' | 'large';
    isChrome?: boolean;
  };

export const PaginationConfig = {
  baseStyle: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  variants: {},
  sizes: {
    sm: {
      gap: '12',
    },
    lg: {
      gap: '16',
    },
  },
  defaultProps: {},
};

const BUTTON_SIZE = {
  sm: '40px',
  lg: '48px',
};

export const Pagination = (props: TPaginationProps) => {
  const {totalPages, currentPage, onPageSelect, ...rest} = props;
  const breakPointValue = useBreakpointValue({base: 'sm', md: 'lg'});
  const size = breakPointValue ? breakPointValue : 'lg';

  const paginationItems =
    size === 'sm'
      ? getItemsSmall({totalPages, currentPage})
      : getItems({totalPages, currentPage});
  const styles = useStyleConfig('Pagination', {size});

  return (
    <Box __css={styles} {...rest} size={size}>
      <Button
        variant='icon'
        data-testid='previous-button'
        aria-label='previous'
        icon={<Icon as={FaChevronLeft} />}
        size='large'
        colorScheme='primary'
        disabled={currentPage === 1}
        w={BUTTON_SIZE[size]}
        h={BUTTON_SIZE[size]}
        borderRadius='50%'
        onClick={() => onPageSelect(currentPage - 1)}
      />
      {paginationItems.map((paginationItem, index) => {
        const isSelected = paginationItem === currentPage;
        if (paginationItem === '...') {
          return (
            <Icon
              key={'pagination-item-' + index}
              as={FaEllipsisH}
              w='16px'
              h='16px'
              color='neutral.500'
            />
          );
        }

        return (
          <Button
            data-testid='pagination-item'
            key={'pagination-item-' + index}
            aria-label='select page'
            size='large'
            variant='ghost'
            colorScheme='primary'
            borderRadius='50%'
            w={BUTTON_SIZE[size]}
            h={BUTTON_SIZE[size]}
            borderStyle='solid'
            borderWidth='1px'
            borderColor='neutral.500'
            color='neutral.500'
            backgroundColor='white'
            fontSize='small'
            isActive={isSelected}
            _hover={{
              borderColor: 'primary.500',
              backgroundColor: 'primary.50',
              color: 'primary.500',
            }}
            _active={{
              borderColor: 'primary.500',
              color: 'primary.500',
              backgroundColor: 'primary.50',
            }}
            _focus={{
              borderColor: 'primary.500',
              color: 'primary.500',
            }}
            onClick={() => onPageSelect(Number(paginationItem))}
          >
            {paginationItem}
          </Button>
        );
      })}
      <Button
        data-testid='forward-button'
        variant='icon'
        aria-label='next'
        icon={<Icon as={FaChevronRight} />}
        size='large'
        colorScheme='primary'
        w={BUTTON_SIZE[size]}
        h={BUTTON_SIZE[size]}
        disabled={currentPage === totalPages || totalPages === 0}
        borderRadius='50%'
        onClick={() => onPageSelect(currentPage + 1)}
      />
    </Box>
  );
};

export default Pagination;
