import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

interface _Props {
  onClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  onMouseDown?: (e: React.MouseEvent) => void;
  style?: any;
  svgStyle?: any;
  className?: string;
  svgClassname?: string;
  name: IconProp;
  size?:
    | 'xs'
    | 'lg'
    | 'sm'
    | '1x'
    | '2x'
    | '3x'
    | '4x'
    | '5x'
    | '6x'
    | '7x'
    | '8x'
    | '9x'
    | '10x'
    | undefined;
}

class FontawesomeWrapper extends Component<_Props> {
  click = (e: React.MouseEvent) => {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(e);
    }
  };
  mouseEnter = (evt: React.MouseEvent) => {
    if (typeof this.props.onMouseEnter === 'function') {
      this.props.onMouseEnter(evt);
    }
  };
  mouseLeave = (evt: React.MouseEvent) => {
    if (typeof this.props.onMouseLeave === 'function') {
      this.props.onMouseLeave(evt);
    }
  };
  mouseDown = (evt: React.MouseEvent) => {
    if (typeof this.props.onMouseDown === 'function') {
      this.props.onMouseDown(evt);
    }
  };
  render() {
    return (
      <span
        onClick={this.click}
        style={this.props.style}
        className={this.props.className}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
        onMouseDown={this.mouseDown}
        data-testid={this.props['data-testid']}
      >
        <FontAwesomeIcon
          className={this.props.svgClassname}
          style={this.props.svgStyle}
          icon={this.props.name}
          size={this.props.size}
        />
      </span>
    );
  }
}

export default FontawesomeWrapper;
