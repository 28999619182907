import {Team, Teams} from './audienceTeamSelector.types';
import {TeamLookup, logging} from 'spekit-datalayer';
const {capture} = logging;

export const getTeams = async (searchTerm: string) => {
  let teams: Teams | [] = [];
  try {
    teams = await TeamLookup.teamLookup({
      q: searchTerm,
      permissions: 'businessterms.assign_data_expert_own',
    });

    return teams.results.map((team: Team) => ({
      value: team.value,
      label: team.label,
      topics: team.topics,
    }));
  } catch (err) {
    capture(err);
  }
  return teams;
};
