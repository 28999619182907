import {fetchMiddleWare as fetch} from './fetchMiddleWare';
import {capture} from './logging';

export const getFieldTypes = async (): Promise<string[]> => {
  try {
    let result = await fetch('/api/wiki/field_types', {
      credentials: 'include',
    });
    let response = await result.json();
    if (response.success) {
      return response.field_types;
    } else {
      throw new Error('Could not retrieve field types.');
    }
  } catch (e) {
    capture(e);
    throw e;
  }
};
