import React, {RefObject} from 'react';
import {
  DSModal as Modal,
  ModalBody,
  ModalFooter,
  DSButton as Button,
  ModalHeader,
} from 'spekit-ui';

interface IDeleteConfirmationProps {
  isOpen: boolean;
  isDeleting: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  containerRef?: RefObject<HTMLElement>;
}

export default function DeleteConfirmation(props: IDeleteConfirmationProps) {
  const {isOpen, handleDelete, isDeleting, handleClose, containerRef} = props;

  return (
    <Modal
      portalProps={{containerRef}}
      isOpen={isOpen}
      onClose={handleClose}
      size='xs'
      trapFocus={false}
      scrollBehavior='outside'
    >
      <ModalHeader data-testid='delete-confirmation-header'>
        Are you sure you want to delete this file?
      </ModalHeader>
      <ModalBody data-testid='delete-confirmation-description'>
        Users in your org will no longer have access to this file and all related data
        will be lost.
        <ModalFooter>
          <Button
            mr={2}
            colorScheme='white'
            size='medium'
            variant='ghost'
            onClick={handleClose}
            data-testid='delete-asset-reject'
          >
            No, return to editing
          </Button>

          <Button
            data-testid='delete-asset-confirmation'
            size='medium'
            variant='danger'
            onClick={handleDelete}
            isLoading={isDeleting}
          >
            Yes Delete
          </Button>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}
