import React from 'react';
import {FullPageLoader} from 'spekit-ui';
import {CustomBranding} from '../../components/CustomBranding';
import {useCustomBranding} from 'spekit-shared-components';

export const CustomBrandinTab = () => {
  const {data, isLoading} = useCustomBranding();
  if (isLoading || !data) return <FullPageLoader />;
  return <CustomBranding config={data} />;
};
