import {
  ISearchDocumentTypeCount,
  TSearchContentTypes,
  ISearchResult,
  TSearchAPIContentType,
} from 'spekit-types';

interface ICalculateCountParams {
  type: TSearchContentTypes;
  contentTypeCounts: ISearchDocumentTypeCount;
  seismicTypeCount?: number;
}

function getSafeCount(
  contentTypeCounts: ISearchDocumentTypeCount,
  seismicTypeCount?: number
) {
  return {
    flow: contentTypeCounts.flow ?? 0,
    quiz: contentTypeCounts.quiz ?? 0,
    spek: contentTypeCounts.spek ?? 0,
    spotlight: contentTypeCounts.spotlight ?? 0,
    topic: contentTypeCounts.topic ?? 0,
    asset: contentTypeCounts.asset ?? 0,
    seismic: seismicTypeCount ?? 0,
  };
}

export function calculateCount({
  type,
  contentTypeCounts,
  seismicTypeCount,
}: ICalculateCountParams) {
  const {flow, quiz, spek, spotlight, topic, asset, seismic} = getSafeCount(
    contentTypeCounts,
    seismicTypeCount
  );

  const counts = {
    All: flow + quiz + spek + spotlight + topic + asset,
    Flows: flow,
    'Knowledge Checks': quiz,
    Speks: spek,
    Spotlights: spotlight,
    Topics: topic,
    Files: asset,
    Seismic: seismic,
  };

  return counts[type];
}

export function getCorrectRelationship(item: Partial<ISearchResult>) {
  const {parent, parent_object: parentObject, type} = item;

  if (type === 'business_term') {
    return 'Spek';
  }

  if (type === 'object') {
    return 'Object';
  }

  if (type === 'field' && parent) {
    return `Field on ${parent.label}`;
  }
  if (type === 'field_value' && parent && parentObject) {
    return `Picklist value on ${parent.label} on ${parentObject.label}`;
  }

  return 'Spek';
}

export const getColorsForContentTypes = (
  type: TSearchAPIContentType | TSearchContentTypes
) => {
  if (type === 'All') return 'neutral';
  if (type === 'flow' || type === 'Flows') return 'plum';
  if (type === 'quiz' || type === 'Knowledge Checks') return 'turq';
  if (type === 'spek' || type === 'Speks') return 'primary';
  if (type === 'spotlight' || type === 'Spotlights') return 'taffy';
  if (type === 'topic' || type === 'Topics') return 'orchid';
  if (type === 'Files') return 'royal';
  if (type === 'asset') return 'pdf';
  if (type === 'Seismic' || type === 'seismic') return 'seismic';

  return undefined;
};

export const getGraphicType = (type: TSearchAPIContentType) => {
  if (type === 'asset') return 'pdf';
  if (type === 'quiz') return 'knowledge-check';

  // Rest of the types are same as the API type
  return type;
};
