import {
  INotificationListResponse,
  INotificationParams,
  IQuizNotification,
  IReactionNotification,
  ISpotlightNotification,
} from 'spekit-types';
import {retrieve, update, create} from '../utils/APIHelpers';
import {stringifyQs} from '../utils/commonUtils';

export const getNotifications = async (params?: INotificationParams) => {
  const filters = stringifyQs(params);
  const results = await retrieve<INotificationListResponse>(
    `/api/v1.1/notification-messages?${filters}`
  );
  return results;
};

export const getQuizDetails = async (quiz_id: string) => {
  const results = await retrieve<IQuizNotification>(
    `/learning/quizzes/${quiz_id}/user-status/`
  );
  return results;
};

export const getReactionDetails = async (term_type: string, term_id: string) => {
  const results = await retrieve<IReactionNotification>(
    `/api/wiki/${term_type}/${term_id}/recent-reactions/`
  );
  return results;
};

export const getNotificationCounter = async () => {
  return await retrieve('/api/notification-messages/unread-count/');
};

export const updateNotificationCounter = async (fromNotificationClient?: boolean) => {
  return await update('/api/notification-messages/unread-count/', {
    ...(fromNotificationClient && {viewed: false}),
  });
};

export const updateNotificationReadStatus = async (notification_id: string) => {
  return await create(`/api/v1.1/notification-messages/${notification_id}/mark-read/`);
};

export const getSpotlightDetails = async (spotlight_id: string) => {
  let results = await retrieve<ISpotlightNotification>(
    `/walkthroughs/spotlights/${spotlight_id}/notification-details/`
  );
  return results;
};
