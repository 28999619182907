import React from 'react';
import {Input} from 'spekit-ui';
import styles from './pathInput.module.css';

interface Props {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PathInput: React.FC<Props> = ({value, onChange, disabled, onBlur}) => {
  return (
    <Input
      placeholder='Enter path'
      value={value}
      onChange={onChange}
      disabled={disabled}
      onBlur={onBlur}
    />
  );
};

export default PathInput;
