import {TaskResponse} from './checkStatusTasks.types';
import {capture} from '../logging';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';

export const checkTaskStatus = (id: string | undefined): Promise<void> => {
  const poll = (): Promise<TaskResponse> =>
    fetch(`/api/tasks/${id}/`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response: unknown) => response as TaskResponse);
  const test = (result: TaskResponse): boolean | string => {
    const status = result.status.toLowerCase();
    if (status === 'queued' || status === 'processing') {
      return false;
    }
    if (status === 'completed') {
      return true;
    }
    throw new Error('sync failed');
  };
  return new Promise((res, rej) => {
    const pollAndTest = () => {
      poll()
        .then((response) => test(response))
        .then((jobDone) => {
          if (jobDone) {
            res();
          } else {
            setTimeout(() => {
              pollAndTest();
            }, 3000);
          }
        })
        .catch((err) => {
          capture(err);
          rej(err);
        });
    };
    pollAndTest();
  });
};
