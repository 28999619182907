import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Range from '@ckeditor/ckeditor5-engine/src/model/range';
import Element from '@ckeditor/ckeditor5-engine/src/model/element';

export default class LimitIndent extends Plugin {
  init() {
    const editor = this.editor;

    // get the element at the cursor
    function getSelectedElement(): Element {
      return editor.model.document.selection.getFirstPosition()!.parent as Element;
    }

    // get all elements in all provided ranges
    function getSelectedElements(ranges: Range[]) {
      const elements = ranges.map((r: Range) => Array.from(r.getItems())).flat();
      // if the user does not have a selection, the element at the cursor will not be included in the ranges
      elements.push(getSelectedElement());
      return elements;
    }

    // check if any selected elements are list items
    function isList() {
      const selectedElements = getSelectedElements(
        Array.from(editor.model.document.selection.getRanges())
      );
      return selectedElements.some((e) => e.getAttribute('listIndent') !== undefined);
    }

    // disable indent list button if list item is already indented 10 times
    // https://github.com/ckeditor/ckeditor5/issues/9437#issuecomment-821176306
    editor.model.document.on('change', (event) => {
      const selectedElement = getSelectedElement();

      // commands
      const indentListCommand = editor.commands.get('indentList');
      const indentBlockCommand = editor.commands.get('indentBlock');
      const outdentBlockCommand = editor.commands.get('outdentBlock');

      // unique ID used when disabling commands
      const pluginControlID = 'limitIndent';

      if (isList()) {
        // if selection is a list item, disable indent block commands
        if (indentBlockCommand) indentBlockCommand.forceDisabled(pluginControlID);
        if (outdentBlockCommand) outdentBlockCommand.forceDisabled(pluginControlID);

        // remove blockIndent attribute from list items.
        if (
          selectedElement.getAttribute('blockIndent') !== undefined &&
          selectedElement.getAttribute('listIndent') !== undefined
        ) {
          editor.model.change((writer) => {
            writer.removeAttribute('blockIndent', selectedElement);
          });
        }
      } else {
        // if selection is not a list item, enable indent block commands
        if (indentBlockCommand) indentBlockCommand.clearForceDisabled(pluginControlID);
        if (outdentBlockCommand) outdentBlockCommand.clearForceDisabled(pluginControlID);
      }

      // prevent list indenting past 10 levels
      if ((selectedElement.getAttribute('listIndent') as number) >= 10) {
        if (indentListCommand) indentListCommand.forceDisabled(pluginControlID);
      } else {
        if (indentListCommand) indentListCommand.clearForceDisabled(pluginControlID);
      }
    });

    // allow Tab to indent content when not in list
    editor.keystrokes.set('Tab', (_, cancel) => {
      const indentBlockCommand = editor.commands.get('indentBlock');

      if (indentBlockCommand && indentBlockCommand.isEnabled && !isList()) {
        indentBlockCommand.execute();
        cancel();
      }
    });

    // allow Shift+Tab to indent content when not in list
    editor.keystrokes.set('Shift+Tab', (_, cancel) => {
      const outdentBlockCommand = editor.commands.get('outdentBlock');

      if (outdentBlockCommand && outdentBlockCommand.isEnabled && !isList()) {
        outdentBlockCommand.execute();
        cancel();
      }
    });

    // if "dirty" content is pasted, clean it up
    editor.plugins.get('ClipboardPipeline').on(
      'contentInsertion',
      (evt, data) => {
        try {
          // get elements in the pasted range
          const selectedElements = getSelectedElements([data.resultRange] as Range[]);

          // remove blockIndent attribute from pasted elements if they are list items
          selectedElements.forEach((element) => {
            if (
              element.getAttribute('blockIndent') !== undefined &&
              element.getAttribute('listIndent') !== undefined
            ) {
              editor.model.change((writer) => {
                writer.removeAttribute('blockIndent', element);
              });
            }
          });
        } catch (error) {
          // some paste events don't the properties we check for, and will throw an error
          // we ignore it and let CKEditor handle the paste event
          return;
        }
      },
      {priority: 'lowest'}
    );
  }
}
