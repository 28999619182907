import React from 'react';
import styles from './spotlightActions.module.css';
import {faClone, faTrashAlt, faEdit, faSave} from '@fortawesome/free-regular-svg-icons';
import {Fontawesome, Button, DSTooltip as Tooltip} from 'spekit-ui';

interface Props {
  isPublished: boolean;
  unpublish: () => void;
  saveDraft: () => void;
  publish: () => void;
  handleEdit: () => void;
  handleDelete: () => void;
  handleClone: () => void;
  readable: boolean;
  editable: boolean;
  isNew: boolean;
  isSaving: boolean;
}

const SpotlightActions: React.FC<Props> = ({
  isPublished,
  unpublish,
  saveDraft,
  publish,
  readable,
  handleEdit,
  handleDelete,
  editable,
  isNew,
  handleClone,
  isSaving,
}) => {
  return (
    <div className={styles.container}>
      <div
        className={
          readable
            ? styles.subContainerRead
            : !editable && isNew
            ? styles.subContainerEdit
            : styles.subContainer
        }
      >
        {readable ? (
          <>
            <Tooltip shouldWrapChildren label='Clone'>
              <Fontawesome
                name={faClone}
                className={styles.trashIcon}
                onClick={handleClone}
              />
            </Tooltip>
            <Tooltip shouldWrapChildren label='Delete'>
              <Fontawesome
                name={faTrashAlt}
                className={styles.trashIcon}
                onClick={handleDelete}
              />
            </Tooltip>
            <Button className={styles.editButton} onClick={handleEdit}>
              <Fontawesome name={faEdit} className={styles.saveIcon} />
              Edit
            </Button>
          </>
        ) : (
          <>
            {editable && !isNew && (
              <Tooltip shouldWrapChildren label='Delete'>
                <Fontawesome
                  name={faTrashAlt}
                  className={styles.trashIcon}
                  onClick={handleDelete}
                />
              </Tooltip>
            )}
            <>
              {isPublished ? (
                <Button
                  className={styles.emptyButton}
                  onClick={unpublish}
                  noHover
                  disabled={isSaving}
                >
                  Unpublish
                </Button>
              ) : (
                <Button
                  className={styles.emptyButton}
                  onClick={saveDraft}
                  noHover
                  disabled={isSaving}
                >
                  Save Draft
                </Button>
              )}
            </>
            <Button
              className={styles.pinkButton}
              onClick={publish}
              noHover
              disabled={isSaving}
            >
              <Fontawesome name={faSave} className={styles.saveIcon} />
              {'Publish'}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default SpotlightActions;
