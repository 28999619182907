import {useState, useEffect} from 'react';
import {
  Flex,
  Image,
  AsyncSelect,
  FormControl,
  FormLabel,
  Button,
  Textarea,
  useToast,
  Checkbox,
  Input,
} from 'spekit-ui';
import {useLocation} from 'react-router-dom';
import AppCompatibilityOTP from './appCompatibilityOTP';
import layoutImage from '../images/login-background.svg';
import spekitLogo from '../images/spekit-logo-white.svg';
import {appsCompat} from 'spekit-datalayer';

const AddCommentsException = () => {
  const location = useLocation<{appsCompatUser: boolean}>();
  const MAX_DESC_LENGTH = 255;
  const [selectedValue, setSelectedValue] = useState({label: '', value: ''});
  const [defaultValueOfDdl, setDefaultValueOfDdl] = useState({label: '', value: ''});
  const [text, setText] = useState('');
  const [textOrder, setTextOrder] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isTextEmpty, setIsTextEmpty] = useState(false);
  const [validOTP, setValidOTP] = useState(
    (location.state && location.state.appsCompatUser) || false
  );
  const [textOrderVisiblity, setTextOrderVisiblity] = useState(false);

  const toast = useToast();

  const getAppNames = async () => {
    try {
      let {data} = await appsCompat.getAppNames();
      const dropDownData = data.map((app) => ({label: app.app_name, value: app.id}));
      setDefaultValueOfDdl(dropDownData[0]);
      setSelectedValue(dropDownData[0]);
      return dropDownData;
    } catch (err) {
      return [];
    }
  };

  useEffect(() => {
    if (validOTP) {
      getAppNames();
    }
  }, [validOTP]);

  const showErrorToast = (message: string) => {
    toast({
      variant: 'error',
      description: message,
    });
  };

  const saveStep = async () => {
    try {
      if (text === '') {
        setIsTextEmpty(true);
        showErrorToast('Please fill description.');
        return;
      }
      if (isChecked && textOrder === null) {
        showErrorToast('Please fill order.');
        return;
      }

      await appsCompat.postCustomOrder({
        isorderbyupdated: isChecked,
        app_id: selectedValue.value,
        description: text,
        orderby_value: textOrder,
      });

      toast({
        variant: 'success',
        description: 'Data has been Saved. Successfully!',
      });
      setText('');
      setSelectedValue(defaultValueOfDdl);
      setTextOrder(0);
    } catch (err) {
      showErrorToast('Please fill order.');
      toast({
        variant: 'error',
        description: 'Data couldn`t be saved.',
      });
    }
  };

  const updateDescription = (event: any) => {
    setIsTextEmpty(false);
    setText(event.target.value);
  };

  const updateOrderNumber = (event: any) => {
    setTextOrder(event.target.value);
  };

  const handleChange = (selectedOption: any) => {
    setSelectedValue(selectedOption);
  };

  const handleCheckboxChange = (event: any) => {
    setTextOrderVisiblity(event.target.checked);
    setIsChecked(event.target.checked);
  };
  const setValidatedUser = (value: boolean) => {
    setValidOTP(value);
  };

  return (
    <Flex
      bgImage={layoutImage}
      bgRepeat='no-repeat'
      bgSize='cover'
      bgPosition='left'
      width='100vw'
      height='100vh'
      justifyContent='center'
      alignItems='center'
    >
      <Flex
        direction='column'
        fontSize='14px'
        fontWeight='bold'
        color='#637280'
        position='absolute'
        top='16px'
        left='48px'
        textAlign='left'
      >
        <Image src={spekitLogo} />
      </Flex>
      {validOTP ? (
        <Flex w='500px' p={18} bgColor='white'>
          <FormControl>
            <FormLabel htmlFor='app-name'>App Names</FormLabel>

            <AsyncSelect
              id='app-name'
              isMulti={false}
              value={selectedValue}
              placeholder='Select App Name'
              loadOptions={getAppNames}
              cacheOptions
              defaultOptions
              onChange={handleChange}
            />
            <FormLabel htmlFor='add-comments'>Add Comments/Exception</FormLabel>
            <Textarea
              id='add-comments'
              isRequired
              isInvalid={isTextEmpty}
              placeholder='Enter description'
              value={text}
              maxLength={MAX_DESC_LENGTH}
              onChange={updateDescription}
            />
            <Flex paddingTop={4}>
              <Checkbox onChange={handleCheckboxChange}>
                Want to change order of Apps on Dashboard?
              </Checkbox>
            </Flex>
            <Flex paddingTop={4}>
              {textOrderVisiblity ? (
                <Input
                  type='number'
                  placeholder='Enter order number'
                  value={textOrder || 0}
                  defaultValue={0}
                  min={0}
                  max={999999}
                  onChange={updateOrderNumber}
                />
              ) : null}
            </Flex>
            <Flex m={10} alignItems='baseline' justifyContent='center'>
              <Button
                colorScheme='primary'
                variant='contained'
                size='medium'
                onClick={saveStep}
              >
                Save
              </Button>
            </Flex>
          </FormControl>
        </Flex>
      ) : (
        <AppCompatibilityOTP setValidatedUser={setValidatedUser} />
      )}
    </Flex>
  );
};
export default AddCommentsException;
