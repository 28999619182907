export const styles = {
  modal: {
    marginTop: '10vh',
    backgroundColor: '#FAFBFC',
    maxWidth: '900px',
    width: '900px',
    maxHeight: '819px',
    transform: 'translateY(-55px)',
  },
  teamsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column' as 'column',
    flexWrap: 'nowrap' as 'nowrap',
    textAlign: 'left' as 'left',
    marginTop: '20px',
  },
  teamsDropdownContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap' as 'nowrap',
    textAlign: 'left' as 'left',
    marginTop: '20px',
  },
  header: {
    textAlign: 'center' as 'center',
    marginTop: '20px',
    color: '#637280',
    fontSize: '21px',
    letterSpacing: '0.14px',
  },
  whitebox: {
    backgroundColor: '#FFF',
    padding: '20px',
    border: '1px solid #EBEDF8',
    borderRadius: '3.8px',
    margin: '24px 20px 0px 20px',
    maxHeight: '361px',
    overflow: 'auto',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flexWrap: 'nowrap' as 'nowrap',
    textAlign: 'left' as 'left',
  },
  labelWarning: {
    fontSize: '12px',
    marginBottom: '8px',
    color: '#637280',
  },
  iconRule: {
    fontSize: '12px',
    color: '#637280',
    letterSpacing: '0.08px',
    marginBottom: '20px',
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flexWrap: 'nowrap' as 'nowrap',
    justifyContent: 'space-between',
    textAlign: 'left' as 'left',
  },
  content: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'nowrap' as 'nowrap',
    justifyContent: 'space-between',
  },
  description: {
    color: '#637280',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '0.09px',
    marginBottom: '20px',
  },
  textArea: {
    fontSize: '14px',
    color: '#637280',
    padding: '10px',
    lineHeight: '20px',
    resize: 'none' as 'none',
    overflow: 'auto',
    width: '540px',
  },
  domainsContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flexWrap: 'nowrap' as 'nowrap',
    justifyContent: 'space-between',
    textAlign: 'left' as 'left',
    marginTop: '24px',
  },
  domainLabel: {
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '0.09px',
    color: '#637280',
  },
  info: {
    color: 'rgba(99,114,128,0.42)',
    fontSize: '16px',
    marginLeft: '0.5em',
  },
  domainSub: {
    color: '#637280',
    fontSize: '12px',
    letterSpacing: '0.08px',
    marginTop: '10px',
  },
  link: {
    color: '#3576E8',
    textDecoration: 'none',
  },
  trash: {
    color: 'rgb(159, 169, 186)',
    marginRight: '0.5em',
  },
  confirmationContainer: {
    padding: '50px',
    width: '360px',
    color: '#637280',
  },
  confirmationHead: {
    fontSize: '21px',
  },
  confirmationText: {
    fontSize: '15px',
    margin: '16px 0px 36px',
    lineHeight: '1.7',
  },
  confirmBtn: {
    width: '100%',
    padding: '13px 0px',
  },
  dropdownContainer: {
    width: '300px',
  },
  teamPillsContainer: {
    display: 'inline-flex',
    width: '60%',
    maxHeight: '200px',
    overflow: 'auto',
    flexDirection: 'column' as 'column',
  },
  multiTeam: {
    display: 'flex',
    maxWidth: '250px',
    minWidth: '40px',
    marginRight: '10px',
    borderRadius: '2px',
    backgroundColor: '#CED4E0',
    padding: '5px',
    marginBottom: '5px',
  },
  teamLabel: {
    fontSize: '14px',
    color: 'rgb(51, 51, 51)',
    letterSpacing: '0.09px',
    marginRight: '10px',
    display: 'flex',
    fontWeight: 400,
  },
  multiTeams: {
    marginTop: '3px',
    marginRight: '10px',
    display: 'inline-flex',
    flexWrap: 'wrap' as 'wrap',
  },
  fieldWithPlaceholder: {
    height: '20px',
    width: '445px',
  },
  icon: {
    color: 'rgb(51, 51, 51)',
  },
  teamsLink: {
    color: '#3576e8',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    marginRight: '5px',
    textDecoration: 'none',
  },
  teamsdisableLink: {
    color: '#56606a',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
  },
  deleteAllContainer: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#3576E8',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  deleteAll: {
    marginLeft: '0.5em',
  },
  deleteAndTopicsContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  mustBeAlphanumeric: {
    height: '12px',
    color: '#DF4B54',
    fontSize: '12px',
    letterSpacing: '0.08px',
    lineHeight: '12px',
    marginTop: '-26px',
    display: 'flex',
    marginBottom: '14px',
  },
  trackCompletionLabel: {
    fontSize: '15px',
    marginTop: '25px',
    letterSpacing: '0.09px',
  },
  trackCompletionSubLabel: {
    fontSize: '12px',
    marginTop: '4px',
    letterSpacing: '0.09px',
  },
  trackCompletionSwitch: {
    margin: '0px 10px',
  },
};
