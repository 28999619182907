import {IAppCompatibilityStatusResponse, IAppList, IAppPostData} from 'spekit-types';
import {retrieve, create} from '../utils/APIHelpers';

export const appsCompatStatus = (): Promise<Array<IAppCompatibilityStatusResponse>> =>
  retrieve(`/app-compatibility/apppost`);

export const getAppNames = (): Promise<IAppList> =>
  retrieve(`/app-compatibility/appdescription`);

export const postCustomOrder = (data: IAppPostData): Promise<{msg: string}> =>
  create(`/app-compatibility/appdescription`, data);
