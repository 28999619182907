import {useLocation} from 'react-router';
import styles from './SideNavigation.module.css';
import {NavLink} from 'react-router-dom';
import {Props} from './types';

function SideNavigation(props: Props) {
  const {items} = props;

  const location = useLocation();
  const currentPath = location.pathname;

  const blackList = ['/app/dashboard', '/app/settings/profile'];

  return (
    <div className={styles.main}>
      {!blackList.includes(currentPath) &&
        items
          .filter((item) => item.permission)
          .map((item) => (
            <NavLink
              data-testid={item.testId}
              key={item.name}
              exact
              to={item.route}
              className={styles.link}
              activeClassName={styles.activeLink}
            >
              {item.name}
            </NavLink>
          ))}
    </div>
  );
}

export default SideNavigation;
