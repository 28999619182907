import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {stringifyQs} from '../utils/commonUtils';
export interface Query {
  q: string;
  teams: string[];
}

interface Icon {
  view: string;
  download: string;
}

interface Topic {
  label: string;
  value: string;
  icon: Icon | null;
  font_awesome_id: null | string;
}
export interface Response {
  results: Array<Topic>;
}

export interface TopicDetailResponse {
  id: string;
  name: string;
  company: string;
  description: string;
  font_awesome_id: null;
  icon: null;
  can_edit: boolean;
  domains: any[];
  created_by: string;
  updated_by: string;
  created_on: string;
  updated_on: string;
  all_teams: boolean;
  teams: Team[];
}

export interface Team {
  id: string;
  name: string;
  access_all_topics: boolean;
}

export const topicLookup = async (query: Query): Promise<Response> => {
  const querystring = stringifyQs(query, {arrayFormat: 'comma'});
  const response = await fetch(`/api/lookups/topics?${querystring}`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  });
  const result = await response.json();
  return result;
};

export const topicDetailLookup = async (
  topicId: string
): Promise<TopicDetailResponse> => {
  const response = await fetch(`/api/topics/${topicId}`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  });
  const result = await response.json();
  return result;
};
