import {StoreType, StateType} from './types';
let _store: StoreType;
let state: StateType;

const hasSomeOf = function (permissions: any) {
  state = _store.getState();

  if (
    state.layout &&
    state.layout.me &&
    state.layout.me.permissions &&
    Array.isArray(state.layout.me.permissions) &&
    Array.isArray(permissions)
  ) {
    return !!permissions.some((perm) => state.layout.me.permissions.indexOf(perm) >= 0);
  } else {
    return false;
  }
};

const hasAllOf = function (permissions: any) {
  state = _store.getState();
  if (
    state.layout &&
    state.layout.me &&
    state.layout.me.permissions &&
    Array.isArray(state.layout.me.permissions) &&
    Array.isArray(permissions)
  ) {
    let sortedSuper = state.layout.me.permissions.sort();
    let sortedSub = permissions.sort();
    let index = sortedSuper.findIndex((p: any) => p === sortedSub[0]);
    let snipedSuper = sortedSuper.slice(index, index + sortedSub.length);
    return JSON.stringify(sortedSub) === JSON.stringify(snipedSuper);
  } else {
    return false;
  }
};

function permissionMiddleWare(store: StoreType) {
  _store = store;
}

export {permissionMiddleWare, hasSomeOf, hasAllOf};
