const OUTER_LIMIT = 2;
const INNER_LIMIT = 1;

const union = (...arr: number[][]) => {
  return Array.from(new Set(arr.flat()));
};

export const generatePages = (totalPages: number, currentPage: number) => {
  const allPages = [...Array(totalPages).keys()].map((p) => p + 1);

  if (totalPages <= 7) return allPages;

  const outerLeftPages = allPages.slice(0, OUTER_LIMIT);
  const outerRightPages = allPages.slice(1).slice(-OUTER_LIMIT);

  const lastPageOfOuterLeftPages = outerLeftPages.slice(-1)[0];
  const firstPageOfOuterRightPages = outerRightPages[0];

  const generateRightInnerPages = () => {
    const rightInnerLastIndex = currentPage + INNER_LIMIT;
    const isAfterFirstOuterRightPage = rightInnerLastIndex > firstPageOfOuterRightPages;

    return isAfterFirstOuterRightPage
      ? allPages.slice(currentPage + 1, firstPageOfOuterRightPages)
      : allPages.slice(currentPage, rightInnerLastIndex);
  };

  const generateLeftInnerPages = () => {
    const leftInnerFirstIndex = currentPage - INNER_LIMIT;
    const isBeforeLastOuterLeftPage = leftInnerFirstIndex < lastPageOfOuterLeftPages;

    return isBeforeLastOuterLeftPage
      ? allPages.slice(lastPageOfOuterLeftPages, currentPage - 1)
      : allPages.slice(leftInnerFirstIndex - 1, currentPage - 1);
  };

  const leftInnerPages = generateLeftInnerPages();
  const leftPages = union([...outerLeftPages], [...leftInnerPages]);
  const shouldHaveLeftSeparator = leftInnerPages[0] > lastPageOfOuterLeftPages + 1;

  const rightInnerPages = generateRightInnerPages();
  const rightPages = union([...rightInnerPages], [...outerRightPages]);
  const shouldHaveRightSeparator =
    rightInnerPages.slice(-1)[0] < firstPageOfOuterRightPages - 1;

  const unduplicatedPages = union([...leftPages], [currentPage], [...rightPages]);

  const addSeparators = (pages: number[]) =>
    pages.reduce<number[]>((acc: number[], page: number) => {
      const checkPageForSeparator = () => {
        if (page === lastPageOfOuterLeftPages && shouldHaveLeftSeparator) {
          return [lastPageOfOuterLeftPages, 0];
        }

        if (page === firstPageOfOuterRightPages && shouldHaveRightSeparator) {
          return [-1, firstPageOfOuterRightPages];
        }

        return [page];
      };

      return [...acc, ...checkPageForSeparator()];
    }, []);

  const pages = addSeparators(unduplicatedPages);

  return pages;
};
