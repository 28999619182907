import React, {useState, useEffect} from 'react';
import {DataTable, DSTooltip as Tooltip, Fontawesome} from 'spekit-ui';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {formatDurationFromSeconds} from 'spekit-datalayer';
import styles from './analyticsOverview.module.css';
import {Statistic} from './types';

interface OverviewDetails {
  count: number | null;
  percentage: number | null;
}

interface OverviewStatistics {
  pendingAudience: number | null;
  uniqueViewers: number | null;
  engaged: OverviewDetails;
  snoozed: OverviewDetails;
  avgTimeSpent: number | null;
}

interface Prop {
  statistics: Array<Statistic>;
  total: number;
}

const PENDING_AUDIENCE_TOOLTIP_TEXT = `An estimate of the total reach for a Spotlight. 
If targetted publishing conditions include things like 
"Salesforce events" these may happen for different users at different times.`;

const SNOOZED_TOOLTIP_TEXT = `If the user saw the Spotlight, but has dismissed it to view later.`;
const AVG_TIME_SPENT_VIEWING = `Includes time spent preceeding all engagement types, including dismissal.`;

const AnalyticsOverview: React.FC<Prop> = ({statistics, total}) => {
  const [overviewStatistics, setoverviewStatistics] = useState<OverviewStatistics>({
    pendingAudience: null,
    uniqueViewers: null,
    engaged: {count: null, percentage: null},
    snoozed: {count: null, percentage: null},
    avgTimeSpent: null,
  });

  const getPendingAudienceCount = () =>
    statistics.filter((statistic: Statistic) => !statistic.viewed).length;

  const getUniqueViewersCount = () =>
    statistics.filter((value: Statistic) => value.last_viewed).length;

  const getViewedCount = () =>
    statistics.filter((statistic: Statistic) => !!statistic.viewed).length;

  const getEngagedCount = () =>
    statistics.filter((statistic: Statistic) => !!statistic.is_engaged).length;

  const getSnoozedCount = () =>
    statistics
      .map((statistic: Statistic) =>
        statistic.snoozed_count ? statistic.snoozed_count : 0
      )
      .reduce(
        (total: number, currentNumber: any) =>
          currentNumber ? currentNumber + total : total,
        0
      );

  const getAvgTimeSpentCount = (totalViewed: number) =>
    totalViewed
      ? statistics
          .map((statistics: Statistic) => statistics.time_spent)
          .reduce(
            (total: number, currentNumber: any) =>
              currentNumber ? currentNumber + total : total,
            0
          ) / totalViewed
      : 0;

  const calculate = () => {
    let viewedCount = getViewedCount();

    let engagedCount = getEngagedCount();
    let engagedPercentage = calculatePercentage(engagedCount, viewedCount);

    let snoozedCount = getSnoozedCount();
    let snoozedPercentage = calculatePercentage(snoozedCount, viewedCount);

    setoverviewStatistics({
      pendingAudience: getPendingAudienceCount(),
      uniqueViewers: getUniqueViewersCount(),
      engaged: {count: engagedCount, percentage: engagedPercentage},
      snoozed: {count: snoozedCount, percentage: snoozedPercentage},
      avgTimeSpent: getAvgTimeSpentCount(viewedCount),
    });
  };

  const calculatePercentage = (actual: number, total: number) =>
    total ? Math.ceil((actual / total) * 100) : 0;

  useEffect(calculate, [statistics, total]);

  const getDataTableColumns = () => {
    return [
      {
        fieldName: 'pendingAudience',
        template: (pendingAudience: number, n: any) => {
          return (
            <div>
              <label>{pendingAudience}</label>
            </div>
          );
        },
        headerTemplate: () => {
          return (
            <span className={styles.headerPendingViewersContainer}>
              <span className={styles.headerPendingViewersText}>
                PENDING AUDIENCE ESTIMATE
              </span>
              <Tooltip shouldWrapChildren label={PENDING_AUDIENCE_TOOLTIP_TEXT}>
                <Fontawesome
                  style={{color: 'rgba(99, 114, 128, 0.42)', marginLeft: '0.5em'}}
                  name={faInfoCircle}
                />
              </Tooltip>
            </span>
          );
        },
        height: '100px',
        width: '135px',
        noSort: true,
      },
      {
        fieldName: 'uniqueViewers',
        template: (uniqueViewers: number, n: any) => {
          return (
            <div>
              <label>{uniqueViewers}</label>
            </div>
          );
        },
        headerTemplate: () => {
          return <span className={styles.headerUniqueViewers}>UNIQUE VIEWERS</span>;
        },
        height: '100px',
        width: '95px',
        noSort: true,
      },
      {
        fieldName: 'engaged',
        template: (engaged: any, n: any) => {
          return (
            <div>
              <label>
                {engaged.count}({engaged.percentage}%)
              </label>
            </div>
          );
        },
        headerTemplate: () => {
          return <span className={styles.headerEngaged}>ENGAGED</span>;
        },
        height: '100px',
        width: '70px',
        noSort: true,
      },
      {
        fieldName: 'snoozed',
        template: (snoozed: OverviewDetails, n: any) => {
          return (
            <div>
              <label>
                {snoozed.count}({snoozed.percentage}%)
              </label>
            </div>
          );
        },
        headerTemplate: () => {
          return (
            <span className={styles.headerSnoozedContainer}>
              <span className={styles.headerSnoozedText}>SNOOZED</span>
              <Tooltip shouldWrapChildren label={SNOOZED_TOOLTIP_TEXT}>
                <Fontawesome
                  style={{color: 'rgba(99, 114, 128, 0.42)', marginLeft: '0.5em'}}
                  name={faInfoCircle}
                />
              </Tooltip>
            </span>
          );
        },
        height: '100px',
        width: '75px',
        noSort: true,
      },
      {
        displayName: 'AVG.TIME SPENT VIEWING',
        fieldName: 'avgTimeSpent',
        template: (avgTimeSpent: number) => (
          <div>
            <label>
              {avgTimeSpent ? formatDurationFromSeconds(avgTimeSpent) : 'N/A'}
            </label>
          </div>
        ),
        headerTemplate: () => {
          return (
            <span className={styles.headerAvgTimeSpentContainer}>
              <span className={styles.headerAvgTimeSpentText}>
                AVG.TIME SPENT VIEWING
              </span>
              <Tooltip shouldWrapChildren label={AVG_TIME_SPENT_VIEWING}>
                <Fontawesome
                  style={{color: 'rgba(99, 114, 128, 0.42)', marginLeft: '0.5em'}}
                  name={faInfoCircle}
                />
              </Tooltip>
            </span>
          );
        },
        height: '100px',
        width: '120px',
        noSort: true,
      },
    ];
  };

  return (
    <div>
      <div className={styles.headingAnalyticsOverview}>Analytics Overview</div>
      <DataTable
        style={{border: 'none', fontSize: '9px'}}
        emptyState={<div className={styles.emptyState}>No Analytics found.</div>}
        stickyHeader
        data={[overviewStatistics]}
        columns={getDataTableColumns()}
      />
    </div>
  );
};

export default AnalyticsOverview;
