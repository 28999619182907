import {Store, AnyAction} from 'redux';
import Cookies from 'js-cookie';
import {jwtHandler} from './jwtUtils';
import {ResultType, setJWTRuleType} from './fetchMiddlewareTypes';

const TIMEOUT_MS = 60000;
export interface CommType {
  genericFetch: (ulr: string, options: any) => Promise<any>;
}

export interface CustomFetchOptions extends RequestInit {
  runningWrapped?: boolean;
  headers?: any;
}

export type ShowPaywallType = (
  name: 'licensesExpired' | 'lockedOut' | 'accountBlocked',
  type: 'hard' | 'soft'
) => AnyAction;

export type IsRunningInChromeBGType = () => boolean;

// from backend spekit/authentication/jwt_auth.py
const ERR_MSGS_401 = [
  'Authentication credentials were not provided.',
  'Unauthorized',
  'User account is disabled.',
  'Authentication token has been revoked.',
  'Invalid user.',
];

let _commsSendToBackground: CommType | null = null;
let _showPaywall: ShowPaywallType | null = null;
let _store: Store | null = null;
let _isRunningInChromeBG: IsRunningInChromeBGType | null = null;
let _setJWTRule: setJWTRuleType | null = null;
let _outlook: boolean = false;
let defaultHost: string | undefined;

/**
 * fetchMiddleware requires the use of the token which currently
 * sits in chrome storage. While the fetchMiddleware is in spekit-datalayer,
 * The only way to get the token is to depend on the runtime and call
 * this function if we are in the chrome runtime.
 *
 * @returns Token from the chrome.storage
 */
async function getToken() {
  return new Promise((res, rej) => {
    try {
      chrome.storage.local.get('token', (item) => {
        res(item['token']);
      });
    } catch (error) {
      rej(error);
    }
  });
}

const fetchWithTimeout = (
  url: string,
  options: CustomFetchOptions
): Promise<Response> => {
  return new Promise((resolve, reject) => {
    let timedOut = false;
    let timeout = setTimeout(() => {
      timedOut = true;
      reject(new Error('TIMEOUT'));
    }, TIMEOUT_MS);
    fetch(url, options).then((response) => {
      clearTimeout(timeout);
      if (!timedOut) {
        resolve(response);
      }
    });
  });
};

const fetchMiddleWareForChrome = (
  url: string,
  options?: CustomFetchOptions,
  backoff?: number,
  retries?: number
): Promise<Response> => {
  return new Promise((res, rej) => {
    if (
      options &&
      options.headers &&
      (options.headers['Authorization'] === 'JWT null' ||
        options.headers['Authorization'] === 'JWT undefined')
    ) {
      chrome.storage.local.remove('token');
      rej(new Error('NOLOGIN'));
    } else if (
      !options ||
      (!options.credentials && (!options.headers || !options.headers['Authorization']))
    ) {
      rej(new Error('NOLOGIN'));
    } else {
      fetchWithTimeout(url, {...options, runningWrapped: true})
        .then((response: Response) => {
          let responseT = response.clone();
          if (response.status === 401) {
            response.json().then((result: ResultType) => {
              const msg = result?.message || '';
              if (result.success === false && ERR_MSGS_401.includes(msg)) {
                chrome.storage.local.remove('token');
                rej(new Error('NOLOGIN'));
              }
            });
          } else if (response.status === 403) {
            response.json().then((result) => {
              if (result.license_expired === true) {
                rej(new Error('NOLICENSE'));
              } else {
                res(responseT);
              }
            });
          } else if (response.status === 429) {
            const contentTypeHeader = response.headers.get('Content-Type');
            if (
              contentTypeHeader &&
              contentTypeHeader.toLowerCase() === 'application/json'
            ) {
              response.json().then((result) => {
                if (result.locked_out === true) {
                  rej(new Error('LOCKEDOUT'));
                }
              });
            } else {
              if (!retries) {
                retries = 0;
              }
              if (backoff) {
                backoff = backoff * 2 ** retries;
                if (backoff > TIMEOUT_MS) {
                  rej(new Error('TIMEOUT RETRIES'));
                  return;
                }
              } else {
                backoff = Math.random() * 100 + 100;
              }
              retries++;
              setTimeout(
                () => res(fetchMiddleWareForChrome(url, options, backoff, retries)),
                backoff
              );
            }
          } else {
            res(responseT);
          }
        })
        .catch((err) => {
          if (err.message?.includes('TIMEOUT')) {
            const errData = JSON.stringify({
              url,
              options,
              backoff,
              retries,
            });
            rej(new Error(err.message + ': ' + errData));
          } else {
            rej(err);
          }
        });
    }
  });
};

export const _fetchMiddleWare = async (
  url: string,
  options: CustomFetchOptions
): Promise<Response> => {
  if (!!_commsSendToBackground) {
    options.headers['X-CLIENT'] = 'ext';
    if (!!_isRunningInChromeBG && _isRunningInChromeBG()) {
      if (!options.headers['Authorization'] && url.indexOf('/api/auth/generate') === -1) {
        const token = await getToken();
        options!.headers!['Authorization'] = `JWT ${token}`;
      }
      if (url.indexOf('http') !== 0) {
        // eslint-disable-next-line no-param-reassign
        url = `${defaultHost}${url}`;
      }
      return fetchMiddleWareForChrome(url, options);
    } else {
      return new Promise((res, rej) => {
        if (!!_commsSendToBackground) {
          _commsSendToBackground.genericFetch(url, options).then(res).catch(rej);
        }
      });
    }
  } else {
    let notCsrfMethods = ['GET', 'HEAD', 'OPTIONS', 'TRACE'];
    if (options && options.method && notCsrfMethods.indexOf(options.method) === -1) {
      let headers = {};
      if (options.headers) {
        headers = options.headers;
      }
      let csrfToken = Cookies.get('kag');
      if (csrfToken) {
        headers['X-CSRFTOKEN'] = csrfToken;
      }
      options.headers = headers;
    }
    return new Promise((res, rej) => {
      fetch(url, {...options, runningWrapped: true} as RequestInit).then((response) => {
        let responseT = response.clone();
        if (response.status === 403) {
          response.json().then((result) => {
            if (!!result.license_status && result.license_status === 'blocked') {
              if (!!_store && !!_showPaywall) {
                _store.dispatch(_showPaywall('accountBlocked', 'hard'));
                rej('Account Blocked');
              }
            } else if (!!result.csrf_missing && result.csrf_missing === true) {
              document.write(
                'An error occurred while performing request. The page will automatically reload in 5 seconds.'
              );
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            } else {
              res(responseT);
            }
          });
        } else if (response.status === 429) {
          const contentTypeHeader = response.headers.get('Content-Type');
          if (
            contentTypeHeader &&
            contentTypeHeader.toLowerCase() === 'application/json'
          ) {
            response.json().then((result) => {
              if (result.locked_out === true) {
                if (!!_store && !!_showPaywall)
                  _store.dispatch(_showPaywall('lockedOut', 'hard'));
              } else {
                res(responseT);
              }
            });
          } else {
            let backoff = Math.ceil(Math.random() * 200 + 200);
            setTimeout(() => res(fetchMiddleWare(url, options)), backoff);
          }
        } else {
          res(responseT);
        }
      });
    });
  }
};

export const fetchMiddleWareInjector = (
  store: Store | null,
  showPaywall: ShowPaywallType | null,
  commsSendToBackground?: CommType | null,
  isRunningInChromeBG?: IsRunningInChromeBGType,
  setJWTRule?: setJWTRuleType | null,
  host?: string,
  outlook?: boolean
) => {
  _commsSendToBackground = commsSendToBackground || null;
  _showPaywall = showPaywall;
  _store = store;
  _isRunningInChromeBG = isRunningInChromeBG || null;
  _setJWTRule = setJWTRule || null;
  defaultHost = host;
  _outlook = outlook || false;
};

export const fetchMiddleWare = (url: string, options: RequestInit = {}) =>
  (async () => {
    const optionsWithToken = await jwtHandler(
      options,
      _commsSendToBackground,
      _setJWTRule,
      url,
      _outlook,
      defaultHost
    );
    return _fetchMiddleWare(url, optionsWithToken);
  })();
