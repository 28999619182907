import {
  ICreateShareableContentEventRequest,
  IGenerateShareLinkRequest,
  IGenerateShareLinkResponse,
  IPublicContent,
  IPublicContentError,
  IShareableCompanyResponse,
  IShareableCompanyListResponse,
  IShareLinkAnalyticsResponse,
  IShareLinkSearchQuery,
  IShareContentActivityResponse,
  ICreateShareableContentActivity,
  IGenerateExternalLinkRequest,
  IShareLinkCompanyAnalyticsResponse,
  IShareLinkRecentActivitysResponse,
  IShareLinkContentListResponse,
  IShareLinkContentDetailResponse,
  IShareLinkPagination,
  IShareLinkCompanySearchQuery,
  IShareLinkCompanyResponse,
} from 'spekit-types';

import {
  SHARABLE_CONTENT_ACTIVITY,
  EXTERNAL_LINK_API,
  SHARE_LINK_API,
  SHAREABLE_CONTENT_EVENT_API,
  SHAREABLE_COMPANY_API,
  COMPANY_SHAREABLE_ANALYTICS_API,
} from '../api';

import {retrieve, create} from '../utils/APIHelpers';
import {stringifyQs} from '../utils/commonUtils';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
export const getShareLinkAnaytics = (searchQuery: IShareLinkSearchQuery) => {
  const query = stringifyQs(searchQuery, {
    addQueryPrefix: true,
  });
  return retrieve<IShareLinkAnalyticsResponse>(`${SHARE_LINK_API}${query}`);
};

export const generateShareLink = async (payload: IGenerateShareLinkRequest) => {
  const response: IGenerateShareLinkResponse = await create(`${SHARE_LINK_API}`, payload);
  return response;
};

export const getExternalLink = async (payload: IGenerateExternalLinkRequest) => {
  const response: IGenerateShareLinkResponse = await create(
    `${EXTERNAL_LINK_API}`,
    payload
  );
  return response;
};
export const createShareableContentEvent = async (
  payload: ICreateShareableContentEventRequest
) => {
  return await create(`${SHAREABLE_CONTENT_EVENT_API}`, payload);
};

export const createShareableContentActivity = async (
  payload: ICreateShareableContentActivity
) => {
  return await create(`${SHARABLE_CONTENT_ACTIVITY}`, payload);
};

export const getShareLinkActivity = (id: string) => {
  return retrieve<IShareContentActivityResponse>(`${SHARABLE_CONTENT_ACTIVITY}${id}`);
};
export const getPublicContent = async (
  contentType: string,
  contentId: string
): Promise<IPublicContent | IPublicContentError> => {
  const requestUrl =
    contentType === 'content'
      ? `/share/v1/${contentType}/${contentId}/`
      : `/public/${contentType}s/${contentId}/`;

  const response = await fetch(requestUrl);
  return response.json();
};

export const getCompanyNames = () => {
  return retrieve<IShareableCompanyListResponse>(`${SHAREABLE_COMPANY_API}`);
};

export const createCompany = async (name: string) => {
  const response: IShareableCompanyResponse = await create(`${SHAREABLE_COMPANY_API}`, {
    name,
  });
  return response;
};

export const getShareLinksByCompany = (searchQuery: IShareLinkCompanySearchQuery) => {
  const query = stringifyQs(searchQuery, {
    addQueryPrefix: true,
  });
  return retrieve<IShareLinkCompanyAnalyticsResponse>(
    `${COMPANY_SHAREABLE_ANALYTICS_API}${query}`
  );
};

export const getRecentActivity = (
  companyId: string,
  paginationQuery: IShareLinkPagination
) => {
  const query = stringifyQs(paginationQuery, {
    addQueryPrefix: true,
  });
  return retrieve<IShareLinkRecentActivitysResponse>(
    `${COMPANY_SHAREABLE_ANALYTICS_API}${companyId}/recent_activity/${query}`
  );
};
export const getShareLinkList = (
  companyId: string,
  paginationQuery: IShareLinkPagination
) => {
  const query = stringifyQs(paginationQuery, {
    addQueryPrefix: true,
  });
  return retrieve<IShareLinkContentListResponse>(
    `${COMPANY_SHAREABLE_ANALYTICS_API}${companyId}/share_links/${query}`
  );
};
export const getShareLinkDetail = (
  contentId: string,
  paginationQuery: IShareLinkPagination
) => {
  const query = stringifyQs(paginationQuery, {
    addQueryPrefix: true,
  });
  return retrieve<IShareLinkContentDetailResponse>(
    `${COMPANY_SHAREABLE_ANALYTICS_API}${contentId}/share_link_detail/${query}`
  );
};

export const getCompanyDetails = (companyId: string) => {
  return retrieve<IShareLinkCompanyResponse>(
    `${COMPANY_SHAREABLE_ANALYTICS_API}${companyId}`
  );
};
