import {PUBLIC_ROUTES, BUILD_VERSION} from './constants';
import {getTokenKey, tokenStorage, setItem} from './jwtUtilsHelpers';
import {clear} from './utils/localStorage';
import {capture} from './logging';

interface IResponse {
  success: boolean;
  'app-token': string;
  token: string;
}

export const authTokenRefreshCall = async (
  host: string,
  xClient: string,
  url: string,
  retryCount: number = 0
): Promise<Response> => {
  const MAX_RETRIES = 4;
  const BASE_DELAY = 1000;

  try {
    const response = await fetch(`${host}/api/auth/refresh`, {
      method: 'POST',
      headers: {
        credentials: 'include',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CLIENT': xClient,
        'X-VERSION': BUILD_VERSION,
        'X-URL-FOR': url,
      },
    });

    const is50xError = response.status >= 500 && response.status < 600;

    if (!response.ok && is50xError) {
      throw new Error(`Auth token refresh failed with status ${response.status}`);
    }

    return response;
  } catch (e) {
    capture(e);

    if (retryCount < MAX_RETRIES) {
      const delay = BASE_DELAY * Math.pow(2, retryCount);
      await new Promise((resolve) => setTimeout(resolve, delay));
      return authTokenRefreshCall(host, xClient, url, retryCount + 1);
    }
    throw e;
  }
};

const isChromeExtensionOrOutlook = (xClient: string): boolean => {
  return xClient === 'ext' || xClient === 'outlook';
};

/**
 * Call refresh token api to obtain new token. If the endpoint does not response 200,
 * we redirect user to logout page to perform logout.
 *
 * @param xClientName {string}
 * @param host {string}
 * @returns {string | void}
 */
export const getRefreshToken = async (
  xClientName = 'app',
  host = '',
  url = ''
): Promise<string | undefined> => {
  const xClient = xClientName || 'app';
  const tokenKey = getTokenKey(xClient);

  // Do not call generate token api on webapp is the route is defined
  // as public route.
  if (xClient === 'app') {
    const pathname = window.location?.pathname || '';
    const isPublic = PUBLIC_ROUTES.some((path) => pathname.startsWith(path));
    if (isPublic) {
      return undefined;
    }
  }

  try {
    const resp = await authTokenRefreshCall(host, xClient, url);
    const results = (await resp.json()) as IResponse;
    if (resp.status !== 200 && results.success !== true) {
      // Remove chrome storage only if ERROR is 401 and the
      // user is really logged out.
      if (resp.status === 401 && xClient === 'ext') {
        // refresh failed
        await chrome.storage.local.remove('spekit_session');
        // let's not try this again on 401
        await setItem('spekit_block_refresh', 'true');
      }
      throw new Error('JWT Refresh token unsuccessful.');
    }
    const newToken = results[tokenKey];
    await tokenStorage.setToken(tokenKey, newToken);
    return newToken;
  } catch (err) {
    if (isChromeExtensionOrOutlook(xClient)) {
      await tokenStorage.deleteToken(tokenKey);
    } else {
      clear();
      const redirectPath = window.location?.pathname
        ? `?redir=${encodeURIComponent(
            window.location.pathname + window.location.search
          )}`
        : '';
      window.location.replace(`${host}/logout${redirectPath}`);
    }
    return undefined;
  }
};
