import React from 'react';
import Select from 'react-select';
import {Dropdown, Select as DSSelect} from 'spekit-ui';
import styles from './linksDropdown.module.css';
import {State} from '../types';
const EnhancedDropDown: any = Dropdown(Select);

interface Props {
  onSelect: (value: any) => void;
  monitorScrollElement: any;
  value: string;
  state: State;
}
const formatGroupLabel = (data: any) => <div className={styles.main}>{data.label}</div>;
const groupedOptions = [
  {
    label: 'Spekit Resource',
    options: [
      {
        value: 'term',
        label: 'Spek',
      },
      {
        value: 'file',
        label: 'File',
      },
      {
        value: 'topic',
        label: 'Topic',
      },
      {
        value: 'flow',
        label: 'Flow',
      },
      {
        value: 'quiz',
        label: 'Knowledge Check',
      },
    ],
  },
  {
    label: 'External Resource',
    options: [
      {
        value: 'url',
        label: 'URL',
      },
    ],
  },
  {
    label: 'No Link',
    options: [
      {
        label: 'Acknowledge and Close',
        value: 'acknowledge-and-close',
      },
    ],
  },
];

const LinksDropdown: React.FC<Props> = ({
  onSelect,
  monitorScrollElement,
  value,
  state,
}) => {
  const hasSpotlightsRevampFlag = state.flag?.hasSpotlightsRevampFlag;
  const mapOptions = () => {
    let foundValue;
    let valueToFind;
    groupedOptions.forEach((go) => {
      foundValue = go.options.find((o) => o.value === value);
      if (foundValue) {
        valueToFind = foundValue;
      }
    });

    return valueToFind;
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    let {action} = actionMeta;

    if (action === 'select-option') {
      onSelect({label: newValue.label, value: newValue.value});
    }
    if (action === 'clear') {
      onSelect(null);
    }
  };
  const getCorrectOptions = () => {
    const {hasQuizFlag, hasSpekitShareFlag} = state.flag || {};
    if (hasQuizFlag && hasSpekitShareFlag) return groupedOptions;

    return groupedOptions.map((groupOption) => {
      let options = groupOption.options;
      if (!hasQuizFlag) options = options.filter((option) => option.value !== 'quiz');
      if (!hasSpekitShareFlag)
        options = options.filter((option) => option.value !== 'file');
      return {
        label: groupOption.label,
        options,
      };
    });
  };

  const getOnlySpekitResource = () => {
    return getCorrectOptions().find((option) => option.label === 'Spekit Resource')
      ?.options;
  };

  return (
    <>
      {hasSpotlightsRevampFlag ? (
        <DSSelect
          options={getOnlySpekitResource()}
          value={mapOptions()}
          onChange={handleChange}
          placeholder='Select resource type'
          blurInputOnSelect
          isDisabled={state.read_mode}
        />
      ) : (
        <EnhancedDropDown
          isClearable={false}
          defaultOptions
          isSearchable={false}
          noOptionMessage='No rules found'
          placeholder='Select'
          groupStyled
          options={getCorrectOptions()}
          formatGroupLabel={formatGroupLabel}
          menuPortalTarget={document.querySelector('body')}
          value={mapOptions()}
          isDisabled={state.read_mode}
          closeMenuOnScroll={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (
              monitorScrollElement &&
              monitorScrollElement.current &&
              event.target.isSameNode(monitorScrollElement.current)
            ) {
              return true;
            }
            return false;
          }}
          handleChange={handleChange}
        />
      )}
    </>
  );
};

export default LinksDropdown;
