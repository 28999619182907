import React, {useState, useEffect, useCallback} from 'react';
import {
  DataTable,
  Pagination,
  DSTooltip as Tooltip,
  Fontawesome,
  Text,
  Flex,
  Avatar,
} from 'spekit-ui';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {
  formatDurationFromSeconds,
  isValid,
  format,
  parseISO,
  DATE_FORMAT,
} from 'spekit-datalayer';
import styles from './analyticsByUser.module.css';
import {Statistic} from './types';

interface Props {
  statistics: Array<Statistic>;
  loading?: boolean;
}

const AnalyticsByUser: React.FC<Props> = ({statistics, loading}) => {
  const pageSize = 7;

  const [page, setPage] = useState<number>(1);

  const [currentDisplayedStats, setCurrentDisplayedStats] = useState<{
    displayedStats: Array<Statistic>;
    busy: boolean;
  }>({
    displayedStats: [],
    busy: true,
  });

  const [totalPages, setTotalPages] = useState<number>(0);

  const {displayedStats, busy} = currentDisplayedStats;

  const [sortBy, setSortBy] = useState({
    viewed: null,
    is_engaged: null,
    last_viewed: null,
    snoozed_count: null,
    time_spent: null,
    full_name: null,
  });

  const [sortedResults, setSortedResults] = useState<Array<Statistic>>([]);

  const paginateStats = useCallback(() => {
    let EndIndex = pageSize * page;
    let startIndex = EndIndex - pageSize;
    let results;
    if (sortedResults.length) {
      results = sortedResults.slice(startIndex, EndIndex);
    } else {
      results = statistics.slice(startIndex, EndIndex);
    }
    setCurrentDisplayedStats({displayedStats: results, busy: false});
  }, [statistics, page, sortedResults]);

  useEffect(() => {
    setTotalPages(Math.ceil(statistics.length / pageSize));
  }, [statistics]);

  useEffect(() => {
    paginateStats();
  }, [paginateStats]);

  const onPageSelect = (pageNumber: number) => {
    setPage(pageNumber);
    paginateStats();
  };

  const onColumnClick = (columnName: string, sortOrder: string) => {
    let lastColumnName: any = columnName.split('.').pop();
    let _originalResults: Array<Statistic> = [];
    if (sortOrder) {
      const isAscending = sortOrder === 'ASCENDING';
      _originalResults = [...statistics];

      _originalResults.sort((itemA: any, itemB: any) => {
        columnName.split('.').forEach((name: string) => {
          itemA = itemA[name];
          itemB = itemB[name];
          lastColumnName = name;
        });

        if (typeof itemA === 'string' && typeof itemB === 'string') {
          itemA = itemA.toLowerCase();
          itemB = itemB.toLowerCase();
        }

        if (columnName === 'last_viewed') {
          itemA = itemA ? parseISO(itemA) : new Date(0);
          itemB = itemB ? parseISO(itemB) : new Date(0);
        }

        if (itemA > itemB) {
          return isAscending ? 1 : -1;
        }
        if (itemA < itemB) {
          return isAscending ? -1 : 1;
        }
        return 0;
      });
    }
    setSortBy((prevState) => ({...prevState, [lastColumnName]: sortOrder}));
    paginateStats();
    setSortedResults(_originalResults);

    setPage(1);
  };

  const getDataTableColumns = () => {
    return [
      {
        template: (statistic: Statistic) => {
          const fullName = statistic.user.full_name;

          return (
            <Flex gap={10} align='center'>
              <Avatar name={fullName} size='xs' />
              <Text>{fullName}</Text>
            </Flex>
          );
        },
        headerTemplate: () => {
          return <span className={styles.headerViewers}>VIEWERS</span>;
        },
        height: '100px',
        width: '130px',
        sort: sortBy.full_name,
        sortBy: 'user.full_name',
      },
      {
        template: (statistic: Statistic, n: any) => {
          return (
            <div>
              <label>{statistic.viewed ? 'Yes' : 'No'} </label>
            </div>
          );
        },
        headerTemplate: () => {
          return <span className={styles.headerViewed}>VIEWED</span>;
        },
        width: '80px',
        height: '100px',
        sort: sortBy.viewed,
        sortBy: 'viewed',
      },
      {
        template: (statistic: Statistic, n: any) => {
          return (
            <div>
              <label>{statistic.is_engaged ? 'Yes' : 'No'} </label>
            </div>
          );
        },
        headerTemplate: () => {
          return <span className={styles.headerEngaged}>ENGAGED</span>;
        },
        width: '90px',
        height: '100px',
        sort: sortBy.is_engaged,
        sortBy: 'is_engaged',
      },
      {
        template: (statistic: Statistic) => {
          let label = '-';
          if (statistic.last_viewed) {
            let lastViewed = parseISO(statistic.last_viewed);

            if (isValid(lastViewed)) {
              label = format(lastViewed, DATE_FORMAT);
            }
          }

          return (
            <div>
              <label>{label}</label>
            </div>
          );
        },
        headerTemplate: () => {
          return <span className={styles.headerLastViewed}>LAST VIEWED</span>;
        },
        width: '100px',
        height: '100px',
        sort: sortBy.last_viewed,
        sortBy: 'last_viewed',
      },
      {
        template: (statistic: Statistic, n: any) => {
          let count = statistic.snoozed_count;
          return (
            <div>
              <label>{count ? `${count} time${count === 1 ? '' : 's'}` : '-'} </label>
            </div>
          );
        },
        headerTemplate: () => {
          return (
            <span className={styles.headerSnoozedContainer}>
              <span className={styles.headerSnoozedText}>SNOOZED</span>
              <Tooltip
                shouldWrapChildren
                label='If the user saw the Spotlight, but has dismissed it to view later.'
              >
                <Fontawesome
                  style={{color: 'rgba(99, 114, 128, 0.42)', marginLeft: '0.5em'}}
                  name={faInfoCircle}
                />
              </Tooltip>
            </span>
          );
        },
        width: '90px',
        height: '100px',
        sort: sortBy.snoozed_count,
        sortBy: 'snoozed_count',
      },
      {
        displayName: 'AVG TIME SPENT VIEWING',
        template: (statistic: Statistic, n: any) => {
          return (
            <div>
              <span>
                {statistic.time_spent
                  ? formatDurationFromSeconds(statistic.time_spent)
                  : 'N/A'}
              </span>
            </div>
          );
        },
        headerTemplate: () => {
          return (
            <span className={styles.headerAvgTimeSpentContainer}>
              <span className={styles.headerAvgTimeSpentText}>
                AVG.TIME SPENT VIEWING
              </span>
              <Tooltip
                shouldWrapChildren
                label='Includes time spent preceeding all engagement types, including dismissal.'
              >
                <Fontawesome
                  style={{color: 'rgba(99, 114, 128, 0.42)', marginLeft: '0.5em'}}
                  name={faInfoCircle}
                />
              </Tooltip>
            </span>
          );
        },
        width: '170px',
        height: '100px',
        sort: sortBy.time_spent,
        sortBy: 'time_spent',
      },
    ];
  };

  return (
    <div>
      <div className={styles.headingAnalyticsByUser}>Analytics By User</div>
      <DataTable
        style={{border: 'none', fontSize: '9px'}}
        emptyState={<div className={styles.emptyState}>No Analytics found.</div>}
        stickyHeader
        busy={busy || loading}
        data={displayedStats}
        columns={getDataTableColumns()}
        onColumnClick={onColumnClick}
      />
      <div className={styles.pagination_container}>
        {displayedStats.length > 0 ? (
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageSelect={onPageSelect}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AnalyticsByUser;
