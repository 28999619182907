import {
  logging,
  QUERY_KEYS,
  brandConfigAPI,
  FALLBACK_BRAND_CONFIG,
} from 'spekit-datalayer';
import {useQuery} from '@tanstack/react-query';
import {IBrandConfig} from 'spekit-types';

const getEmptyResponse = () => FALLBACK_BRAND_CONFIG;

interface IUseCustomBrandingProps {
  queryFn?: () => Promise<IBrandConfig | undefined>;
  shouldFetch?: boolean;
}

export const useCustomBranding = ({
  queryFn = brandConfigAPI.getBrandConfig,
  shouldFetch = true,
}: IUseCustomBrandingProps = {}) => {
  return useQuery({
    staleTime: Infinity,
    queryKey: [QUERY_KEYS.BrandConfig],
    queryFn: async () => {
      if (!shouldFetch) return getEmptyResponse();
      return queryFn().catch(getEmptyResponse);
    },
    onError: (e) => logging.capture(e),
  });
};
