import {Flex, Heading, Text, DSButton as Button, Icon} from 'spekit-ui';
import {FaChrome} from 'react-icons/fa';
import {BsArrowRight} from 'react-icons/bs';
import {CHROME_EXTENSION_URL} from 'spekit-datalayer';
import {track} from '../../utils/analytics';

const ExtensionNotInstalled = () => (
  <Flex direction='column' p='30' gap='24' alignItems='flex-start'>
    <Heading as='h1'>Install the Spekit Chrome extension for access.</Heading>
    <Text variant='body1' fontWeight='normal'>
      To access this Knowledge Check, you need to install our Chrome extension.
    </Text>
    <Button
      leftIcon={<Icon as={FaChrome} height='14px' w='14px' />}
      rightIcon={<Icon as={BsArrowRight} h='16px' w='16px' />}
      variant='contained'
      size='medium'
      colorScheme='primary'
      onClick={() => {
        window.open(CHROME_EXTENSION_URL, '_blank');
        track('Clicked on Install Now', {screen_name: 'Extension Not Installed'});
      }}
    >
      Install now
    </Button>
  </Flex>
);

export default ExtensionNotInstalled;
