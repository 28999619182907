import {getBrowser} from '../../utils/getBrowser';
import {CHROME_EXTENSION_URL} from 'spekit-datalayer';
import {Flex, Text, Link, Icon} from 'spekit-ui';
import {RiShareBoxLine} from 'react-icons/ri';

const isEdge = () => {
  return getBrowser().indexOf('edge') !== -1;
};

export function ChromeBanner() {
  return (
    <Flex
      zIndex={1}
      backgroundColor='neutral.50'
      py={14}
      px={24}
      justify='space-between'
      align='center'
    >
      <Text variant='caption1' fontWeight={600}>
        Install the Spekit {isEdge() ? 'Edge' : 'Chrome'} extension to surface content
        just-in-time from anywhere you work
      </Text>
      <Link
        href={CHROME_EXTENSION_URL}
        target='_blank'
        rel='noopener noreferrer'
        textDecoration='none'
        color='neutral.800'
        fontWeight={400}
        fontSize='tiny'
        lineHeight='normal'
      >
        <Flex align='center' gap={4}>
          <Icon as={RiShareBoxLine} width='16px' height='16px' />
          Install extension
        </Flex>
      </Link>
    </Flex>
  );
}
