import React from 'react';
import Fontawesome from './fontawesome.tsx';
import {PropagateLoader} from 'react-spinners';
import CheckBox from './checkbox';
import {Checkbox as DSCheckbox, DSButton as Button, Icon} from './design-system';
import {faCaretUp, faCaretDown, faSort, faLock} from '@fortawesome/free-solid-svg-icons';
import {RiDeleteBin6Line} from 'react-icons/ri';

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRow: null,
      hoveredRow: null,
      columns: this.props.columns.filter((col) => col !== null),
    };
    this.container = null;
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.unexpand = this.unexpand.bind(this);
    this.setContainer = this.setContainer.bind(this);
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (JSON.stringify(newProps.columns) !== JSON.stringify(this.props.columns)) {
      this.setState({columns: newProps.columns.filter((col) => col !== null)});
    }
  }
  handleColumnClick(columnName) {
    if (typeof this.props.onColumnClick === 'function') {
      let sortOrder = null;
      let column = this.props.columns.find(
        (col) => col.fieldName === columnName || col.sortBy === columnName
      );
      if (!column.noSort) {
        if (typeof column.sort !== 'string') {
          sortOrder = 'ASCENDING';
        } else if (column.sort === 'ASCENDING') {
          sortOrder = 'DESCENDING';
        }
        this.props.onColumnClick(columnName, sortOrder);
      }
    }
  }
  expandRow(rowKey) {
    const isExistingSelected = rowKey === this.state.expandedRow;
    /* this if statement handles logic when databale is not in expanded mode eg: in wiki*/
    if (!this.props.expandable) {
      this.props.onRowClick && this.props.onRowClick(rowKey);
    } else {
      /*from here it handles logic for datable in expandable mode eg : usersform*/
      if (isExistingSelected) {
        this.setState({expandedRow: null});
      } else {
        this.setState({expandedRow: rowKey}, () => {
          if (typeof this.props.onRowClick === 'function') {
            this.props.onRowClick(rowKey);
          }
        });
      }
    }
  }
  hoverRow(rowKey) {
    if (rowKey === this.state.hoveredRow) {
      this.setState({hoveredRow: null});
    } else {
      this.setState({hoveredRow: rowKey});
    }
  }
  handleSelectAll(value) {
    if (typeof this.props.onSelectAllToggle === 'function') {
      this.props.onSelectAllToggle(!this.props.DSCheckbox ? value : value.target.checked);
    }
  }
  handleSelect(index, evt) {
    if (typeof this.props.onSelectToggle === 'function') {
      this.props.onSelectToggle(index);
    }
  }
  unexpand() {
    this.setState({expandedRow: null});
  }
  expand(rowKey) {
    this.setState({expandedRow: rowKey});
  }
  setContainer(el) {
    if (typeof this.props.containerRef === 'function') {
      this.props.containerRef(el);
    }
    this.container = el;
  }
  handleDelete(row, index) {
    if (typeof this.props.onDelete === 'function') {
      this.props.onDelete(row, index);
    }
  }

  renderSelectedIndex = () => {
    if (!this.props.data || !this.props.data.length) {
      return null;
    }

    return (
      this.props.selectedIndexes.length ===
      this.props.data.filter((f) => !f.is_system_spek).length
    );
  };
  render() {
    const self = this;
    let keyCol = 'id';
    let styles = {
      main: {
        backgroundColor: this.props.transparent ? null : '#FFF',
        border: this.props.transparent ? null : '1px solid #EBEDF8',
        borderRadius: '4px',
        overflowY: 'hidden',
        overflowX: 'auto',
        ...this.props.style,
      },
      tableMain: {
        width: '100%',
        textAlign: 'left',
        paddingBottom: '24px',
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
        isolation: 'isolate',
      },
      spinnerHContainer: {
        textAlign: 'center',
      },
      spinnerVContainer: {
        padding: 'calc(50vh - 150px)',
        margin: 'auto',
        width: '0px',
      },
      colHeader: {
        padding: '12px 12px 12px 24px',
        color: '#343A40',
        fontSize: '11px',
        fontWeight: '400',
        letterSpacing: '1.5px',
        textTransform: 'uppercase',
        borderBottom: '0.5px solid rgba(99, 114, 128, 0.33)',
        cursor: this.props.showArrowOnHeader ? 'arrow' : 'pointer',
        userSelect: 'none',
        position: 'relative',
        transition: 'box-shadow 300ms ease-in-out',
      },
      colHeaderText: {
        width: 'calc(100% - 120px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      stickyColHeader: {
        padding: '12px',
        color: '#343A40',
        fontSize: '11px',
        fontWeight: '400',
        letterSpacing: '1.5px',
        textTransform: 'uppercase',
        borderBottom: '0.5px solid rgba(99, 114, 128, 0.33)',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        userSelect: 'none',
        position: 'sticky',
        left: '0px',
        zIndex: '1',
        backgroundColor: 'inherit',
        boxShadow: 'rgba(0, 0, 0, 0.05) 12px 3px 17px -8px',
        transition: 'box-shadow 300ms ease-in-out',
      },
      vStickyColHeader: {
        position: 'sticky',
        top: '0px',
        zIndex: '2',
        backgroundColor: 'white',
        boxShadow: 'inherit -22px 0px 0px 0px,rgba(0, 0, 0, 0.05) 0px 12px 40px -8px',
        transition: 'box-shadow 300ms ease-in-out',
      },
      checkbox: {
        border: '2px solid #3576E8',
      },
      expandedRowCheckbox: {
        border: '2px solid #FFF',
      },
      row: {
        backgroundColor: this.props.transparent ? null : 'inherit',
        color: this.props.changeRowColor ? '#000000' : '#22252A',
        cursor: this.props.expandable ? 'pointer' : null,
        transition: 'all 300ms ease-in-out',
      },
      expandedRow: {
        backgroundColor: this.props.transparent
          ? null
          : this.props.isExpanded
          ? '#653FE6'
          : '#3576E8',
        color: '#FFF',
        cursor: this.props.expandable ? 'pointer' : null,
        transition: 'all 300ms ease-in-out',
        ...this.props.expandedRowStyles,
      },
      hoveredRow: {
        backgroundColor: this.props.transparent ? null : '#F5F6F8',
        color: this.props.changeRowColor ? '#000000' : '#22252A',
        cursor: 'pointer',
        transition: 'all 300ms ease-in-out',
      },
      rowCell: {
        padding: '12px 5px',
        fontSize: '15px',
        minWidth: '30px',
        position: 'relative',
        wordWrap: 'break-word',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        zIndex: '0',
        transition: 'box-shadow 300ms ease-in-out',
      },
      stickyRowCell: {
        padding: '12px 15px',
        fontSize: '15px',
        minWidth: '30px',
        wordWrap: 'break-word',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'sticky',
        left: '0px',
        zIndex: '1',
        backgroundColor: 'white',
        boxShadow:
          'rgb(255,255,255) 0px 3px 0px 0px,rgba(0, 0, 0, 0.05) 12px 11px 25px 0px',
        transition: 'box-shadow 300ms ease-in-out',
      },
      caret: {
        verticalAlign: 'middle',
        marginLeft: '11px',
        fontSize: '14px',
      },
      chevron: {
        marginTop: '10px',
        transform: 'rotate( 0deg )',
        transition: 'all 500ms ease-in-out',
      },
      chevronExpanded: {
        marginTop: '10px',
        transform: 'rotate( 90deg )',
        transition: 'all 500ms ease-in-out',
      },
      trExpanded: {
        display: 'table-row',
        overflowY: 'hidden',
      },
      expandContentHidden: {
        maxHeight: '0px',
        overflow: 'hidden',
        transition: 'all 500ms cubic-bezier(0.73, 0.72, 0.08, 0.98)',
      },
      expandContentExpanded: {
        maxHeight: '1000px',
        overflowX: 'hidden',
        overflowY: 'auto',
        transition: 'all 500ms cubic-bezier(0.73, 0.72, 0.08, 0.98)',
      },
      checkboxContainer: {
        position: 'absolute',
        top: '12px',
        left: '24px',
      },
      DSCheckboxContainer: {
        left: '32px',
      },
      checkboxAllContainer: {
        position: 'absolute',
        top: '20px',
        left: '24px',
      },
      DSCheckboxAllContainer: {
        top: '30px',
        left: '32px',
      },
      chevronContainer: {
        textAlign: 'center',
      },
      lock: {
        marginLeft: '0.4em',
      },
    };
    return (
      <div style={styles.main} ref={this.props.containerRef}>
        {this.props.busy ? (
          <div style={styles.spinnerHContainer}>
            <div style={styles.spinnerVContainer}>
              <PropagateLoader color='#3576E8' loading={this.state.busy} />
            </div>
          </div>
        ) : (self.props.data && self.props.data.length > 0) || !self.props.emptyState ? (
          <table style={styles.tableMain}>
            <tbody>
              <tr key='header'>
                {self.props.selectable ? (
                  <th
                    style={{
                      ...styles.colHeader,
                      ...(self.props.stickyHeader ? styles.vStickyColHeader : {}),
                      zIndex: '4',
                      width: '56px',
                    }}
                  >
                    <div
                      style={{
                        ...styles.checkboxAllContainer,
                        ...(this.props.DSCheckbox && styles.DSCheckboxAllContainer),
                      }}
                    >
                      {!this.props.DSCheckbox ? (
                        <CheckBox
                          checkboxStyle={styles.checkbox}
                          onToggle={this.handleSelectAll}
                          value={this.renderSelectedIndex()}
                        />
                      ) : (
                        <DSCheckbox
                          variant={this.props.DSCheckboxVariant}
                          onChange={this.handleSelectAll}
                          isChecked={this.renderSelectedIndex()}
                        />
                      )}
                    </div>
                  </th>
                ) : null}
                {self.state.columns.map((col, n) => {
                  if (col.isKey) {
                    keyCol = col.fieldName;
                  }
                  return (
                    <th
                      key={JSON.stringify(col)}
                      onClick={self.handleColumnClick.bind(
                        self,
                        col.sortBy || col.fieldName
                      )}
                      style={
                        !self.props.selectable && n === 0
                          ? {
                              ...(col.sticky &&
                              self.container &&
                              self.container.scrollWidth > self.container.offsetWidth
                                ? styles.stickyColHeader
                                : styles.colHeader),
                              ...(self.props.stickyHeader ? styles.vStickyColHeader : {}),
                              ...(self.props.stickyHeader && col.sticky
                                ? {zIndex: '3'}
                                : {}),
                              width: col.width || '13.8vw',
                              padding: '12px',
                            }
                          : {
                              ...(col.sticky &&
                              self.container &&
                              self.container.scrollWidth > self.container.offsetWidth
                                ? styles.stickyColHeader
                                : styles.colHeader),
                              ...(self.props.stickyHeader ? styles.vStickyColHeader : {}),
                              ...(self.props.stickyHeader && col.sticky
                                ? {zIndex: '3'}
                                : {}),
                              width: col.width || '13.8vw',
                            }
                      }
                    >
                      {col.headerTemplate ? (
                        col.headerTemplate()
                      ) : (
                        <span style={col.noSort ? null : styles.colHeaderText}>
                          {col.displayName || col.fieldName}{' '}
                        </span>
                      )}
                      {col.noSort ? null : col.sort ? (
                        col.sort === 'ASCENDING' ? (
                          <Fontawesome
                            style={{...styles.caret, ...col.sortStyle}}
                            name={faCaretUp}
                          />
                        ) : col.sort === 'DESCENDING' ? (
                          <Fontawesome
                            style={{...styles.caret, ...col.sortStyle}}
                            name={faCaretDown}
                          />
                        ) : (
                          <Fontawesome
                            style={{...styles.caret, ...col.sortStyle}}
                            name={faSort}
                          />
                        )
                      ) : (
                        <Fontawesome
                          style={{...styles.caret, ...col.sortStyle}}
                          name={faSort}
                        />
                      )}
                    </th>
                  );
                })}
                {self.props.canDelete ? (
                  <th
                    style={{
                      ...styles.colHeader,
                      ...(self.props.stickyHeader ? styles.vStickyColHeader : {}),
                      zIndex: '4',
                      width: '1.4vw',
                    }}
                  ></th>
                ) : null}
              </tr>
              {self.props.data && self.props.data.length
                ? self.props.data.map((dataItem, n) => {
                    const isDataItemExpanded =
                      dataItem[keyCol] === self.state.expandedRow;
                    return [
                      <tr
                        key={dataItem[keyCol]}
                        onClick={self.expandRow.bind(self, dataItem[keyCol])}
                        style={
                          isDataItemExpanded
                            ? styles.expandedRow
                            : dataItem[keyCol] === self.state.hoveredRow &&
                              !self.props.fromTeams
                            ? styles.hoveredRow
                            : styles.row
                        }
                        onMouseEnter={self.hoverRow.bind(self, dataItem[keyCol])}
                        onMouseLeave={self.hoverRow.bind(self, dataItem[keyCol])}
                      >
                        {self.props.selectable ? (
                          <td style={styles.rowCell} data-testid='selectable-row'>
                            <div
                              style={{
                                ...styles.checkboxContainer,
                                ...(this.props.DSCheckbox && styles.DSCheckboxContainer),
                              }}
                              onClick={(e) => e.stopPropagation()}
                            >
                              {!dataItem.is_system_spek ? (
                                !this.props.DSCheckbox ? (
                                  <CheckBox
                                    checkboxStyle={
                                      isDataItemExpanded
                                        ? styles.expandedRowCheckbox
                                        : styles.checkbox
                                    }
                                    onToggle={self.handleSelect.bind(self, n)}
                                    value={
                                      self.props.selectedIndexes.findIndex(
                                        (i) => i === n
                                      ) !== -1
                                    }
                                  />
                                ) : (
                                  <DSCheckbox
                                    variant={this.props.DSCheckboxVariant}
                                    onChange={self.handleSelect.bind(self, n)}
                                    isChecked={
                                      self.props.selectedIndexes.findIndex(
                                        (i) => i === n
                                      ) !== -1
                                    }
                                  />
                                )
                              ) : (
                                <Fontawesome name={faLock} style={styles.lock} />
                              )}
                            </div>
                          </td>
                        ) : null}
                        {self.state.columns.map((col, columnN) => (
                          <td
                            style={
                              !self.props.selectable && columnN === 0
                                ? {
                                    ...(col.sticky &&
                                    self.container &&
                                    self.container.scrollWidth >
                                      self.container.offsetWidth
                                      ? styles.stickyRowCell
                                      : styles.rowCell),
                                    padding: '12px 20px',
                                  }
                                : col.sticky &&
                                  self.container &&
                                  self.container.scrollWidth > self.container.offsetWidth
                                ? styles.stickyRowCell
                                : styles.rowCell
                            }
                            key={JSON.stringify(col)}
                          >
                            {typeof col.template === 'function'
                              ? col.template(
                                  col.fieldName ? dataItem[col.fieldName] : dataItem,
                                  n,
                                  dataItem[keyCol] === self.state.expandedRow,
                                  self.props.data
                                )
                              : dataItem[col.fieldName]}
                          </td>
                        ))}
                        {self.props.canDelete ? (
                          <td style={{...styles.rowCell}}>
                            {self.props.data.length > 1 ? (
                              <Button
                                aria-label='delete-bulk-user'
                                icon={<Icon as={RiDeleteBin6Line} />}
                                variant='icon'
                                size='medium'
                                colorScheme='white'
                                onClick={self.handleDelete.bind(self, dataItem, n)}
                              />
                            ) : null}
                          </td>
                        ) : null}
                      </tr>,
                      self.props.expandable && isDataItemExpanded ? (
                        <tr key={dataItem[keyCol] + '_detail'} style={styles.trExpanded}>
                          <td
                            colSpan={
                              self.props.selectable
                                ? Object.keys(self.state.columns).length + 1
                                : Object.keys(self.state.columns).length
                            }
                          >
                            <div
                              style={
                                self.state.expandedRow === dataItem[keyCol]
                                  ? styles.expandContentExpanded
                                  : styles.expandContentHidden
                              }
                            >
                              {typeof self.props.expandedTemplate === 'function'
                                ? self.props.expandedTemplate(
                                    dataItem,
                                    self.unexpand,
                                    self.expand.bind(self, dataItem[keyCol])
                                  )
                                : null}
                            </div>
                          </td>
                        </tr>
                      ) : null,
                    ];
                  })
                : null}
            </tbody>
          </table>
        ) : (
          self.props.emptyState
        )}
      </div>
    );
  }
}

export default DataTable;
