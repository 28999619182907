import React, {useMemo, useState} from 'react';
import {RiDeleteBin6Line, RiEdit2Line} from 'react-icons/ri';
import {useMutation} from '@tanstack/react-query';
import {Column} from 'react-table';
import {
  adminToolsAPI,
  format,
  parseISO,
  SHORTENED_DATE_FORMAT,
  utils,
  checkTaskStatus,
  CUSTOM_FIELD_DELETE_MESSAGE,
  logging,
} from 'spekit-datalayer';
import {TCustomField} from 'spekit-types';
import {
  DSDataTable as DataTable,
  FullPageLoader,
  MoreMenuButton,
  SimpleMenuItem,
  Flex,
  useToast,
  ConfirmModal,
  Box,
  Text,
} from 'spekit-ui';
import {FIELD_TYPES} from '../CustomFieldsForm/form';
import {CreateFieldModal} from './CreateFieldModal';
import {EditFieldModal} from './EditFieldModal';
import {useCustomFields} from 'spekit-shared-components';

const {mapContentTypesToReadableName, getFullName} = utils;

export const CustomFields = () => {
  const {refetch, data, isLoading} = useCustomFields();
  const customFields = data || [];
  const [fieldIndexToEdit, setFieldIndexToEdit] = useState();
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const toast = useToast();

  const {isLoading: isDeleting, mutate: handleDelete} = useMutation({
    mutationFn: adminToolsAPI.deleteCustomField,
    onSuccess: async (data) => {
      await checkTaskStatus(data.task_id);
      refetch();
      toast({variant: 'success', description: 'Custom field deleted!'});
    },
    onError: (e) => {
      toast({variant: 'error', description: 'Custom field deletion failed!'});
      logging.capture(e);
    },
    onSettled: () => setDeleteId(null),
  });

  const onEditSave = () => {
    refetch();
    setFieldIndexToEdit(undefined);
  };

  const columns = useMemo<Column<TCustomField>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        sortType: 'string',
        Cell: ({value}) => (
          <Text variant='body2' color='neutral.900' isTruncated maxW='120px'>
            {value}
          </Text>
        ),
      },
      {
        Header: 'Type',
        accessor: 'data_type',
        Cell: ({value}) => FIELD_TYPES.find((type) => type.value === value)?.label,
      },
      {
        Header: 'Applies to',
        accessor: 'on',
        sortType: ({original: a}, {original: b}) => {
          const appliedToAsStringA = mapContentTypesToReadableName(a.on.sort()).join(
            ', '
          );
          const appliedToAsStringB = mapContentTypesToReadableName(b.on.sort()).join(
            ', '
          );
          return appliedToAsStringA.localeCompare(appliedToAsStringB);
        },
        Cell: ({value}) => mapContentTypesToReadableName(value).join(', '),
      },
      {
        Header: 'Created by',
        accessor: 'created_by',
        sortType: ({original: a}, {original: b}) => {
          const fullNameA = getFullName(a.created_by);
          const fullNameB = getFullName(b.created_by);
          return fullNameA.localeCompare(fullNameB);
        },
        Cell: ({value}) => getFullName(value),
      },
      {
        Header: 'Created on',
        accessor: 'created_on',
        sortType: (a, b) => {
          const dateA = parseISO(a.original.created_on).getTime();
          const dateB = parseISO(b.original.created_on).getTime();
          return dateB - dateA;
        },
        Cell: ({value}) => format(parseISO(value), SHORTENED_DATE_FORMAT),
      },
      {
        disableSortBy: true,
        accessor: 'id',
        Cell: ({value, rowIndex, ...rest}) => {
          return (
            <MoreMenuButton
              data-testid={`more-btn-${rest.row.values.name}`}
              size='medium'
              onFocus={(e) => e.preventDefault()}
              menuPlacement='bottom-start'
              tooltipPlacement='bottom'
            >
              <SimpleMenuItem
                icon={RiEdit2Line}
                data-testid={`edit-field-${rest.row.values.name}`}
                onClick={() => setFieldIndexToEdit(rowIndex)}
              >
                Edit
              </SimpleMenuItem>
              <SimpleMenuItem
                icon={RiDeleteBin6Line}
                data-testid={`delete-field-${rest.row.values.name}`}
                onClick={() => setDeleteId(value)}
              >
                Delete
              </SimpleMenuItem>
            </MoreMenuButton>
          );
        },
      },
    ],
    [setDeleteId]
  );
  return (
    <Flex direction='column' gap={30}>
      <EditFieldModal
        onSave={onEditSave}
        handleClose={() => setFieldIndexToEdit(undefined)}
        field={
          fieldIndexToEdit !== undefined ? customFields[fieldIndexToEdit] : undefined
        }
      />
      <ConfirmModal
        isOpen={!!deleteId}
        isConfirming={isDeleting}
        onClose={() => setDeleteId(null)}
        handleConfirm={() => {
          if (!deleteId) throw new Error('Custom field must be selected for deletion.');
          handleDelete(deleteId);
        }}
        confirmLabel='Yes, delete'
        description={CUSTOM_FIELD_DELETE_MESSAGE}
        header='Delete custom field'
      />
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <Box
          border='1px solid'
          borderRadius='md'
          borderColor='neutral.200'
          marginBottom={24}
          data-testid='loaded-content'
        >
          <DataTable
            data={customFields}
            columns={columns}
            isSortable
            title='Custom fields'
            rightElement={<CreateFieldModal />}
            name='custom-field'
          />
        </Box>
      )}
    </Flex>
  );
};
