import React, {useState} from 'react';
import {RiInformationLine} from 'react-icons/ri';
import {
  DSModal as Modal,
  ModalBody,
  EncapsulatedInput,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  DSButton as Button,
  Flex,
  Icon,
} from 'spekit-ui';

type TProtectedActionBuilder<T> = T & {
  isOpen: boolean;
  onClose: () => void;
  confirmLabel: string;
  description?: string;
  header?: string;
};

type TProtectedActionTypeText = TProtectedActionBuilder<{
  type: 'text';
  action: () => void | Promise<void>;
}>;
type TProtectedActionTypePassword = TProtectedActionBuilder<{
  type: 'password';
  action: (password: string) => void | Promise<void>;
}>;

type TProtectedActionProps = TProtectedActionTypeText | TProtectedActionTypePassword;

const TO_MATCH = 'OVERRIDE';

export const configMapper = {
  password: {
    header: 'Enter password',
    description: 'Please type in your password to authorize this request.',
    input: {
      label: 'Password',
      placeholder: 'Enter password',
    },
    error: {
      required: 'Enter password',
      mismatch: '',
    },
  },
  text: {
    header: 'Enter confirmation text',
    description: 'Please type in OVERRIDE exactly to confirm this action.',
    input: {
      label: 'Enter OVERRIDE to confirm',
      placeholder: `Enter ${TO_MATCH}`,
    },
    error: {
      required: `Enter ${TO_MATCH}`,
      mismatch: `Please enter ${TO_MATCH} exactly to confirm`,
    },
  },
};

export const ProtectedAction = (props: TProtectedActionProps) => {
  const {isOpen, onClose, confirmLabel, type, header, description} = props;
  const [text, setText] = useState('');
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const config = configMapper[type];

  const handleClose = () => {
    onClose();
    setError(undefined);
    setText('');
  };
  const verify = () => {
    let error;
    if (!text) error = config.error.required;
    else if (type === 'text' && text !== TO_MATCH) error = config.error.mismatch;
    if (error) throw new Error(error);
  };
  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      verify();
      if (props.type === 'password') await props.action(text);
      else await props.action();
      handleClose();
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='sm'>
      <ModalHeader>{header || config.header}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>{description || config.description}</ModalBody>
      <EncapsulatedInput
        value={text}
        onChange={(value) => setText(value)}
        type={type}
        {...config.input}
        isRequired={true}
        errorMessage={error}
        isInvalid={!!error}
        inputControl={
          !!error && <Icon as={RiInformationLine} h='18px' w='18px' color='error.600' />
        }
        testId='protected-input'
      />
      <ModalFooter>
        <Flex gap={6}>
          <Button size='medium' variant='ghost' colorScheme='white' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            size='medium'
            variant='contained'
            colorScheme='primary'
            data-testid='confirm-btn'
            isLoading={isLoading}
            onClick={handleConfirm}
          >
            {confirmLabel}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};
