import {IDrive, IStoreParams} from 'spekit-types';
import {fileMimeTypes, mimeTypeGroups, TIntegrationKeys} from './constants';
import {v4 as uuidv4} from 'uuid';

const parseId = (id: string) => {
  try {
    return JSON.parse(id);
  } catch (e) {
    return null;
  }
};

const buildQuery = (parentId: string) => {
  const mimeTypes = [
    fileMimeTypes.folder,
    fileMimeTypes.pdf,

    // arrays of mimetypes
    ...mimeTypeGroups.slides,
    ...mimeTypeGroups.docs,
    ...mimeTypeGroups.sheets,
  ]
    .map((mimeType) => `mimeType='${mimeType}'`)
    .join(' or ');

  return `(${mimeTypes}) and '${parentId}' in parents and trashed = false`.trim();
};

export const getParams = (
  store: TIntegrationKeys,
  drive: IDrive,
  parentId?: string
): IStoreParams => {
  if (store === 'gdrive') {
    const q = buildQuery(parentId || drive.id);
    return {
      q,
      corpora: 'drive',
      driveId: drive.id,
    };
  }

  let folderId = 'root';

  if (parentId) {
    folderId = parseId(parentId).fileId;
  }

  return {
    siteId: 'root',
    driveId: drive.id,
    folderId,
    recursive: false,
  };
};

export const connectionEventTracker = (
  connectionKey: string,
  eventScreen: string,
  track: (eventName: string, data?: Record<string, any>) => void
) => {
  const eventName = 'Content Store Connection';
  const eventId = uuidv4();
  const trackingData = {
    connectionKey,
    eventId,
    eventStatus: 'init',
    eventError: '',
    eventDescription: '',
    eventScreen,
  };

  const trackEvent = (status: string, error: string, description: string) => {
    const d = {
      ...trackingData,
      eventStatus: status,
      eventError: error,
      eventDescription: description,
    };
    track(eventName, d);
  };
  return trackEvent;
};
