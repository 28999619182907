import React from 'react';
import {forwardRef} from '@chakra-ui/react';
import {useController, UseControllerProps} from 'react-hook-form';
import {AsyncSelect, Select} from 'chakra-react-select';
import {
  TReactSelectProps,
  TReactSelectAsyncProps,
  chakraStyles,
  components,
} from '../Select';
import {FormControl, FormLabel, FormErrorMessage, FormHelperText} from '../../Form';
import {Box} from '../../Layout';
import {Tooltip} from '../../Tooltip';
import {RiInformationLine} from 'react-icons/ri';
import {ERROR_MESSAGE_TEST_ID} from 'spekit-datalayer';

interface IControlledSelectCustomProps {
  id: string;
  label: string | React.ReactNode;
  helperText?: string;
  isAsync?: boolean;
  haveFixedOptions?: boolean;
  toolTipForFixedOptions?: string;
  labelTooltip?: string;
  isRequired?: boolean;
  testId?: string;
  menuTestId?: string;
}

type TControlledSelectProps = UseControllerProps &
  IControlledSelectCustomProps &
  (TReactSelectProps | TReactSelectAsyncProps);

/**
 * This Select should always be used with React hook form
 * Leverages useController to control the state of this Select
 */
export const ControlledSelect = forwardRef(
  (
    {
      control,
      name,
      id,
      label,
      rules,
      isAsync = false,
      haveFixedOptions = false,
      toolTipForFixedOptions = '', // tooltip label for fixed values
      helperText = '',
      labelTooltip,
      isRequired = false,
      testId = '',
      menuTestId = '', // data-testid for menu container
      ...props
    }: TControlledSelectProps,
    ref
  ) => {
    const {
      field: {onChange, onBlur, value},
      fieldState: {invalid, error},
    } = useController({
      name,
      control,
      rules,
    });

    const selectProps = {name, onChange, onBlur, value, ...props};

    const customOnChange = (newValue: any, actionMeta: any) => {
      switch (actionMeta.action) {
        case 'remove-value':
        case 'pop-value':
          if (actionMeta.removedValue.isFixed) {
            return;
          }
          break;
        case 'clear':
          newValue = value.filter((v: any) => v.isFixed);
          break;
      }
      onChange(newValue);
    };

    return (
      <FormControl isInvalid={invalid} id={id} isRequired={isRequired}>
        <Box display={'flex'} alignItems={'center'}>
          <FormLabel data-testid={testId} me={1}>
            {label}
          </FormLabel>
          {labelTooltip && (
            <Tooltip label={labelTooltip} placement='top'>
              <Box
                as={'span'}
                display={'flex'}
                alignItems={'center'}
                data-testid='label-tooltip'
              >
                <RiInformationLine fontSize={'1rem'} />
              </Box>
            </Tooltip>
          )}
        </Box>
        {isAsync ? (
          haveFixedOptions ? (
            <div>
              <AsyncSelect
                chakraStyles={chakraStyles}
                components={components(toolTipForFixedOptions, menuTestId)}
                id='select-container'
                {...selectProps}
                onChange={customOnChange}
                closeMenuOnSelect={!props.isMulti}
                blurInputOnSelect={!props.isMulti}
              />
            </div>
          ) : (
            <div>
              <AsyncSelect
                chakraStyles={chakraStyles}
                components={components(toolTipForFixedOptions, menuTestId)}
                id='select-container'
                {...selectProps}
                closeMenuOnSelect={!props.isMulti}
                blurInputOnSelect={!props.isMulti}
              />
            </div>
          )
        ) : (
          <div>
            <Select
              chakraStyles={chakraStyles}
              components={components(toolTipForFixedOptions, menuTestId)}
              id='select-container'
              {...selectProps}
              closeMenuOnSelect={!props.isMulti}
              blurInputOnSelect={!props.isMulti}
            />
          </div>
        )}
        {invalid && (
          <FormErrorMessage data-testid={ERROR_MESSAGE_TEST_ID}>
            {error && error.message}
          </FormErrorMessage>
        )}{' '}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

export default ControlledSelect;
