import {
  CreateTeamRequest,
  CreateTeamResponse,
  DeleteTeamResponse,
  CloneTeamResponse,
  TeamsListResponseType,
  TeamDetail,
  InviteMenuResponse,
  PendingInvitesRequest,
  PendingInvitesResponse,
  InviteSFUsersRequest,
  InviteSFUsersResponse,
  getTeamListBySuggestionsOptions,
  removeUsersResponse,
  Query,
  TeamsTopic,
  BulkUserInvite,
  CloneTeamApiError,
  GenericUsersCountsApiError,
  TeamDetailApiError,
  TeamsApiError,
  TeamsListApiError,
} from './team.types';
import checkTaskStatus from '../utils/taskStatus';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {stringifyQs} from '../utils/commonUtils';

export const team = {
  post: function (body: CreateTeamRequest): Promise<CreateTeamResponse> {
    return new Promise((res, rej) => {
      fetch('/api/teams/', {
        credentials: 'include',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((r) => r.json())
        .then((result) => {
          if (result.hasOwnProperty('success') && !result.success) {
            throw new TeamsApiError(result.message);
          } else {
            let response: CreateTeamResponse = result;
            res(response);
          }
        })
        .catch((e) => rej(e));
    });
  },
  put: function (
    teamId: string | undefined,
    body: CreateTeamRequest
  ): Promise<CreateTeamResponse> {
    return new Promise((res, rej) => {
      fetch(`/api/teams/${teamId}/`, {
        credentials: 'include',
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((r) => r.json())
        .then((result) => {
          if (result.hasOwnProperty('success') && !result.success) {
            throw new TeamsApiError(result.message);
          } else {
            let response: CreateTeamResponse = result;
            res(response);
          }
        })
        .catch((e) => rej(e));
    });
  },
  deleteTeam: function (teamId: string | undefined): Promise<DeleteTeamResponse> {
    return new Promise((res, rej) => {
      fetch(`/api/teams/${teamId}/`, {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((r) => r.json())
        .then((result) => {
          if (!result) {
            throw new TeamsApiError('unexpected response from teams deletion api');
          } else {
            let response: DeleteTeamResponse = result;
            res(response);
          }
        })
        .catch((e) => rej(e));
    });
  },
  getTopicsId: function (teamId: string | undefined): Promise<Array<TeamsTopic>> {
    return new Promise((res, rej) => {
      fetch(`/api/teams/${teamId}/topics`, {
        credentials: 'include',
      })
        .then((r) => r.json())
        .then((result) => {
          if (!result) {
            throw new TeamsApiError('unexpected response from teams deletion api');
          } else {
            let response: Array<TeamsTopic> = result;
            res(response);
          }
        })
        .catch((e) => rej(e));
    });
  },
  clone: function (teamId: string, teamName: string): Promise<CloneTeamResponse> {
    return new Promise((res, rej) => {
      fetch(`/api/teams/${teamId}/clone/`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: teamName,
        }),
      })
        .then((r) => r.json())
        .then((result) => {
          if (!result) {
            throw new CloneTeamApiError('unexpected response from clone team api');
          } else {
            let response: CloneTeamResponse = result;
            res(response);
          }
        })
        .catch((e) => rej(e));
    });
  },
  // BUG?  It makes no sense for teamId to be optional here.  It's part of the URL!
  addUsers: (userIds: Array<string> | string, group: number, teamId?: string | null) => {
    if (!Array.isArray(userIds)) {
      userIds = [userIds];
    }
    const body = {
      users: userIds.map((user_id) => ({
        user_id,
        group_id: group,
      })),
    };
    return new Promise((res, rej) => {
      fetch(`/api/teams/${teamId}/add_users/`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((r) => r.json())
        .then((result) => {
          if (!result) {
            throw new TeamsApiError('unexpected response from teams creation api');
          } else {
            res(result);
          }
        })
        .catch((e) => rej(e));
    });
  },
  removeUsers: (userIds: Array<string> | string, teamId: string) => {
    if (!Array.isArray(userIds)) {
      userIds = [userIds];
    }
    const body = {
      users: userIds.map((user_id) => ({user_id})),
    };
    return new Promise((res, rej) => {
      let resultG: removeUsersResponse | null = null;
      fetch(`/api/teams/${teamId}/remove_users/`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then((r) => r.json())
        .then((result) => {
          if (result.hasOwnProperty('success') && !result.success) {
            throw new TeamsApiError(result.message);
          } else {
            if (!!result.message_id) {
              resultG = result;
              return checkTaskStatus(result.message_id);
            } else if (result.removed_users_count === 0) {
              throw new TeamsApiError('No users have been removed from this team.');
            } else {
              throw new TeamsApiError('no message id received');
            }
          }
        })
        .then(() => {
          res({
            removed_users_count: resultG ? resultG.removed_users_count : 0,
          });
        })
        .catch((e) => rej(e));
    });
  },
  /**
   * Gives the teams list depending on the query provided.
   * If passing in the 'q' parameter it will return the results
   * depending on the on 'sentence' provided
   *
   * @param {object} query - Query parameters to pass in the API
   * @return {TeamsListResponseType} Provides details for every teams,
   * total count, next page url, previous page url
   *
   */
  getTeamList: function (query?: object): Promise<TeamsListResponseType> {
    let querystring = stringifyQs(query);
    return fetch('/api/teams/?' + querystring, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((r) => r.json())
      .then((result) => {
        if (!result) {
          throw new TeamsApiError('unexpected response from user api');
        } else if (result.hasOwnProperty('success') && !result.success) {
          throw new TeamsApiError(result.message ?? 'failed to retrieve team list');
        } else {
          let response: TeamsListResponseType = result;
          return response;
        }
      });
  },

  /**
   * Retrieves team details based on uuid.
   *
   * @param {string} uuid
   * @returns {Promise<TeamDetail>}
   */
  fetchTeamDetails: (uuid: string): Promise<TeamDetail> => {
    return new Promise((res, rej) => {
      fetch('/api/teams/' + uuid, {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result) {
            throw new TeamDetailApiError('unexpected response from team detail api');
          } else {
            res(result);
          }
        })
        .catch((e) => rej(e));
    });
  },

  /**
   * Gives the list of teams which have access to all topics.
   *
   * @return {TeamsListResponseType} Provides details for every teams,
   * total count, next page url, previous page url
   *
   */
  getTeamsWithAllTopicsAccess: function (): Promise<TeamsListResponseType> {
    return this.getTeamList({access_all_topics: true});
  },

  /**
   * Gives the teams list depending on the query provided
   * If passing in the 'q' parameter it will return the results
   * depending on the on 'character' provided
   *
   * @param {Query} query - Query parameters to pass in the API
   * @return {Array<TeamDetail>} List of teams
   *
   */
  getTeamListBySuggestions: function (
    query?: Query,
    options?: getTeamListBySuggestionsOptions
  ): Promise<Array<TeamDetail>> {
    if (options && !!options.assignableOnly) {
      if (!query) {
        query = {q: '', ordering: '', permissions: ''};
      }
      query.permissions = ['tags.add_own', 'tags.update_own', 'tags.delete_own'].join(
        ','
      );
    }
    let querystring = stringifyQs(query);
    return new Promise((res, rej) => {
      fetch('/api/v1/lookups/teams?' + querystring, {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((r) => r.json())
        .then((result) => {
          if (!result) {
            throw new TeamsListApiError('unexpected response from user api');
          } else {
            let response: Array<TeamDetail> = result.results;
            res(response);
          }
        })
        .catch((e) => rej(e));
    });
  },
  getAssignableTeamSuggestions: function (search: string) {
    return this.getTeamListBySuggestions(
      {
        q: search,
        ordering: 'name',
        permissions: 'teams.update_own',
      },
      {
        sortAlphabetically: true,
        assignableOnly: true,
      }
    );
  },
  getCount: function (): Promise<InviteMenuResponse> {
    return new Promise((res, rej) => {
      fetch('/api/users/count/', {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((r) => r.json())
        .then((result) => {
          if (!result) {
            throw new GenericUsersCountsApiError('unexpected response from user api');
          } else {
            let response: InviteMenuResponse = result;
            res(response);
          }
        })
        .catch((e) => rej(e));
    });
  },
  resendBulkInvite: function (
    payload: PendingInvitesRequest
  ): Promise<PendingInvitesResponse> {
    return new Promise((res, rej) => {
      fetch('/api/users/bulk_resend_invite/', {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      })
        .then((r) => r.json())
        .then((result) => {
          if (!result) {
            throw new TeamsListApiError('unexpected response from user api');
          } else {
            let response: PendingInvitesResponse = result;
            res(response);
          }
        })
        .catch((e) => rej(e));
    });
  },
  cancelBulkInvite: function (
    payload: PendingInvitesRequest
  ): Promise<PendingInvitesResponse> {
    return new Promise((res, rej) => {
      fetch('/api/users/bulk_cancel_invite/', {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
        body: JSON.stringify(payload),
      })
        .then((r) => r.json())
        .then((result) => {
          if (!result) {
            throw new TeamsListApiError('unexpected response from user api');
          } else {
            let response: PendingInvitesResponse = result;
            res(response);
          }
        })
        .catch((e) => rej(e));
    });
  },
  inviteUsers: function (payload: InviteSFUsersRequest): Promise<InviteSFUsersResponse> {
    return new Promise((res, rej) => {
      fetch('/api/users/invite_users', {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      })
        .then((r) => r.json())
        .then((result) => {
          if (result.hasOwnProperty('success') && !result.success) {
            throw new TeamsListApiError(result.message);
          } else {
            let response: InviteSFUsersResponse = result;
            res(response);
          }
        })
        .catch((e) => rej(e));
    });
  },
  bulkEmailInvite: function (payload: any): Promise<any> {
    return new Promise((res, rej) => {
      fetch('/api/users/bulk_invite', {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      })
        .then((r) => r.json())
        .then((result: BulkUserInvite) => {
          if (!result.success) {
            throw new TeamsListApiError(
              result?.message ?? 'unexpected response from user api'
            );
          } else {
            let response: any = result;
            res(response);
          }
        })
        .catch((e) => rej(e));
    });
  },
};
