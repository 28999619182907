import Ably from 'ably/build/ably-webworker.min';
import {fetchMiddleWare as fetch} from './fetchMiddleWare';

interface ITokenDetails {
  keyName: string;
  clientId: string;
  ttl: number;
  nonce: string;
  capability: string;
  timestamp: number;
  mac: string;
}

const getAblyTokenRequest = async () => {
  const res = await fetch('/api/ably/auth');
  const responseData = <ITokenDetails>await res.json();
  return responseData;
};

export const ablyAuth = () => {
  const ably = new Ably.Realtime({
    authCallback: async (tokenParams, callback) => {
      const tokenDetails = await getAblyTokenRequest();
      callback(null, tokenDetails);
    },
  });

  return ably;
};

// Clean up Ably Realtime instance on component unmount
export const stopAbly = (realtimeAbly: Ably.Realtime | null, channels: string[]) => {
  if (realtimeAbly !== null) {
    if (channels) {
      channels.forEach((channel) => {
        const channelObj = realtimeAbly.channels.get(channel);
        channelObj.unsubscribe();
      });
    }
    realtimeAbly.close();
  }
};

export const getAblyChannels = (realtimeAbly: Ably.Realtime, channels: string[]) => {
  const channelobjs = channels.map((name) =>
    realtimeAbly.channels.get(name, {
      params: {rewind: '2m'},
    })
  );

  return channelobjs;
};

export const getAblyChannel = (realtimeAbly: Ably.Realtime, channel: string) => {
  const channelobj = realtimeAbly.channels.get(channel, {
    params: {rewind: '2m'},
  });
  return channelobj;
};
