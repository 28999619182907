import React, {useState, useRef} from 'react';

import {faImage, faVideo, faEllipsisH} from '@fortawesome/free-solid-svg-icons';

import {multimedia} from 'spekit-datalayer';

import Button from '../../button';
import styles from './multimediaActions.module.css';
import useOutsideClick from '../../hooks/useOutsideClick';
import Fontawesome from '../../fontawesome';

import notifications from '../../notifications';
import {normalizeUrl} from '../../utils/url';
import {getLibraryItems} from '../../utils/imagesGallery';

const {notify} = notifications;

interface Props {
  handleEmbed: (isOpen: boolean) => void;
  handleLibrary: (isOpen: boolean) => void;
  embedPopover: boolean;
  spekitLibrary: boolean;
  editMode?: boolean;
  handleImageLoading: (isLoading: boolean) => void;
  handleImage: (image: string) => void;
  handleEmbedVideo: (href: string) => void;
  handleDeletePreview: () => void;
  handleSystemImage: (payload: string) => void;
  track: any;
  screenName: string;
  notify: any;
}

const {uploadFile} = multimedia;

const MultimediaActions: React.FC<Props> = ({
  editMode,
  embedPopover,
  handleEmbed,
  spekitLibrary,
  handleLibrary,
  handleImageLoading,
  handleImage,
  handleEmbedVideo,
  handleDeletePreview,
  handleSystemImage,
  track,
  screenName,
}) => {
  const [embedValue, setEmbedValue] = useState<string>('');
  const [menuPopover, setMenuPopover] = useState<boolean>(false);

  const ref: any = useRef();
  const libraryRef: any = useRef();
  const menuPopoverRef: any = useRef();
  const addedOrEdited = editMode ? 'edited' : 'added';

  useOutsideClick(ref, () => {
    if (ref.current && embedPopover) {
      handleEmbed(false);
    }
  });

  useOutsideClick(libraryRef, () => {
    if (libraryRef.current && spekitLibrary) {
      handleLibrary(false);
    }
  });

  useOutsideClick(menuPopoverRef, () => {
    if (menuPopoverRef.current && menuPopover) {
      setMenuPopover(false);
    }
  });

  const previewFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let extensionsToCheck = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'tif', 'tiff', 'svg'];
    if (e.target.files) {
      try {
        let fileName = e.target.files[0].name;
        let fileExtension = fileName.split('.').pop();
        let fileAllowedToUpload = extensionsToCheck.some((extension) =>
          fileExtension?.toLowerCase().includes(extension)
        );
        if (!fileAllowedToUpload) {
          throw new Error('Wrong File Type');
        }
        setMenuPopover(false);
        handleImageLoading(true);
        let response = await uploadFile(e.target.files[0]);
        handleImage(response.url.view);
        track(`Image ${addedOrEdited}`, {
          screen_name: screenName,
        });
      } catch (err) {
        handleImageLoading(false);
        if (err.message === '403') {
          notify({
            error: true,
            text: 'You’ve reached the media hosting limit for your Spekit account. Please contact us to upgrade.',
          });
          return;
        } else if (err.message === '413') {
          notify({
            error: true,
            text: 'The file is too big. Please make sure file size is less than 20 MB.',
          });
          return;
        } else if (err.message === 'false') {
          notify({
            error: true,
            text: 'There was an error uploading the file. Please try again.',
          });
        } else if (err.message === 'Invalid format of file') {
          notify({
            error: true,
            text: 'File format not supported.',
          });
        } else if (err.message === 'Wrong File Type') {
          notify({
            error: true,
            text: 'You can only upload images',
          });
        }
      }
    }
  };

  const handlePopoverOpen = (e: any) => {
    e.stopPropagation();
    handleEmbed(true);
    setMenuPopover(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEmbedValue(e.target.value);
  };

  const handleEmbedClick = (e: any) => {
    e.stopPropagation();
    if (embedValue) {
      try {
        let container = document.createElement('html');
        container.innerHTML = embedValue;

        let videoUrl = (container.querySelector('iframe') as any).getAttribute('src');
        videoUrl = new URL(normalizeUrl(videoUrl));
        let videoHref = videoUrl.href;

        handleEmbedVideo(videoHref);
        track(`Embeded Video ${addedOrEdited}`, {
          screen_name: screenName,
        });
      } catch (err) {
        notify({
          error: true,
          text: 'Please enter a valid embedding provider or a valid iframe.',
        });
      }
    }
    handleEmbed(false);
  };
  const menuHandler = (e: any) => {
    e.stopPropagation();
    setMenuPopover(true);
  };

  const handleLibraryOpen = (e: any) => {
    e.stopPropagation();
    handleLibrary(true);
    setMenuPopover(false);
  };

  const deleteHandler = (e: any) => {
    e.stopPropagation();
    setMenuPopover(false);
    handleDeletePreview();
    notify({text: 'Image removed successfully.'});
  };

  const handleMenuPopoverClose = (e: any) => {
    setMenuPopover(false);
  };

  const selectGraphic = (payload: string) => {
    handleSystemImage(payload);
    track(`Graphic from spekit library ${addedOrEdited}`, {
      screen_name: screenName,
    });
  };
  return (
    <div className={styles.container}>
      <label className={styles.item}>
        <Fontawesome name={faImage} className={styles.icon} />
        <input type='file' onChange={previewFile} className={styles.input} />
      </label>
      <div className={styles.item} onClick={handlePopoverOpen} ref={ref}>
        <Fontawesome name={faVideo} className={styles.icon} />
        {embedPopover ? (
          <div className={styles.popoverContainer}>
            <textarea
              className={styles.popover}
              value={embedValue}
              onChange={handleChange}
              placeholder={`Paste your video embed code and press enter\n<iframe width="" height="" src="" allowfullscreen></iframe>`}
            />
            <div className={styles.embedButtonContainer}>
              <Button className={styles.embedButton} onClick={handleEmbedClick}>
                Add Video
              </Button>
            </div>
          </div>
        ) : null}
      </div>

      <div className={styles.item} onClick={menuHandler}>
        <Fontawesome name={faEllipsisH} className={styles.icon} />
        {menuPopover && (
          <div className={styles.menuPopover} ref={menuPopoverRef}>
            <span onClick={handleMenuPopoverClose}>
              <label>
                <div className={styles.uploadItem}>Upload Image</div>
                <input type='file' onChange={previewFile} className={styles.input} />
              </label>
            </span>
            <span onClick={handlePopoverOpen}>Embed Video</span>
            <span onClick={handleLibraryOpen}>Select from Spekit library</span>
            <span onClick={deleteHandler}>Remove</span>
          </div>
        )}
      </div>
      {spekitLibrary ? (
        <div className={styles.libraryContainer} ref={libraryRef}>
          <div className={styles.graphicText}>Choose Graphic</div>
          <div className={styles.libraryGrid}>
            {getLibraryItems().map((libraryItem) => (
              <img
                key={libraryItem.text}
                src={libraryItem.src}
                alt='graphic-item'
                className={styles.gridItem}
                onClick={() => selectGraphic(libraryItem.text)}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MultimediaActions;
