const initialState = {
  toggle: false,
  term: null,
};
function topicModalReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOWTOPICMODAL':
      return {
        ...state,
        toggle: true,
        term: action.term,
      };
    case 'CLOSETOPICMODAL':
      return {
        ...state,
        toggle: false,
      };
    default:
      return state;
  }
}

export default topicModalReducer;
