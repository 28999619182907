import {useState, useEffect} from 'react';

const timeAsNumber = () => window.Date.now();

export const activeTime = (stamps: number[]): number => {
  const _isOdd = (n: number) => n % 2;
  if (_isOdd(stamps.length)) {
    stamps.push(timeAsNumber());
  }
  const time = stamps.reduce((viewTime, ts, n) => {
    if (_isOdd(n)) {
      return viewTime + ts;
    } else {
      return viewTime - ts;
    }
  }, 0);
  return time / 1000;
};

export const useWindowActiveTimer = function () {
  const [timeStamps, setTimeStamps] = useState<number[]>([timeAsNumber()]);

  const pushTimeStamp = () => {
    setTimeStamps([...timeStamps, timeAsNumber()]);
  };

  useEffect(() => {
    window.document.addEventListener('visibilitychange', pushTimeStamp, false);
    return () => {
      window.document.removeEventListener('visibilitychange', pushTimeStamp, false);
    };
  }, [timeStamps]);

  return {
    getActiveTime: () => {
      return activeTime(timeStamps);
    },
  };
};
