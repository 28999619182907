import React from 'react';
import {SpotlightsProps} from './audienceTeamSelector.types';
import {
  AsyncSelect,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from 'spekit-ui';
import debounce from 'debounce-promise';
import {getTeams} from './audienceUtils';

const SpotlightAudienceTeamSelector = (props: SpotlightsProps) => {
  const {value, onSelect, isDisabled, state, dispatch, handleDataChange} = props;

  const handleChange = (newValue: any, actionMeta: any) => {
    let teams = value ? [...value] : [];
    let {action, option, removedValue} = actionMeta;

    if (action === 'clear') {
      teams = [];
    }

    if (action === 'select-option') {
      teams.push({value: option.value, label: option.label, topics: option.topics});
    }

    if (action === 'remove-value' || action === 'pop-value') {
      teams = teams.filter((team) => team.value !== removedValue.value);
    }

    onSelect(value);
    dispatch({type: 'SET_TEAMS', payload: teams});
  };

  const getDebouncedTeams = debounce(getTeams, 200, {leading: false});
  return (
    <Flex flexDirection='column'>
      <Box>
        <FormControl isInvalid={state.teamError} isRequired data-testid='select-teams'>
          <FormLabel>Teams</FormLabel>
          <AsyncSelect
            value={value}
            loadOptions={getDebouncedTeams}
            onChange={handleDataChange ? handleDataChange : handleChange}
            defaultOptions
            cacheOptions
            placeholder='Select Teams'
            noOptionsMessage={() => `No teams found.`}
            isClearable={true}
            blurInputOnSelect
            isDisabled={isDisabled}
            isMulti={true}
          />
          {state.teamError && (
            <FormErrorMessage data-testid='team-error-message'>
              Select at least one team.
            </FormErrorMessage>
          )}
        </FormControl>
      </Box>
    </Flex>
  );
};

export default SpotlightAudienceTeamSelector;
