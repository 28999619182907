import {BRAND_LOGO_DIMENSIONS, ICON_LOGO_DIMENSIONS} from 'spekit-datalayer';
import SpekitLogo from '../../../../images/spekit-nav-logo.svg';
import SpekitLogoMin from '../../../../images/spek-icon.svg';
import WhiteSpekitLogo from '../../../../images/white-logo.svg';
import WhiteSpekitLogoMin from '../../../../images/white-logo-min.svg';

export const SUPPORTED_FORMATS = ['image/png', 'image/jpeg', 'image/svg+xml'];
export const DEFAULTS = {
  brandLogo: SpekitLogo,
  iconLogo: SpekitLogoMin,
  whiteBrandLogo: WhiteSpekitLogo,
  whiteIconLogo: WhiteSpekitLogoMin,
  primaryColor: '#653FE6',
};
export const setValueOptions = {
  shouldValidate: true,
  shouldDirty: true,
};
export const TEST_IDS = {
  logoType: {
    imagePreview: 'logo-type-preview-image',
    delete: 'logo-type-delete-btn',
    error: 'logo-type-error',
    upload: 'logo-type-upload-btn',
  },
  logoMark: {
    imagePreview: 'logo-mark-preview-image',
    delete: 'logo-mark-delete-btn',
    error: 'logo-mark-error',
    upload: 'logo-mark-upload-btn',
  },
  colorInput: 'color-input',
  whiteLogoType: {
    imagePreview: 'white-logo-type-preview-image',
    delete: 'white-logo-type-delete-btn',
    error: 'white-logo-type-error',
    upload: 'white-logo-type-upload-btn',
  },
  whiteLogoMark: {
    imagePreview: 'white-logo-mark-preview-image',
    delete: 'white-logo-mark-delete-btn',
    error: 'white-logo-mark-error',
    upload: 'white-logo-mark-upload-btn',
  },
  reset: {
    resetToDefault: 'reset-to-default-btn',
    cancel: 'reset-cancel-btn',
    confirm: 'reset-confirm-btn',
  },
  save: 'save-btn',
};
export const ERROR_MESSAGES = {
  brandImage: 'Logotype dimensions must be 130x40px maximum',
  iconImage: 'Logomark dimensions must be 30x30px maximum',
  hexColor: 'Enter valid hex code (#000000)',
  supportedFormat: 'Only PNG, JPG, and SVG files are allowed',
} as const;

export const LOGO_THEME_CONFIG = {
  light: {
    borderColor: 'neutral.200',
    background: 'neutral.0',
  },
  dark: {
    borderColor: 'neutral.800',
    background: 'neutral.800',
  },
} as const;

export const LOGO_PREVIEW = {
  Logotype: {
    previewContainer: {h: '65px', w: '151px'},
    previewImageProps: BRAND_LOGO_DIMENSIONS,
    previewDefaultSize: {h: '27.4px', w: '96px'},
  },
  Logomark: {
    previewContainer: {h: '65px', w: '73px'},
    previewImageProps: ICON_LOGO_DIMENSIONS,
    previewDefaultSize: {h: '35px', w: '33.2px'},
  },
} as const;
