import React from 'react';
import styles from './confirmation.module.css';
import Button from '../button';
import Fontawesome from '../fontawesome';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

export interface Props {
  heading: string;
  subHeading: string;
  firstOptionHandler: (e: React.MouseEvent) => void;
  secondOptionHandler: (e: React.MouseEvent) => void;
  firstOptionText?: string;
  secondOptionText?: string;
  cancelAndKeepHandler?: (e: React.MouseEvent) => void;
  cancelAndKeepText?: string;
}

const Confirmation: React.FC<Props> = ({
  heading,
  subHeading,
  firstOptionHandler,
  secondOptionHandler,
  firstOptionText = 'Ok',
  secondOptionText = 'Cancel',
  cancelAndKeepText,
  cancelAndKeepHandler,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <Fontawesome name={faExclamationTriangle} className={styles.icon} />
        <span>{heading}</span>
      </div>
      <div className={styles.subHeading}>{subHeading}</div>
      <div>
        <Button className={styles.primaryButton} onClick={firstOptionHandler}>
          {firstOptionText}
        </Button>
        <Button
          secondary
          className={styles.secondaryButton}
          onClick={secondOptionHandler}
        >
          {secondOptionText}
        </Button>
      </div>
      {cancelAndKeepText && cancelAndKeepHandler && (
        <div className={styles.cancel} onClick={cancelAndKeepHandler}>
          {cancelAndKeepText}
        </div>
      )}
    </div>
  );
};

export default Confirmation;
