import React from 'react';
import {Box, Loader, Text} from 'spekit-ui';
import {AspectRatio} from '@chakra-ui/react';

export interface IFramerOptions {
  aspectRatio?: number;
}

export interface IFramerProps {
  src: string;
  options: IFramerOptions;
}

export const defaultAspectRatio = 4 / 5;
export const videoAspectRatio = 16 / 9;
export const videoProviders = [
  'youtube.com',
  'youtube-nocookie.com',
  'drive.google.com/file',
  'vidyard.com',
  'loom.com',
  'vimeo.com',
  'spotify.com',
  'docs.google.com/spreadsheets',
  'docs.google.com/presentation',
  'wistia.com',
  'wistia.net',
];

export const getAspectRatio = (src: string, options: IFramerOptions) => {
  // if aspect ratio is defined, use it
  if (options.aspectRatio) {
    return options.aspectRatio;
  }

  // if src is in videoProviders, use 16:9
  if (videoProviders.some((provider) => src.includes(provider))) {
    return videoAspectRatio;
  }

  // otherwise, use 4:5
  return defaultAspectRatio;
};

// display iFrames at 16:9 ratio with a loader
export const IFramer = ({src, options}: IFramerProps) => {
  const [loading, setLoading] = React.useState(true);

  return (
    <AspectRatio
      position={'relative'}
      width='100%'
      maxWidth={'800px'}
      margin='20px auto'
      ratio={getAspectRatio(src, options)}
    >
      {/* aspect ration wants one child. wrap with box to support loader and controls */}
      <Box height={'100%'} width='100%' position='relative'>
        {/* iframes can look messy when loading. this loader is a nice touch and will fade out slowly to ease the transition */}
        <Box
          backgroundColor={'white'}
          opacity={loading ? 1 : 0}
          pointerEvents={loading ? 'all' : 'none'}
          transition={'opacity 1000ms cubic-bezier(1,0,.5,1)'}
          display='flex'
          top={0}
          left={0}
          position='absolute'
          flexDir={'column'}
          justifyContent='center'
          alignItems='center'
          height='100%'
          width='100%'
        >
          {loading && <Loader variant='pulse' size={14} />}
          {loading && <Text>Loading Embedded Content</Text>}
        </Box>
        <iframe
          onLoad={() => {
            setLoading(false);
          }}
          title={src}
          src={src}
          style={{width: '100%', height: '100%', border: 'none', boxSizing: 'border-box'}}
          allow='autoplay; encrypted-media; fullscreen; picture-in-picture'
          allowFullScreen={true}
          loading='lazy'
        ></iframe>
      </Box>
    </AspectRatio>
  );
};
