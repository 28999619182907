import {INotificationState, IExtendedNotificationState} from 'spekit-types';

export const safeNotificationProperties = ({
  createSpotlight = false,
  message = '',
  notifyByEmail = false,
  notifyInApp = false,
  teams = [],
}: Partial<INotificationState> = {}) => {
  return {
    notifyByEmail,
    notifyInApp,
    sendNotification: notifyByEmail || notifyInApp,
    teams,
    message,
    createSpotlight,
  };
};

export const prepareNotificationPayload = (notification: IExtendedNotificationState) => {
  const {notifyByEmail, notifyInApp, sendNotification, message} = notification;
  if (!sendNotification) return undefined;
  return {
    in_app_notification: notifyInApp,
    email: notifyByEmail,
    message,
  };
};
