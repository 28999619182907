/*
INTEGRATIONS CONTEXT:
The integrations state object holds the necessary values for the child components to be hydrated with data without having to perform their own API requests.
In the web application, the context is in App.js. In the Chrome extension, the context is located in the layout file.
*/
import * as React from 'react';
import {integrations, utils} from 'spekit-datalayer';
import host from '../endpoint';
import {IntegrationsContextProviderProps} from './IntegrationsContext.types';

const IntegrationsContext = React.createContext<any | undefined>(undefined!);

const useIntegrationsContext = () => {
  const integrationsContext = React.useContext(IntegrationsContext);
  if (integrationsContext === undefined) {
    throw new Error('useIntegrationsContext was accessed outside of Provider');
  }

  return integrationsContext;
};

const IntegrationsContextProvider: React.FC<IntegrationsContextProviderProps> = ({
  children,
}) => {
  // Each integration should have it's own state object within the integrationsState.
  const [integrationsState, setIntegrationsState] = React.useState({
    seismicState: {
      seismicChildren: [],
      seismicError: '',
      seismicStatus: {status: 'NOT_CONNECTED', access_token: '', token_expired_on: ''},
      seismicProcessed: false,
      seismicInProcess: false,
      seismicStateLoaded: false,
      seismicContentCache: {},
      seismicNeedsRefresh: false,
    },
  });

  /******
  SEISMIC
  ******/
  const seismicContentQueue = React.useRef(new Set());
  // Checks for Seismic connection status
  const checkForSeismicConnected = async () => {
    let response: {seismic: {}};
    try {
      response = (await integrations.getSeismicStatus()) as any;
      setIntegrationsState((prevState: any) => ({
        ...prevState,
        seismicState: {...prevState.seismicState, seismicStatus: response.seismic},
      }));
    } catch (err) {
      setIntegrationsState((prevState: any) => ({
        ...prevState,
        seismicState: {...prevState.seismicState, seismicError: err as string},
      }));
    }
  };
  // Handles the connection redirect -- for the web app, it opens the URL sent in the integrations.connectSeismic() response, while the Chrome extension is hard-coded
  const connectSeismicInstance = async () => {
    if (
      integrationsState.seismicState?.seismicStatus?.status !== 'CONNECTED' ||
      !integrationsState.seismicState?.seismicStatus?.access_token ||
      !integrationsState.seismicState?.seismicStatus?.token_expired_on ||
      new Date() >=
        new Date(`${integrationsState.seismicState?.seismicStatus?.token_expired_on} UTC`)
    ) {
      if (utils.getEnvironment() !== 'webapp') {
        window.open(`${host}/seismic/connect?install=true`, '_blank');
        await checkForSeismicConnected();
      } else {
        try {
          const response: Response = (await integrations.connectSeismic()) as Response;
          window.open(response.url, '_blank');
        } catch (err) {
          setIntegrationsState((prevState: any) => ({
            ...prevState,
            seismicState: {...prevState.seismicState, seismicError: err as string},
          }));
        } finally {
          await checkForSeismicConnected();
        }
      }
    }
  };
  // Takes all items from the seismicContentQueue ref and makes a single request to the Seismic API
  const processSeismicData = async () => {
    // copy the current queue
    const idsToFetch = new Set(seismicContentQueue.current);

    try {
      const response = await fetch('https://api.seismic.com/search/v1/content/query', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${integrationsState.seismicState.seismicStatus?.access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          filter: {
            operator: 'and',
            conditions: [
              {
                attribute: 'id',
                operator: 'in',
                value: [...idsToFetch],
              },
            ],
          },
          sort: [{field: 'publishDate', order: 'desc'}],
          options: {
            returnFields: [
              'id',
              'name',
              'type',
              'format',
              'description',
              'modifiedDate',
              'applicationUrls',
            ],
          },
        }),
      });
      const json = await response.json();
      let seismicItems = {};
      json.documents.forEach((doc: any) => {
        // add the seismic content to the cache
        seismicItems[doc.id] = doc;

        // remove the item from the temporary queue
        idsToFetch.delete(doc.id);
      });

      // for any items that were not returned, add them to the cache with a null value
      idsToFetch.forEach((id: any) => {
        seismicItems[id] = null;
      });

      const data = seismicItems;
      setIntegrationsState((prevState: any) => ({
        ...prevState,
        seismicState: {
          ...prevState.seismicState,
          seismicInProcess: false,
          seismicStateLoaded: true,
        },
      }));
      return data;
    } catch (err) {
      setIntegrationsState((prevState: any) => ({
        ...prevState,
        seismicState: {...prevState.seismicState, seismicError: err as string},
      }));
      return null;
    }
  };
  // Fetches the content from the ref that is not in the state cache
  const getIntegratedSeismicContent = async () => {
    const newSeismicItems = await processSeismicData();
    setIntegrationsState((prevState: any) => ({
      ...prevState,
      seismicState: {
        ...prevState.seismicState,
        seismicContentCache: {
          ...prevState.seismicState.seismicContentCache,
          ...newSeismicItems,
        },
      },
    }));
  };
  // Add an item by libraryContentId to the queue
  const appendSeismicChild = (childId: string) => {
    seismicContentQueue.current.add(childId);
    if (
      integrationsState.seismicState.seismicProcessed &&
      !integrationsState.seismicState.seismicInProcess &&
      Object.keys(integrationsState.seismicState.seismicContentCache).length !==
        seismicContentQueue.current.size &&
      Object.keys(integrationsState.seismicState.seismicContentCache).indexOf(childId) ===
        -1
    ) {
      setIntegrationsState((prevState: any) => ({
        ...prevState,
        seismicState: {...prevState.seismicState, seismicNeedsRefresh: true},
      }));
    }
  };
  // Returns the status as a string
  const getSeismicConnectionStatus = () => {
    return integrationsState.seismicState.seismicStatus.status;
  };
  const findSeismicChild = (filter: string) => {
    return integrationsState.seismicState.seismicContentCache[filter];
  };
  // Sets a 3 second interval to test for the validity of the connection.
  const handleSeismic = () => {
    setIntegrationsState((prevState: any) => ({
      ...prevState,
      seismicState: {...prevState.seismicState, seismicInProcess: true},
    }));
    const queueInterval = window.setInterval(async () => {
      const hasValidToken =
        !!integrationsState.seismicState.seismicStatus?.access_token &&
        new Date() <=
          new Date(
            `${integrationsState.seismicState.seismicStatus?.token_expired_on} UTC`
          );
      if (
        seismicContentQueue.current.size > 0 &&
        integrationsState.seismicState.seismicStatus?.status === 'CONNECTED' &&
        hasValidToken
      ) {
        try {
          await getIntegratedSeismicContent();
          setIntegrationsState((prevState: any) => ({
            ...prevState,
            seismicState: {
              ...prevState.seismicState,
              seismicProcessed: true,
            },
          }));
          window.clearInterval(queueInterval);
        } catch (err) {
          setIntegrationsState((prevState: any) => ({
            ...prevState,
            seismicState: {...prevState.seismicState, seismicError: err as string},
          }));
        }
      }
    }, 3000);
  };
  React.useEffect(() => {
    // wait for queue to build up
    if (integrationsState.seismicState.seismicNeedsRefresh) {
      setTimeout(() => {
        handleSeismic();
        setTimeout(() => {
          setIntegrationsState((prevState: any) => ({
            ...prevState,
            seismicState: {
              ...prevState.seismicState,
              seismicNeedsRefresh: false,
            },
          }));
        }, 500);
      }, 500);
    }
  }, [integrationsState.seismicState.seismicNeedsRefresh]);
  React.useEffect(() => {
    if (!integrationsState.seismicState.seismicProcessed) {
      handleSeismic();
    }
  }, [integrationsState.seismicState.seismicStatus]);
  // Check connection status on page load
  React.useEffect(() => {
    const getSeismicStatusResponse = async () => {
      await checkForSeismicConnected();
    };
    if (!(integrationsState.seismicState.seismicStatus?.status === 'CONNECTED')) {
      getSeismicStatusResponse();
    }
  }, []);

  return (
    // Any methods that need to be available to consumers should be included in the value attribute
    <IntegrationsContext.Provider
      value={{
        integrationsState,
        appendSeismicChild,
        findSeismicChild,
        getSeismicConnectionStatus,
        connectSeismicInstance,
        checkForSeismicConnected,
        handleSeismic,
        seismicContentQueue,
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};

export {useIntegrationsContext, IntegrationsContextProvider, IntegrationsContext};
