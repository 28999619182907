import React from 'react';
import {Flex, DSButton as Button, Icon, DSTooltip as Tooltip} from 'spekit-ui';
import {RiDeleteBin6Line} from 'react-icons/ri';
import {ReactComponent as clone} from '../icons/clone.svg';
import SpotlightConfirmationPopup from '../SpotlightConfirmationPopup/SpotlightConfirmationPopup';

interface ISpotlightActionsProps {
  isPublished: boolean;
  readable: boolean;
  editable: boolean;
  isNew: boolean;
  isSaving: boolean;
  unpublish: () => void;
  saveDraft: () => void;
  handleEdit: () => void;
  handleDelete: () => void;
  handleClone: () => void;
  saveAsPublished: () => Promise<void>;
}

const SpotlightActionButtons = (props: ISpotlightActionsProps) => {
  const {
    isPublished,
    unpublish,
    saveDraft,
    readable,
    handleEdit,
    handleDelete,
    editable,
    isNew,
    handleClone,
    isSaving,
    saveAsPublished,
  } = props;

  return (
    <Flex>
      {readable ? (
        <Flex gap={12}>
          <Tooltip label='Clone' shouldWrapChildren placement='bottom'>
            <Button
              colorScheme='transparent'
              variant='icon'
              size='medium'
              aria-label='clone'
              icon={<Icon as={clone} w='16px' h='16px' />}
              onClick={handleClone}
              data-testid='spotlight-clone-button'
            />
          </Tooltip>

          <SpotlightConfirmationPopup
            primaryAction={handleDelete}
            primaryActionText='Yes, delete'
            secondaryActionText='Cancel'
            header='Delete this Spotlight'
            message='Are you sure? Users in your org will no longer have access to this Spotlight and all related data will be lost.'
            icon={RiDeleteBin6Line}
            actionTooltip='Delete'
            primaryButtonTestId='spotlight-delete-button'
          />

          <SpotlightConfirmationPopup
            primaryAction={handleEdit}
            primaryActionText='Yes, edit'
            secondaryActionText='Cancel'
            header='Edit this Spotlight'
            message='Are you sure? If the Spotlight is live, any edits will unpublish and revert it to draft status.'
            primaryButtonTestId='spotlight-edit-button'
            primaryButtonLabel='Edit'
          />
        </Flex>
      ) : (
        <Flex gap={12}>
          {editable && !isNew && (
            <SpotlightConfirmationPopup
              primaryAction={handleDelete}
              primaryActionText='Yes, delete'
              secondaryActionText='Cancel'
              header='Delete this Spotlight'
              message='Are you sure? Users in your org will no longer have access to this Spotlight and all related data will be lost.'
              icon={RiDeleteBin6Line}
              actionTooltip='Delete'
              primaryButtonTestId='spotlight-delete-button'
            />
          )}
          <>
            {isPublished ? (
              <Button
                variant='outlined'
                size='medium'
                onClick={unpublish}
                disabled={isSaving}
                data-testid='spotlight-unpublish-button'
              >
                Unpublish
              </Button>
            ) : (
              <Button
                variant='outlined'
                size='medium'
                onClick={saveDraft}
                disabled={isSaving}
                data-testid='spotlight-draft-button'
              >
                Save draft
              </Button>
            )}
            {editable && !isNew ? (
              <SpotlightConfirmationPopup
                primaryAction={saveAsPublished}
                primaryActionText='Publish'
                secondaryActionText='Cancel'
                header='This spotlight will only display for users who have not seen it.'
                message='If you want all users to see this spotlight, please make a clone instead'
                primaryButtonTestId='spotlight-publish-button'
                primaryButtonLabel='Publish'
              />
            ) : (
              <Button
                colorScheme='primary'
                aria-label='publish button'
                variant='contained'
                size='medium'
                data-testid='spotlight-publish-button'
                onClick={saveAsPublished}
              >
                Publish
              </Button>
            )}
          </>
        </Flex>
      )}
    </Flex>
  );
};

export default SpotlightActionButtons;
