import React from 'react';
import {utils, Track} from 'spekit-datalayer';
import {useDispatch} from 'react-redux';
import {ISpekCreated} from 'spekit-types';

export function CreateSpekSpotlightWrapper(WrappedComponent: React.ComponentClass) {
  return function CreateSpekSpotlight(props: any) {
    const dispatch = useDispatch();

    const segmentTrack = props.track ? props.track : Track.track;

    const onSpekCreated = ({
      businessTerm,
      editSpek = false,
      message = '',
      teams = [],
    }: ISpekCreated) => {
      /*
        TODO: anyone fixing something here,
        consider removing this component altogether
        and doing everything via redux.
        Will need to be duplicated for all 4 places
        Where this component is used.
      */
      const query = {
        showSpotlightForm: true,
        term: businessTerm.label,
        termId: businessTerm.id,
      };

      const queryString = utils.stringifyQs(query);
      props.history.push({search: `?${queryString}`});
      dispatch({
        type: 'SHOW_SPOTLIGHT',
        message,
        teams,
      });
      let createdOrEdited = editSpek ? 'Edit' : 'Create';
      segmentTrack(`Create Spotlight form opened via ${createdOrEdited} Spek`, {
        screen_name: `${createdOrEdited} a Spek`,
      });
    };

    return <WrappedComponent openSpotlightModal={onSpekCreated} {...props} />;
  };
}
