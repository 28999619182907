import React from 'react';
import {FormLabel as ChakraFormLabel, FormLabelProps, forwardRef} from '@chakra-ui/react';
import {FONT_FAMILY} from '../../Typography';

export type TFormLabelProps = FormLabelProps;

export const FormLabel = forwardRef((props: TFormLabelProps, ref) => (
  <ChakraFormLabel
    ref={ref}
    fontSize='small'
    fontWeight='normal'
    color='neutral.800'
    fontFamily={FONT_FAMILY}
    my={6}
    {...props}
  />
));
