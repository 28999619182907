import {
  UserGetResponse,
  UserDetailResponse,
  User,
  UserQuery,
  UserRoleResponse,
  DataExpertResponse,
  UserStagesResponse,
} from './user.types';
import {capture} from '../logging';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {stringifyQs} from '../utils/commonUtils';
import {UserStagesApiError} from './UserStagesApiError';
import {UserApiError} from './UserApiError';
import {create} from '../utils/APIHelpers';

export const get = (query: UserQuery): Promise<UserGetResponse> => {
  let options = {
    indices: false,
    arrayFormat: 'comma' as 'comma',
    skipNulls: true,
  };
  let {g, ...queryWithoutRoles} = query;
  let roles;
  if (g) {
    roles = (g as Array<string>).join('|');
  }
  let querystring = stringifyQs({...queryWithoutRoles, g: [roles]}, options);
  return new Promise((res, rej) => {
    fetch('/api/users/?' + querystring, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((r) => r.json())
      .then((result) => {
        if (!result.results) {
          throw new UserApiError('unexpected response from user api');
        } else {
          const response: UserGetResponse = {
            users: result.results as Array<User>,
            count: result.highest_count as number,
          };
          res(response);
        }
      })
      .catch((e) => rej(e));
  });
};

export const getUserDetails = (
  id: string,
  query?: UserQuery
): Promise<UserDetailResponse> => {
  let querystring = stringifyQs(query, {
    encode: false,
    indices: false,
    arrayFormat: 'comma',
    skipNulls: true,
  });
  return new Promise((res, rej) => {
    fetch(`/api/users/${id}` + querystring, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((r) => r.json())
      .then((result) => {
        if (!result) {
          throw new UserApiError('unexpected response from user api');
        } else {
          const response: UserDetailResponse = {
            userDetails: result,
          };
          res(response);
        }
      })
      .catch((e) => rej(e));
  });
};

export const userTeamRoles = (userId: string) => {
  return fetch(`/api/v1/users/${userId}/teams`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  })
    .then((r) => r.json())
    .then((result) => {
      if (!result) {
        throw new UserApiError('unexpected response from user team roles api');
      } else {
        let response: UserRoleResponse = {
          userRoleDetails: result,
        };
        return response;
      }
    });
};

/**
 * Gets all the experts for the given search.
 * @param {string} n - The search query.
 * @param {string[]} tags - Search users that have edit access on topics. (Optional)
 * @return {Promise<DataExpertResponse>} Response containing all the experts results.
 */
export const getExperts = async (
  n: string,
  tags?: string[]
): Promise<DataExpertResponse> => {
  try {
    let query = {
      q: `${n}`,
      topic_names: tags,
      active: true,
    };
    let options = {
      encode: true,
      indices: false,
      arrayFormat: 'comma' as 'comma',
      skipNulls: true,
    };
    let querystring = stringifyQs(query, options);

    let response = await fetch(`/api/lookups/users?${querystring}`, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    });
    let result: DataExpertResponse = await response.json();
    return result;
  } catch (e) {
    capture(e);
    throw e;
  }
};

export const getStages = (): Promise<UserStagesResponse> => {
  return fetch('/api/users/profile/stages/', {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  })
    .then((r) => r.json())
    .then((result) => {
      if (!result) {
        throw new UserStagesApiError('unexpected response from user stages api');
      } else {
        let response: UserStagesResponse = result;
        return response;
      }
    });
};

export const reminderEmails = async (
  reminderUserIds: Array<string>
): Promise<Response> => {
  const response: Response = await create(
    `/user-management/install-extension/chrome-reminder-email/`,
    {users: reminderUserIds}
  );
  return response;
};
