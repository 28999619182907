const initialState = {
  roles: [],
  gettingRoles: false,
  hasLoaded: false,
};
function roleInputReducer(state = initialState, action) {
  switch (action.type) {
    case 'SETROLES':
      return {
        ...state,
        hasLoaded: true,
        roles: action.roles,
      };
    case 'GETTINGROLES':
      return {
        ...state,
        gettingRoles: true,
      };
    default:
      return state;
  }
}

export default roleInputReducer;
