import Editor from '@ckeditor/ckeditor5-core/src/editor/editor';

// https://ckeditor.com/docs/ckeditor5/latest/features/tables/tables.html#disallow-nesting-tables
export default function DisallowNestingTables(editor: Editor) {
  editor.model.schema.addChildCheck((context, childDefinition) => {
    if (
      childDefinition.name === 'table' &&
      Array.from(context.getNames()).includes('table')
    ) {
      return false;
    }
    return;
  });
}
