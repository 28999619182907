import React from 'react';
import {
  InputLeftElement as ChakraInputLeftElement,
  InputElementProps,
} from '@chakra-ui/react';

export type TInputLeftElementProps = InputElementProps;

export const InputLeftElement = (props: TInputLeftElementProps) =>
  props.children ? <ChakraInputLeftElement {...props} /> : null;
