import {yupResolver} from '@hookform/resolvers/yup';
import {ICustomFieldOption, TCustomField, TCustomFieldContentTypes} from 'spekit-types';
import * as yup from 'yup';

export const getContentTypes = (showFileOption: boolean) => {
  return [
    ...(showFileOption
      ? [
          {
            label: 'Files',
            value: 'files',
          },
        ]
      : []),
    {
      label: 'Speks',
      value: 'business_terms',
    },
    {
      label: 'Salesforce objects',
      value: 'objects',
    },
    {
      label: 'Salesforce picklist values',
      value: 'field_values',
    },
    {
      label: 'Salesforce fields',
      value: 'fields',
    },
  ];
};

export const FIELD_TYPES = [
  {
    label: 'String',
    value: 'string',
  },
  {
    label: 'Multi-select dropdown',
    value: 'multi-option',
  },
];

export const getFormValues = (
  field: TCustomField | undefined,
  showFileOption: boolean
) => {
  if (field) {
    const contentTypes = getContentTypes(showFileOption);
    const on = contentTypes.filter((ct) =>
      field.on.includes(ct.value as TCustomFieldContentTypes)
    );
    const data_type = FIELD_TYPES.find((type) => type.value === field.data_type);
    let options: Pick<ICustomFieldOption, 'id' | 'name'>[] = [];
    if (field.data_type === 'multi-option') {
      options = field.options.map((opt) => ({id: opt.id, name: opt.name}));
    }

    return {
      name: field.name,
      data_type,
      options,
      on,
    };
  }
  return {name: '', on: [], options: []};
};

const getOptionSchema = (areOptionsMoreThanTwo = false) => {
  return yup
    .array(
      yup.object().shape({
        id: yup.string(),
        name: yup
          .string()
          .required(areOptionsMoreThanTwo ? 'Enter or remove option' : 'Enter option')
          .max(255, 'Option name must be less than 255 characters'),
      })
    )
    .test('unique-options', 'Duplicate options are not allowed', function (options) {
      const optionNames = new Set();
      const errors: yup.ValidationError[] = [];
      if (!Array.isArray(options)) return true;
      options.forEach((option, index) => {
        if (optionNames.has(option.name))
          errors.push(
            this.createError({
              path: `options[${index}].name`,
              message: 'Option name must be unique',
            })
          );
        else optionNames.add(option.name);
      });
      if (errors.length > 0) throw new yup.ValidationError(errors);
      return true;
    });
};
const isMultiOption = (data_type: typeof FIELD_TYPES[0] | undefined) =>
  data_type?.value === FIELD_TYPES[1].value;

const selectOption = yup.object().shape({label: yup.string(), value: yup.string()});

const schema = yup.object({
  name: yup
    .string()
    .required('Enter name')
    .max(255, 'Field name must be less than 255 characters'),
  on: yup
    .array(selectOption)
    .min(1, 'Select at least one content type this custom field applies to'),
  data_type: selectOption.default(undefined).required('Select type'),
  options: yup.array().when('data_type', {
    is: isMultiOption,
    then: yup.array().when('.length', {
      is: (length: number) => length > 2,
      then: getOptionSchema(true),
      otherwise: getOptionSchema(),
    }),
  }),
});

export const resolver = yupResolver(schema);
