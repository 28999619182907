import {ISearchResult} from 'spekit-types';

type TActionType = 'SHOW_CONTENT_SHARE_MODAL' | 'HIDE_CONTENT_SHARE_MODAL';

interface IPayload {
  isInternalShare: boolean;
  selectedContent: ISearchResult;
}

interface IAction {
  type: TActionType;
  isInternalShare: boolean;
  payload?: null | IPayload;
}

interface IShareModalState {
  isOpen: boolean;
  isInternalShare: boolean;
  selectedContent: ISearchResult | null;
}

const initialState: IShareModalState = {
  isOpen: false,
  isInternalShare: false,
  selectedContent: null,
};

export default function contentShareModalReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case 'SHOW_CONTENT_SHARE_MODAL':
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    case 'HIDE_CONTENT_SHARE_MODAL':
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
