import React from 'react';
import {
  ModalCloseButton as ChakraModalCloseButton,
  CloseButtonProps,
} from '@chakra-ui/react';
import {MdClose} from 'react-icons/md';

export type TModalCloseButtonProps = CloseButtonProps;

export const ModalCloseButton = (props: TModalCloseButtonProps) => (
  <ChakraModalCloseButton {...props} as={MdClose} />
);
