import {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
const SeismicPage = lazy(() => import('../Integrations/Seismic/pages/SeismicPage'));

const IntegrationsRoute = () => {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route path='/app/integrations/seismic'>
          <SeismicPage />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default IntegrationsRoute;
