import React from 'react';
import {Box} from 'spekit-ui';

export const NavBorder = ({color}: {color?: string | null}) => {
  return (
    <Box
      width='100%'
      height='8px'
      data-testid='nav-border'
      bg={color ? color : 'linear-gradient(90deg, #CB2BBA 0%, #653FE6 100%)'}
    />
  );
};
