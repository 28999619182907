import React from 'react';
import {FormControl, FormErrorMessage, Input} from 'spekit-ui';
interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  error?: boolean;
  errorText?: string;
}

const URLInput: React.FC<Props> = ({
  value,
  onChange,
  disabled,
  onBlur,
  placeholder,
  error,
  errorText,
}) => {
  return (
    <FormControl isRequired isInvalid={error} width='330px'>
      <Input
        isRequired
        placeholder={placeholder}
        isInvalid={error}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
      />
      <FormErrorMessage position={'absolute'}>{error && errorText}</FormErrorMessage>
    </FormControl>
  );
};

export default URLInput;
