import React from 'react';
import {
  PopoverContent as ChakraPopoverContent,
  PopoverContentProps,
} from '@chakra-ui/react';

export type TPopoverContentProps = PopoverContentProps;

export const PopoverContent = (props: TPopoverContentProps) => (
  <ChakraPopoverContent {...props} />
);
