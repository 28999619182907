import React from 'react';
class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.hoverOn = this.hoverOn.bind(this);
    this.hoverOff = this.hoverOff.bind(this);
  }
  handleClick(evt) {
    if (typeof this.props.onTouchTap === 'function' && !this.props.disabled) {
      this.props.onTouchTap(evt);
    }
  }
  hoverOn() {
    this.setState({hovered: true});
  }
  hoverOff() {
    this.setState({hovered: false});
  }
  render() {
    let styles = {
      main: {
        outline: 'none',
        cursor: this.props.disabled ? 'not-allowed' : 'pointer',
        backgroundColor: this.state.hovered || this.props.active ? '#F6F7F9' : '#FFF',
        padding: '10px 20px',
        color: this.props.disabled ? '#C8CCD1' : '#637280',
        textAlign: 'left',
        transition: 'all 300ms ease-in-out',
        fontSize: '15px',
      },
    };
    return (
      <div
        style={{
          ...styles.main,
          ...this.props.style,
        }}
        onClick={this.handleClick}
        onMouseEnter={this.hoverOn}
        onMouseLeave={this.hoverOff}
        data-testid={this.props['data-testid']}
        role='menuitem'
      >
        {this.props.label}
        {this.props.children}
      </div>
    );
  }
}

export default MenuItem;
