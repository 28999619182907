import React from 'react';
import {
  TableContainer as ChakraTableContainer,
  TableContainerProps,
} from '@chakra-ui/react';

export type TTableContainerProps = TableContainerProps;

export const TableContainer = (props: TTableContainerProps) => (
  <ChakraTableContainer {...props} />
);
