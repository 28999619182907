import {Component} from 'react';
import {Link} from 'react-router-dom';

class DemoBanner extends Component {
  render() {
    let styles = {
      link: {
        textDecoration: 'none',
        fontWeight: '600',
        color: '#FFF',
        margin: '0px 0.5em',
      },
      main: {
        position: 'relative',
        zIndex: '1',
        background: 'linear-gradient(45deg, #C673D2 0%, #3576E8 100%)',
        padding: '16px 40px',
        color: '#FFF',
        fontSize: '14px',
        boxShadow: '0 10px 20px 0 rgba(0,0,0,0.2)',
      },
      action: {
        textDecoration: 'none',
        fontWeight: '600',
        color: '#FFF',
        float: 'right',
      },
    };
    return (
      <div style={styles.main}>
        Like our limited feature demo?
        <a href='mailto:demo@spekit.co' style={styles.link}>
          Contact us
        </a>
        for a full demo - or
        <Link style={styles.link} to='/app/connectSF'>
          start your free 14 day trial.
        </Link>
        <Link style={styles.action} to='/app/connectSF'>
          Connect to your Salesforce org
        </Link>
      </div>
    );
  }
}

export default DemoBanner;
