import React, {useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {ILoginFormState} from 'spekit-types';
import {RiArrowLeftLine, RiMailUnreadLine} from 'react-icons/ri';
import {retrieveUsernamePassword, utils} from 'spekit-datalayer';
import {
  Flex,
  Heading,
  Text,
  Loader,
  DSButton as Button,
  Icon,
  Link as DSLink,
} from 'spekit-ui';
import UsernameInput from '../../components/LoginInputFields/UsernameInput';
import LoginLayout from '../../components/LoginLayout';

const RetrieveUsername = () => {
  const history = useHistory();
  const methods = useForm<ILoginFormState>();
  const [isLoading, setIsLoading] = useState(false);
  const [retrieveRequestStatus, setRetrieveRequestStatus] = useState(false);

  const validate = (email: string) => {
    if (email && !email.match(/.+@.+\..+/)) {
      methods.setError('username', {
        type: 'invalid-email',
        message: 'Enter a valid email address.',
      });
      return false;
    }
    return true;
  };

  const retrieveUsername = async (data: ILoginFormState) => {
    if (validate(data.username) && !isLoading) {
      setIsLoading(true);
      try {
        const result = await retrieveUsernamePassword.retrieveUsername(
          data.username.trim()
        );
        if (result.success === true) {
          setRetrieveRequestStatus(true);
        }
        if (result.success === false && result.logged_in) {
          const {redir} = utils.parseQs(history.location.search);
          history.push(redir ? decodeURIComponent(redir as string) : '/app/generate');
        }
        if (result.success === false && !!result.message) {
          methods.setError('username', {
            type: 'general',
            message: result.message,
          });
        }
      } catch (e) {
        methods.setError('username', {
          type: 'general',
          message: 'Something went wrong. Please try again later.',
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      methods.handleSubmit(retrieveUsername)();
    }
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    methods.clearErrors();
    methods.setValue('username', e.target.value);
  };

  return (
    <LoginLayout>
      <Flex direction='column' px={144} maxW={384} pt={176}>
        <Flex alignItems='flex-start'>
          <Button
            data-testid='back-btn'
            size='small'
            variant='ghost'
            colorScheme='primary'
            leftIcon={<Icon as={RiArrowLeftLine} />}
            onClick={() =>
              history.push({
                pathname: '/login',
                search: history.location.search,
                state: {loginFlow: true},
              })
            }
          >
            Back to login
          </Button>
        </Flex>
        {retrieveRequestStatus ? (
          <>
            <Heading
              as='h3'
              fontWeight='semibold'
              fontSize='20px'
              textAlign='center'
              mt={20}
            >
              <Flex direction='row' justifyContent='center' alignItems='center' gap='5px'>
                <Icon as={RiMailUnreadLine} />
                Check your email!
              </Flex>
            </Heading>
            <Text variant='body2' fontWeight='normal' textAlign='center' mt={16} mb={32}>
              If your email is associated with a Spekit account you’ll receive an email
              containing your username.
            </Text>
            <Text
              variant='caption1'
              fontWeight='normal'
              textAlign='center'
              mt={16}
              mb={32}
            >
              If you do not receive an email within 30 minutes, please contact your
              Administrator for further assistance, or email us at
              <DSLink href='mailto:support@spekit.co'> support@spekit.co</DSLink>
            </Text>
          </>
        ) : (
          <>
            <Heading
              as='h3'
              fontWeight='semibold'
              fontSize='20px'
              textAlign='center'
              mt={20}
            >
              Retrieve your Spekit username
            </Heading>
            <Text variant='body2' fontWeight='normal' textAlign='center' mt={16} mb={32}>
              Enter the email address associated with your Spekit account, and we’ll send
              you an email containing your username.
            </Text>

            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(retrieveUsername)}>
                <Flex gap={20} direction='column'>
                  <UsernameInput
                    hideforgotlink
                    label='Email address'
                    placeholder='Enter your email address'
                    requiredmsg='An email address is required.'
                    onKeyDown={handleKeyDown}
                    onChange={handleUsernameChange}
                  />

                  {isLoading ? (
                    <Flex justifyContent='center' alignItems='center' py={20}>
                      <Loader size={8} />
                    </Flex>
                  ) : (
                    <Button
                      data-testid='retrieve-btn'
                      size='large'
                      variant='contained'
                      colorScheme='primary'
                      onClick={methods.handleSubmit(retrieveUsername)}
                    >
                      Send me my username
                    </Button>
                  )}
                </Flex>
              </form>
            </FormProvider>
          </>
        )}
      </Flex>
    </LoginLayout>
  );
};

export default RetrieveUsername;
