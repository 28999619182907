export const space = {
  2: '2px',
  4: '4px',
  6: '6px',
  8: '8px',
  10: '10px',
  12: '12px',
  14: '14px',
  16: '16px',
  20: '20px',
  24: '24px',
  26: '26px',
  28: '28px',
  32: '32px',
  40: '40px',
  42: '42px',
  44: '44px',
  46: '46px',
  52: '52px',
  60: '60px',
  64: '64px',
  80: '80px',
  96: '96px',
  112: '112px',
  144: '144px',
  160: '160px',
  176: '176px',
  192: '192px',
  208: '208px',
  224: '224px',
  240: '240px',
  256: '256px',
  288: '288px',
  320: '320px',
  384: '384px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

export type TSpace = typeof space;

export default space;
