import React from 'react';
import {
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps,
} from '@chakra-ui/react';
import {FONT_FAMILY} from '../../Typography';

export type TFormErrorMessageProps = FormErrorMessageProps;

export const FormErrorMessage = (props: TFormErrorMessageProps) => (
  <ChakraFormErrorMessage
    {...props}
    fontSize='tiny'
    fontWeight='normal'
    fontFamily={FONT_FAMILY}
    color={props.colorScheme === 'warning' ? 'warning.600' : 'error.600'}
    my={6}
  />
);
