// toolbar buttons
import textStylesIcon from '../icons/text.svg';

// default toolbar layout
export const editorToolbarOptions = [
  'undo',
  '|',
  {
    label: 'Fonts',
    icon: textStylesIcon,
    items: ['heading', 'fontFamily', 'fontColor', 'fontBackgroundColor'],
  },
  '|',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  '|',
  'bulletedList',
  'numberedList',
  'outdent',
  'indent',
  'alignment',
  '|',
  'removeFormat',
  '|',
  'specialCharacters',
  'insertTable',
  'codeBlock',
  'blockQuote',
  'horizontalLine',
  'link',
  'embedPdf',
  'uploadFromComputer',
];

export interface EditorColor {
  color: string;
  label: string;
}

export const colors: EditorColor[] = [
  {
    color: '#000000',
    label: 'Black',
  },
  {
    color: '#4E4E4E',
    label: 'Dark gray',
  },
  {
    color: '#8E8E8E',
    label: 'Gray',
  },
  {
    color: '#E2E2E2',
    label: 'Light gray',
  },
  {
    color: '#FFFFFF',
    label: 'White',
  },
  {
    color: '#EC0008',
    label: 'Red',
  },
  {
    color: '#F1007F',
    label: 'Pink',
  },
  {
    color: '#FC974D',
    label: 'Orange',
  },
  {
    color: '#F5F375',
    label: 'Yellow',
  },
  {
    color: '#2DE253',
    label: 'Green',
  },
  {
    color: '#70F0B9',
    label: 'Aquamarine',
  },
  {
    color: '#25E2E0',
    label: 'Turquoise',
  },
  {
    color: '#52A5EF',
    label: 'Light blue',
  },
  {
    color: '#3E44DB',
    label: 'Blue',
  },
  {
    color: '#9044DB',
    label: 'Purple',
  },
];

// default heading options
export const editorHeadingOptions = [
  {
    model: 'heading1',
    view: 'h1',
    title: 'Heading 1',
    class: 'ck-heading_heading1',
  },
  {
    model: 'heading2',
    view: 'h2',
    title: 'Heading 2',
    class: 'ck-heading_heading2',
  },
  {
    model: 'heading3',
    view: 'h3',
    title: 'Heading 3',
    class: 'ck-heading_heading3',
  },
  {
    model: 'heading4',
    view: 'h4',
    title: 'Heading 4',
    class: 'ck-heading_heading4',
  },
  {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
  // https://ckeditor.com/docs/ckeditor5/latest/features/headings.html#configuring-custom-heading-elements
  {
    model: 'spekitCaption',
    view: {
      name: 'paragraph',
      classes: 'spekit-ck-heading_caption', // to style captions in the editor
    },
    title: 'Caption',
    class: 'spekit-ck-heading_caption', // to style the caption option in the dropdown

    // It needs to be converted before the standard 'paragraph'.
    converterPriority: 'high',
  },
];
