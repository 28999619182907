import {Component} from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import {Fontawesome} from 'spekit-ui';
import {SingleDatePicker} from 'react-dates';
import './styles/datepicker.css';

import {connect} from 'react-redux';
import 'react-dates/initialize';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import {DATE_FORMAT, fetchMiddleWare} from 'spekit-datalayer';
import {updateUser} from './redux/actions';

const fetch = fetchMiddleWare.fetchMiddleWare;
class Support extends Component {
  constructor(props) {
    super(props);
    this.saveDate = this.saveDate.bind(this);
    this.renderViewMode = this.renderViewMode.bind(this);
    this.renderEditMode = this.renderEditMode.bind(this);
    this.renderAccessText = this.renderAccessText.bind(this);
    this.state = {
      edit: false,
      grantHover: false,
      revokeHover: false,
      selectedDate: moment(props.me.support_login_expiry),
      focused: false,
    };
  }

  renderAccessText(granted, expired) {
    const self = this;
    const styles = {
      grant: {
        textDecoration: self.state.grantHover ? 'underline' : 'none',
        cursor: 'pointer',
      },
      revoke: {
        textDecoration: self.state.revokeHover ? 'underline' : 'none',
        cursor: 'pointer',
        fontWeight: 'bold',
      },
    };
    granted = granted !== 'undefined' && granted ? true : false;
    return (
      <div>
        <span
          onClick={() => self.setState({edit: true})}
          onMouseEnter={() => self.setState({grantHover: true})}
          onMouseLeave={() => self.setState({grantHover: false})}
          style={styles.grant}
        >
          {granted ? 'Review' : expired ? 'Renew' : 'Grant'} access now?
        </span>{' '}
        {granted ? (
          <span
            onClick={() => {
              self.setState({selectedDate: moment(null)}, self.saveDate);
            }}
            onMouseEnter={() => self.setState({revokeHover: true})}
            onMouseLeave={() => self.setState({revokeHover: false})}
            style={styles.revoke}
          >
            Or revoke here.
          </span>
        ) : (
          ''
        )}
      </div>
    );
  }

  renderViewMode() {
    const self = this;
    const loginExpiry = self.props.me.support_login_expiry;
    const loginExpiryDate =
      loginExpiry !== 'undefined' ? moment.utc(loginExpiry).local() : null;
    let text;
    let granted = false;
    let expired = false;
    if (!loginExpiry || loginExpiry === 'undefined') {
      text = `Access to Spekit support not granted. `;
    } else if (loginExpiryDate < moment()) {
      text = `Spekit support access expired on ${loginExpiryDate.format(
        DATE_FORMAT.toUpperCase()
      )}. `;
      expired = true;
    } else {
      text = `Spekit support access valid until ${loginExpiryDate.format(
        DATE_FORMAT.toUpperCase()
      )}. `;
      granted = true;
    }
    return (
      <div>
        {text}
        {self.renderAccessText(granted, expired)}
      </div>
    );
  }

  renderEditMode() {
    const self = this;
    const styles = {
      text: {
        display: 'inline-block',
        margin: '0px 20px',
      },
      date: {
        display: 'inline-block',
        margin: '0px 20px',
      },
      check: {
        display: 'inline-block',
        margin: '0px 10px',
        color: 'green',
        cursor: 'pointer',
      },
      times: {
        display: 'inline-block',
        margin: '0px 10px',
        color: 'red',
        cursor: 'pointer',
      },
    };
    return (
      <div>
        <div style={styles.text}>Grant Spekit support access until: </div>
        <div style={styles.date}>
          <SingleDatePicker
            date={self.state.selectedDate.isValid() ? self.state.selectedDate : null}
            numberOfMonths={1}
            block
            noBorder
            hideKeyboardShortcutsPanel
            daySize={32}
            openDirection={'up'}
            focused={self.state.focused}
            onFocusChange={(focus) => self.setState({focused: focus.focused})}
            onDateChange={(v) => self.setState({selectedDate: moment(v)})}
            isOutsideRange={(d) => d.isBefore(new Date(), 'day')}
          />
        </div>
        <div style={styles.check}>
          <Fontawesome name={faCheck} onClick={self.saveDate} />
        </div>
        <div style={styles.times}>
          <Fontawesome name={faTimes} onClick={() => self.setState({edit: false})} />
        </div>
      </div>
    );
  }

  saveDate() {
    const self = this;
    const newDate = self.state.selectedDate;
    const days = newDate ? newDate.diff(new Date(), 'days') : null;
    fetch('/api/users/enable_support/', {
      credentials: 'include',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({days: days}),
    }).then(() => {
      self.setState({edit: false});
      fetch('/api/auth/session', {
        credentials: 'include',
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          throw new Error('not logged in');
        })
        .then((result) => {
          if (result) {
            this.props.updateUser(result);
          }
        });
    });
  }

  render() {
    return this.state.edit ? this.renderEditMode() : this.renderViewMode();
  }
}

export default connect(
  (state, ownProps) => {
    return {
      me: state.layout.me,
    };
  },
  (dispatch) => {
    return {
      updateUser: (user) => {
        dispatch(updateUser(user));
      },
    };
  }
)(Support);
