import React from 'react';
import {
  EncapsulatedInput,
  Flex,
  Text,
  Checkbox,
  DSTooltip as Tooltip,
  Icon,
} from 'spekit-ui';
import {RiInformationLine} from 'react-icons/ri';
import {TeamPicker} from '../../speks/TeamPicker';
import {Controller, useFormContext} from 'react-hook-form';
import {
  INotificationState,
  TNotificationButtonMode,
  TNotificationContentType,
} from 'spekit-types';

function getPostAction(
  contentType: TNotificationContentType,
  mode: TNotificationButtonMode
) {
  if (contentType === 'File' && mode === 'upload') return 'uploading';
  if (contentType === 'File' && mode === 'edit') return 'saving changes';

  return 'publishing';
}

function getSettings(
  contentType: TNotificationContentType,
  mode: TNotificationButtonMode
) {
  const postAction = getPostAction(contentType, mode);
  const settings = [
    {
      label: 'In-app notification',
      testId: 'notify-in-app',
      name: 'notifyInApp',
      tooltipLabel: undefined,
    },
    {
      label: 'Email',
      testId: 'notification-email',
      name: 'notifyByEmail',
      tooltipLabel: undefined,
    },
    {
      label: 'Create Spotlight',
      testId: 'notification-create-spotlight',
      name: 'createSpotlight',
      tooltipLabel: `After ${postAction}, you will be prompted to create a Spotlight that links to this ${contentType}`,
    },
  ] as const;

  return settings;
}

interface INotificationForm {
  topics: string[];
  contentType: TNotificationContentType;
  mode: TNotificationButtonMode;
}

export const NotificationForm = ({topics, contentType, mode}: INotificationForm) => {
  const {control} = useFormContext<INotificationState>();
  const settings = getSettings(contentType, mode);

  return (
    <Flex
      direction='column'
      gap={12}
      alignItems='flex-start'
      data-testid='notification-form'
    >
      <Text fontWeight='semibold'>Notify users</Text>
      <TeamPicker topics={topics} />
      <Controller
        name='message'
        control={control}
        render={({field, fieldState}) => (
          <EncapsulatedInput
            {...field}
            testId='notification-message'
            label='Message'
            placeholder='Enter message'
            errorMessage={fieldState.error?.message}
            isInvalid={!!fieldState.error}
            multiline
            isRequired
          />
        )}
      />
      <Flex direction='column' gap={8}>
        {settings.map(({label, testId, name, tooltipLabel}) => (
          <Controller
            key={name}
            name={name}
            control={control}
            render={({field}) => (
              <Flex gap={4} alignItems='center'>
                <Checkbox
                  isChecked={field.value}
                  onChange={field.onChange}
                  data-testid={testId}
                >
                  {label}
                </Checkbox>
                {tooltipLabel && (
                  <Tooltip label={tooltipLabel} placement='top'>
                    <Flex alignItems='center'>
                      <Icon as={RiInformationLine} h='16px' w='16px' />
                    </Flex>
                  </Tooltip>
                )}
              </Flex>
            )}
          />
        ))}
      </Flex>
    </Flex>
  );
};
