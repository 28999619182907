import {combineReducers} from 'redux';

import integrationsReducer from './reducers/integrationsReducer';
import layoutReducer from './reducers/layout.js';
import fieldTypeSelectReducer from './reducers/fieldTypeSelect.js';
import roleInputReducer from './reducers/roleInputReducer.js';
import notificationReducer from './reducers/notificationReducer.js';
import BulkUploadReducer from './reducers/bulkUpload';
import WikiStoredStateReducer from './reducers/wikiStoredState.js';
import pushNotificationReducer from './reducers/pushNotificationReducer.js';
import TopicModalReducer from './reducers/topicModalReducer';
import uploadReducer from './reducers/upload';
import topicIndexReducer from './reducers/topicIndexReducer';
import cloneSpekReducer from './reducers/cloneSpek';
import createSpekWithTopicReducer from './reducers/createSpekWithTopic';
import teamsReducer from './reducers/teamsReducer';
import userStagesReducer from './reducers/userStagesReducer';
import spotlightModalReducer from './reducers/spotlightModalReducer';
import flagReducer from './reducers/flagsReducer';
import featureFlagReducer from './reducers/featureFlagReducer';
import spekModalReducer from './reducers/spekModalReducer';
import ablyChannelReducer from './reducers/ablyReducer';
import assetUploadModalReducer from './reducers/assetUploadModalReducer';
import searchResultsReducer from './reducers/searchResultsReducer';
import contentPrivacySharingReducer from './reducers/contentPrivacySharing';
import contentShareModalReducer from './reducers/contentShareModalReducer';
import shareLinkAnalyticsReducer from './reducers/shareLinkAnalyticsReducer';
import asyncSpotlightCreateReducer from './reducers/asyncSpotlightCreateReducer';
import contentImportModalReducer from './reducers/contentImportModalReducer';

export default combineReducers({
  layout: layoutReducer,
  FieldTypeSelect: fieldTypeSelectReducer,
  roleInput: roleInputReducer,
  notifications: notificationReducer,
  bulkUpload: BulkUploadReducer,
  wikiStoredState: WikiStoredStateReducer,
  pushNotifications: pushNotificationReducer,
  topicModal: TopicModalReducer,
  upload: uploadReducer,
  topicIndex: topicIndexReducer,
  cloneSpek: cloneSpekReducer,
  createSpekWithTopic: createSpekWithTopicReducer,
  teamDetails: teamsReducer,
  userStages: userStagesReducer,
  spotlightModal: spotlightModalReducer,
  featureFlags: featureFlagReducer,
  flag: flagReducer,
  integrations: integrationsReducer,
  spekModal: spekModalReducer,
  ablyChannel: ablyChannelReducer,
  assetUploadModal: assetUploadModalReducer,
  searchResults: searchResultsReducer,
  contentPrivacy: contentPrivacySharingReducer,
  contentShareModal: contentShareModalReducer,
  shareLinkAnalytics: shareLinkAnalyticsReducer,
  asyncSpotlightCreate: asyncSpotlightCreateReducer,
  contentImportModal: contentImportModalReducer,
});
