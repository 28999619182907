import {IBrandConfigResponse, ICustomBrandingFormState} from 'spekit-types';
import {createForm, retrieve} from '../utils/APIHelpers';
import {BRAND_CONFIG_API} from '../api';
import {apiToState, stateToApi} from './transformer';

export async function getBrandConfig() {
  const response = await retrieve<IBrandConfigResponse>(BRAND_CONFIG_API);
  return apiToState(response);
}

export async function updateBrandConfig(state: ICustomBrandingFormState) {
  const payload = stateToApi(state);
  const response = await createForm<IBrandConfigResponse>(BRAND_CONFIG_API, payload);
  return apiToState(response);
}
