import React from 'react';
import styles from './line.module.css';

interface Props {
  length?: number;
  orientation: 'horizontal' | 'vertical';
}

const Line: React.FC<Props> = ({length, orientation}) => {
  return (
    <>
      {orientation === 'vertical' ? (
        <div className={styles.vertical} style={{maxHeight: `${length}px`}}></div>
      ) : (
        <div className={styles.horizontal} style={{maxWidth: `${length}px`}}></div>
      )}
    </>
  );
};

export default Line;
