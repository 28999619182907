import React from 'react';
import {TCustomField, TComputedValue, IOptionType} from 'spekit-types';
import {utils} from 'spekit-datalayer';
import {StringField} from './StringField';
import {MultiOptionField} from './MultiOptionField';

const {assertExhaustive} = utils;

interface IFieldsHandler {
  field: TCustomField;
  value: TComputedValue;
  handleUpdate: (id: string, values: TComputedValue) => void;
}

export const FieldsHandler = ({field, value, handleUpdate}: IFieldsHandler) => {
  const {data_type} = field;
  switch (data_type) {
    case 'string':
      return (
        <StringField handleUpdate={handleUpdate} field={field} value={value as string} />
      );
    case 'multi-option':
      return (
        <MultiOptionField
          field={field}
          handleUpdate={handleUpdate}
          value={value as IOptionType[]}
        />
      );
    default:
      return assertExhaustive(data_type);
  }
};
