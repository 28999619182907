import {Grid, GridItem, Text, Flex} from 'spekit-ui';
import IconSelectorButton from '../IconSelectorButton.tsx/IconSelectorButton';
import {
  documentIcons,
  faIcons,
  generalIcons,
  graphIcons,
  learningIcons,
  miscIcons,
  moneyIcons,
  processIcons,
  salesIcons,
} from '../../icons';
import {logos} from '../..';

interface ITopicIconGridProps {
  onClick: (icon: any, iconName: string, faIcon: boolean) => void;
}

export default function TopicIconGrid({onClick}: ITopicIconGridProps) {
  const iconMapper = {
    'General Business': generalIcons,
    Sales: salesIcons,
    Money: moneyIcons,
    Processes: processIcons,
    Graphs: graphIcons,
    Documents: documentIcons,
    Learning: learningIcons,
    Misc: miscIcons,
    Logos: logos,
    Original: faIcons,
  };

  return (
    <Flex direction='column' wrap='nowrap' alignItems='center' p={32} gap={26}>
      {Object.entries(iconMapper).map(([key, value]) => {
        return (
          <Flex gap={16} direction='column' key={key}>
            <Text variant='body2'>{key}</Text>
            <Grid
              gridAutoRows='50px'
              gridTemplateColumns='50px 50px 50px 50px'
              gridAutoFlow='row'
              gap={12}
              alignSelf='center'
              justifySelf='center'
            >
              {Object.keys(value).map((iconName) => {
                return (
                  <GridItem key={iconName}>
                    <IconSelectorButton
                      faIcon={key === 'Original' ? true : false}
                      iconName={iconName}
                      icon={value[iconName]}
                      onClick={onClick}
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </Flex>
        );
      })}
    </Flex>
  );
}
