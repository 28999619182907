import {logging} from 'spekit-datalayer';

/**
 * get item saved in localStorage
 * @param {string} key
 * @return {*}
 */
export const getItem = (key: string): any => {
  try {
    const item = localStorage.getItem(key);
    if (item) {
      try {
        return JSON.parse(item);
      } catch (error) {
        return item;
      }
    }
  } catch (error) {
    logging.capture(error);
    return null;
  }
};

/**
 * set item saved in localStorage
 * @param {string} key
 * @param {*} payload
 */
export const setItem = (key: string, payload: any): void => {
  try {
    let _payload = payload;
    if (typeof _payload !== 'string') {
      _payload = JSON.stringify(payload);
    }
    localStorage.setItem(key, _payload);
  } catch (error) {
    logging.capture(error);
  }
};

/**
 * delete item saved in localStorage
 * @param {string} key
 */
export const removeItem = (key: string): void => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    logging.capture(error);
  }
};

/**
 * removes all the Storage Object
 * item for this domain
 */
export const clear = (): void => {
  try {
    const username = localStorage.getItem('username');
    localStorage.clear();
    if (username) localStorage.setItem('username', username);
  } catch (error) {
    logging.capture(error);
  }
};

/**
 * returns the key present
 * on the index
 * @param {number} index
 * @return {key}  {(string | null)}
 */
export const key = (index: number): string | null => {
  try {
    return localStorage.key(index);
  } catch (error) {
    logging.capture(error);
    return null;
  }
};
