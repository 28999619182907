import {logging} from 'spekit-datalayer';

/**
 * Deriving this type due to lack of support in every browser for `checkVisibility`
 * method. Hence there is no type available for it as of now.
 * However for our use case, it works perfectly fine as we use chrome extensions.
 *
 * See: https://caniuse.com/mdn-api_element_checkvisibility
 *
 */
export type TElementWithVisibility = HTMLElement & {
  checkVisibility: (options?: Record<string, boolean>) => boolean;
};

export const isComponentVisible = ({
  element,
  message,
  tag,
}: {
  element: TElementWithVisibility | null;
  message: string;
  tag: string;
}) => {
  const isVisible = element?.checkVisibility({
    checkOpacity: true, // Check CSS opacity property too
    checkVisibilityCSS: true, // Check CSS visibility property too
  });

  if (!isVisible) {
    logging.sentryMessage(message, {tags: {LiveAssert: tag, force: 'true'}});
  }
};
