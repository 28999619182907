import styles from './create.module.css';
import {State} from './types';
import React from 'react';
import {Fontawesome, DSTooltip as Tooltip} from 'spekit-ui';
import {faInfoCircle, faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';

interface IEngagementDestinationProps {
  state: State;
}

const EngagementDestination = ({state}: IEngagementDestinationProps) => {
  return (
    <>
      <div className={styles.hr} />

      <div className={styles.headerContainer}>
        <div className={styles.header}>Action Button</div>
        <Tooltip
          label={`The action you want someone to take after they've viewed your Spotlight message (example: click a button labelled "Got it" that closes the message).`}
          shouldWrapChildren
        >
          <Fontawesome name={faInfoCircle} className={styles.infoCircle} />
        </Tooltip>
      </div>

      <div className={styles.labelsContainer}>
        <div className={styles.subHeading}>
          Engagement type{' '}
          <Tooltip
            label={`By default, when your user clicks "show me later," the Spotlight dismisses for 24 hours, until the rules are met again.`}
            shouldWrapChildren
          >
            <Fontawesome name={faInfoCircle} className={styles.infoCircleSmall} />
          </Tooltip>
        </div>
        <div className={styles.subHeading}>
          Links to{' '}
          <Tooltip
            label={`Where should we take the viewer once they click the button?`}
            shouldWrapChildren
          >
            <Fontawesome name={faInfoCircle} className={styles.infoCircleSmall} />
          </Tooltip>
        </div>
      </div>
      {['term', 'topic', 'quiz', 'flow'].includes(state.engagements.type) && (
        <span className={styles.subHeadingDestination}>
          Destination{' '}
          <Fontawesome name={faExternalLinkAlt} className={styles.infoCircleSmall} />
        </span>
      )}
    </>
  );
};

export default EngagementDestination;
