import React, {useState, useEffect} from 'react';
import styles from './topicInput.module.css';
import Select from 'react-select/async';
import {components} from 'react-select';
import {Fontawesome, Dropdown, AsyncSelect} from 'spekit-ui';
import {faFolder, faGlobe} from '@fortawesome/free-solid-svg-icons';

import {TopicLookup, logging} from 'spekit-datalayer';
import debounce from 'debounce-promise';
import {Props, Topics, CardProps} from './topicInput.types';
import {Topic} from '../types';
import {iconMapper} from '../../utils/iconMapper';
import {PropagateLoader} from 'react-spinners';
const {capture} = logging;
const {topicLookup, topicDetailLookup} = TopicLookup;

const EnhancedDropDown: any = Dropdown(Select);

const Card: React.FC<CardProps> = ({topicId, host}) => {
  const [term, setTerm] = useState<any>(null);
  useEffect(() => {
    topicDetailLookup(topicId).then((res: any) => {
      setTerm(res);
    });
  }, []);
  return (
    <>
      {term ? (
        <>
          <div className={styles.content}>
            {term.font_awesome_id && !term.font_awesome_id.startsWith('fa') ? (
              <img
                src={iconMapper(term.font_awesome_id)}
                alt='logo'
                className={styles.avatar}
              />
            ) : term.font_awesome_id !== null && term.font_awesome_id !== '' ? (
              <Fontawesome
                name={iconMapper(term.font_awesome_id)}
                svgClassname={styles.favatar}
              />
            ) : term.icon !== null ? (
              <img
                src={host ? `${host}${term.icon.view}` : term.icon.view}
                alt='logo'
                className={styles.avatar}
              />
            ) : (
              <Fontawesome
                name={iconMapper(term.font_awesome_id)}
                svgClassname={styles.favatar}
              />
            )}
            <h1 className={styles.label}>{term.name}</h1>
            <hr className={styles.hr} />
            <div className={styles.description}>{term.description}</div>
            <div className={styles.footer}>
              <span className={styles.domainAvatar}>
                <Fontawesome name={faGlobe} />
              </span>
              <div className={styles.domains}>
                {term.domains[0] ? term.domains[0].name : null}
              </div>
              {term.domains.length > 1 ? (
                <span className={styles.domainCount}>
                  {'+' + (term.domains.length - 1) + ' more'}
                </span>
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.contentLoading}>
          <PropagateLoader loading={true} color='#3576E8' size={10} />
        </div>
      )}
    </>
  );
};

const Icon = (props: any) => (
  <>
    {!props.data.font_awesome_id && props.data.icon && props.data.icon.view ? (
      <img
        src={props.host ? `${props.host}${props.data.icon.view}` : props.data.icon.view}
        alt='icon'
        height={16}
        width={16}
        className={styles.imageIcon}
      />
    ) : props.data.font_awesome_id &&
      props.data.font_awesome_id.startsWith('fa') &&
      !props.data.icon ? (
      <Fontawesome
        name={iconMapper(props.data.font_awesome_id)}
        className={styles.fawesome}
      />
    ) : props.data.font_awesome_id && !props.data.icon ? (
      <img
        src={iconMapper(props.data.font_awesome_id)}
        alt='font'
        height={16}
        width={16}
        className={styles.imageIcon}
      />
    ) : (
      <Fontawesome
        name={iconMapper(props.data.font_awesome_id)}
        className={styles.fawesome}
      />
    )}
  </>
);

const SingleValue = (props: any) => {
  if (props.selectProps.spekInput) {
    return (
      <>
        <components.SingleValue {...props}>
          <span style={{fontSize: '10px'}}>
            <span
              style={{
                display: 'inline-block',
                padding: '2px',
                marginRight: '0.3em',
                borderRadius: '3px',
                backgroundColor: '#DDDDDD',
                color: '#637280',
              }}
            >
              <Fontawesome name={faFolder} style={{marginRight: '0.2em'}} />
              <span>Topic</span>
            </span>
            <Icon data={props.data} host={props.selectProps.host} />
            {props.children}
          </span>
        </components.SingleValue>
      </>
    );
  } else {
    return <components.SingleValue {...props}>{props.children}</components.SingleValue>;
  }
};

const Option = (props: any) => {
  const [isCardOpen, setIsCardOpen] = useState<boolean>(false);
  return (
    <React.Fragment>
      {props.selectProps.spekInput ? (
        <span
          onMouseEnter={() => setIsCardOpen(true)}
          onMouseLeave={() => setIsCardOpen(false)}
        >
          <components.Option {...props}>
            <span className={styles.optionWrapper}>
              {isCardOpen ? (
                <span className={styles.cardWrapper}>
                  <Card topicId={props.value} host={props.selectProps.host} />
                </span>
              ) : null}
              <span className={styles.option}>
                <Fontawesome name={faFolder} style={{marginRight: '0.2em'}} />
                <span>Topic</span>
              </span>
              <Icon data={props.data} host={props.selectProps.host} />
              {props.children}
            </span>
          </components.Option>
        </span>
      ) : (
        <components.Option {...props}>{props.children}</components.Option>
      )}
    </React.Fragment>
  );
};

const TopicInput = ({monitorScrollElement, value, onSelect, state, teams}: Props) => {
  const hasSpotlightsRevampFlag = state.flag?.hasSpotlightsRevampFlag;
  const getTopics = async (searchTerm: string) => {
    try {
      let speks: Topics = await topicLookup({
        q: searchTerm,
        teams: teams.reduce(
          (accumulator: any, currentValue: any) => accumulator.concat(currentValue.value),
          []
        ),
      });
      let mappedTopics = speks.results.map((topic: Topic) => ({
        value: topic.value,
        label: topic.label,
        icon: topic.icon,
        font_awesome_id: topic.font_awesome_id,
      }));
      return mappedTopics;
    } catch (err) {
      capture(err);
    }
    return;
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    let {action} = actionMeta;
    if (action === 'select-option') {
      onSelect({
        value: newValue.value,
        label: newValue.label,
        icon: newValue.icon,
        font_awesome_id: newValue.font_awesome_id,
      });
    }
  };
  const getDebouncedTopics = debounce(getTopics, 200, {leading: false});

  return (
    <>
      {hasSpotlightsRevampFlag ? (
        <AsyncSelect
          value={value}
          loadOptions={getDebouncedTopics}
          onChange={handleChange}
          cacheOptions
          defaultOptions
          placeholder='Select Topic'
          blurInputOnSelect
          isDisabled={state.read_mode}
          key={JSON.stringify(teams)}
        />
      ) : (
        <EnhancedDropDown
          isClearable={false}
          hideSelectedOptions={false}
          defaultOptions
          filterLabel='Topic'
          noOptionMessage='No topics found'
          placeholder='Search for a Topic'
          spekInput
          host={state.host}
          isDisabled={state.read_mode}
          menuPortalTarget={document.querySelector('body')}
          closeMenuOnScroll={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (
              monitorScrollElement &&
              monitorScrollElement.current &&
              event.target.isSameNode(monitorScrollElement.current)
            ) {
              return true;
            }
            return false;
          }}
          handleChange={handleChange}
          loadOptions={getDebouncedTopics}
          components={{Option, SingleValue}}
          value={value}
          key={JSON.stringify(teams)}
        />
      )}
    </>
  );
};

export default TopicInput;
