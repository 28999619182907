import React from 'react';
import {AlertIcon as ChakraAlertIcon} from '@chakra-ui/react';
import {
  MdInfoOutline,
  MdErrorOutline,
  MdOutlineWarningAmber,
  MdCheckCircleOutline,
} from 'react-icons/md';

import {TAlertIconProps} from './types';

const STATUSES = {
  info: MdInfoOutline,
  warning: MdOutlineWarningAmber,
  success: MdCheckCircleOutline,
  error: MdErrorOutline,
};

export const AlertIcon = (props: TAlertIconProps) => {
  const {variant, ...chakraProps} = props;
  const icon = STATUSES[variant];

  return <ChakraAlertIcon as={icon} {...chakraProps} />;
};
