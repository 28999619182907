import {createStore, compose, applyMiddleware} from 'redux';
import reducer from './reducer';

let composeEnhancers = null;
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const store = createStore(
  reducer,
  composeEnhancers ? composeEnhancers(applyMiddleware()) : undefined
);

export type RootState = ReturnType<typeof reducer>;

export default store;
