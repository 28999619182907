import Fingerprint2 from 'fingerprintjs2';

function getFingerprint() {
  var promise = new Promise(function (resolve, reject) {
    let options = {
      excludes: {
        deviceMemory: true,
        cpuClass: true,
        enumerateDevices: true,
      },
    };
    if (window.requestIdleCallback) {
      requestIdleCallback(function () {
        Fingerprint2.getV18(options, function (result, components) {
          resolve(result);
        });
      });
    } else {
      setTimeout(function () {
        Fingerprint2.getV18(options, function (result, components) {
          resolve(result);
        });
      }, 500);
    }
  });

  return promise;
}

export default getFingerprint;
