import React from 'react';
import {Icon, TIconProps} from '../design-system/Icon';

export const PresentationIcon = (props: TIconProps) => (
  <Icon
    {...props}
    viewBox='0 0 66 86'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-testid='presentation-icon'
  >
    <path
      d='M55.9823 19.6052C53.806 19.6031 51.7194 18.7375 50.1805 17.1986C48.6416 15.6597 47.7762 13.5732 47.7741 11.3968V0H11.0955C8.25443 0 5.52981 1.12863 3.52089 3.13756C1.51196 5.14648 0.383331 7.8711 0.383331 10.7121V75.2878C0.383331 78.1288 1.51196 80.8535 3.52089 82.8624C5.52981 84.8714 8.25443 86 11.0955 86H55.1225C56.5296 86.0005 57.923 85.7238 59.2231 85.1857C60.5232 84.6476 61.7046 83.8587 62.6998 82.8639C63.6949 81.8691 64.4843 80.6881 65.0229 79.3881C65.5615 78.0882 65.8387 76.6948 65.8387 75.2878V19.6052H55.9823Z'
      fill='#FFF2ED'
    />
    <path
      d='M65.8385 19.6052H55.9822C53.8059 19.6031 51.7193 18.7375 50.1804 17.1986C48.6415 15.6597 47.7761 13.5731 47.774 11.3968V0L65.8385 19.6052Z'
      fill='#FF8147'
    />
    <path
      d='M30.1156 35.0507H12.7238C12.3285 35.0508 11.9494 34.8941 11.6696 34.615C11.3897 34.3359 11.232 33.9572 11.231 33.562C11.231 33.166 11.3883 32.7864 11.6682 32.5064C11.9482 32.2265 12.3278 32.0692 12.7238 32.0692H30.0997C30.4956 32.0692 30.8753 32.2265 31.1553 32.5064C31.4352 32.7864 31.5924 33.166 31.5924 33.562C31.5914 33.9544 31.4359 34.3307 31.1595 34.6093C30.8831 34.888 30.5081 35.0466 30.1156 35.0507Z'
      fill='#FF8147'
    />
    <path
      d='M30.1156 43.9557H12.7238C12.3285 43.9557 11.9494 43.7989 11.6696 43.5198C11.3897 43.2407 11.232 42.8621 11.231 42.4669C11.231 42.071 11.3883 41.6912 11.6682 41.4113C11.9482 41.1313 12.3278 40.9741 12.7238 40.9741H30.0997C30.4956 40.9741 30.8753 41.1313 31.1553 41.4113C31.4352 41.6912 31.5924 42.071 31.5924 42.4669C31.5914 42.8594 31.4359 43.2357 31.1595 43.5143C30.8831 43.7929 30.5081 43.9515 30.1156 43.9557Z'
      fill='#FF8147'
    />
    <path
      d='M30.1156 52.8606H12.7238C12.3278 52.8606 11.9482 52.7033 11.6682 52.4233C11.3883 52.1434 11.231 51.7637 11.231 51.3678C11.2341 50.974 11.3928 50.5973 11.6724 50.3199C11.952 50.0426 12.3299 49.887 12.7238 49.887H30.0997C30.4949 49.887 30.874 50.0437 31.1538 50.3228C31.4337 50.6019 31.5914 50.9806 31.5924 51.3758C31.5904 51.7676 31.4344 52.1428 31.1581 52.4206C30.8818 52.6984 30.5074 52.8564 30.1156 52.8606Z'
      fill='#FF8147'
    />
    <path
      d='M30.1156 61.7656H12.7238C12.3278 61.7656 11.9482 61.6083 11.6682 61.3284C11.3883 61.0484 11.231 60.6687 11.231 60.2728C11.232 59.8776 11.3897 59.4989 11.6696 59.2198C11.9494 58.9407 12.3285 58.784 12.7238 58.784H30.0997C30.4949 58.784 30.874 58.9407 31.1538 59.2198C31.4337 59.4989 31.5914 59.8776 31.5924 60.2728C31.5925 60.666 31.4374 61.0432 31.1609 61.3227C30.8844 61.6022 30.5087 61.7614 30.1156 61.7656Z'
      fill='#FF8147'
    />
    <path
      d='M47.0574 48.6928V35.4608C43.8338 35.631 40.7983 37.0313 38.5765 39.3731C36.3546 41.7149 35.1158 44.8198 35.1152 48.0479C35.1146 49.7031 35.4402 51.3422 36.0734 52.8715C36.7066 54.4008 37.6349 55.7904 38.8053 56.9608C39.9757 58.1312 41.3653 59.0595 42.8946 59.6927C44.4239 60.3259 46.063 60.6515 47.7182 60.651C50.9461 60.6513 54.0511 59.4126 56.3925 57.1904C58.7339 54.9683 60.1331 51.9323 60.3013 48.7087L47.0574 48.6928Z'
      fill='#FF8147'
    />
    <path
      d='M49.4698 34.4536V46.3959H61.4121C61.2526 43.2802 59.9431 40.3345 57.7372 38.1285C55.5312 35.9226 52.5855 34.6131 49.4698 34.4536Z'
      fill='#FF8147'
    />
  </Icon>
);
