import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
  isAxiosError,
} from 'axios';
import {
  api,
  WebappTokenStorage,
  withAuth,
  LocalStorage as localStorage,
} from 'spekit-datalayer';
import {addNotification, showPaywall} from './redux/actions';
import store from './reduxStore';

const refreshToken = async () => {
  const host = process.env.REACT_APP_API_HOST || '';
  // dont use common instance as it has other interceptors
  const response = await axios.post<
    void,
    AxiosResponse<{success: boolean; 'app-token': string}>
  >(host + '/api/auth/refresh', null, {
    headers: {credentials: 'include', 'X-CLIENT': 'app'},
  });

  if (response.status !== 200 || !response.data.success || !response.data['app-token']) {
    throw new Error('JWT Refresh token unsuccessful.');
  }

  return response.data['app-token'];
};

const errorInterceptor = (error: AxiosError | Error) => {
  if (isAxiosError(error)) {
    const {code} = error;
    const {status, data} = (error.response as AxiosResponse) ?? {};

    if (code === 'ERR_NETWORK') {
      store.dispatch(
        addNotification({
          text: 'Disconnected. Please check your internet connection',
          error: true,
        })
      );
      return;
    }

    switch (status) {
      case 401:
        localStorage.clear();
        const redirectPath = window.location?.pathname
          ? `?redir=${encodeURIComponent(
              window.location.pathname + window.location.search
            )}`
          : '';
        window.location.replace(`/logout${redirectPath}`);

        break;
      case 403:
        // "Permission denied"
        if (!store) break;

        if (data.license_status === 'blocked')
          store.dispatch(showPaywall('accountBlocked', 'hard'));

        break;

      default:
        break;
    }
  }

  return Promise.reject(error);
};

export const initAPI = () => {
  // add error interceptor first so that anything unhandled feeds later down the chain
  api.interceptors.response.use((response) => response, errorInterceptor);

  const storage = new WebappTokenStorage();
  withAuth(api, {refreshToken, storage});

  // add static headers
  api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    config.headers['X-CLIENT'] = 'app';
    return config;
  });
};
