import React from 'react';
import {PopoverTrigger as ChakraPopoverTrigger} from '@chakra-ui/react';

export interface IPopoverTriggerProps {
  children: React.ReactNode;
}

export const PopoverTrigger = (props: IPopoverTriggerProps) => (
  <ChakraPopoverTrigger {...props} />
);
