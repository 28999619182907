import React from 'react';
import {useStyleConfig, forwardRef} from '@chakra-ui/react';
import {Box, TBoxProps} from '../../Layout/Box';
import {FONT_FAMILY} from '../../Typography';

export interface ICardProps extends TBoxProps {
  variant?: string;
}

export const CardConfig = {
  baseStyle: {
    bg: 'neutral.0',
    boxSizing: 'border-box',
    fontFamily: FONT_FAMILY,
  },
  variants: {
    smooth: {
      shadow: 'md',
      rounded: 8,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'neutral.200',
      p: 8,
    },
  },
  sizes: {},
  defaultProps: {
    variant: 'smooth',
  },
};

export const Card = forwardRef((props: ICardProps, ref) => {
  const {variant, ...rest} = props;
  const styles = useStyleConfig('Card', {variant});

  return <Box __css={styles} {...rest} ref={ref} />;
});
