import _normalizeUrl from 'normalize-url';
import {url as urlUtil} from 'spekit-datalayer';

export interface IOpts extends _normalizeUrl.Options {}

export const normalizeUrl = (url: string, options?: IOpts): string => {
  const _options = {
    forceHttps: true,
    stripWWW: false,
    removeQueryParameters: [],
    ...options,
  };
  let isValidUrl = urlUtil.validateURL(url);
  if (!isValidUrl) {
    throw new Error('Please enter a valid URL');
  }
  return _normalizeUrl(url, _options);
};
