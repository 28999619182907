import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useCustomBranding} from 'spekit-shared-components';
import {ThemeProvider} from 'spekit-ui';
import {IThemeProvider} from 'spekit-ui/src/design-system/theme';
import {RootState} from '../../reduxStore';

export const CustomThemeProvider = (props: IThemeProvider) => {
  const session = useSelector((state: RootState) => state.layout.me);

  // window === window.top is used to stop API call in iframes. We are using
  // PDF viewer as an iframe in extension and this API call breaks the viewer.
  const {
    data: branding,
    isLoading,
    refetch,
  } = useCustomBranding({shouldFetch: window === window.top});

  // refetch brand config after login
  useEffect(() => {
    if (!!session && !branding?.colors.primary) refetch();
  }, [session, branding, refetch]);

  if (isLoading) return null;

  return <ThemeProvider primaryColor={branding?.colors.primary} {...props} />;
};
