import React from 'react';
import {Flex, Text} from '../design-system';

export interface Props {
  heading: string;
  subHeading: string;
}

const ContentPreview: React.FC<Props> = ({heading, subHeading, children}) => {
  return (
    <Flex flexDirection='column' pt={44} px={24} bg='#292a33'>
      <Text fontSize='xl' color='#fff' mb={10} fontWeight='light' letterSpacing={0}>
        {heading}
      </Text>
      <Text fontSize='medium' color='#fff' letterSpacing={0.11} mb={30}>
        {subHeading}
      </Text>
      {children}
    </Flex>
  );
};

export default ContentPreview;
