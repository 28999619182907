import {fetchMiddleWare as fetch} from './fetchMiddleWare';

interface Data {
  term_id: string;
  term_type: string;
  source: string;
  event_id?: string;
}
interface TermViewResponse {
  success: boolean;
  event_id: string | null;
}

export const termViewMethods = {
  termView: function (data: Data): Promise<TermViewResponse> {
    return new Promise((resolve, reject) => {
      fetch('/api/events/', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'term_view',
          data: data,
        }),
        method: 'POST',
      })
        .then((r: any) => r.json())
        .then((response: any) => {
          if (response.hasOwnProperty('success') && response.success) {
            resolve(response);
          } else {
            reject(response);
          }
        });
    });
  },
  getTotalTimeSpent: function (termTimeViewAt: number) {
    let currentTime = new Date().getTime();
    return (currentTime - termTimeViewAt) / 1000;
  },
};
