import {useLocation} from 'react-router-dom';
import styles from './MagicLinkLogin.module.css';
import {Button} from 'spekit-ui';
import {utils} from 'spekit-datalayer';
const MagicLinkLogin = (props: any) => {
  const location = useLocation();
  let {token} = utils.parseQs(location.search);
  return (
    <div className={styles.content}>
      <div className={styles.header}>Click the button below to log into Spekit.</div>
      <div className={styles.loginBox}>
        <div>
          <a href={`/magic/login/?token=${token}`}>
            <Button className={styles.loginBtn}>Login</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MagicLinkLogin;
