const initialState = {
  termToClone: null,
  openCloneSpek: false,
};

function cloneSpekReducer(state = initialState, action) {
  switch (action.type) {
    case 'CLONESPEK':
      return {
        termToClone: action.termToClone,
        openCloneSpek: action.openCloneSpek,
      };
    default:
      return state;
  }
}

export default cloneSpekReducer;
