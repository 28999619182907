import React from 'react';
import Select from 'react-select/async';
import {
  components,
  SingleValueProps,
  OptionTypeBase,
  OptionProps,
  ActionMeta,
} from 'react-select';
import {Icon, Dropdown, Box, AsyncSelect} from 'spekit-ui';
import {RiFlowChart} from 'react-icons/ri';
import {Flows, logging} from 'spekit-datalayer';
import debounce from 'debounce-promise';
import {State, Team, Select as ISelect} from '../types';

const {flowLookup} = Flows;

const {capture} = logging;

export interface IProps {
  monitorScrollElement: React.MutableRefObject<Element>;
  value: ISelect;
  onSelect: (value: ISelect) => void;
  state: State;
  teams: Array<Team>;
}

const EnhancedDropDown: any = Dropdown(Select);

const OptionContent: React.FC = ({children}) => {
  return (
    <Box as='span' fontSize='10px'>
      <Box
        d='inline-flex'
        p='2'
        mr='1'
        borderRadius='3px'
        bg='#DDDDDD'
        color='#637280'
        alignItems='center'
      >
        <Icon as={RiFlowChart} />
        <Box as='span'>Flow</Box>
      </Box>
      {children}
    </Box>
  );
};

const SingleValue = (props: SingleValueProps<OptionTypeBase>) => {
  return (
    <components.SingleValue {...props}>
      <OptionContent>{props.children}</OptionContent>
    </components.SingleValue>
  );
};

const Option = (props: OptionProps<OptionTypeBase, boolean>) => {
  return (
    <components.Option {...props}>
      <OptionContent>{props.children}</OptionContent>
    </components.Option>
  );
};

export const FlowInput = (props: IProps) => {
  const {monitorScrollElement, value, onSelect, state, teams} = props;
  const hasSpotlightsRevampFlag = state.flag?.hasSpotlightsRevampFlag;

  const getFlows = async (query: string) => {
    try {
      let flow = await flowLookup({
        q: query,
        status: 'published',
        teams: teams.reduce((acc: string[], cur: Team) => acc.concat(cur.value), []),
      });
      let mappedFlow = flow.results.map(({value: _value, label}) => ({
        value: _value,
        label: label,
      }));
      return mappedFlow;
    } catch (err) {
      capture(err);
    }
    return [];
  };

  const handleChange = (newValue: ISelect, actionMeta: ActionMeta<OptionTypeBase>) => {
    let {action} = actionMeta;
    if (action === 'select-option') {
      onSelect({
        value: newValue.value,
        label: newValue.label,
      });
    }
  };

  const handleChangeNext = (selectedOption: any) => {
    onSelect(selectedOption);
  };

  const getDebouncedFlows = debounce(getFlows, 200, {leading: false});

  return (
    <>
      {hasSpotlightsRevampFlag ? (
        <AsyncSelect
          value={value}
          loadOptions={getDebouncedFlows}
          onChange={handleChangeNext}
          cacheOptions
          defaultOptions
          placeholder='Select Flow'
          blurInputOnSelect
          isDisabled={state.read_mode}
          key={JSON.stringify(teams)}
        />
      ) : (
        <EnhancedDropDown
          isClearable={false}
          hideSelectedOptions={false}
          defaultOptions
          spekInput
          filterLabel='Flow'
          noOptionMessage='No Flows found'
          placeholder='Search for a Flow'
          isDisabled={state.read_mode}
          menuPortalTarget={document.querySelector('body')}
          closeMenuOnScroll={(event: React.ChangeEvent<HTMLInputElement>) =>
            monitorScrollElement.current &&
            event.target.isSameNode(monitorScrollElement.current)
          }
          handleChange={handleChange}
          loadOptions={getDebouncedFlows}
          components={{Option, SingleValue}}
          value={value}
          key={JSON.stringify(teams)}
        />
      )}
    </>
  );
};
