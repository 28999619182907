import React from 'react';
import {
  DSPopover as Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverCloseButton,
  DSTooltip as Tooltip,
  DSButton as Button,
  Icon,
  Text,
} from 'spekit-ui';

interface ISpotlightConfirmationPopupProps {
  header: string;
  message: string;
  icon?: React.ElementType;
  primaryAction: () => void;
  secondaryAction?: () => void;
  skipConfirmation?: boolean;
  actionTooltip?: string;
  primaryButtonTestId?: string;
  secondaryButtonTestId?: string;
  primaryActionText: string;
  secondaryActionText: string;
  primaryButtonLabel?: string;
}

const SpotlightConfirmationPopup = ({
  header,
  message,
  icon,
  primaryAction,
  secondaryAction,
  primaryActionText,
  secondaryActionText,
  actionTooltip,
  skipConfirmation,
  primaryButtonTestId = 'confirmation-accept',
  secondaryButtonTestId = 'confirmation-reject',
  primaryButtonLabel,
}: ISpotlightConfirmationPopupProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Popover
      isOpen={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      autoFocus={false}
      placement='bottom-end'
      returnFocusOnClose={false}
    >
      <Tooltip
        label={actionTooltip}
        isDisabled={!actionTooltip}
        shouldWrapChildren
        placement='bottom'
      >
        <PopoverTrigger>
          {icon ? (
            <Button
              data-testid='spotlight-confirmation-popover-btn'
              icon={<Icon fontSize={'lg'} as={icon} />}
              aria-label={`${header} button`}
              colorScheme='transparent'
              variant='icon'
              size='medium'
              onClick={() => {
                if (skipConfirmation) {
                  primaryAction();
                }
              }}
            />
          ) : (
            <Button
              colorScheme='primary'
              aria-label={`${header} button`}
              variant='contained'
              size='medium'
              data-testid='spotlight-confirmation-popover-btn'
              onClick={() => {
                if (skipConfirmation) {
                  setIsOpen(false);
                  primaryAction();
                }
              }}
            >
              {primaryButtonLabel}
            </Button>
          )}
        </PopoverTrigger>
      </Tooltip>
      <PopoverContent data-testid='confirmation-popover' textAlign='left'>
        <PopoverCloseButton role='button' top={14} right={14} />
        <PopoverHeader data-testid='confirmation-header' mr={20}>
          {header}
        </PopoverHeader>
        <PopoverBody>
          <Text whiteSpace='normal' fontSize='small' data-testid='confirmation-message'>
            {message}
          </Text>
        </PopoverBody>
        <PopoverFooter>
          <Button
            onClick={() => {
              setIsOpen(false);
              if (secondaryAction) secondaryAction();
            }}
            colorScheme='white'
            size='medium'
            variant='ghost'
            data-testid={secondaryButtonTestId}
          >
            {secondaryActionText}
          </Button>
          <Button
            onClick={() => {
              setIsOpen(false);
              primaryAction();
            }}
            size='medium'
            variant='danger'
            data-testid={primaryButtonTestId}
          >
            {primaryActionText}
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default SpotlightConfirmationPopup;
