import {logging, fetchMiddleWare, notification as Notification} from 'spekit-datalayer';
import store from './reduxStore';
import {updateUnreadCount, topicReindexed} from './redux/actions';
import {sendUserDataToSW, attachSwMsgListener} from './utils/swBrowserUtils';
import {handleBroadCastMessage} from './ablyListeners';

const fetch = fetchMiddleWare.fetchMiddleWare;

let mySocket = null;
let isSwMsgListenerAttached = false;
let broadcastChannel = null;

export const init = async function () {
  const state = store.getState();
  sendUserDataToSW(state.layout.me);
  if (!isSwMsgListenerAttached) {
    isSwMsgListenerAttached = await attachSwMsgListener();
  }
  const socketBaseURL =
    window.location.host === 'localhost'
      ? 'ws://localhost'
      : `wss://${window.location.host}`;
  try {
    fetch('/api/auth/ws/generate', {
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.token) {
          mySocket = new window.WebSocket(
            socketBaseURL + '/ws/notifications?token=' + res.token
          );
          mySocket.onmessage = function (evt) {
            pushHandler(JSON.parse(evt.data));
          };
          mySocket.onclose = init;
        }
      })
      .catch((err) => {
        logging.capture(err);
      });
  } catch (err) {
    pollForNotificationCount();
  }
};

const startNotificationsListener = function () {
  init();
  if (!broadcastChannel) {
    broadcastChannel = new BroadcastChannel('ably_channel');
    broadcastChannel.addEventListener('message', handleBroadCastMessage);
  }
};

const stopNotificationListener = function () {
  if (mySocket !== null) {
    mySocket.onclose = null;
    mySocket.close();
  }
  if (broadcastChannel) {
    broadcastChannel.removeEventListener('message', handleBroadCastMessage);
    broadcastChannel.close();
    broadcastChannel = null;
  }
};

const pushHandler = (msg) => {
  if (msg.type === 'unread_count') {
    store.dispatch(updateUnreadCount(msg.data.count));
  }
  if (msg.type === 'reindexing_completed') {
    store.dispatch(topicReindexed(msg.data.success, msg.data.task_id));
  }
};

const getNotificationCount = () => {
  Notification.getNotificationCounter().then((res) => {
    store.dispatch(updateUnreadCount(res.unread_count));
  });
};

const pollForNotificationCount = () => {
  window.setInterval(getNotificationCount, 60000);
};

export default {
  startNotificationsListener,
  stopNotificationListener,
};
