import React from 'react';
import {Box, Datepicker, Flex, Text} from 'spekit-ui';
import {Dispatch, State} from '../types';

interface ISpotlightExpirationRadioProps {
  state: State;
  dispatch: Dispatch;
}

const SpotlightExpirationRadio = (props: ISpotlightExpirationRadioProps) => {
  const {state, dispatch} = props;

  const handleChangeExpiryRadioGroup = (value: boolean) => () => {
    dispatch({type: 'SET_NEVER_EXPIRES', payload: value});
  };

  return (
    <Flex direction='column' gap='8' mb={64}>
      <Flex alignItems='center' gap={8}>
        <Box
          data-testid='expires-on-radio'
          sx={{accentColor: 'var(--spekit-colors-primary-500)'}}
          h='16px'
          w='16px'
          m={0}
          position='relative'
          borderColor='primary.500'
          borderRadius='50%'
          _hover={{backgroundColor: 'pink.25'}}
          _checked={{
            borderColor: 'primary.500',
            color: 'primary.500',
            background: 'white',
            _hover: {
              borderColor: 'primary.500',
              backgroundColor: 'pink.25',
            },
            _disabled: {
              backgroundColor: 'neutral.50',
              borderColor: 'neutral.500',
              color: 'neutral.500',
              cursor: 'not-allowed',
            },
          }}
          _disabled={{
            backgroundColor: 'neutral.50',
            borderColor: 'neutral.500',
            color: 'neutral.500',
            cursor: 'not-allowed',
          }}
          as='input'
          onChange={handleChangeExpiryRadioGroup(false)}
          checked={!state.isNeverExpires}
          id='expires_on'
          name='expiry'
          type='radio'
          disabled={state.read_mode}
        />
        <Text variant='body2' fontWeight='normal'>
          Schedule expiration date
        </Text>
      </Flex>

      {!state.isNeverExpires && (
        <Flex ml={24} direction='column' gap={8} width='30%'>
          <Text variant='body2'>Date</Text>
          <Datepicker
            aria-label='Select date'
            name='Datepicker'
            onDateChange={(value: Date) => {
              dispatch({type: 'SET_EXPIRES_ON', payload: value});
            }}
            placeholder='Select date'
            disabled={state.read_mode}
            date={state.end_on}
            idPrefix='spotlight-expiration-date'
          />
        </Flex>
      )}
      <Flex alignItems='center' gap={8}>
        <Box
          data-testid='expires-never-radio'
          sx={{accentColor: 'var(--spekit-colors-primary-500)'}}
          h='16px'
          w='16px'
          m={0}
          position='relative'
          borderColor='primary.500'
          borderRadius='50%'
          _hover={{backgroundColor: 'pink.25'}}
          _checked={{
            borderColor: 'primary.500',
            color: 'primary.500',
            background: 'white',
            _hover: {
              borderColor: 'primary.500',
              backgroundColor: 'pink.25',
            },
            _disabled: {
              backgroundColor: 'neutral.50',
              borderColor: 'neutral.500',
              color: 'neutral.500',
              cursor: 'not-allowed',
            },
          }}
          _disabled={{
            backgroundColor: 'neutral.50',
            borderColor: 'neutral.500',
            color: 'neutral.500',
            cursor: 'not-allowed',
          }}
          as='input'
          onChange={handleChangeExpiryRadioGroup(true)}
          checked={state.isNeverExpires}
          id='expires_never'
          name='expiry'
          type='radio'
          disabled={state.read_mode}
        />
        <Text variant='body2' fontWeight='normal'>
          Never expires
        </Text>
      </Flex>
    </Flex>
  );
};

export default SpotlightExpirationRadio;
