import React from 'react';
import {
  RiArrowDownSLine,
  RiBarChart2Line,
  RiFunctionLine,
  RiHome4Line,
  RiSettings3Line,
} from 'react-icons/ri';

import {useHistory} from 'react-router-dom';
import {
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  NavigationButton,
  SimpleMenuItem,
} from 'spekit-ui';

interface IMoreButtonProps {
  routes: {
    home: string;
    wiki: string;
    analytics: string;
    settings: string;
  };
  hasAnalyticsPermissions: boolean;
  hasSettingsPermissions: boolean;
}

export const MoreButton = ({
  routes,
  hasAnalyticsPermissions,
  hasSettingsPermissions,
}: IMoreButtonProps) => {
  const history = useHistory();

  const handleNavigation = (path: string) => {
    history.push(path);
  };

  return (
    <Box
      display={{base: 'revert', 'desktop-sm': 'none'}}
      data-testid='nav-item-more-button-container'
    >
      <Menu>
        {/*@ts-ignore*/}
        <MenuButton
          as={NavigationButton}
          data-testid='nav-item-more-button'
          rightIcon={<Icon as={RiArrowDownSLine} />}
        >
          More
        </MenuButton>

        <MenuList zIndex={2}>
          <SimpleMenuItem
            display={{base: 'flex', 'tablet-md': 'none'}}
            onClick={() => handleNavigation(routes.home)}
            icon={RiHome4Line}
            data-testid='more-menu-item-home'
          >
            Home
          </SimpleMenuItem>
          <SimpleMenuItem
            display={{base: 'flex', 'tablet-lg': 'none'}}
            onClick={() => handleNavigation(routes.wiki)}
            icon={RiFunctionLine}
            data-testid='more-menu-item-wiki'
          >
            Wiki
          </SimpleMenuItem>
          {hasAnalyticsPermissions && (
            <SimpleMenuItem
              display={{base: 'flex', 'desktop-sm': 'none'}}
              onClick={() => handleNavigation(routes.analytics)}
              icon={RiBarChart2Line}
              data-testid='more-menu-item-analytics'
            >
              Analytics
            </SimpleMenuItem>
          )}
          {hasSettingsPermissions && (
            <SimpleMenuItem
              display={{base: 'flex', 'desktop-sm': 'none'}}
              onClick={() => handleNavigation(routes.settings)}
              icon={RiSettings3Line}
              data-testid='more-menu-item-settings'
            >
              Settings
            </SimpleMenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};
