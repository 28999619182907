import React from 'react';
import {
  DSPopover as Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  UnorderedList,
  ListItem,
  Box,
  Portal,
} from 'spekit-ui';

const appliedItems = [
  'Certain buttons, input fields, links, tabs, and various other components',
  'Dock background color',
  'Color bar within the primary navigation',
];

export const ColorGuider = () => (
  <Popover autoFocus={false} placement='top'>
    <PopoverTrigger>
      <Box tabIndex={0} cursor='pointer' role='button'>
        <Text variant='caption1' color='primary.500'>
          Where will this color be applied?
        </Text>
      </Box>
    </PopoverTrigger>
    <Portal>
      <PopoverContent left='50px'>
        <PopoverCloseButton tabIndex={0} role='button' top={14} right={14} />
        <PopoverHeader>Color application and accessibility</PopoverHeader>
        <PopoverBody>
          <Text variant='body2' color='neutral.80'>
            If a new primary color is chosen, it will be applied to the following elements
            within Spekit:
          </Text>
          <UnorderedList ml={0}>
            {appliedItems.map((item) => (
              <ListItem key={item}>
                <Text variant='body2' color='neutral.80'>
                  {item}
                </Text>
              </ListItem>
            ))}
          </UnorderedList>
          <Text variant='body2' color='neutral.80'>
            To be accessible for users, the primary color should meet the WCAG AA minimum
            color contrast ratio of 4.5:1 with white text.
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Portal>
  </Popover>
);
