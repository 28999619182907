export const teamStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F6F7F9',
      color: '#637280',
    },
    backgroundColor: state.isSelected || state.isFocused ? '#F6F7F9' : '#fff',
    color: '#637280',
    fontSize: '14px',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    cursor: 'pointer',
    borderRadius: '24px',
    backgroundColor: state.isDisabled
      ? '#FFF'
      : state.selectProps.error
      ? 'rgba(223,75,84,0.1)'
      : '#EAF1FC',
    transition: 'all .2s ease',
    boxShadow: state.isDisabled ? 'none' : 'initial',
    border: state.isDisabled
      ? '1px solid #C4C3D0'
      : state.selectProps.error
      ? '1px solid #DF4B54'
      : state.isFocused || state.isSelected
      ? '1px solid #3576E8'
      : '1px solid transparent',
    '&:hover': {
      boxSizing: 'border-box',
      border: state.selectProps.error ? '1px solid #DF4B54' : '1px solid #3576E8',
    },
    fontSize: '14px',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '8px',
    zIndex: '2147483648',
    fontSize: '14px',
  }),
  menuList: (provided: any) => ({
    ...provided,
    borderRadius: '8px',
  }),
  menuPortal: (provided: any) => ({
    ...provided,
    borderRadius: '8px',
    zIndex: '2147483648',
    fontSize: '14px',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: state.isDisabled ? '#C4C3D0' : state.selectProps.error ? '#F23A4F' : '#3576E8',
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#3576E8',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    borderRadius: '15px',
    backgroundColor: '#2576E8',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: '#FFF',
    fontSize: '12px',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#2576E8',
      filter: 'brightness(1.4)',
      borderRadius: '15px',
    },
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    color: state.isDisabled ? '#C4C3D0' : state.selectProps.error ? '#333' : '#3576E8',
    transition: 'all .2s ease',
  }),
  placeholder: (base: any, state: any) => ({
    ...base,
    color: state.isDisabled ? '#C4C3D0' : state.selectProps.error ? '#F23A4F' : '#3576E8',
    fontSize: '14px',
    letterSpacing: '0.09px',
    textAlign: 'right',
  }),
  indicatorSeparator: () => ({display: 'none'}),
  groupHeading: (base: any) => ({
    ...base,
    paddingLeft: '0px',
    paddingRight: '0px',
    marginRight: '0px',
    marginLeft: '0px',
  }),
};
export const centeredLabelStyles = {
  ...teamStyles,
  valueContainer: (base: any, state: any) => ({
    ...base,
    justifyContent: 'center',
  }),
};

export const groupStyled = {
  ...centeredLabelStyles,
  menu: (provided: any) => ({
    ...provided,
    width: '244px',
    zIndex: '2147483638',
    fontSize: '14px',
  }),
  menuPortal: (provided: any) => ({
    ...provided,
    width: '244px',
    zIndex: '2147483645',
    fontSize: '14px',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: '12px',
    color: state.isDisabled ? '#C4C3D0' : '#3576E8',
  }),
};

export const applyWikiFilterStyles = {
  ...teamStyles,
  valueContainer: (base: any, state: any) => ({
    ...base,
    justifyContent: 'center',
  }),
};

export const teamTopicStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    textAlign: 'left',
    cursor: 'pointer',
    zIndex: '2147483645',
    '&:hover': {
      backgroundColor: '#F6F7F9',
      color: '#637280',
    },
    backgroundColor: state.isSelected || state.isFocused ? '#F6F7F9' : '#fff',
    color: '#637280',
    fontSize: '14px',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#CED4E0',
  }),
  control: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    transition: 'all .2s ease',
    zIndex: '2147483645',
    fontSize: '14px',
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '244px',
    zIndex: '2147483638',
    fontSize: '14px',
  }),
  menuPortal: (provided: any) => ({
    ...provided,
    width: '244px',
    zIndex: '2147483645',
    fontSize: '14px',
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transition: 'all .2s ease',
  }),
  placeholder: (base: any, state: any) => ({
    ...base,
    color: '#9FA9BA',
    fontSize: '14px',
    letterSpacing: '0.09px',
  }),
  indicatorSeparator: () => ({display: 'none'}),
  groupHeading: (base: any) => ({
    ...base,
    paddingLeft: '0px',
    paddingRight: '0px',
    marginRight: '0px',
    marginLeft: '0px',
  }),
};

export const searchDropdownStyle = {
  ...teamTopicStyles,
  container: (provided: any, state: any) => {
    // optional width property to override the default width, useful if passing a component as a label
    const {
      label: {length: labelLength},
      width: labelWidth,
    } = state.getValue()[0];
    const variableWidth = labelWidth
      ? `${labelWidth + 45}px`
      : labelLength > 0
      ? `${3 * labelLength + 85}px`
      : '95px';
    return {
      ...provided,
      height: '32px',
      width: variableWidth,
      transition: 'width 300ms ease-in-out',
    };
  },
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: '32px',
    height: '32px',
    cursor: 'pointer',
    boxShadow: 'none',
    transition: 'all .2s ease',
    zIndex: '4',
    fontSize: '14px',
    color: '#9FA9BA',
    borderRadius: '4px 0px 0px 4px',
    borderTop: '1px solid #C0C5D2',
    borderLeft: '1px solid #C0C5D2',
    borderBottom: '1px solid #C0C5D2',
    borderRight: '0px solid transparent',
    '>div:first-of-type': {
      height: '32px',
      position: 'initial',
      paddingRight: '0',
    },
    '>div:last-child': {
      height: '32px',
      paddingLeft: '0',
    },
    '&:hover': {
      boxSizing: 'border-box',
      borderTop: '1px solid #C0C5D2',
      borderLeft: '1px solid #C0C5D2',
      borderBottom: '1px solid #C0C5D2',
      borderRight: '0px solid transparent',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#ACB5BD',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#000',
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#9FA9BA',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#000',
    paddingLeft: '0',
  }),
};
export const spekInput = {
  ...teamTopicStyles,
  dropdownIndicator: () => ({display: 'none'}),
  menuList: (base: any) => ({
    ...base,
    position: 'initial',
  }),
  control: (provided: any) => ({
    ...provided,
    zIndex: 'revert',
  }),
};

export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    textAlign: 'left',
    cursor: 'pointer',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#F6F7F9',
      color: '#637280',
    },
    backgroundColor: state.isSelected || state.isFocused ? '#F6F7F9' : '#fff',
    color: '#637280',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#CED4E0',
  }),
  control: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'all .2s ease',
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '244px',
    fontSize: '14px',
    zIndex: '10',
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transition: 'all .2s ease',
  }),
  placeholder: (base: any, state: any) => ({
    ...base,
    color: '#9FA9BA',
    fontSize: '14px',
    letterSpacing: '0.09px',
  }),
  indicatorSeparator: () => ({display: 'none'}),
  groupHeading: (base: any) => ({
    ...base,
    paddingLeft: '0px',
    paddingRight: '0px',
    marginRight: '0px',
    marginLeft: '0px',
  }),
};

export const multiValueLabel = {
  text: {
    fontSize: '14px',
    color: '#3576E8',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  textDisabled: {
    fontSize: '14px',
    color: '#C4C3D0',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  allowDropdown: {
    top: '-2px',
    position: 'absolute' as 'absolute',
  },
} as any;

export const menuOption = {
  checkbox: {
    border: '2px solid #3576E8',
  },
};

export const bulkStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F6F7F9',
      color: '#637280',
    },
    backgroundColor: state.isSelected || state.isFocused ? '#F6F7F9' : '#fff',
    color: '#637280',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    cursor: 'pointer',
    borderRadius: '24px',
    backgroundColor: '#3576E8',
    transition: 'all .2s ease',
    border:
      state.isFocused || state.isSelected ? '1px solid #3576E8' : '1px solid transparent',
    '&:hover': {
      boxSizing: 'border-box',
      border: '1px solid #3576E8',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '8px',
    zIndex: '10',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#3576E8',
  }),
  input: (provided: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#FFF',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    borderRadius: '15px',
    backgroundColor: '#2576E8',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: '#FFF',
    fontSize: '12px',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: '#FFF',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#2576E8',
      filter: 'brightness(1.4)',
      borderRadius: '15px',
    },
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    color: '#FFF',
    transition: 'all .2s ease',
  }),
  placeholder: (base: any, state: any) => ({
    ...base,
    color: '#FFF',
    fontSize: '14px',
    letterSpacing: '0.09px',
  }),
  indicatorSeparator: () => ({display: 'none'}),
  groupHeading: (base: any) => ({
    ...base,
    paddingLeft: '0px',
    paddingRight: '0px',
    marginRight: '0px',
    marginLeft: '0px',
  }),
};
