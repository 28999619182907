import {stringifyQs} from '../utils/commonUtils';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {handleErrors} from '../utils/handleErrors';
import {capture} from '../logging';
import {
  DeleteSpotlightResponse,
  PublishSpotlightResponse,
  CloneSpotlightResponse,
  TriggeredSpotlight,
  SpotlightType,
  Data,
  SearchQuery,
  SpotlightListResponseType,
} from './spotlight.types';
import {checkTaskStatus} from '../task/checkStatusTask';
import {retrieve} from '../utils/APIHelpers';
import moment from 'moment';
import {format} from '../datetime';
import {SpotlightApiError} from './error.types';

export const stateToAPITransformer = (state: any, status: string) => {
  let content: any;
  if (Object.keys(state.engagements.content)) {
    if (state.engagements.type === 'file') {
      try {
        content = {
          file: state.engagements.content.file.value,
        };
      } catch (e) {
        throw new Error('Please select a file');
      }
    } else if (state.engagements.type === 'topic') {
      try {
        content = {
          topic: state.engagements.content.topic.value,
        };
      } catch (e) {
        throw new Error('Please select a topic');
      }
    } else if (state.engagements.type === 'term') {
      try {
        let _spekType = null;
        if (state.engagements.content['object']) {
          _spekType = 'object';
        } else if (state.engagements.content['field']) {
          _spekType = 'field';
        } else if (state.engagements.content['field_value']) {
          _spekType = 'field_value';
        } else if (state.engagements.content['business_term']) {
          _spekType = 'business_term';
        } else {
          throw new Error('Please select a spek');
        }
        content = {
          [_spekType]: state.engagements.content[_spekType].value,
        };
      } catch (e) {
        throw new Error('Please select a spek');
      }
    } else if (state.engagements.type === 'quiz') {
      try {
        content = {
          quiz: state.engagements.content.quiz.value,
        };
      } catch (e) {
        throw new Error('Please select a Quiz');
      }
    } else if (state.engagements.type === 'flow') {
      try {
        content = {
          flow: state.engagements.content.flow.value,
        };
      } catch (e) {
        throw new Error('Please select a Flow');
      }
    } else {
      try {
        content = {
          url: state.engagements.content.url,
        };
      } catch (e) {
        throw new Error('Please enter a url.');
      }
    }
  } else {
    content = {};
  }
  let end_on = null;

  if (!state.isNeverExpires) {
    if (moment.isMoment(state.end_on)) {
      end_on = state.end_on.format('YYYY-MM-DD HH:mm:ss.SSS ZZ');
    } else {
      end_on = format(state.end_on, 'yyyy-MM-dd HH:mm:ss.SSS xx');
    }
  }

  return {
    index: 0,
    label: state.headline,
    teams: state.teams.reduce(
      (accumulator: any, currentValue: any) => accumulator.concat(currentValue.value),
      []
    ),
    start_on: null,
    end_on,
    status: status,
    frequency_value: 0,
    frequency_period: 'weekly',
    steps: [
      {
        index: '0',
        label: state.headline,
        description: state.message,
        type: 'modal',
        show_navigation: false,
        image: state.image
          ? state.image.match(
              /[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gm
            )[0]
          : null,
        embed_url: state.embed,
        system_image_id: state.system_image,
        show_created_by: true,
        initial: true,
        terminal: true,
        engagements: {
          buttons: [
            {
              label: state.engagements.label,
              type: state.engagements.type,
              content: content,
            },
          ],
        },
        locations: state.targeting
          .filter(
            (target: any) =>
              (target.domain_comparison && target.domain_value) || target.type
          )
          .map((target: any) => {
            if (target.type === 'url') {
              target.path_rules = target.path_rules.filter(
                (rule: any) => rule.comparison && rule.value
              );
            }
            return target;
          }),
      },
    ],
  };
};

export const APItoStateTransformer = (spotlight: any) => {
  let {label, steps, status, teams, id, published_on, end_on, start_on} = spotlight;
  let {image, description, embed_url, system_image_id, engagements, locations} = steps[0];
  let engagementsContent = {};
  if (engagements.length) {
    if (engagements[0].type === 'url') {
      engagementsContent = {
        id: engagements[0].content.id,
        url: engagements[0].content.url,
      };
    } else if (engagements[0].type === 'term') {
      engagementsContent = {
        id: engagements[0].content.id,
        [engagements[0].content.term.term_type]: {
          value: engagements[0].content.term.id,
          label: engagements[0].content.term.label,
          term_type: engagements[0].content.term.term_type,
        },
      };
    } else if (engagements[0].type === 'topic') {
      engagementsContent = {
        id: engagements[0].content.id,
        topic: {
          value: engagements[0].content.topic.id,
          label: engagements[0].content.topic.name,
          icon: engagements[0].content.topic.icon,
          font_awesome_id: engagements[0].content.topic.font_awesome_id,
        },
      };
    } else if (engagements[0].type === 'quiz') {
      engagementsContent = {
        id: engagements[0].content.id,
        quiz: {
          value: engagements[0].content.quiz.id,
          label: engagements[0].content.quiz.name,
        },
      };
    } else if (engagements[0].type === 'flow') {
      engagementsContent = {
        id: engagements[0].content.id,
        flow: {
          value: engagements[0].content.flow.id,
          label: engagements[0].content.flow.name,
        },
      };
    } else if (engagements[0].type === 'file') {
      engagementsContent = {
        id: engagements[0].content.id,
        file: {
          value: engagements[0].content.file.id,
          label: engagements[0].content.file.name,
        },
      };
    } else {
      engagementsContent = {};
    }
  }
  return {
    headline: label,
    message: description,
    image: image ? `/api/v1/media/${image.id}/view/` : ``,
    embed: embed_url,
    system_image: system_image_id,
    is_published: status === 'published' ? true : false,
    isExpired: status === 'expired',
    published_on: published_on,
    end_on: end_on,
    start_on: start_on,
    teams: teams.map((team: any) => ({
      label: team.name,
      value: team.id,
      topics: team.topics ? team.topics : [],
    })),
    audienceRules: [],
    id: id,
    step: steps[0].id,
    engagements: {
      id: engagements.length ? engagements[0].id : '',
      label: engagements.length ? engagements[0].label : '',
      type: engagements.length ? engagements[0].type : null,
      instance_url: engagements.length ? engagements[0].instance_url : '',
      content: engagementsContent,
    },
    targeting: locations.length
      ? locations.map((location: any) => ({
          id: location.id,
          type: location.type,
          css_selector: location.css_selector,
          position: location.position,
          path_rules: location.path_rules,
          domain_value: location.domain_value,
          instance_url: location.instance_url,
        }))
      : [{type: null}],
  };
};

const stateToAPITransformerForUpdate = (state: any, status: string) => {
  let {steps, ...restSpotlight} = stateToAPITransformer(state, status);
  // @ts-ignore
  steps[0].id = state.step;
  restSpotlight['steps'] = [steps[0]];
  return restSpotlight;
};

export const updateEngagement = async (payload: any) => {
  let engagement = JSON.parse(JSON.stringify(payload));
  if (engagement.content.topic) {
    engagement.content.topic = engagement.content.topic.value;
  } else if (engagement.type === 'term') {
    if (engagement.content['object']) {
      engagement.content['object'] = engagement.content['object'].value;
    } else if (engagement.content['field']) {
      engagement.content['field'] = engagement.content['field'].value;
    } else if (engagement.content['field_value']) {
      engagement.content['field_value'] = engagement.content['field_value'].value;
    } else if (engagement.content['business_term']) {
      engagement.content['business_term'] = engagement.content['business_term'].value;
    }
  } else if (engagement.content.quiz) {
    engagement.content.quiz = engagement.content.quiz.value;
  } else if (engagement.content.flow) {
    engagement.content.flow = engagement.content.flow.value;
  } else if (engagement.type === 'file') {
    engagement.content.file = engagement.content.file.value;
  }
  try {
    let result = await fetch(`${engagement.instance_url}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        step: engagement.step,
        engagement_type: 'button',
        type: engagement.type,
        content: engagement.content,
        label: engagement.label,
      }),
    });

    let response = await result.json();
    let handledResult = handleErrors(result, response);
    return handledResult;
  } catch (e) {
    capture(e);
    throw e;
  }
};

export const create = async (state: any, status: string): Promise<any> => {
  try {
    let body = stateToAPITransformer(state, status);
    let response = await fetch('/walkthroughs/spotlights/', {
      credentials: 'include',
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    let result = await response.json();
    let handledResult = handleErrors(response, result);
    let transformedResult = APItoStateTransformer(handledResult);
    return transformedResult;
  } catch (e) {
    capture(e);
    throw e;
  }
};

export const update = async (state: any, status: string): Promise<any> => {
  try {
    let body = stateToAPITransformerForUpdate(state, status);
    let response = await fetch(`/walkthroughs/spotlights/${state.id}/`, {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    let result = await response.json();
    let handledResult = handleErrors(response, result);
    return handledResult;
  } catch (e) {
    capture(e);
    throw e;
  }
};

export const getSpotlight = async (spotlightId: string): Promise<any> => {
  try {
    let response = await fetch(`/walkthroughs/spotlights/${spotlightId}`, {});
    let result = await response.json();
    let handledResult = handleErrors(response, result);
    let transformedResult = APItoStateTransformer(handledResult);
    return transformedResult;
  } catch (e) {
    capture(e);
    throw e;
  }
};

export const fetchCreator = (searchTerm: string) => {
  let query = {
    g: 'Expert|Team Admin|Account Admin',
    n: `${searchTerm}`,
  };
  let options = {
    encode: true,
    indices: false,
    arrayFormat: 'comma' as 'comma',
    skipNulls: true,
  };
  let querystring = stringifyQs(query, options);

  return fetch(`/api/users/?${querystring}`, {}).then((res) => res.json());
};

export const loadDropdownOptions = (url: string, searchTerm: string) => {
  const URL = `${url}?q=${searchTerm}`;
  return fetch(URL, {}).then((res) => res.json());
};
export const fetchSpotlight = (
  query: SearchQuery,
  page?: number
): Promise<SpotlightListResponseType> => {
  query = {
    ...query,
    limit: query.limit ? query.limit : 15 * ((page || 0) + 1),
    offset: query.offset ? query.offset : 15 * (page || 0),
  };

  let querystring = stringifyQs(query, {
    indices: false,
    arrayFormat: 'comma',
    skipNulls: true,
    encode: true,
  });
  return new Promise((res, rej) =>
    fetch(`/walkthroughs/v1/spotlights/?${querystring}`, {})
      .then((res) => res.json())
      .then((response) => {
        res({
          next:
            response.highest_count > 15 * ((page || 0) + 1)
              ? () => fetchSpotlight(query, (page || 0) + 1)
              : undefined,
          spotlights: response.results,
          highest_count: response.highest_count,
        });
      })
  );
};

export const fetchSpotlightById = async (id: string) => {
  let result = await fetch(`/walkthroughs/v1/spotlights/${id}`, {});
  let spotlight = await result.json();
  if (result.status !== 200) {
    throw new SpotlightApiError('Error while fetching a spotlight.');
  }
  return {
    ...spotlight,
    steps: [
      {
        ...spotlight.steps[0],
        image: spotlight.steps[0].image
          ? `/api/v1/media/${spotlight.steps[0].image.id}/view`
          : null,
      },
    ],
  };
};

export const deleteSpotlight = function (
  id: string | undefined
): Promise<DeleteSpotlightResponse> {
  return new Promise((res, rej) => {
    fetch(`/walkthroughs/v1/spotlights/${id}/`, {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((result) => {
        if (!result) {
          throw new SpotlightApiError('unexpected response from spotlights deletion api');
        } else {
          let response: DeleteSpotlightResponse = result;
          res(response);
        }
      })
      .catch((e) => rej(e));
  });
};

export const publishSpotlight = function (
  id: string | undefined
): Promise<PublishSpotlightResponse> {
  return new Promise((res, rej) => {
    fetch(`/walkthroughs/v1/spotlights/${id}/publish/`, {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((result) => {
        if (!result) {
          throw new SpotlightApiError('unexpected response from spotlights deletion api');
        } else {
          let response: PublishSpotlightResponse = result;
          res(response);
        }
      })
      .catch((e) => rej(e));
  });
};

export const unPublishSpotlight = function (
  id: string | undefined
): Promise<PublishSpotlightResponse> {
  return new Promise((res, rej) => {
    fetch(`/walkthroughs/v1/spotlights/${id}/unpublish/`, {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((result) => {
        if (!result) {
          throw new SpotlightApiError('unexpected response from spotlights deletion api');
        } else {
          let response: PublishSpotlightResponse = result;
          res(response);
        }
      })
      .catch((e) => rej(e));
  });
};

export const getTriggered = function (): Promise<TriggeredSpotlight[]> {
  return new Promise((res, rej) => {
    fetch('/walkthroughs/v1.3/spotlights/triggered/', {
      credentials: 'include',
    })
      .then((r) => {
        if (r.status === 404) {
          res([]);
        }
        return r.json();
      })
      .then((response) => {
        if (response.success) {
          res(response.results);
        } else {
          rej(new SpotlightApiError('Spotlights trigger api error.'));
        }
      });
  });
};

export const cloneSpotlight = function (
  id: string | undefined,
  label: string | undefined
): Promise<CloneSpotlightResponse> {
  return new Promise((res, rej) => {
    fetch(`/walkthroughs/v1/spotlights/${id}/clone/`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({label: label}),
    })
      .then((r) => r.json())
      .then((result: unknown) => {
        if (!result) {
          throw new SpotlightApiError('unexpected response from spotlights deletion api');
        } else {
          const response = result as CloneSpotlightResponse;
          checkTaskStatus(response.message_id)
            .then(() => {
              res(response);
            })
            .catch((e) => {
              rej(e);
            });
        }
      })
      .catch((e) => rej(e));
  });
};

export const domainEdit = async (payload: any): Promise<any> => {
  try {
    let result = await fetch(
      `/walkthroughs/v1/spotlights/step_locations/${payload.id}/`,
      {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          domain_value: payload.domain_value,
          path_rules: payload.path_rules,
        }),
      }
    );

    let response = await result.json();
    let handledResult = handleErrors(result, response);
    return handledResult;
  } catch (e) {
    capture(e);
    throw e;
  }
};

export const domainCreate = async (payload: any): Promise<any> => {
  try {
    let result = await fetch(`/walkthroughs/v1/spotlights/step_locations/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...payload,
        event_rule: {},
      }),
    });

    let response = await result.json();
    let handledResult = handleErrors(result, response);
    return handledResult;
  } catch (e) {
    capture(e);
    throw e;
  }
};

export const domainDelete = async (id: string): Promise<any> => {
  try {
    let result = await fetch(`/walkthroughs/v1/spotlights/step_locations/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!result.ok && result.status !== 200) {
      throw new Error('Unable to delete!');
    }
    return true;
  } catch (e) {
    capture(e);
    throw e;
  }
};

export const byId = function (id: string): Promise<SpotlightType> {
  return fetch(`/walkthroughs/v1/spotlights/${id}`, {
    credentials: 'include',
  }).then((r) => r.json());
};

export const ack = function (engagementId: string, sessionId: string): Promise<void> {
  return fetch('/walkthroughs/v1/spotlights/engagements/completion/', {
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      engagement: engagementId,
      session: sessionId,
    }),
  })
    .then((r) => r.json())
    .then((r) => {
      console.log(r);
    });
};

export const updateTeams = async (teams: any, id: string) => {
  try {
    let selectedTeams = teams.reduce(
      (accumulator: any, currentValue: any) => accumulator.concat(currentValue.value),
      []
    );
    let result = await fetch(`/walkthroughs/v1/spotlights/${id}/`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        teams: selectedTeams,
      }),
    });
    let response = await result.json();
    let handledResult = handleErrors(result, response);
    return handledResult;
  } catch (e) {
    capture(e);
    throw e;
  }
};

export const fetchAnalytics = async (id: string | undefined): Promise<any> => {
  return await retrieve(`/api/stats/?type=spotlights-details&spotlight_id=${id}`);
};

export const viewEvent = async function (viewType: string, data: Data): Promise<any> {
  return new Promise((resolve, reject) => {
    fetch('/api/v1/events/', {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: viewType,
        data,
      }),
      method: 'POST',
    })
      .then((r) => r.json())
      .then((response) => {
        if (response.hasOwnProperty('success') && response.success) {
          resolve(response);
        } else {
          reject(new Error(response.message ?? 'failed to retrieve event information'));
        }
      });
  });
};

export const dismissSpotlight = function (sessionId: string): Promise<any> {
  return new Promise((res, rej) => {
    fetch(`/walkthroughs/v1/spotlights/${sessionId}/dismissals/`, {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((result) => {
        if (!result) {
          throw new SpotlightApiError(
            'unexpected response from spotlights dismissal api'
          );
        } else {
          res(result);
        }
      })
      .catch((e) => rej(e));
  });
};
