import {useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {Link, useHistory} from 'react-router-dom';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
  Text,
  TInputProps,
  InputRightElement,
  InputGroup,
  Link as DSLink,
} from 'spekit-ui';
import {RiEyeOffLine, RiEyeLine} from 'react-icons/ri';

const PasswordInput = (props: TInputProps) => {
  const {
    register,
    formState: {errors},
  } = useFormContext();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  return (
    <FormControl isInvalid={!!errors.password}>
      <Flex justifyContent='space-between' alignItems='center'>
        <FormLabel>Password</FormLabel>
        <Text fontWeight='normal' fontSize='small'>
          <DSLink
            data-testid='forgot-password'
            as={Link}
            to={{pathname: '/reset-password', search: history.location.search}}
          >
            Forgot password?
          </DSLink>
        </Text>
      </Flex>
      <InputGroup>
        <InputRightElement
          data-testid='show-password'
          cursor='pointer'
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          {!errors.password && <>{showPassword ? <RiEyeLine /> : <RiEyeOffLine />}</>}
        </InputRightElement>
        <Input
          data-testid='password'
          isInvalid={!!errors.password}
          autoComplete='off'
          placeholder='Enter password'
          type={showPassword ? 'text' : 'password'}
          {...register('password', {
            required: 'A password is required to login.',
          })}
          {...props}
        />
      </InputGroup>
      {errors.password && (
        <FormErrorMessage data-testid='password-error'>
          {errors.password.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default PasswordInput;
