import {Suspense, lazy} from 'react';
import {Switch} from 'react-router-dom';
import ProtectedSearchRoute from './components/ProtectedSearchRoute';

const ListPage = lazy(() => import('./pages/ListPage'));

function SearchRoutes() {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <ProtectedSearchRoute path='/app/search'>
          <ListPage />
        </ProtectedSearchRoute>
      </Switch>
    </Suspense>
  );
}

export default SearchRoutes;
