import {TaskResponse} from '../task/checkStatusTasks.types';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {capture} from '../logging';

export const checkTaskStatus = function (id: string | undefined): Promise<string> {
  let poll = function (): Promise<TaskResponse> {
    return fetch('/api/tasks/' + id + '/', {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    }).then((response) => response.json());
  };
  let test = function (result: TaskResponse): Promise<{status: boolean; msg: string}> {
    return new Promise((res) => {
      let status = result.status.toLowerCase();
      if (status === 'queued' || status === 'processing') {
        res({status: false, msg: result.message});
      } else if (status === 'completed' || status === 'warning') {
        res({status: true, msg: result.message});
      } else {
        throw new Error(result.message);
      }
    });
  };
  return new Promise((res, rej) => {
    let pollAndTest = function (): any {
      poll()
        .then((response) => test(response))
        .then((result) => {
          if (result.status) {
            res(result.msg);
          } else {
            setTimeout(() => {
              pollAndTest();
            }, 3000);
          }
        })
        .catch((err) => {
          capture(err);
          rej(err);
        });
    };
    pollAndTest();
  });
};

export const uploadFile = async (formData: FormData): Promise<Response> => {
  let response = await fetch(`/user-management/v1/bulk-users/import/`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
  });

  if (response.status === 413) {
    throw new Error(
      'This file is too big. Please make sure file size is less than 20 MB.'
    );
  }

  const result = await response.json();
  await checkTaskStatus(result.task_id);

  return response;
};

export const uploadUsersToTeams = async (formData: FormData): Promise<string> => {
  let response = await fetch(`/user-management/v1/bulk-add-users-to-teams/import/`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: formData,
  });

  if (response.status === 413) {
    throw new Error(
      'This file is too big. Please make sure file size is less than 20 MB.'
    );
  }

  const result = await response.json();
  let status = await checkTaskStatus(result.task_id);

  return status;
};
