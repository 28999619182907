import React from 'react';
import {
  InputRightElement as ChakraInputRightElement,
  InputElementProps,
} from '@chakra-ui/react';

export type TInputRightElementProps = InputElementProps;

export const InputRightElement = (props: TInputRightElementProps) =>
  props.children ? <ChakraInputRightElement {...props} /> : null;
