import {
  IQuizState,
  IQuizBuilderPayload,
  IQuestionPayload,
  IQuizDetails,
  IQuestionState,
  TOptionState,
  IOption,
  IQuizUserResponse,
} from 'spekit-types';
import {QUESTION_TYPE_OPTIONS} from '../constants';
import {removeUndefinedFromObject} from '../utils/commonUtils';

export const stateToAPI = (data: Partial<IQuizState>) => {
  const {
    passingScore,
    status,
    title,
    resource,
    doNotifyEmail,
    doNotifyInApp,
    showIntro: show_intro,
    introResource: intro_resource_url,
    introduction,
    createSpotlight,
    allowRetake,
  } = data;

  let questions: IQuizBuilderPayload['questions'] = [];

  if (data.questions) {
    questions = data.questions?.map((question, questionIndex) => {
      removeUndefinedFromObject(question);
      const options = question.options?.map((option, optionIndex) => {
        removeUndefinedFromObject(option);
        const o: Partial<IOption> = {};
        if (option.id) o.id = option.id;

        if ('option' in option) o.option = option.option;
        if ('isCorrect' in option) o.is_correct = option.isCorrect;
        if ('order' in option && (option.order !== optionIndex + 1 || !option.id))
          o.order = optionIndex + 1;

        return o;
      });

      const q: Partial<IQuestionPayload> = {};

      if (question.id) q.id = question.id;

      if ('title' in question) q.title = question.title;
      if ('options' in question) q.options = options;
      if ('questionType' in question) q.question_type = question.questionType.value;
      if (question.order !== questionIndex + 1 || !question.id)
        q.order = questionIndex + 1;

      return q;
    });
  }

  let teams: IQuizBuilderPayload['teams'] = [];
  if (data.teams) {
    teams = data.teams.filter((team) => Boolean(team.value)).map((team) => team.value);
  }

  const payload: Partial<IQuizBuilderPayload> = {
    title,
    status,
    resource,
    email_notification: doNotifyEmail,
    inapp_notification: doNotifyInApp,
    show_intro,
    introduction,
    intro_resource_url,
    create_spotlight: createSpotlight,
    allow_retake: allowRetake,
  };
  if (questions.length > 0) payload.questions = questions;
  if (teams.length > 0) payload.teams = teams;
  if (passingScore?.value) payload.passing_score = passingScore.value;
  return payload;
};

export const APIToQuizBuilderState = (response: IQuizDetails, isCloned?: boolean) => {
  const {
    title,
    status,
    resource,
    teams,
    passing_score: passingScore,
    email_notification: emailNotification,
    inapp_notification: inappNotificaiton,
    show_intro,
    introduction,
    intro_resource_url,
    create_spotlight,
    allow_retake,
  } = response;

  const questions = response.questions.map((question) => {
    const options = question.options.map((option, index) => {
      const o: TOptionState = {
        isCorrect: option.is_correct,
        order: option.order,
        option: option.option,
      };

      if (option.id) o.id = option.id;
      return o;
    });

    const q: IQuestionState = {
      options,
      order: question.order,
      questionType: QUESTION_TYPE_OPTIONS.find(
        (option) => option.value === question.question_type
      )!,
      title: question.title,
      score: question.score,
    };

    if (question.id) q.id = question.id;
    return q;
  });

  const quiz: IQuizState & {publishedOn: string} = {
    title,
    status,
    resource,
    teams: teams.map((team) => ({
      label: team.name,
      value: team.id,
      isFixed: !isCloned && (status === 'published' || status === 'archived'),
    })),
    questions,
    passingScore: {
      label: `${passingScore} of ${questions.length}`,
      value: passingScore,
    },
    doNotifyEmail: emailNotification,
    doNotifyInApp: inappNotificaiton,
    publishedOn: response.published_on,
    showIntro: show_intro,
    introduction: introduction,
    introResource: intro_resource_url,
    createSpotlight: create_spotlight,
    isPublishedBefore: isCloned ? false : status !== 'draft',
    allowRetake: allow_retake,
  };

  return quiz;
};

export const userResponseToOptions = (usersResponse: Array<IQuizUserResponse>) => {
  return usersResponse.map((user) => ({label: user.name, value: user.user_id}));
};

export default stateToAPI;
