type TActionType =
  | 'SHOW_ASSET_UPLOAD_MODAL'
  | 'HIDE_ASSET_UPLOAD_MODAL'
  | 'SHOW_EDITABLE_ASSET_MODAL';

interface IPayload {
  selectedFiles?: null | File[];
}

interface IAction {
  type: TActionType;
  editableAsset?: any;
  editableFile: File;
  payload?: null | IPayload;
}

const initialState = {
  isOpen: false,
  selectedFiles: null,
};

function assetUploadModalReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case 'SHOW_ASSET_UPLOAD_MODAL':
      return {
        ...state,
        isOpen: true,
        selectedFiles: action.payload?.selectedFiles || null,
      };
    case 'HIDE_ASSET_UPLOAD_MODAL':
      return {
        ...state,
        editableAsset: undefined,
        isOpen: false,
      };
    case 'SHOW_EDITABLE_ASSET_MODAL':
      return {
        ...state,
        editableAsset: action.editableAsset,
        editableFile: action?.editableFile,
        isOpen: true,
      };
    default:
      return state;
  }
}

export default assetUploadModalReducer;
