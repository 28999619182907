import {createBrowserHistory} from 'history';
import {utils} from 'spekit-datalayer';

const forUrlMatches = (forUrls, srcPath) => {
  let urlMatchIndex = forUrls.findIndex((u) => {
    return srcPath.indexOf(u) === 0;
  });
  return urlMatchIndex !== -1;
};

const historyProxy = new Proxy(createBrowserHistory(), {
  get(origHistory, property) {
    if (property === 'push') {
      return function (route, state) {
        if (
          typeof route === 'object' &&
          !!route.preserveQueryValue &&
          Array.isArray(route.forUrls) &&
          forUrlMatches(route.forUrls, origHistory.location.pathname)
        ) {
          let qsSource = (origHistory.location.search || '').replace('?', '');
          let qoSource = utils.parseQs(qsSource);
          let qsDest = (route.search || '').replace('?', '');
          let qoDest = utils.parseQs(qsDest);
          qoDest[route.preserveQueryValue] = qoSource[route.preserveQueryValue];
          return origHistory.push({
            ...route,
            search: '?' + utils.stringifyQs(qoDest),
          });
        } else {
          return origHistory.push(route, state);
        }
      };
    } else if (origHistory[property]) {
      return origHistory[property];
    } else {
      return undefined;
    }
  },
});

export default historyProxy;
