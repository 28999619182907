// types
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';

// we support adding emoji
import emoji from 'emoji.json';

// add emoji to special characters
// https://ckeditor.com/docs/ckeditor5/latest/features/special-characters.html#adding-a-new-category
function SpecialCharactersEmoji(editor: ClassicEditor) {
  const specialCharactersPlugin = editor.plugins.get(SpecialCharacters);

  // filter all emoji variations and map to the correct format
  const mappedEmoji = emoji
    .filter((e) => !e.name.includes(':'))
    .map((e) => ({title: e.name, character: e.char}));

  // add emoji to special characters
  specialCharactersPlugin.addItems('Emoji', mappedEmoji, {label: 'Emoji'});
}

export default SpecialCharactersEmoji;
