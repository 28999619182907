import React from 'react';
import {Tabs as ChakraTabs, TabsProps} from '@chakra-ui/react';
import {StyleConfig} from '../types';
import {FONT_FAMILY} from '../Typography';

export type TTabsProps = TabsProps;

export const TabsConfig: StyleConfig = {
  parts: ['root', 'tab', 'tablist', 'tabpanel', 'tabpanels'],
  baseStyle: {
    tab: {
      cursor: 'pointer',
      padding: 0,
      textTransform: 'none',
      overflow: 'visible',
      my: 0,
      mx: 0,
      background: 'transparent',
      border: '1px solid',
      borderColor: 'transparent',
      boxSizing: 'border-box',
      fontWeight: 400,
      fontSize: 'small',
      fontFamily: FONT_FAMILY,
    },
    tablist: {
      gap: 16,
    },
    tabpanels: {
      boxSizing: 'border-box',
    },
  },
  variants: {
    line: {
      tablist: {
        borderWidth: '1px',
        borderColor: 'neutral.200',
      },
      tab: {
        borderBottom: '2px solid',
        borderBottomColor: 'transparent',
        color: 'neutral.700',
        marginBottom: '-1px',
      },
    },
  },
  sizes: {
    md: {
      tab: {
        fontSize: 'small',
        px: 12,
        py: 12,
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'line',
  },
};

export const Tabs = (props: TTabsProps) => <ChakraTabs {...props} />;
