import React from 'react';
import {components, StylesConfig} from 'react-select';
import Fontawesome from '../fontawesome';
import {
  customStyles,
  teamTopicStyles,
  teamStyles,
  multiValueLabel,
  menuOption,
  bulkStyles,
  searchDropdownStyle,
  centeredLabelStyles,
  groupStyled,
  spekInput,
} from './Dropdown.styles';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import CheckBox from '../checkbox';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <Fontawesome data-testid='dropdown-indicator' name={faChevronDown} />
    </components.DropdownIndicator>
  );
};

const Option = (props: any) => {
  return (
    <React.Fragment>
      {(props.selectProps.centeredLabelStyles || props.selectProps.teamStyles) &&
      props.selectProps.isMulti ? (
        <components.Option {...props}>
          <div style={{display: 'flex'}}>
            <div>
              <CheckBox
                allowPropagation
                small
                checkboxStyle={menuOption.checkbox}
                value={props.isSelected}
              />
            </div>
            <div>{props.children}</div>
          </div>
        </components.Option>
      ) : (
        <components.Option {...props}>{props.children}</components.Option>
      )}
      {props.childElement}
    </React.Fragment>
  );
};

const isReactElement = (child: any): child is React.ReactElement => {
  return !!child.type;
};

const ValueContainer = (props: any) => {
  let selectInput = React.Children.toArray(props.children).find((input) =>
    isReactElement(input)
      ? typeof input.type !== 'string'
        ? input.type.name === 'Input' || input.type.name === 'DummyInput'
        : false
      : false
  );
  return (
    <React.Fragment>
      {(props.selectProps.centeredLabelStyles || props.selectProps.teamStyles) &&
      props.selectProps.isMulti &&
      props.hasValue ? (
        <components.ValueContainer {...props}>
          {props.getValue().length === 1 ? (
            <span
              style={
                props.selectProps.isDisabled
                  ? multiValueLabel.textDisabled
                  : multiValueLabel.text
              }
            >
              {props.selectProps.filterLabel} ({props.selectProps.value[0].label})
              <span style={multiValueLabel.allowDropdown}>{selectInput}</span>
            </span>
          ) : (
            <span
              style={
                props.selectProps.isDisabled
                  ? multiValueLabel.textDisabled
                  : multiValueLabel.text
              }
            >
              Multiple ({props.getValue().length})
              <span style={multiValueLabel.allowDropdown}>{selectInput}</span>
            </span>
          )}
        </components.ValueContainer>
      ) : (
        <components.ValueContainer {...props}>{props.children}</components.ValueContainer>
      )}
    </React.Fragment>
  );
};

type IsMulti = false;
type OptionType = {
  label: string;
  value: string;
};
interface Props {
  noOptionMessage: string;
  placeholder: string;
  onSelect?: (text: string) => void;
  loadOptions: (props: any) => Promise<any>;
  handleChange?: (newValue: any, actionMeta: any) => void;
  isClearable?: boolean;
  teamTopicStyle?: boolean;
  teamStyles?: boolean;
  bulkStyles?: boolean;
  defaultOptions?: boolean;
  searchDropdownStyle?: boolean;
  menuPosition?: string;
  centeredLabelStyles?: boolean;
  ariaLabel?: string;
  params?: string[];
  groupStyled?: boolean;
  spekInput?: boolean;
  components?: any;
  styles?: StylesConfig<OptionType, IsMulti>;
  childElement?: Function;
  menuIsOpen?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}

const Dropdown = (WrappedComponent: any) => {
  return class Dropdown extends React.Component<Props> {
    noOptionMessage = (): any => <div>{this.props.noOptionMessage}</div>;

    render() {
      const {components, ...restProps} = this.props;
      const MenuList = (restProps: Props) => (
        <>
          <Option {...restProps} />
          {this.props.childElement && this.props.childElement()}
        </>
      );
      return (
        <WrappedComponent
          key={JSON.stringify(this.props.params)}
          aria-label={this.props['ariaLabel']}
          escapeClearsValue
          backspaceRemovesValue
          components={{
            DropdownIndicator,
            ValueContainer,
            ...components,
            ...(this.props.childElement && {MenuList}),
          }}
          allowCreateWhileLoading
          createOptionPosition='first'
          onChange={this.props.handleChange}
          menuIsOpen={this.props.menuIsOpen}
          onMenuOpen={this.props.onMenuOpen}
          onMenuClose={this.props.onMenuClose}
          styles={{
            ...(this.props.teamTopicStyle
              ? teamTopicStyles
              : this.props.teamStyles
              ? teamStyles
              : this.props.bulkStyles
              ? bulkStyles
              : this.props.searchDropdownStyle
              ? searchDropdownStyle
              : this.props.centeredLabelStyles
              ? centeredLabelStyles
              : this.props.groupStyled
              ? groupStyled
              : this.props.spekInput
              ? spekInput
              : customStyles),
            ...this.props.styles,
          }}
          noOptionsMessage={this.noOptionMessage}
          {...restProps}
        />
      );
    }
  };
};

export default Dropdown;
