import React from 'react';
import styles from './pill.module.css';

interface Props {
  children: React.ReactNode;
}

const Pill: React.FC<Props> = ({children}) => {
  return <div className={styles.main}>{children}</div>;
};

export default Pill;
