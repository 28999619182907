/**
 * The embed card is used to display embedded content in the editor and read-only views.
 * Embedded images will render as images and the CKEditor Image plugin will handle all image formatting
 * Embedded files will display as a card with an icon and a link
 * If there is no file supplied it will show a loading spinner
 */

import React from 'react';
import {Loader, Box, Text, Link} from 'spekit-ui';

// embedded file interface
export interface EmbeddedFile {
  filepath: string;
  filename: string;
  filetype: string;
}

// embed card props
interface EmbedCardProps {
  host?: string | null;
  file?: EmbeddedFile;
  enabled: boolean; // we disable the embed card when editing to allow the user to drag and drop
  tabIndex?: number;
}

// function that returns an svg depending on the different file extension
const getFileIcon = (file: EmbeddedFile) => {
  let fileExtension = file.filename.split('.').pop();
  switch (fileExtension) {
    case 'pdf':
    case 'ai':
      return (
        <svg
          className='pdf-file-icon'
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M8.95113 18.6362C9.15992 18.5129 9.39071 18.3934 9.63903 18.2789C9.40873 18.6189 9.18209 18.9254 8.9644 19.1878C8.54495 19.6934 8.2178 19.9619 8.01262 20.0464C7.98926 20.056 7.97175 20.0615 7.95933 20.0646C7.94957 20.0516 7.93607 20.0308 7.91991 19.9985C7.83658 19.8318 7.84004 19.6738 7.98012 19.4567C8.13937 19.2098 8.45922 18.9267 8.95113 18.6362Z'
            fill='#B42318'
          />
          <path
            d='M12.6335 16.1661C12.4558 16.2023 12.2776 16.2411 12.0996 16.2827C12.2437 16.0001 12.384 15.7142 12.5192 15.4277C12.6321 15.1887 12.7421 14.948 12.8484 14.7071C12.9621 14.9176 13.0799 15.1256 13.2008 15.3296C13.3341 15.5546 13.4725 15.7769 13.6147 15.994C13.2888 16.042 12.9601 16.0998 12.6335 16.1661Z'
            fill='#B42318'
          />
          <path
            d='M16.4205 17.5746C16.2124 17.4103 15.9932 17.2027 15.769 16.9602C16.1104 16.9659 16.4201 16.9923 16.6871 17.0403C17.1627 17.1259 17.3863 17.2603 17.464 17.3529C17.4909 17.3851 17.5007 17.4114 17.502 17.4491C17.5038 17.4966 17.4917 17.5923 17.4136 17.7484C17.3528 17.8701 17.299 17.9172 17.272 17.9349C17.2498 17.9494 17.2219 17.9604 17.1688 17.9583C17.0321 17.9528 16.7813 17.8596 16.4205 17.5746Z'
            fill='#B42318'
          />
          <path
            d='M13.0843 10.4562C13.0247 10.8212 12.9221 11.2411 12.7843 11.6981C12.7316 11.5195 12.6869 11.3461 12.6508 11.1793C12.5363 10.6497 12.52 10.2342 12.5812 9.947C12.638 9.68085 12.7452 9.57454 12.8757 9.52234C12.9877 9.47753 13.057 9.4653 13.0932 9.4624C13.1116 9.50797 13.1353 9.59985 13.1412 9.75926C13.148 9.94131 13.1304 10.1742 13.0843 10.4562Z'
            fill='#B42318'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.66666 0H14.606C15.0038 0 15.3854 0.158035 15.6667 0.43934L21.2273 6C21.5086 6.28131 21.6667 6.66284 21.6667 7.06066V21C21.6667 22.6569 20.3235 24 18.6667 24H6.66666C5.0098 24 3.66666 22.6569 3.66666 21V3C3.66666 1.34315 5.0098 0 6.66666 0ZM14.9167 2.25V5.25C14.9167 6.07843 15.5882 6.75 16.4167 6.75H19.4167L14.9167 2.25ZM6.9137 20.5016C7.04919 20.7726 7.25904 21.016 7.57127 21.13C7.8815 21.2433 8.18864 21.1906 8.44118 21.0866C8.91863 20.8898 9.39423 20.4317 9.83025 19.9061C10.3293 19.3045 10.8549 18.5161 11.3619 17.6414C11.8452 17.4975 12.3499 17.3718 12.8576 17.2686C13.3707 17.1643 13.8767 17.0851 14.3571 17.0329C14.8067 17.6065 15.2724 18.1015 15.7233 18.4575C16.1426 18.7887 16.6267 19.0624 17.1237 19.0824C17.3873 19.093 17.651 19.0313 17.888 18.8762C18.1201 18.7243 18.2932 18.5049 18.4198 18.2516C18.5556 17.9802 18.6368 17.6958 18.6263 17.4082C18.6155 17.1107 18.5075 16.8466 18.3261 16.6302C17.9873 16.226 17.4321 16.0313 16.8863 15.9331C16.3161 15.8305 15.6264 15.8114 14.884 15.8583C14.6397 15.5152 14.3987 15.1443 14.1686 14.7561C13.8916 14.2887 13.6364 13.8062 13.4142 13.3296C13.7888 12.3396 14.0696 11.4025 14.1946 10.6377C14.2481 10.3103 14.2759 9.99826 14.2655 9.71743C14.2553 9.44335 14.2074 9.15657 14.075 8.90992C13.9314 8.64217 13.6917 8.43307 13.3599 8.36211C13.0566 8.29726 12.7452 8.36287 12.4578 8.4778C11.892 8.70415 11.5941 9.18174 11.481 9.71244C11.3723 10.2221 11.4213 10.8164 11.5512 11.4171C11.6827 12.025 11.908 12.6883 12.1954 13.3595C11.9881 13.8777 11.7543 14.4129 11.502 14.9474C11.2175 15.5497 10.9135 16.1424 10.6028 16.7C9.7751 16.9713 9.00465 17.298 8.37901 17.6675C7.82325 17.9958 7.33146 18.3868 7.03473 18.8469C6.71884 19.3366 6.62201 19.9183 6.9137 20.5016Z'
            fill='#B42318'
          />
        </svg>
      );
    case 'doc':
    case 'docx':
    case 'txt':
    case 'odt':
    case 'rtf':
      return (
        <svg
          className='doc-file-icon'
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M14.2727 0H6.33334C4.67649 0 3.33334 1.34315 3.33334 3V21C3.33334 22.6569 4.67649 24 6.33334 24H18.3333C19.9902 24 21.3333 22.6569 21.3333 21V7.06066C21.3333 6.66284 21.1753 6.28131 20.894 6L15.3333 0.43934C15.052 0.158035 14.6705 0 14.2727 0ZM14.5833 5.25V2.25L19.0833 6.75H16.0833C15.2549 6.75 14.5833 6.07843 14.5833 5.25ZM7.08334 13.5C6.66913 13.5 6.33334 13.1642 6.33334 12.75C6.33334 12.3358 6.66913 12 7.08334 12H17.5833C17.9976 12 18.3333 12.3358 18.3333 12.75C18.3333 13.1642 17.9976 13.5 17.5833 13.5H7.08334ZM6.33334 15.75C6.33334 15.3358 6.66913 15 7.08334 15H17.5833C17.9976 15 18.3333 15.3358 18.3333 15.75C18.3333 16.1642 17.9976 16.5 17.5833 16.5H7.08334C6.66913 16.5 6.33334 16.1642 6.33334 15.75ZM7.08334 19.5C6.66913 19.5 6.33334 19.1642 6.33334 18.75C6.33334 18.3358 6.66913 18 7.08334 18H13.0833C13.4976 18 13.8333 18.3358 13.8333 18.75C13.8333 19.1642 13.4976 19.5 13.0833 19.5H7.08334Z'
            fill='#155DBF'
          />
        </svg>
      );
    case 'xls':
    case 'ods':
    case 'csv':
    case 'xlsx':
      return (
        <svg
          className='xls-file-icon'
          width='24'
          height='25'
          viewBox='0 0 24 25'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M9 18.5V15.5H13.5V18.5H9Z' fill='#027A48' />
          <path
            d='M13.9393 0.5H6C4.34315 0.5 3 1.84315 3 3.5V21.5C3 23.1569 4.34315 24.5 6 24.5H18C19.6569 24.5 21 23.1569 21 21.5V7.56066C21 7.16284 20.842 6.78131 20.5607 6.5L15 0.93934C14.7187 0.658035 14.3372 0.5 13.9393 0.5ZM14.25 5.75V2.75L18.75 7.25H15.75C14.9216 7.25 14.25 6.57843 14.25 5.75ZM4.5 14H19.5V15.5H15V18.5H19.5V20H15V23H13.5V20H9V23H7.5V20H4.5V18.5H7.5V15.5H4.5V14Z'
            fill='#027A48'
          />
        </svg>
      );
    case 'ppt':
    case 'odp':
    case 'pptx':
      return (
        <svg
          className='ppt-file-icon'
          width='25'
          height='25'
          viewBox='0 0 25 25'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.1667 15.1688V11.3312C11.1667 11.1854 11.3257 11.0954 11.4506 11.1704L14.6487 13.0892C14.7701 13.162 14.7701 13.338 14.6487 13.4108L11.4506 15.3296C11.3257 15.4046 11.1667 15.3146 11.1667 15.1688Z'
            fill='#B54708'
          />
          <path
            d='M14.606 0.5H6.66666C5.0098 0.5 3.66666 1.84315 3.66666 3.5V21.5C3.66666 23.1569 5.0098 24.5 6.66666 24.5H18.6667C20.3235 24.5 21.6667 23.1569 21.6667 21.5V7.56066C21.6667 7.16284 21.5086 6.78131 21.2273 6.5L15.6667 0.93934C15.3854 0.658035 15.0038 0.5 14.606 0.5ZM14.9167 5.75V2.75L19.4167 7.25H16.4167C15.5882 7.25 14.9167 6.57843 14.9167 5.75ZM8.16666 9.5H17.1667C17.5449 9.5 17.864 9.78165 17.9109 10.157L18.6609 16.157C18.6876 16.3704 18.6213 16.5851 18.4789 16.7464C18.3365 16.9076 18.1318 17 17.9167 17H13.4167V20.0236C14.7106 20.1069 15.6667 20.4005 15.6667 20.75C15.6667 21.1642 14.3235 21.5 12.6667 21.5C11.0098 21.5 9.66666 21.1642 9.66666 20.75C9.66666 20.4005 10.6227 20.1069 11.9167 20.0236V17H7.41666C7.20154 17 6.99677 16.9076 6.8544 16.7464C6.71203 16.5851 6.64577 16.3704 6.67245 16.157L7.42245 10.157C7.46937 9.78165 7.78842 9.5 8.16666 9.5Z'
            fill='#B54708'
          />
        </svg>
      );
    default:
      return (
        <svg
          className='default-file-icon'
          width='19'
          height='24'
          viewBox='0 0 19 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M18.3333 6.75V21C18.3333 22.6569 16.9902 24 15.3333 24H3.33331C1.67646 24 0.333313 22.6569 0.333313 21V3C0.333313 1.34314 1.67646 0 3.33331 0H11.5833L18.3333 6.75ZM13.8333 6.75C12.5907 6.75 11.5833 5.74264 11.5833 4.5V1.5H3.33331C2.50489 1.5 1.83331 2.17157 1.83331 3V21C1.83331 21.8284 2.50489 22.5 3.33331 22.5H15.3333C16.1617 22.5 16.8333 21.8284 16.8333 21V6.75H13.8333Z'
            fill='#343A40'
          />
        </svg>
      );
  }
};

export const EmbedCard: React.FC<EmbedCardProps> = ({file, host, enabled, tabIndex}) => {
  // style the card
  return (
    <Box
      alignItems={'center'}
      background={'#F8F9FA'}
      border='1px solid #BDC5CC'
      borderRadius='6px'
      color={'var(--spekit-colors-primary-500)'}
      display='inline-flex'
      flexDirection={file ? 'row' : 'column'}
      gap='8px'
      justifyContent={file ? 'flex-start' : 'center'}
      maxWidth={'400px'}
      minWidth='300px'
      padding='12px'
      sx={{_hover: {boxShadow: '0 1px 2px -2px #10182840', background: '#BDC5CC'}}}
      pointerEvents={enabled ? 'all' : 'none'}
    >
      {/* show file icon and download link if there is a file */}
      {file ? (
        <>
          {getFileIcon(file)}
          {/* host is used to correctly render content in the extension */}
          <Link
            width='100%'
            isExternal={true}
            href={host ? `${host}${file.filepath}` : file.filepath}
            tabIndex={tabIndex}
          >
            {file.filename}
          </Link>
        </>
      ) : (
        <>
          {/* show a loader if there is no file */}
          <Box width={10.5} height={10.5}>
            {/* our loaders don't center nicely. This box fixes that. */}
            <Loader variant='propagate' size={14} />
          </Box>
          <Text color='primary.500'>Loading Content</Text>
        </>
      )}
    </Box>
  );
};
