export const getFriendlyFileSize = (
  fileSizeInBytes: number,
  decimalPlaces: number = 1
) => {
  const fileSizeInKB = fileSizeInBytes / 1024;
  const fileSizeInMB = fileSizeInKB / 1024;

  if (fileSizeInMB >= 1) {
    return `${fileSizeInMB.toFixed(decimalPlaces)} MB`;
  }

  return `${fileSizeInKB.toFixed(decimalPlaces)} KB`;
};
