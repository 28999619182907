import {Modal, ModalBody, ModalHeader, ModalFooter, ModalCloseButton} from '../../Modal';
import {Flex} from '../../Layout';
import {Button} from '../../Button';

interface IConfirmModal {
  isOpen: boolean;
  onClose: () => void;
  handleConfirm: () => void;
  header: React.ReactNode;
  description: React.ReactNode;
  confirmLabel: React.ReactNode;
  isConfirming: boolean;
}

export const ConfirmModal = ({
  isOpen,
  onClose,
  handleConfirm,
  header,
  description,
  confirmLabel,
  isConfirming,
}: IConfirmModal) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>{header}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>{description}</ModalBody>
      <ModalFooter>
        <Flex gap={6}>
          <Button size='medium' variant='ghost' colorScheme='white' onClick={onClose}>
            Cancel
          </Button>
          <Button
            size='medium'
            variant='danger'
            onClick={handleConfirm}
            isLoading={isConfirming}
            data-testid='confirm-btn'
          >
            {confirmLabel}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};
