export const INVALID_FILE_SIZE_MESSAGE =
  'Upload failed. Please only upload files that are 20MB or less and try again.';
export const INVALID_LARGE_FILE_SIZE_MESSAGE =
  'Upload failed. Please only upload files that are 500MB or less and try again.';
export const FILE_SIZE_LIMIT = 1024 * 1024 * 20; // 20 MB
export const LARGE_FILE_SIZE_LIMIT = 1024 * 1024 * 500; // 500 MB
export const INVALID_FILE_MESSAGE =
  'Upload failed. Please only select PDF or PowerPoint files (PPTX) for upload and try again.';
export const SUPPORTED_FORMAT_TEXT = 'Supported formats: PDF, PowerPoint (PPTX)';
export const MAX_FILE_LABEL_CHARACTERS = 255;
export const SUPPORTED_FORMAT_TEXT_EXTENDED =
  'Supported: PDF, PowerPoint (PPTX), Word (DOCX), Excel (XLSX)';
