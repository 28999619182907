import React from 'react';

import {PulseLoader} from 'react-spinners';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';

import styles from './multimediaPreview.module.css';
import Fontawesome from '../../fontawesome';
import {imageToGraphic} from '../../utils/imagesGallery';

interface ImageProps {
  image: string;
  host?: string;
}

interface SystemImageProps {
  image: string;
  handleLibrary: (isOpen: boolean) => void;
}

interface EmbedProps {
  embed: string;
  handleEmbed: (isOpen: boolean) => void;
}

interface Props {
  image: null | string;
  imageLoading: boolean;
  embed: string;
  systemImage: string;
  host?: string;
  handleLibrary: (isOpen: boolean) => void;
  handleEmbed: (isOpen: boolean) => void;
}

const SystemImage: React.FC<SystemImageProps> = ({image, handleLibrary}) => {
  const libraryHandler = () => {
    handleLibrary(true);
  };
  return (
    <div
      style={{background: `url(${imageToGraphic(image)})`}}
      onClick={libraryHandler}
      className={styles.systemImage}
    />
  );
};

const Image: React.FC<ImageProps> = ({image, host}) => {
  return (
    <img
      src={host ? `${host}${image}` : image}
      alt='spekit media'
      className={styles.image}
    />
  );
};

const Embed: React.FC<EmbedProps> = ({embed, handleEmbed}) => {
  const embedHandler = () => {
    handleEmbed(true);
  };
  return (
    <div className={styles.embedContainer} onClick={embedHandler}>
      <div className={styles.embedOverlay}>
        <Fontawesome name={faPencilAlt} />
        <div>Edit Video Embed</div>
      </div>
      <iframe
        frameBorder='0'
        tabIndex={-1}
        title={embed}
        src={embed}
        className={styles.embed}
      />
    </div>
  );
};

const Loading = () => {
  return (
    <div className={styles.loadingContainer}>
      <div>Uploading</div>
      <PulseLoader loading={true} size={10} color='#3576E8' />
    </div>
  );
};

const MultimediaPreview: React.FC<Props> = ({
  image,
  imageLoading,
  embed,
  systemImage,
  host,
  handleLibrary,
  handleEmbed,
}) => {
  return (
    <div className={styles.container}>
      {imageLoading ? (
        <Loading />
      ) : image ? (
        <Image image={image} host={host} />
      ) : embed ? (
        <Embed embed={embed} handleEmbed={handleEmbed} />
      ) : systemImage ? (
        <SystemImage image={systemImage} handleLibrary={handleLibrary} />
      ) : (
        <div className={styles.empty}>
          <Fontawesome name={faPencilAlt} className={styles.pencil} />
          <div className={styles.text}>Add image or video</div>
        </div>
      )}
    </div>
  );
};

export default MultimediaPreview;
