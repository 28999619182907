import {fetchMiddleWare as fetch} from './fetchMiddleWare';
import {CustomError} from './utils/customError';

class FeatureFlagErr extends CustomError {}

export const getFeatures = (): Promise<string[]> => {
  return fetch('/api/feature_flags/', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((r) => r.json())
    .then((r) => {
      if (r && r.count >= 0) {
        return r.results?.map((f: {name: string}) => f.name);
      } else {
        let stringResp;
        try {
          stringResp = JSON.stringify(r);
        } catch (e) {
          stringResp = 'could not stringify response';
        }
        throw new FeatureFlagErr(stringResp);
      }
    });
};
