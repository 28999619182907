import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Alert, AlertDescription, Link as DSLink} from 'spekit-ui';
import {SF_ERRORS} from '../../constants';

interface ISFErrorParams {
  error?: string;
}
const IPBlockedMessage = () => {
  return (
    <>
      There was an error connecting to Salesforce. Visit this{' '}
      <DSLink
        href='https://help.spekit.com/hc/en-us/articles/12563356404507-Salesforce-Troubleshooting#h_01GGR7E02NKVDE1SQ82NTQ5F57'
        isExternal
      >
        article
      </DSLink>{' '}
      for help with troubleshooting or contact us at{' '}
      <DSLink href='mailto:support@spekit.co'> support@spekit.co</DSLink>
    </>
  );
};
const GenericError = () => {
  return (
    <>
      There was an error with your request. Please try again or contact us at{' '}
      <DSLink href='mailto:support@spekit.co'> support@spekit.co</DSLink>
    </>
  );
};

const SFError = () => {
  let {error} = useParams<ISFErrorParams>();
  const [sfError, setSFError] = useState(error);
  const handleAlertClose = () => {
    setSFError('');
  };
  return (
    <>
      {sfError ? (
        <Alert
          mt={20}
          mx={20}
          variant='error'
          onClose={handleAlertClose}
          alignSelf='flex-end'
          data-testid='sf-alert'
        >
          <AlertDescription>
            {error === 'ip-blocked' ? (
              <IPBlockedMessage />
            ) : (
              SF_ERRORS[sfError] || <GenericError />
            )}
          </AlertDescription>
        </Alert>
      ) : null}
    </>
  );
};

export default SFError;
