// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleErrors = (response: Response, result: any) => {
  if (!response.ok && response.status !== 200) {
    if (Array.isArray(result.message)) {
      let message = Object.entries(result.message[0]);
      let errorMessage = message[0].join(' ');
      throw new Error(errorMessage);
    } else if (typeof result.message === 'string') {
      throw new Error(result.message);
    } else if (result.message instanceof Object) {
      let message = Object.values(result.message).pop() || 'Something went wrong';
      throw new Error(String(message));
    }
  }
  if (response.status > 499) throw new Error('ServerError');

  return result;
};
