type TActionType = 'SHOW_CONTENT_IMPORT_MODAL' | 'HIDE_CONTENT_IMPORT_MODAL';

interface IAction {
  type: TActionType;
  payload?: null;
}
const initialState = {
  isOpen: false,
};

function contentImportModalReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case 'SHOW_CONTENT_IMPORT_MODAL':
      return {
        ...state,
        isOpen: true,
      };
    case 'HIDE_CONTENT_IMPORT_MODAL':
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}

export default contentImportModalReducer;
