import React, {useState} from 'react';
import {
  DSModal as Modal,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  DSButton as Button,
  Flex,
} from 'spekit-ui';

interface IResetButton {
  handleReset: () => void;
  disabled: boolean;
}

export const ResetButton = ({handleReset, disabled}: IResetButton) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  return (
    <>
      <Button
        variant='danger'
        size='medium'
        onClick={() => setIsOpen(true)}
        data-testid='reset-to-default-btn'
      >
        Reset to default
      </Button>
      <Modal isOpen={isOpen} onClose={close} size='sm'>
        <ModalHeader>Reset all changes to default</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text variant='body2' color='neutral.80'>
            Are you sure? All of your changes will be reverted and the default selections
            will be applied.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex gap={6}>
            <Button
              onClick={close}
              colorScheme='white'
              size='medium'
              variant='ghost'
              data-testid='reset-cancel-btn'
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                close();
                handleReset();
              }}
              size='medium'
              variant='danger'
              data-testid='reset-confirm-btn'
              disabled={disabled}
            >
              Yes, reset changes
            </Button>
          </Flex>
        </ModalFooter>
      </Modal>
    </>
  );
};
