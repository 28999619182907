import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useForm, FormProvider} from 'react-hook-form';
import {RiArrowLeftLine} from 'react-icons/ri';
import {Flex, Icon, Heading, Text, Loader, DSButton as Button} from 'spekit-ui';
import LoginLayout from '../../components/LoginLayout';
import UsernameInput from '../../components/LoginInputFields/UsernameInput';
import {ILoginFormState} from 'spekit-types';
import ResetPasswordEmailConfirmation from './ResetPasswordEmailConfirmation';
import {retrieveUsernamePassword, utils} from 'spekit-datalayer';
import {getExpirationTime} from '../../helpers';

const ResetPasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [expiryTime, setExpiryTime] = useState<number>();
  const [isResetPasswordRequest, setIsResetPasswordRequest] = useState(false);
  const methods = useForm<ILoginFormState>();
  const history = useHistory();

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    methods.clearErrors();
    methods.setValue('username', e.target.value);
  };

  const resetPasswordHandler = async (data: ILoginFormState) => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const result = await retrieveUsernamePassword.resetPassword(data.username.trim());
        if (result.success) {
          setIsResetPasswordRequest(true);
          setExpiryTime(result.link_expiry_time);
        } else {
          if (result.logged_in) {
            const {redir} = utils.parseQs(history.location.search);
            history.push(redir ? decodeURIComponent(redir as string) : '/app/generate');
          }
          const message = result.wait
            ? `Too many request to reset password. Please wait and try again in ${getExpirationTime(
                result.wait
              )}.`
            : result.message;

          methods.setError('username', {
            type: 'general',
            message: message,
          });
        }
      } catch (e) {
        methods.setError('username', {
          type: 'general',
          message: e.message,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <LoginLayout>
      <Flex direction='column' px={144} maxW={384} pt={176}>
        <Flex alignItems='flex-start'>
          <Button
            data-testid='back-btn'
            size='small'
            variant='ghost'
            colorScheme='primary'
            leftIcon={<Icon as={RiArrowLeftLine} />}
            onClick={() =>
              history.push({
                pathname: '/login',
                search: history.location.search,
                state: {loginFlow: true},
              })
            }
          >
            Back to login
          </Button>
        </Flex>
        {isResetPasswordRequest ? (
          <ResetPasswordEmailConfirmation expiryTime={expiryTime} />
        ) : (
          <>
            <Heading
              as='h3'
              fontWeight='semibold'
              fontSize='20px'
              textAlign='center'
              mt={20}
            >
              Reset your Spekit password
            </Heading>
            <Text variant='body2' fontWeight='normal' textAlign='center' mt={16} mb={32}>
              Enter the username associated with your Spekit account and we’ll send you an
              email with a link to reset your password.
            </Text>

            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(resetPasswordHandler)}>
                <Flex gap={20} direction='column'>
                  <UsernameInput
                    hideforgotlink
                    label='Spekit username'
                    placeholder='Enter your username'
                    requiredmsg='Username is required.'
                    onChange={handleUsernameChange}
                  />

                  {isLoading ? (
                    <Flex justifyContent='center' alignItems='center' py={20}>
                      <Loader size={8} />
                    </Flex>
                  ) : (
                    <Button
                      data-testid='reset-password-btn'
                      size='large'
                      variant='contained'
                      colorScheme='primary'
                      onClick={methods.handleSubmit(resetPasswordHandler)}
                    >
                      Reset my password
                    </Button>
                  )}
                </Flex>
              </form>
            </FormProvider>
          </>
        )}
      </Flex>
    </LoginLayout>
  );
};

export default ResetPasswordPage;
