import React from 'react';
import {Box, FormControl, FormLabel, Select, FormErrorMessage} from 'spekit-ui';
import {Dispatch, RuleSelectorSelectProps} from '../types';

const options = [
  {label: 'Specific URL', value: 'url-contains', type: 'url'},
  {label: 'Any URL', value: 'any-url', type: 'anywhere'},
];

interface IDisplaySelectorProps {
  onSelect: (value: RuleSelectorSelectProps) => void;
  value: string | null;
  disabled: boolean;
  error?: string;
  dispatch: Dispatch;
}

const DisplaySelector = (props: IDisplaySelectorProps) => {
  const {onSelect, value, disabled, error, dispatch} = props;

  const handleChange = (newValue: any) => {
    dispatch({
      type: 'SET_PUBLISH_ERROR',
      payload: '',
    });
    onSelect({label: newValue.label, value: newValue.value, type: newValue.type});
  };

  return (
    <Box p={20} data-testid='display-options' width='80%' isInvalid={!!error}>
      <FormControl isRequired isInvalid={!!error}>
        <FormLabel>Display Spotlight on</FormLabel>
        <Select
          options={options}
          isDisabled={disabled}
          isInvalid={!!error}
          value={options.filter((option) => option.type === value)[0]}
          onChange={handleChange}
          placeholder='Select where the Spotlight will display'
        />
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    </Box>
  );
};

export default DisplaySelector;
