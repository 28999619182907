import React from 'react';
import {
  Textarea as ChakraTextarea,
  TextareaProps,
  ComponentStyleConfig,
  forwardRef,
} from '@chakra-ui/react';
import {FONT_FAMILY} from '../../Typography';

export type TTextareaProps = TextareaProps;

export const TextareaConfig: ComponentStyleConfig = {
  baseStyle: {fontFamily: FONT_FAMILY},
  variants: {
    outline: ({theme}) => {
      const {colors} = theme;
      return {
        borderRadius: '8',
        border: '1px solid',
        borderColor: 'neutral.300',
        fontSize: 'small',
        fontWeight: 'normal',
        color: 'neutral.800',
        bg: 'neutral.0',
        _placeholder: {
          color: 'neutral.500',
        },
        _focus: {
          borderColor: 'primary.500',
          boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.primary['100']}`,
        },
        _disabled: {
          color: 'neutral.500',
          bg: 'neutral.25',
          opacity: '1',
        },
        _invalid: {
          boxShadow: 'none',
          borderColor: 'error.600',
          _focus: {
            borderColor: 'error.600',
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.error['100']}`,
          },
        },
      };
    },
  },
  sizes: {
    md: {
      boxSizing: 'border-box',
      py: 8,
      px: 12,
    },
  },
  defaultProps: {variant: 'outline'},
};
export const Textarea = forwardRef((props: TTextareaProps, ref) => (
  <ChakraTextarea ref={ref} {...props} />
));
