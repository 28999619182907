import {getEnvironment} from '../utils/commonUtils';
import {getCurrentTab} from './helpers';

export async function getSearchParam() {
  const environment = getEnvironment();
  if (environment === 'webapp') {
    return window.location.search;
  } else {
    const tab = await getCurrentTab();
    const url = new URL(tab.url || '');
    return url.search;
  }
}
