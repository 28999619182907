import {ISeismicStatusResponse} from 'spekit-types';
import {retrieve, create} from '../utils/APIHelpers';

export const getSeismicStatus = async (): Promise<ISeismicStatusResponse> => {
  const response: ISeismicStatusResponse = (await retrieve(
    '/seismic/v1/status'
  )) as ISeismicStatusResponse;
  return response;
};

export const requestSeismicActivation = async (): Promise<unknown> => {
  const response: unknown = await create('/seismic/v1/approval');
  return response;
};

export const connectSeismic = async (): Promise<unknown> => {
  const response: unknown = await retrieve(
    '/seismic/connect?install=true',
    'text/html; charset=utf-8'
  );
  return response;
};
