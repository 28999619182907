import {useState} from 'react';
import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Icon,
  Text,
  InputRightElement,
  InputGroup,
} from 'spekit-ui';
import {UseFormReturn} from 'react-hook-form';
import {IPasswordFormState} from 'spekit-types';
import {RiCloseLine, RiCheckboxCircleLine, RiEyeOffLine, RiEyeLine} from 'react-icons/ri';
interface IProps {
  passwordLabel: string;
  confirmPasswordLabel: string;
  methods: UseFormReturn<IPasswordFormState>;
  resetPasswordHandler: (data: IPasswordFormState) => void;
}

const Password = (props: IProps) => {
  const {passwordLabel, confirmPasswordLabel, methods, resetPasswordHandler} = props;
  const {
    watch,
    register,
    formState: {errors, isSubmitted},
    trigger,
  } = methods;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showValidators, setIsShowValidators] = useState(false);
  const password = watch('password');

  const validatePasswordOnSubmission = () => {
    trigger('confirmPassword');
    return true;
  };

  const validatePasswords = (confirmPassword: string) => {
    return confirmPassword === password;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && isPasswordValidated) {
      methods.handleSubmit(resetPasswordHandler)();
    }
  };
  const isPasswordValidated = password && !errors?.password;
  const showPasswordIcon = !errors.password || !isSubmitted;
  const showConfirmPasswordIcon = !errors.confirmPassword || !isSubmitted;
  return (
    <Flex gap={20} direction='column' w='265px'>
      <FormControl isInvalid={!!errors.password && isSubmitted}>
        <FormLabel>{passwordLabel}</FormLabel>
        <InputGroup>
          <InputRightElement
            data-testid='show-password-icon'
            cursor='pointer'
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {showPasswordIcon && <>{showPassword ? <RiEyeLine /> : <RiEyeOffLine />} </>}
          </InputRightElement>
          <Input
            data-testid='password'
            isInvalid={!!errors.password && isSubmitted}
            autoComplete='off'
            placeholder='Enter password'
            type={showPassword ? 'text' : 'password'}
            {...register('password', {
              required: 'This field is required.',
              validate: {
                minLength: (v) =>
                  v.length >= 8 || 'Password doesn’t meet the minimum requirements.',
                uppercase: (v) =>
                  /[A-Z]/.test(v) || 'Password doesn’t meet the minimum requirements.',
                symbol: (v) =>
                  /[-[+\]=_!@#$%^&*/\\(),.?"':;{}|<>]/.test(v) ||
                  'Password doesn’t meet the minimum requirements.',
                numbers: (v) =>
                  /\d/.test(v) || 'Password doesn’t meet the minimum requirements.',
                passwordMatch: () => validatePasswordOnSubmission(),
              },
            })}
            onFocus={() => setIsShowValidators(true)}
            onKeyDown={handleKeyDown}
          />
        </InputGroup>

        {errors.password && (
          <FormErrorMessage data-testid='password-error' wordBreak='break-word'>
            {errors.password.message}
          </FormErrorMessage>
        )}
      </FormControl>

      <FormControl isInvalid={!!errors.confirmPassword && isSubmitted}>
        <FormLabel>{confirmPasswordLabel}</FormLabel>
        <InputGroup>
          <InputRightElement
            data-testid='show-confirm-password-icon'
            cursor='pointer'
            onClick={() => {
              setShowConfirmPassword(!showConfirmPassword);
            }}
          >
            {showConfirmPasswordIcon && (
              <>{showConfirmPassword ? <RiEyeLine /> : <RiEyeOffLine />} </>
            )}
          </InputRightElement>
          <Input
            data-testid='confirm-password'
            isInvalid={!!errors.confirmPassword && isSubmitted}
            autoComplete='off'
            placeholder='Re-enter password'
            type={showConfirmPassword ? 'text' : 'password'}
            {...register('confirmPassword', {
              required: 'This field is required.',
              validate: {
                passwordMatch: (v) => validatePasswords(v) || 'Passwords do not match.',
              },
            })}
            onKeyDown={handleKeyDown}
          />
        </InputGroup>

        {errors.confirmPassword && (
          <FormErrorMessage data-testid='confirm-password-error' wordBreak='break-word'>
            {errors.confirmPassword.message}
          </FormErrorMessage>
        )}
      </FormControl>

      {showValidators && (
        <Flex direction='column' gap={16}>
          <Flex gap={10} alignItems='center'>
            {(!errors.password && !password) || errors.password?.types?.minLength ? (
              <Icon as={RiCloseLine} color='error.500' data-testid='length-error' />
            ) : (
              <Icon
                as={RiCheckboxCircleLine}
                color='success.400'
                data-testid='length-success'
              />
            )}

            <Text variant='body2'>At least 8 characters</Text>
          </Flex>
          <Flex gap={10} alignItems='center'>
            {(!errors.password && !password) || errors.password?.types?.uppercase ? (
              <Icon as={RiCloseLine} color='error.500' data-testid='uppercase-error' />
            ) : (
              <Icon
                as={RiCheckboxCircleLine}
                color='success.400'
                data-testid='uppercase-success'
              />
            )}

            <Text variant='body2'>1 uppercase character</Text>
          </Flex>
          <Flex gap={10} alignItems='center'>
            {(!errors.password && !password) || errors.password?.types?.numbers ? (
              <Icon as={RiCloseLine} color='error.500' data-testid='numbers-error' />
            ) : (
              <Icon
                as={RiCheckboxCircleLine}
                color='success.400'
                data-testid='numbers-success'
              />
            )}
            <Text variant='body2'>1 number</Text>
          </Flex>
          <Flex gap={10} alignItems='center'>
            {(!errors.password && !password) || errors.password?.types?.symbol ? (
              <Icon as={RiCloseLine} color='error.500' data-testid='symbol-error' />
            ) : (
              <Icon
                as={RiCheckboxCircleLine}
                color='success.400'
                data-testid='symbol-success'
              />
            )}
            <Text variant='body2'>1 symbol</Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default Password;
