const initialState = {
  status: null,
  tasks: [],
};

function topicIndexReducer(state = initialState, action) {
  switch (action.type) {
    case 'TOPICREINDEXED':
      let taskIndex = state.tasks.findIndex((t) => t.id === action.taskId);
      if (taskIndex !== -1) {
        return {
          ...state,
          status: action.status,
          tasks: state.tasks.filter((t) => {
            return t.id !== action.taskId;
          }),
        };
      }
      return {
        ...state,
      };
    case 'ADDINDEXINGTASK':
      return {
        ...state,
        tasks: [
          ...state.tasks,
          {
            id: action.taskId,
            label: action.label,
          },
        ],
      };
    default:
      return state;
  }
}

export default topicIndexReducer;
