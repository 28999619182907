import {fetchMiddleWare as fetch} from './fetchMiddleWare';
import {capture} from './logging';

export const uploadFile = async (file: any): Promise<any> => {
  try {
    let formData = new FormData();
    formData.append('file', file);
    let response = await fetch('/api/media/handle_upload/', {
      credentials: 'include',
      method: 'post',
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    });
    if (response.status === 413) {
      throw new Error(JSON.stringify(413));
    } else if (response.status === 403) {
      throw new Error(JSON.stringify(403));
    }

    let result = await response.json();
    if (response.status === 400 && result.message === 'Invalid format of file') {
      throw new Error(result.message);
    }
    if (result.url && result.url.view) {
      return result;
    } else {
      throw new Error(JSON.stringify(result.success));
    }
  } catch (e) {
    capture(e);
    throw e;
  }
};
