import isURL from 'validator/lib/isURL';

/**
 * validate url on the basis of
 * all required attributes
 * @param {string} str
 * @return {boolean}
 */
export const validateURL = (str: string): boolean => {
  return isURL(str, {
    protocols: ['http', 'https'],
    require_protocol: true,
    require_valid_protocol: true,
  });
};
