interface ActionType {
  type: 'SET_ASYNC_SPOTLIGHT_CREATE';
  payload?: any;
}

const initialState = {
  asyncSpotlightCreate: null,
};

function asyncSpotlightCreateReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case 'SET_ASYNC_SPOTLIGHT_CREATE':
      return {
        ...state,
        asyncSpotlightCreate: action.payload,
      };
    default:
      return state;
  }
}

export default asyncSpotlightCreateReducer;
