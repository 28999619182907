import {useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Flows} from 'spekit-datalayer';
import ExtensionActions from '../../../utils/ExtensionActions';

interface RouteParams {
  flowId: string;
}

interface IUseFlow {
  action: 'previewFlow' | 'viewFlow';
}

export const useFlow = ({action}: IUseFlow) => {
  const {flowId} = useParams<RouteParams>();
  const history = useHistory();

  useEffect(() => {
    const fetchFlowAndSendChromeMessage = async () => {
      try {
        await Flows.getFlowDetails(flowId);
        ExtensionActions.handleView({
          action,
          data: {flowId, sessionId: null},
          history,
        });
      } catch (e) {
        history.push('/app/wiki/flow-permission-denied');
      }
    };
    fetchFlowAndSendChromeMessage();
  }, [flowId, history, action]);
};
