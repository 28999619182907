import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useEffect} from 'react';
import {TElementWithVisibility, isComponentVisible} from 'spekit-shared-components';
import {closeTopicModal, addIndexingTask} from '../../../../redux/actions';
import {RootState} from '../../../../reduxStore';
import TopicForm from './TopicForm';

/**
 * This component renders null if the topic is closed
 * it is not always rendered + clearing state is handled on auto and we dont have to
 * check componentDidUpdate and clear or update state manually.
 */
// need to fix delete modal
export const TopicFormContainer = () => {
  const {toggle, term: topic} = useSelector((state: RootState) => state.topicModal);
  const flag = useSelector((state: RootState) => state.flag);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const handleClose = () => dispatch(closeTopicModal());
  const handleAddTask = (id: string, label: string) =>
    dispatch(addIndexingTask(id, label));

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (toggle) {
      timeout = setTimeout(() => {
        const el = document.getElementById('Topic Modal') as TElementWithVisibility;
        isComponentVisible({
          element: el,
          message: 'Topic create modal is not visible',
          tag: 'Topic-create-modal',
        });
      }, 4000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [toggle]);
  if (!toggle) return null;

  return (
    <TopicForm
      addIndexingTask={handleAddTask}
      closeTopicModal={handleClose}
      flag={flag}
      topic={topic}
      toggle={toggle}
      history={history}
      location={location}
      mode={topic ? 'edit' : 'create'}
    />
  );
};
