import React from 'react';
import {
  RadioGroup as ChakraRadioGroup,
  Radio as ChakraRadio,
  RadioGroupProps,
  RadioProps,
  forwardRef,
} from '@chakra-ui/react';
import {Flex} from '../Layout/Flex';
import {Text} from '../Typography/Text';
import {StyleConfig} from '../types';

export type TRadioGroupProps = RadioGroupProps;
export interface IRadioProps extends RadioProps {
  helperText?: string;
}

export const RadioConfig: StyleConfig = {
  parts: ['container', 'control', 'label'],
  baseStyle: {
    container: {},
    control: {
      position: 'relative',
      top: '1px',
    },
  },
  variants: {
    default: ({theme}) => {
      const {colors} = theme;
      return {
        container: {
          borderColor: 'primary.500',
        },
        control: {
          _hover: {
            backgroundColor: 'primary.25',
          },
          _focus: {
            boxShadow: `0px 0px 0px 4px ${colors['primary']['100']}`,
          },
          _disabled: {
            backgroundColor: 'neutral.50',
            borderColor: 'neutral.500',
            color: 'neutral.500',
            cursor: 'not-allowed',
          },
          _checked: {
            borderColor: 'primary.500',
            color: 'primary.500',
            background: 'white',
            _hover: {
              borderColor: 'primary.500',
              backgroundColor: 'primary.25',
            },
            _disabled: {
              backgroundColor: 'neutral.50',
              borderColor: 'neutral.500',
              color: 'neutral.500',
              cursor: 'not-allowed',
            },
          },
        },
        label: {
          m: 0,
          fontSize: 'small',
          fontWeight: 'normal',
          color: 'neutral.800',
          _disabled: {
            color: 'neutral.500',
            cursor: 'not-allowed',
          },
        },
      };
    },
  },
  sizes: {
    default: {
      control: {
        borderRadius: '50%',
        h: '16px',
        w: '16px',
        me: '8px',
      },
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'default',
  },
};

export const RadioGroup = forwardRef((props: TRadioGroupProps, ref) => (
  <ChakraRadioGroup ref={ref} {...props} />
));

export const Radio = forwardRef((props: IRadioProps, ref) => {
  const {helperText, ...chakraProps} = props;
  return (
    <ChakraRadio ref={ref} {...chakraProps} alignItems='flex-start'>
      <Flex direction='column'>
        <Text variant='none' size='none'>
          {chakraProps.children}
        </Text>
        {helperText && (
          <Text data-testid='helper-text' variant='body2' fontWeight='normal'>
            {helperText}
          </Text>
        )}
      </Flex>
    </ChakraRadio>
  );
});
