import {Component} from 'react';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {Fontawesome} from 'spekit-ui';
import {Button} from 'spekit-ui';
class StopImpersonateBanner extends Component {
  render() {
    let styles = {
      main: {
        position: 'relative',
        zIndex: '1',
        background: 'linear-gradient(45deg, #C673D2 0%, #3576E8 100%)',
        boxShadow: '0 10px 20px 0 rgba(0,0,0,0.2)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      textContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '41px',
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontSize: '16px',
        fontWeight: '800',
        letterSpacing: '0.11px',
        lineHeight: '22px',
        textAlign: 'center',
        marginTop: '2px',
        marginBottom: '3px',
      },
      stopImpersonatingButton: {
        marginLeft: '15px',
        display: 'flex',
        justifyContent: 'center',
        height: '30px',
        width: '184px',
        borderRadius: '18px',
        color: '#3576E8',
        backgroundColor: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '19px',
        padding: '0px 0px 0px',
      },
    };
    return (
      <div style={styles.main}>
        <div style={styles.textContainer}>
          <span>
            <Fontawesome name={faExclamationTriangle} /> You are currently impersonating{' '}
            {this.props.firstName} {this.props.lastName}
          </span>
          <Button style={styles.stopImpersonatingButton} onClick={this.props.onClick}>
            Stop Impersonating
          </Button>
        </div>
      </div>
    );
  }
}

export default StopImpersonateBanner;
