import React from 'react';
import {FormControl, FormHelperText, FormErrorMessage, FormLabel, Input} from 'spekit-ui';
interface IDestinationInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  placeholder?: string;
  error?: boolean;
  errorText?: string;
  helperText?: string;
  maxLength?: number;
  hasSpotlightsRevampFlag?: boolean;
  formControlDataTestId?: string;
}

const DestinationInput = ({
  value,
  onChange,
  disabled,
  onBlur,
  label,
  placeholder,
  error,
  errorText,
  helperText,
  maxLength,
  hasSpotlightsRevampFlag,
  formControlDataTestId,
}: IDestinationInputProps) => {
  return (
    <FormControl
      isRequired
      isInvalid={error}
      style={hasSpotlightsRevampFlag ? {} : {width: '180px'}}
      data-test-id={formControlDataTestId}
    >
      <FormLabel mt={0}>{label}</FormLabel>
      <Input
        isRequired
        placeholder={placeholder}
        isInvalid={error}
        maxLength={maxLength ? maxLength : -1}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
      />

      <FormHelperText>{helperText}</FormHelperText>

      <FormErrorMessage data-test-id='button-text-error-message'>
        {error && errorText}
      </FormErrorMessage>
    </FormControl>
  );
};

export default DestinationInput;
