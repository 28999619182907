import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import uploadIcon from '../icons/upload.svg';
import embeddPdfIcon from '../icons/embed-pdf.svg';
import {logging, spekAPI} from 'spekit-datalayer';
import {checkForFileType, createInputElement, supportedFileInputs} from './util';
import Editor from '@ckeditor/ckeditor5-core/src/editor/editor';
import Item from '@ckeditor/ckeditor5-engine/src/model/item';
import {IRTEFileUploadResponse} from 'spekit-types';

interface UploadConfig {
  onError: (message: string) => void;
  host: string;
  // these are redux actions passed down from the parent component in the extension or web app
  // they disable/enable the save button
  onUploadStart: () => void;
  onUploadEnd: () => void;
}

interface EventData {
  dataTransfer: DataTransfer;
  preventDefault: () => void;
}

export default class InsertFile extends Plugin {
  init() {
    const editor = this.editor;

    // handle file drop
    editor.editing.view.document.on(
      'drop',
      (event, data: EventData) => {
        /**
         * Dragging content within the editor sometimes adds the dragged image to the dataTransfer.files array (Windows)
         * Example: dragging image content from one place in the editor to another is an internal drag and drop event
         * We let CKEditor handle all internal drag and drop events, so we check for it and only handle external drop events
         * Example: dragging an image into the editor from somewhere else (file system, browser, etc.) is an external drop event
         */
        const isExternalDropEvent = !data.dataTransfer.types.includes(
          'application/ckeditor5-dragging-uid'
        );
        if (isExternalDropEvent && data.dataTransfer.files?.length > 0) {
          // stop default drop event
          event.stop();
          data.preventDefault();

          // call insert handler
          this.insert(data.dataTransfer.files[0], editor);
        }
      },
      {priority: 'high'}
    );

    // handle paste
    editor.editing.view.document.on(
      'paste',
      async (event, data: EventData) => {
        /* 
        some apps place erroneous image content in the clipboard when text is copied
        skip any paste event with text content, we only care about files and images
        */
        const textContent = data.dataTransfer.getData('text/plain');

        if (data.dataTransfer.files?.length > 0 && !textContent) {
          // stop default paste event. pasting images can sometimes cause a duplicate if the editor handles the paste event
          event.stop();
          data.preventDefault();

          // call insert handler
          this.insert(data.dataTransfer.files[0], editor);
        }
      },
      {priority: 'high'}
    );

    // add new command to editor that handles file upload
    editor.ui.componentFactory.add('uploadFromComputer', () => {
      // create a toolbar button
      const button = new ButtonView();

      // set button options
      button.set({
        label: 'Upload from computer',
        icon: uploadIcon,
        tooltip: true,
      });

      // add execute (click) listener to button
      button.on('execute', () => {
        // create a file input
        const inputElement = createInputElement(supportedFileInputs.join());
        // add change listener to file input
        inputElement.addEventListener('change', (event) => {
          // call insert handler when file is selected
          const input = event.target as HTMLInputElement;
          const file = input?.files?.[0];
          if (file) {
            this.insert(file, editor);
          }

          // remove file input from DOM
          inputElement.remove();
        });

        // perform click on file input
        inputElement.dispatchEvent(new MouseEvent('click'));
      });

      return button;
    });

    editor.ui.componentFactory.add('embedPdf', () => {
      // create a toolbar button
      const button = new ButtonView();

      // set button options
      button.set({
        label: 'Embed PDF',
        icon: embeddPdfIcon,
        tooltip: true,
      });

      // add execute (click) listener to button
      button.on('execute', () => {
        const inputElement = createInputElement('application/pdf');

        // add change listener to file input
        inputElement.addEventListener('change', (event) => {
          // call insert handler when file is selected
          const input = event.target as HTMLInputElement;
          const file = input?.files?.[0];
          if (file) {
            this.insert(file, editor, true);
          }

          // remove file input from DOM
          inputElement.remove();
        });

        // perform click on file input
        inputElement.dispatchEvent(new MouseEvent('click'));
      });

      return button;
    });
  }

  // upload file and insert into editor
  insert(file: File, editor: Editor, isPdfEmbed?: boolean) {
    // only handle if file is provided
    if (file) {
      // get error handler function
      const {onError, onUploadStart, onUploadEnd, host} = editor.config.get(
        'uploadConfig'
      ) as UploadConfig;

      // show error if file type is not supported
      const isValidFileType = checkForFileType(file);
      if (!isValidFileType) {
        return onError('Unsupported file type');
      }

      // show error if file is too big
      if (file.size > 20972544) {
        return onError('Files must be less than 20 MB in size');
      }

      // executing embedContent command will show a loading indicator if no file is provided
      const uploadLoading: Item = editor.execute('embedContent', null) as Item;

      // create upload payload
      const formData = new window.FormData();
      formData.append('file', file);

      onUploadStart();
      // upload file
      return spekAPI
        .uploadFileRTE(formData)
        .then((res: IRTEFileUploadResponse) => {
          if (res.url && res.url.view) {
            editor.model.change((writer) => {
              // get position of placeholder to embed content
              const position = writer.createPositionAt(uploadLoading, 'before');

              // remove placeholder
              writer.remove(uploadLoading);

              // if image, embed img tag. CKEditor Image plugin will handle the rest
              if (file.type.includes('image')) {
                const content = `<img src = "${
                  host
                    ? `${host}${res.url.view}`
                    : `${window.location.origin}${res.url.view}`
                }" alt = "${res.file_name}" /> `;
                const viewFragment = editor.data.processor.toView(content);
                const modelFragment = editor.data.toModel(viewFragment);
                writer.insert(modelFragment, position);
              } else if (isPdfEmbed) {
                // host is only set in the extension
                const iframeSrc = host
                  ? `${host}${res.url.view}`
                  : `${window.location.origin}${res.url.view}`;

                editor.execute('embedIframe', iframeSrc, null, JSON.stringify({}));
              } else {
                // if not image or PDF, embed custom embed schema
                const embeddedFile = {
                  filepath: res.url.download,
                  filename: res.file_name,
                  filetype: res.content_type,
                };

                editor.execute('embedContent', JSON.stringify(embeddedFile), position);
              }
            });
          } else {
            throw new Error(JSON.stringify(res.success));
          }
        })
        .catch((err) => {
          // This error handling mimics the error handling in the old editor

          // get position of placeholder and remove it
          editor.model.change((writer) => {
            writer.remove(uploadLoading);
          });

          // handle error codes
          if (err.message === '403') {
            return onError(
              'You’ve reached the media hosting limit for your Spekit account. Please contact us to upgrade.'
            );
          } else if (err.message === '413') {
            return onError('Files must be less than 20 MB in size.');
          } else if (err.message === 'false') {
            return onError('There was an error uploading your file');
          }

          // fallback error
          onError('There was an error uploading your file');

          if (typeof err !== 'boolean') {
            logging.capture(err);
          }
        })
        .finally(onUploadEnd);
    }
  }
}
