import {useHistory} from 'react-router-dom';
import {Flex, Heading, Text, DSButton as Button} from 'spekit-ui';

const PasswordConfirmation = () => {
  const history = useHistory();

  return (
    <Flex direction='column' px={144} maxW={450} pt={112}>
      <Heading as='h3' fontWeight='semibold' fontSize='20px' textAlign='center'>
        Success!
      </Heading>
      <Text variant='body2' fontWeight='normal' textAlign='center' mt={20} mb={32}>
        Your password has been reset.
      </Text>
      <Button
        data-testid='redirect-login'
        size='large'
        variant='contained'
        colorScheme='primary'
        onClick={() => history.push('/login')}
      >
        Login to Spekit
      </Button>
    </Flex>
  );
};
export default PasswordConfirmation;
