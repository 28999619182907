import styled, {css} from 'styled-components';
import {Fontawesome} from 'spekit-ui';
import {Link} from 'react-router-dom';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
`;

export const Heading = styled.label`
  color: #56606a;
  font-family: 'Open Sans';
  font-size: 15px;
  letter-spacing: 0.1px;
  overflow: hidden;
  white-space: normal;
  max-height: 42px;
  text-overflow: ellipsis;
  line-height: 20px;
  ${({active}: any) =>
    active &&
    `
    background: blue;
  `}
`;

export const HeadingLink = styled(Link)`
  color: #56606a;
  font-family: 'Open Sans';
  font-size: 15px;
  letter-spacing: 0.1px;
  overflow: hidden;
  white-space: normal;
  max-height: 42px;
  text-overflow: ellipsis;
  line-height: 20px;
  text-decoration: none;
`;

export const Description = styled.label`
  color: #9fa9ba;
  font-family: 'Open Sans';
  font-size: 12px;
  letter-spacing: 0.08px;
  white-space: normal;
`;

const ActionStyles = css`
  color: #3576e8;
  font-family: 'Open Sans';
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-right: 5px;
  width: 100px;
`;

export const StyledLabel = styled(Link)`
  ${ActionStyles}
  text-decoration: none;
`;

export const StyledLabelText = styled.label`
  ${ActionStyles}
  cursor: pointer;
`;

export const StyledFontAwesome = styled(Fontawesome)`
  ${ActionStyles}
`;

export const LockIcon = styled(Fontawesome)`
  height: 15px;
  width: 14px;
  color: #56606a;
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 15px;
  margin-left: 10px;
`;

export const HeadingContainer = styled.div`
  display: flex;
`;

export const ActionItem = styled.div`
  margin-bottom: 10px;
`;
export const styles = {
  idpTeamIcon: {
    marginLeft: '10px',
  },
  idpTooltipText: {
    textAlign: 'center',
  },
} as any;
