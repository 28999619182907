import {IFlagState} from 'spekit-types';
import {FLAGS} from 'spekit-datalayer';
type ActionType = 'SET_FLAGS';

interface Action {
  type: ActionType;
  payload: IFlagState;
}

/**
 * Uses the flags defined in the FLAGS enum and
 * creates the initial state of the flags.
 * The state is a map of the form `{hasXFlag: boolean}`.
 *
 * @returns {IFlagState} - The initial state of the flags reducer
 */
const getInitialState = () => {
  let flagState = {};
  for (const flag in FLAGS) {
    flagState[`has${flag}Flag`] = false;
  }
  return flagState as IFlagState;
};

let initialState = {...getInitialState(), hasLoaded: false};

function flagsReducer(state = initialState, action: Action) {
  switch (action.type) {
    case 'SET_FLAGS':
      const payload = {};
      for (const flag in FLAGS) {
        if (FLAGS.hasOwnProperty(flag)) {
          const key = `has${flag}Flag`;
          payload[key] = action.payload[key];
        }
      }

      return {
        ...state,
        ...payload,
        hasLoaded: true,
      };
    default:
      return state;
  }
}

export default flagsReducer;
