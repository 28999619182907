import {ITopicParams, ITopicResponse} from 'spekit-types';
import {retrieve} from '../utils/APIHelpers';
import {stringifyQs} from '../utils/commonUtils';
import {TOPIC_API} from '../api';

export const getTopics = async (params: ITopicParams): Promise<ITopicResponse> => {
  const filters = stringifyQs(params);
  const results: ITopicResponse = await retrieve(`${TOPIC_API}?${filters}`);
  return results;
};
