import {useEffect, useState} from 'react';
import {v4 as uniqueId} from 'uuid';

const SESSION_EXPIRY_DURATION = 2 * 60 * 60 * 1000; // 2 hours

export const useSessionExpiry = () => {
  const [sessionId, setSessionId] = useState<string | null>(null);

  useEffect(() => {
    const generateSession = () => {
      const newSessionId = uniqueId();
      const sessionDetail = {
        session_id: newSessionId,
        session_start_timestamp: Date.now(),
      };
      sessionStorage.setItem('session', JSON.stringify(sessionDetail));
      setSessionId(newSessionId);
    };

    generateSession();
    const intervalId = setInterval(generateSession, SESSION_EXPIRY_DURATION);

    return () => clearInterval(intervalId);
  }, []);

  return sessionId;
};
