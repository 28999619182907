import React from 'react';
import {
  Stack as ChakraStack,
  HStack as ChakraHStack,
  VStack as ChakraVStack,
  StackProps,
  forwardRef,
} from '@chakra-ui/react';
import {FONT_FAMILY} from '../../Typography';

export type TStackProps = StackProps;

export const Stack = (props: TStackProps) => (
  <ChakraStack {...props} fontFamily={FONT_FAMILY} />
);
export const HStack = (props: TStackProps) => (
  <ChakraHStack {...props} fontFamily={FONT_FAMILY} />
);
export const VStack = forwardRef((props: TStackProps, ref) => (
  <ChakraVStack {...props} fontFamily={FONT_FAMILY} />
));
