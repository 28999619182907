import {
  TCreateSpekPayload,
  TEditSpekResponse,
  TEditTerm,
  TGetSpekPayload,
} from 'spekit-types';
import {prepareNotificationPayload} from '../notification/transformers';

export const getMediaIds = (definition: string) => {
  const mediaIds = definition.match(
    /(\/media\/)[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gm
  );
  if (!mediaIds) return [];
  return mediaIds.map((id) => id.replace(/\/media\//g, ''));
};

export const sanitizeDefinition = (definition: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = definition;
  // remove all the undo loading containers.
  tempDiv.querySelectorAll('.upload-embed').forEach((element) => {
    if (element.getAttribute('data-file') === 'undefined') {
      element.parentNode?.removeChild(element);
    }
  });
  return tempDiv.innerHTML;
};

export const stateToAPI = (state: TCreateSpekPayload) => {
  const {
    definition,
    title,
    expert,
    salesforceObjects,
    topics,
    shareable,
    customFields,
    notification,
  } = state;
  const media_files = getMediaIds(definition);
  const tags = topics.map((topic) => topic.label);
  // for types other than business_terms, we dont store salesforceObjects list.
  const objects = salesforceObjects?.map((object) => object.value);
  return {
    business_rule: '',
    teams: notification.teams.map((team) => team.value),
    label: title,
    definition: sanitizeDefinition(definition),
    media_files,
    data_expert: expert?.value,
    tags,
    objects,
    notification: prepareNotificationPayload(notification),
    shareable,
    custom_columns: customFields,
  };
};

export const getStateToAPI = ({
  id,
  includeHidden: include_hidden,
  type,
}: TGetSpekPayload) => ({
  [`${type}_scope`]: id,
  type,
  include_hidden,
});

export const editAPIToState = (
  response: TEditSpekResponse,
  {customFields}: TCreateSpekPayload
) => {
  const apiToReadableContent = (): TEditTerm => {
    if ('business_term' in response) return response.business_term;
    if ('field' in response) return response.field;
    if ('object' in response) return response.object;
    return response.field_value;
  };
  const {shareable, ...content} = apiToReadableContent();
  const objects = 'related_objects' in content ? content.related_objects : undefined;
  return {
    ...content,
    last_edited: new Date().toJSON(),
    custom_columns: customFields,
    is_externally_shared: shareable,
    objects,
  };
};
