import {Component} from 'react';

class RenewBanner extends Component {
  render() {
    let styles = {
      link: {
        textDecoration: 'none',
        fontWeight: '600',
        color: '#FFF',
        margin: '0px 0.5em',
      },
      main: {
        position: 'relative',
        zIndex: '1',
        background: 'linear-gradient(45deg, #C673D2 0%, #3576E8 100%)',
        padding: '16px 40px',
        color: '#FFF',
        fontSize: '14px',
        boxShadow: '0 10px 20px 0 rgba(0,0,0,0.2)',
      },
      action: {
        textDecoration: 'none',
        fontWeight: '600',
        color: '#FFF',
        float: 'right',
      },
    };
    return (
      <div style={styles.main}>
        <a
          href='mailto:demo@spekit.co'
          style={styles.link}
          target='_blank'
          rel='noreferrer noopener'
        >
          Your plan will expire soon. Renew to continue using Spekit.
        </a>
      </div>
    );
  }
}

export default RenewBanner;
