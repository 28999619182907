import React from 'react';
import {Divider as ChakraDivider, DividerProps} from '@chakra-ui/react';

export type TDividerProps = DividerProps;

export const DividerConfig = {
  baseStyle: {},
  variants: {},
  sizes: {},
  defaultProps: {},
};
export const Divider = (props: TDividerProps) => <ChakraDivider {...props} />;
