import {Box, chakra} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {
  RiExpandUpDownLine,
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiDownloadLine,
} from 'react-icons/ri';
import {
  Column,
  SortingRule,
  usePagination,
  useSortBy,
  useTable,
  useExpanded,
} from 'react-table';
import {Icon} from '../Icon';
import {Flex} from '../Layout/Flex';
import {ModernPagination} from '../ModernPagination';
import {Link} from '../Link';
import {Table, TableContainer, Tbody, Td, Th, Thead, Tr} from '../Table';
import {Heading, Text} from '../Typography';

export type IDataTableProps<T extends object> = {
  data: Array<T>;
  columns: Array<Column<T>>;

  /** Does this table have sortable fields
   * @default false
   */
  isSortable?: boolean;

  /** Does this table have pagination
   * @default false
   */
  isPaginated?: boolean;

  /** Turn this on if you wish to implement your own sorting outside of
   * the table (eg. server-side or manual row grouping/nesting)
   * @default false
   */
  manualSort?: boolean;

  /** Turn this on if you wish to implement your own pagination outside of
   * table (eg. server-side pagination or any other manual pagination technique)
   * @default false
   */
  manualPagination?: boolean;

  /**  **Required** if `manualPagination` is set to true
   *
   * If `manualPagination` is true, then this value used to determine the
   * amount of pages available. This amount is then used to materialize the
   * pageOptions and also compute the `canNextPage` values on the table instance.
   *
   * Set to `-1` if you don't know or don't want to present the number of
   * pages available. `canNextPage` will return false if page data length is
   * less than `pageSize`, otherwise `true`.
   */
  pageCount?: number;

  /** Determines the amount of rows on any given page.
   * @default 25
   */
  pageSize?: number;

  /** Required when either `manualSorting` or `manualPagination` is `true` */
  onChange?: (params: {
    pageSize?: number;
    pageIndex?: number;
    sortBy?: SortingRule<T>[];
  }) => void;

  /**
   * If 'isExpandable' is true, then data table rows are expandable and
   * custom components or data can be loaded upon clicking the row.
   * This property is not native to 'react-table' and custom made for
   * future use.
   * @default false
   */
  isExpandable?: boolean;

  /**
   * This is primarily used in conjunction with 'isExpanded' to load
   * sub-components upon expanding table rows.
   */
  SubComponent?: any;

  // To use custom width
  isFixedLayout?: boolean;

  title?: string;
  subTitle?: string;
  toCsv?: () => string;
  rightElement?: React.ReactNode;
  /**
   * name should be in kebab case,
   * this helps us to add test ids for different elements ex title,subtitle.
   */
  name?: string;
};

export function DataTable<T extends object>(props: IDataTableProps<T>) {
  const {
    columns,
    data,
    pageCount,
    onChange,
    pageSize = 25,
    isSortable = false,
    isPaginated = false,
    manualSort = false,
    manualPagination = false,
    isExpandable = false,
    SubComponent = undefined,
    isFixedLayout = false,
    title,
    subTitle,
    toCsv,
    rightElement,
    name,
  } = props;

  let hooks = [];
  let tableConfigOptions = {};
  let initialState = {};
  if (isSortable) {
    hooks.push(useSortBy);
    if (manualSort) {
      tableConfigOptions = {
        ...tableConfigOptions,
        manualSortBy: true,
        autoResetSortBy: false,
      };
    }
  }
  if (isExpandable) {
    hooks.push(useExpanded);
  }
  if (isPaginated) {
    hooks.push(usePagination);
    initialState = {...initialState, pageIndex: 0, pageSize};
    if (manualPagination) {
      tableConfigOptions = {
        ...tableConfigOptions,
        manualPagination: true,
        autoResetPage: false,
      };
      if (pageCount === undefined)
        throw new Error('pageCount is required when manualPagination is set to true.');
      tableConfigOptions = {...tableConfigOptions, pageCount};
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    pageOptions,
    prepareRow,
    gotoPage,
    state: {sortBy, pageIndex},
    visibleColumns,
  } = useTable({columns, data, initialState, ...tableConfigOptions}, ...hooks);

  useEffect(() => {
    if (onChange && (manualPagination || manualSort)) {
      onChange({pageSize, pageIndex, sortBy});
    }
  }, [sortBy, pageIndex, pageSize, onChange]);

  const handlePagination = (pageNumber: number) => {
    gotoPage(pageNumber - 1);
  };

  let rowIterator = rows;
  if (isPaginated) rowIterator = page;

  const showHeader = title || subTitle || toCsv;
  const showRightElements = Boolean(toCsv || rightElement);

  return (
    <TableContainer>
      {showHeader && (
        <Flex
          borderBottom='1px solid'
          borderColor='neutral.200'
          justifyContent='space-between'
          paddingBottom={19}
          paddingTop={20}
          paddingX={24}
        >
          <Flex direction='column'>
            <Heading
              data-testid={`${name}-title`}
              as='h4'
              color='neutral.80'
              fontWeight='semibold'
            >
              {title}
            </Heading>
            {subTitle && (
              <Text mt={4} color='neutral.70' data-testid={`${name}-sub-title`}>
                {subTitle}
              </Text>
            )}
          </Flex>
          {showRightElements && (
            <Flex gap={8} data-testid={`${name}-download-btn`}>
              {toCsv && (
                <Box>
                  <Link
                    alignItems='center'
                    display='inline-flex'
                    download={`${(title || 'download')
                      .toLowerCase()
                      .replace(' ', '_')}.csv`}
                    href={`data:text/csv;base64,${toCsv()}`}
                    size='md'
                  >
                    <Icon as={RiDownloadLine} mr={8} />
                    Download CSV
                  </Link>
                </Box>
              )}
              {Boolean(rightElement) && <>{rightElement}</>}
            </Flex>
          )}
        </Flex>
      )}
      <Table {...getTableProps({...(isFixedLayout && {style: {tableLayout: 'fixed'}})})}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                let columnHeaderProps = column.getHeaderProps();
                if (isSortable)
                  columnHeaderProps = column.getHeaderProps(
                    column.getSortByToggleProps()
                  );

                return (
                  <Th {...columnHeaderProps} style={{width: column.width || 'auto'}}>
                    <Box display='flex' alignItems='center'>
                      <Text
                        variant='caption1'
                        fontWeight='semibold'
                        letterSpacing={0}
                        textTransform='none'
                      >
                        {column.render('Header')}
                      </Text>
                      {column.canSort && (
                        <chakra.span
                          pl='4'
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon
                                as={RiArrowDownSLine}
                                aria-label='sorted descending'
                              />
                            ) : (
                              <Icon as={RiArrowUpSLine} aria-label='sorted ascending' />
                            )
                          ) : (
                            <Icon
                              as={RiExpandUpDownLine}
                              aria-label='sorted descending'
                            />
                          )}
                        </chakra.span>
                      )}
                    </Box>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rowIterator?.length === 0 && (
            <Tr>
              <Td
                colSpan={columns.length}
                textAlign='center'
                color='neutral.500'
                fontStyle='italic'
              >
                Nothing to show
              </Td>
            </Tr>
          )}
          {rowIterator.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <>
                <Tr
                  {...row.getRowProps()}
                  onClick={isExpandable ? () => row.toggleRowExpanded() : () => {}}
                >
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render('Cell', {rowIndex})}</Td>
                  ))}
                </Tr>
                {isExpandable && row.isExpanded && (
                  <Tr>
                    <Td colSpan={visibleColumns.length} background='#F8F9FA'>
                      <SubComponent row={row} />
                    </Td>
                  </Tr>
                )}
              </>
            );
          })}
        </Tbody>
      </Table>

      {isPaginated && pageOptions?.length > 0 && (
        <Flex py={12} alignItems='center' justifyContent='center'>
          <ModernPagination
            currentPage={pageIndex + 1}
            onChange={handlePagination}
            totalPages={pageOptions?.length}
          />
        </Flex>
      )}
    </TableContainer>
  );
}
