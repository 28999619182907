import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {PlanResponse} from './plan.types';

export async function getDetails(): Promise<PlanResponse> {
  try {
    let response = await fetch('/api/companies/plan', {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    let result = await response.json();
    if (result.success) {
      return result;
    } else {
      throw new Error(result.message);
    }
  } catch (error) {
    throw error;
  }
}
