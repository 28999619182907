import React from 'react';
import {useStyleConfig} from '@chakra-ui/react';

import {Box, TBoxProps} from '../Layout';
import {FONT_FAMILY} from '../Typography';
import {StyleConfig} from '../types';

export interface IUtilityBarProps extends TBoxProps {
  children?: React.ReactNode;
  variant: 'light' | 'dark';
}

export const UtilityBarConfig: StyleConfig = {
  baseStyle: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    boxSizing: 'border-box',
    px: 20,
    fontFamily: FONT_FAMILY,
  }),
  variants: {
    light: () => ({
      bg: 'neutral.25',
      borderColor: 'neutral.200',
      minHeight: '55px',
    }),
    dark: () => ({
      bg: 'neutral.800',
      borderColor: 'neutral.800',
      minHeight: '70px',
    }),
  },
};

export const UtilityBar = (props: IUtilityBarProps) => {
  const {variant, ...rest} = props;
  const styles = useStyleConfig('UtilityBar', {variant});

  return <Box __css={styles} {...rest} />;
};
