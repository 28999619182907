const initialState = {
  stages: {},
  hasLoaded: false,
};
function userStagesReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_USER_STAGES':
      return {
        ...state,
        hasLoaded: true,
        stages: action.stages,
      };
    default:
      return state;
  }
}

export default userStagesReducer;
