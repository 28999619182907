import store from './reduxStore';
import {
  setFlags,
  setFeatures,
  updateUnreadCount,
  setAsyncSpotlightCreate,
  createSpotlightWithFile,
} from './redux/actions';
import {FeatureFlags, notification as Notification} from 'spekit-datalayer';
import {notifications, getExtensionButton} from 'spekit-ui';

export const featureKey = 'spekit_featureFlags';
const {notify} = notifications;

type TAssetNotification = {
  name: 'spekit_notifications';
  data: {
    type: 'asset_upload';
    id: string;
    virus_scan_status: string;
    label: string;
    file_name: string;
    msg_text: string;
    msg_type: string;
  };
};

type TFeatureFlagNotification = {
  name: 'spekit_featureFlags';
  data: {
    action: 'add' | 'remove';
    flag_name: string;
  };
};

type TUnreadCountNotification = {
  name: 'spekit_notifications';
  data: {
    type: 'unread_count';
    count: number;
  };
};

type TAblyMessages =
  | TAssetNotification
  | TUnreadCountNotification
  | TFeatureFlagNotification;

const {getFeatures} = FeatureFlags;
let refeshInterval: any | null = null;

export const handleAsyncSpotlightCreate = (message: TAblyMessages) => {
  const notificationData = message.data;

  if (
    notificationData &&
    'type' in notificationData &&
    notificationData?.type === 'asset_upload'
  ) {
    const state: any = store.getState();
    const {asyncSpotlightCreate} = state?.asyncSpotlightCreate ?? {};
    const fileId = notificationData?.id;

    if (fileId && asyncSpotlightCreate?.fileDetails?.value === fileId) {
      // reset async spotlight create state after handling the message.
      store.dispatch(setAsyncSpotlightCreate(null));

      // check if virus scan is completed before creating spotlight with file.
      if (notificationData?.virus_scan_status === 'completed') {
        const {teams, message, fileDetails} = asyncSpotlightCreate;
        store.dispatch(
          createSpotlightWithFile({
            teams,
            message,
            fileDetails,
          })
        );
      }
    }
  }
};

export const userListener = (message: TAblyMessages) => {
  if (message.name === 'spekit_notifications') {
    // handle create spotlight with file upload async
    handleAsyncSpotlightCreate(message);
    if (message.data.type === 'unread_count')
      store.dispatch(updateUnreadCount(message.data.count));
    else if (message.data.type === 'asset_upload') {
      const {extensionInstalled} = getExtensionButton;
      const {msg_type, msg_text} = message.data;
      extensionInstalled().then((installed) => {
        if (!installed) {
          if (msg_type === 'error') notify({text: msg_text, error: true});
          else notify({text: msg_text});
        }
      });
    }
  }
};

export const companyListener = (message: TAblyMessages) => {
  if (message.name === featureKey) {
    const state = store.getState();
    const features = state.featureFlags;
    if (message.data.action === 'add') {
      features.push(message.data.flag_name);
    } else if (message.data.action === 'remove') {
      features.splice(features.indexOf(message.data.flag_name), 1);
    }
    store.dispatch(setFeatures(features));
    store.dispatch(setFlags(features));
  }
};

export const refreshDataFromAPI = () => {
  // Refresh the data from the API
  getFeatures().then((features) => {
    store.dispatch(setFeatures(features));
    store.dispatch(setFlags(features));
  });
  Notification.getNotificationCounter().then((res: any) => {
    store.dispatch(updateUnreadCount(res.unread_count));
  });
};

export const startRefreshInterval = () => {
  if (!refeshInterval) {
    refreshDataFromAPI();
    refeshInterval = setInterval(refreshDataFromAPI, 60000);
  }
};

export const stopRefreshInterval = () => {
  if (refeshInterval) {
    clearInterval(refeshInterval);
    refeshInterval = null;
  }
};

export const handleBroadCastMessage = (event: any) => {
  if (event.data.type === 'ably') {
    userListener(event.data.message);
    companyListener(event.data.message);
  }
};
