import React from 'react';
import {RiCloseLine} from 'react-icons/ri';
import {DSButton as Button, HStack} from 'spekit-ui';
import {Input} from 'spekit-ui';

interface SpekiProps {
  makeRequest: (request: string) => void;
  onClose: () => void;
}

// This component is for a temporary POC and is purpose built to work only in the editor.
export const Speki = ({makeRequest, onClose}: SpekiProps) => {
  const [requestText, setRequestText] = React.useState('');

  return (
    <HStack width={'100%'}>
      <Input
        value={requestText}
        onChange={(e) => {
          setRequestText(e.target.value);
        }}
        maxLength={1000}
        placeholder='Request changes or modifications to your Spek'
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            requestText && makeRequest(requestText);
          }
        }}
        autoFocus
      />
      <Button
        aria-label={'Send Request'}
        colorScheme={'primary'}
        onClick={() => requestText && makeRequest(requestText)}
        p={'20px'}
        size={'large'}
        variant='contained'
      >
        Request changes
      </Button>
      <Button
        p={'13px'}
        aria-label={'Close'}
        colorScheme={'primary'}
        onClick={onClose}
        size={'large'}
        variant='outlined'
      >
        <RiCloseLine />
      </Button>
    </HStack>
  );
};
