import {
  TCustomFieldCreationResponse,
  ITask,
  ICustomFieldsFormState,
  TCustomFieldContentTypes,
  TCustomField,
} from 'spekit-types';
import {destroy, retrieve, create, modify, createForm} from '../utils/APIHelpers';

const getAPIpayloadFromState = (state: ICustomFieldsFormState, isInEditMode = false) => {
  let payload = {
    ...state,
    data_type: state.data_type.value,
    on: state.on.map((option) => option.value) as TCustomFieldContentTypes[],
  };

  if (!isInEditMode) {
    return {...payload, options: state.options.map((opt) => opt.name)};
  }

  return payload;
};

export const getCustomFields = () => {
  return retrieve<TCustomField[]>('/api/wiki/custom_columns/');
};

export const deleteCustomField = (id: string) => {
  return destroy<ITask>(`/api/v1/wiki/custom_columns/${id}/`);
};

export const createCustomField = (
  payload: ICustomFieldsFormState
): Promise<TCustomFieldCreationResponse> => {
  return create('/api/wiki/custom_columns/', getAPIpayloadFromState(payload));
};

export const updateCustomField = (
  id: string,
  payload: ICustomFieldsFormState
): Promise<TCustomFieldCreationResponse> => {
  return modify(`/api/wiki/custom_columns/${id}/`, getAPIpayloadFromState(payload, true));
};

export const initiateExportWiki = (
  sfOrganizationId: string,
  password?: string
): Promise<ITask> => {
  return create(`/api/wiki/export/${sfOrganizationId}`, {password});
};

export const initiateImportWiki = (
  sfOrganizationId: string,
  formData: FormData
): Promise<ITask> => {
  return createForm(`/api/wiki/import/${sfOrganizationId}`, formData);
};
