import React from 'react';
import {MenuItem as ChakraMenuItem, MenuItemProps} from '@chakra-ui/react';
import {IconType} from 'react-icons';
import {Text} from '../Typography';
import {Icon} from '../Icon';
import {Flex} from '../Layout';

export type TSimpleMenuItemProps = Omit<MenuItemProps, 'icon'> & {
  icon?: IconType | null;
  testId?: string;
};

export const SimpleMenuItem = ({
  icon = null,
  testId,
  children,
  ...rest
}: TSimpleMenuItemProps) => (
  <ChakraMenuItem {...rest}>
    <Flex gap='9px' alignItems='center' justifyContent='center' data-testid={testId}>
      {icon && <Icon as={icon} height={4} width={4} />}
      <Text variant='body2'>{children}</Text>
    </Flex>
  </ChakraMenuItem>
);
