import {
  IQuizBuilderResponse,
  IQuizState,
  IQuizDetails,
  IQuizView,
  IQuizParams,
  IQuizListResponse,
  IQuizLookupResponse,
  IQuizLookupQuery,
  IQuizStatistics,
  IQuizUserResponse,
  IQuizRetakeResponse,
  IQuizRetakePayload,
  ISaveAnswerPayload,
  ISaveAnswerResponse,
} from 'spekit-types';
import {retrieve, create, modify, destroy} from '../utils/APIHelpers';
import {stringifyQs} from '../utils/commonUtils';
import {
  QUIZ_API,
  QUIZ_QUESTION_API,
  LOOKUP_API,
  QUIZ_OPTION_API,
  REMINDER_EMAIL,
  QUIZ_RETAKE,
} from '../api';
import {APIToQuizBuilderState, stateToAPI, userResponseToOptions} from './transformers';
import {checkTaskStatus} from '../task/checkStatusTask';

export const getQuizzes = async (params?: IQuizParams) => {
  const filters = stringifyQs(params);
  const results = await retrieve<IQuizListResponse>(`${QUIZ_API}?${filters}`);
  return results;
};

export const getUserOptions = async () => {
  const results = await retrieve<Array<IQuizUserResponse>>(`${QUIZ_API}list_creators/`);
  return userResponseToOptions(results);
};

export const getQuizzesForViewer = async (params?: IQuizParams) => {
  const filters = stringifyQs(params);
  const results = await retrieve<IQuizListResponse>(`${QUIZ_API}list_viewer/?${filters}`);
  return results;
};

export const getQuizForViewer = async (quizId: string, isPreview?: boolean) => {
  const result = await retrieve<IQuizView>(
    `${QUIZ_API + quizId}/${isPreview ? 'previewer' : 'viewer'}/`
  );
  return result;
};

export const createQuiz = async (payload: IQuizState) => {
  const transformedPayload = stateToAPI(payload);
  const results: IQuizBuilderResponse = await create(`${QUIZ_API}`, transformedPayload);
  return results;
};

export const saveAnswer = async (
  quiz_id: string,
  question_id: string,
  option_ids: Array<string>
) => {
  const result = await create<ISaveAnswerPayload, null | ISaveAnswerResponse>(
    `${QUIZ_API}save_answer/`,
    {
      quiz_id,
      question_id,
      option_ids,
    }
  );
  return result;
};

export const updateQuiz = async (quizId: string, payload: Partial<IQuizState>) => {
  const transformedPayload = stateToAPI(payload);

  const results: IQuizBuilderResponse = await modify(
    `${QUIZ_API}${quizId}/`,
    transformedPayload
  );
  return results;
};

export const getQuizByQuizId = async (quizId: string, isCloned = false) => {
  const response = await retrieve<IQuizDetails>(`${QUIZ_API}${quizId}/`);
  const result = APIToQuizBuilderState(response, isCloned);
  return result;
};

export const deleteQuiz = async (quizId: string) => destroy(`${QUIZ_API}${quizId}/`);

export const deleteQuestion = async (questionId: string) =>
  destroy(`${QUIZ_QUESTION_API}${questionId}/`);

export const deleteOption = async (optionId: string) =>
  destroy(`${QUIZ_OPTION_API}${optionId}/`);

export const quizLookup = async (query: IQuizLookupQuery) => {
  const queryParams = stringifyQs(query);
  const response: IQuizLookupResponse = await retrieve(
    `${LOOKUP_API}quizzes?${queryParams}`
  );
  return response;
};

export const getQuizAnalytics = async (quizId: string) => {
  return await retrieve<IQuizStatistics>(
    `/api/stats/?type=quiz-details&quiz_id=${quizId}&include_summary_stats=true`
  );
};

export const downloadQuizStats = async (quizId: string) => {
  const response = await retrieve<{task_id: string}>(`${QUIZ_API}${quizId}/export/`);
  await checkTaskStatus(response.task_id);
  return response;
};

export const getProgressCount = async () => {
  const response = await retrieve<{kc_count: number}>(`${QUIZ_API}progress_count/`);
  return response;
};

export const sendReminderEmail = async (kcId: string, teamName: string) => {
  const response = await retrieve<{success: boolean; message: string}>(
    `${REMINDER_EMAIL}?kc_id=${kcId}&team_name=${teamName}`
  );
  return response;
};

export const downloadStatsFile = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  // get filename from response header
  const filename =
    response.headers.get('Content-Disposition')?.split('filename=')[1] ||
    'KnowledgeCheckStats.csv';
  const URL = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = URL;
  a.download = filename;
  // we need to append the element to the dom, otherwise it will not work in firefox
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const retakeQuiz = async (retake: IQuizRetakePayload) => {
  const response = await modify<IQuizRetakePayload, IQuizRetakeResponse>(
    QUIZ_RETAKE,
    retake
  );
  return response;
};
