import {
  Step,
  APIStep,
  APIFlow,
  FlowQuery,
  Image,
  PublishFlowResponse,
  FlowDetails,
  StepDetails,
  DeleteFlowResponse,
  CreateFlowResponse,
  FlowListResponseType,
  StepStatistic,
  IFlowLookupQuery,
  IFlowLookupResponse,
} from './flows.types';
import qs from 'qs';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {retrieve, destroy, update, create} from '../utils/APIHelpers';
import {handleErrors} from '../utils/handleErrors';
import {checkTaskStatus} from '../task/checkStatusTask';
import {mapAPIToFlow, getMediaUrl} from './mappings';
import {capture} from '../logging';
import {IFlowUserStatistics} from 'spekit-types';
import {FlowsApiError} from './error.types';
import {stringifyQs} from '../utils/commonUtils';

const stepToApiStep = (step: Step, index: number, steps: Step[]): APIStep => {
  return {
    index: index,
    label: step?.label ? step.label : `Step ${index}`,
    description: step ? step.description : '',
    type: step.type || 'modal',
    image: '',
    embed_url: '',
    system_image_id: step.system_image_id || '',
    show_created_by: true,
    locations: [
      {
        index: 0,
        type: 'url',
        domain_comparison: 'contains',
        css_selector: '',
        position: step.element!,
        redirect_url: step.redirUrl,
        ...parseURI(step.element?.url),
      },
    ],
    initial: index === 0,
    terminal: index === steps.length - 1,
    engagements: {buttons: step.engagement ? [step.engagement] : []},
    is_highlighted: step.isHighlighted || false,
  };
};

const parseURI = (url: string | undefined) => {
  if (!url) return {domain_value: ''};
  const domain = new URL(url);
  const origin = domain.origin;
  const pathName = domain.pathname;
  const search = domain.search;
  const pathNameWithSearch = pathName + search;
  return {
    domain_value: origin,
    path_rules: [
      {
        comparison: 'exactly',
        value: pathNameWithSearch,
      },
    ],
  };
};

const flowFromSteps = (steps: Step[], flowLabel: string): APIFlow => {
  return {
    label: flowLabel,
    teams: [],
    status: 'draft',
    frequency_value: 0,
    frequency_period: 'weekly',
    steps: steps.map(stepToApiStep),
  };
};

export const getImageId = (image: string | Image | null) =>
  image && typeof image !== 'string' ? image.id : image || '';

export const createFlow = async (
  steps: Array<Step>,
  flowLabel: string
): Promise<CreateFlowResponse> => {
  const body = flowFromSteps(steps, flowLabel);
  const response = await fetch('/walkthroughs/flows/', {
    credentials: 'include',
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const _result = await response.json();
  await checkTaskStatus(_result.task_id);
  return _result;
};

export const updateStepLocation = async (step: StepDetails) => {
  const response = await fetch(
    `/walkthroughs/v1/flows/step_locations/${step.locations[0].id}/`,
    {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...step.locations[0],
      }),
    }
  );

  const result = (await response.json()) as Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const handledResult = handleErrors(response, result);
  return handledResult;
};

export const updateFlowStep = async (step: StepDetails) => {
  const response = await fetch(`/walkthroughs/steps/${step.id}/`, {
    credentials: 'include',
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      description: step.description,
      is_highlighted: step.is_highlighted,
    }),
  });
  const result = (await response.json()) as Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const handledResult = handleErrors(response, result);
  return handledResult;
};

export const fetchFlows = (
  query: FlowQuery,
  page?: number
): Promise<FlowListResponseType> => {
  const limit: number = 15;
  query = {
    ...query,
    limit: query.limit ? query.limit : limit * ((page || 0) + 1),
    offset: query.offset ? query.offset : limit * (page || 0),
  };

  let querystring = qs.stringify(query, {
    indices: false,
    arrayFormat: 'comma',
    skipNulls: true,
    encode: true,
  });
  return new Promise((res, rej) =>
    fetch(`/walkthroughs/v1/flows/?${querystring}`, {})
      .then((res) => res.json())
      .then((response) => {
        query.offset = undefined;
        res({
          next:
            response.highest_count > (query.limit || limit) * ((page || 0) + 1)
              ? () => fetchFlows(query, (page || 0) + 1)
              : undefined,
          flows: response.results,
          highest_count: response.highest_count,
        });
      })
  );
};

export const getFlowAnalytics = async (flow_id: string) => {
  return await retrieve<IFlowUserStatistics>(
    `/api/stats/?type=flows-details&flow_id=${flow_id}`
  );
};

export const publishFlow = (
  id: string,
  status = 'published'
): Promise<PublishFlowResponse> =>
  new Promise((res, rej) => {
    fetch(`/walkthroughs/flows/${id}/`, {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({status}),
    })
      .then((r) => r.json())
      .then((result) => {
        if (!result) {
          throw new FlowsApiError('unexpected response from flows publish api');
        } else {
          let response: PublishFlowResponse = result;
          res(response);
        }
      })
      .catch((e) => rej(e));
  });

export const unPublishFlow = (id: string): Promise<PublishFlowResponse> =>
  publishFlow(id, 'draft');

export const getFlowDetails = async (flowId: string): Promise<FlowDetails> => {
  const response = await fetch(`/walkthroughs/v1/flows/${flowId}/flow-details/`, {
    credentials: 'include',
  });

  const result = (await response.json()) as Record<string, unknown>;
  const handledResult = handleErrors(response, result) as FlowDetails;
  handledResult.steps = handledResult.steps.map((step: StepDetails): StepDetails => {
    const imageId = getImageId(step.image);
    return {...step, image: step.image ? getMediaUrl(imageId) : ''};
  });
  return handledResult;
};

export const getMappedFlowsDetails = async (flowId: string) => {
  const response = await getFlowDetails(flowId);
  let mappedResponse = mapAPIToFlow(response);
  return mappedResponse;
};

export const getFlowStepsAnalytics = async (flow_id: string) => {
  return await retrieve<Array<StepStatistic>>(
    `/api/stats/?type=steps-details&flow_id=${flow_id}`
  );
};

export const deleteFlow = function (id: string | undefined): Promise<DeleteFlowResponse> {
  return new Promise((res, rej) => {
    fetch(`/walkthroughs/v1/flows/${id}/`, {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((result) => {
        if (!result) {
          throw new FlowsApiError('unexpected response from flows deletion api');
        } else {
          let response: DeleteFlowResponse = result;
          res(response);
        }
      })
      .catch((e) => rej(e));
  });
};

export const updateFlow = async (payload: any, flowId: string) => {
  let response = await fetch(`/walkthroughs/flows/${flowId}/`, {
    credentials: 'include',
    body: JSON.stringify(payload),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  });
  if (!response.ok) {
    throw new FlowsApiError('Unable to update');
  }
};

export const updateStep = async (stepId: string, payload: any) => {
  const response = await fetch(`/walkthroughs/steps/${stepId}/`, {
    credentials: 'include',
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  const result = await response.json();
  const handledResult = handleErrors(response, result);
  return handledResult;
};

export const addStep = async (step: Step, stepIndex: number, flowDetail: FlowDetails) => {
  const payload = {
    index: stepIndex,
    flows_steps: [flowDetail.id],
    label: step?.label ? step.label : `Step ${stepIndex}`,
    description: step ? step.description : '',
    type: step.type || 'modal',
    image: '',
    embed_url: '',
    system_image_id: step.system_image_id || '',
    show_created_by: true,
    locations: [
      {
        index: 0,
        type: 'url',
        domain_comparison: 'contains',
        css_selector: '',
        position: step.element!,
        redirect_url: step.redirUrl,
        ...parseURI(step.element?.url),
      },
    ],
    engagements: {buttons: step.engagement ? [step.engagement] : []},
    is_highlighted: step.isHighlighted || false,
  };

  const response = await fetch(`/walkthroughs/v1/steps/`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  let result = await response.json();
  let handledResult = handleErrors(response, result);
  return handledResult;
};

export const deleteStep = async (stepId: string) => {
  const response = await fetch(`/walkthroughs/v1/steps/${stepId}/`, {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const result = await response.json();
  if (!result) {
    throw new FlowsApiError('unexpected response from flows step delete api');
  } else {
    return result;
  }
};

export const domainEdit = async (payload: any): Promise<any> => {
  try {
    let uri = payload.position.url.replace(/https?:\/\/[^\/]+/i, '');
    let params = uri.replace(/[^\?]+/i, '');
    uri = uri.split('?')[0];
    let url = payload.domain_value + payload.path_rules[0]?.value;
    if (uri === payload.path_rules[0]?.value) {
      url = url + params;
    }

    let result = await fetch(`/walkthroughs/v1/flows/step_locations/${payload.id}/`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        domain_value: payload.domain_value,
        path_rules: payload.path_rules,
        position: {...payload.position, url: url, topUrl: url, sfAppId: null},
      }),
    });

    let response = await result.json();
    let handledResult = handleErrors(result, response);
    return handledResult;
  } catch (e) {
    capture(e);
    throw e;
  }
};

export const domainCreate = async (payload: any): Promise<any> => {
  try {
    let result = await fetch(`/walkthroughs/v1/flows/step_locations/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...payload,
        event_rule: {},
      }),
    });

    let response = await result.json();
    let handledResult = handleErrors(result, response);
    return handledResult;
  } catch (e) {
    capture(e);
    throw e;
  }
};

export const domainDelete = async (id: string): Promise<any> => {
  try {
    let result = await fetch(`/walkthroughs/v1/flows/step_locations/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!result.ok) {
      throw new FlowsApiError('Unable to delete the step!');
    }
  } catch (e) {
    capture(e);
    throw e;
  }
};

export const updateSession = async (sessionId: string, stepId: string) => {
  const result = await update('/walkthroughs/flows/session/', {
    session_id: sessionId,
    step_id: stepId,
  });
  return result;
};

export const reportError = async (sessionId: string, stepId: string) => {
  const result = await create('/walkthroughs/steps/error/', {
    session_id: sessionId,
    step_id: stepId,
  });
  return result;
};

export const quit = async (sessionId: string, stepId: string) => {
  const result = await create('/walkthroughs/steps/quit/', {
    session_id: sessionId,
    step_id: stepId,
  });
  return result;
};

export const dismissFlow = async (session_id: string) => {
  return await destroy(`/walkthroughs/flows/${session_id}/dismissals/`);
};

export const getSession = async (flowId: string) => {
  return await retrieve<{success: boolean; session_id: string}>(
    `/walkthroughs/flows/${flowId}/session/`
  );
};

export const flowLookup = async (query: IFlowLookupQuery) => {
  const queryParams = stringifyQs(query);
  return await retrieve<IFlowLookupResponse>(`/api/v1/lookups/flows?${queryParams}`);
};
