import React from 'react';
import {
  Input as ChakraInput,
  InputProps,
  ComponentStyleConfig,
  forwardRef,
} from '@chakra-ui/react';
import {MdErrorOutline, MdWarningAmber} from 'react-icons/md';
import {InputGroup} from './InputGroup';
import {InputRightElement} from './InputRightElement';
import {Icon} from '../../Icon';
import {InputLeftElement} from './InputLeftElement';
import {FONT_FAMILY} from '../../Typography';

interface IInputIconProps {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
}

export type TInputProps = InputProps & IInputIconProps;

export const InputConfig: ComponentStyleConfig = {
  parts: ['addon', 'field', 'element'],
  baseStyle: {
    field: {
      fontFamily: FONT_FAMILY,
    },
  },
  variants: {
    outline: ({theme, colorScheme}) => {
      const {colors} = theme;
      return {
        field: {
          borderRadius: '8',
          border: '1px solid',
          borderColor: 'neutral.300',
          fontSize: 'small',
          fontWeight: 'normal',
          color: 'neutral.800',
          bg: 'neutral.0',
          _placeholder: {
            color: 'neutral.500',
          },
          _focus: {
            borderColor: 'primary.500',
            boxShadow: `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.primary['100']}`,
          },
          _disabled: {
            color: 'neutral.500',
            bg: 'neutral.25',
            opacity: '1',
          },
          _invalid: {
            boxShadow: 'none',
            borderColor: colorScheme === 'warning' ? 'warning.500' : 'error.600',
            _focus: {
              borderColor: colorScheme === 'warning' ? 'warning.500' : 'error.600',
              boxShadow:
                colorScheme === 'warning'
                  ? `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.warning['100']}`
                  : `0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${colors.error['100']}`,
            },
          },
        },
      };
    },
  },
  sizes: {
    md: {
      field: {
        boxSizing: 'border-box',
        py: 8,
        height: '44px',
      },
    },
    sm: {
      field: {
        boxSizing: 'border-box',
        py: 6,
        height: '32px',
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};

export const Input = forwardRef((props: TInputProps, ref) => {
  const {isInvalid, leftElement, rightElement, colorScheme} = props;

  const invalidState =
    colorScheme === 'warning' ? (
      <Icon as={MdWarningAmber} fontSize='1.25em' color='warning.500' />
    ) : (
      <Icon as={MdErrorOutline} fontSize='1.25em' color='error.600' />
    );

  return (
    <InputGroup>
      <InputLeftElement>{leftElement}</InputLeftElement>
      <ChakraInput
        ref={ref}
        pe={isInvalid || rightElement ? 44 : 12}
        ps={leftElement ? 44 : 12}
        {...props}
      />
      <InputRightElement>{isInvalid ? invalidState : rightElement}</InputRightElement>
    </InputGroup>
  );
});
