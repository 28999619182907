import styles from './LicenseExceeded.module.css';
import {
  faLongArrowAltLeft,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import {Fontawesome} from 'spekit-ui';
import {useHistory} from 'react-router-dom';

function LicenseExceeded() {
  const history = useHistory();
  const goBack = () => {
    history.replace('/login');
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.registerContainer}>
          <div>Not a customer?</div>
          <a className={styles.getstartedBtn} href='https://spekit.co/demo-request/'>
            Request a demo
          </a>
        </div>
      </div>
      <div className={styles.errorBox}>
        <div className={styles.errorContainer}>
          <div>
            <div
              className={styles.back}
              role={'button'}
              tabIndex={0}
              onClick={goBack}
              onKeyDown={goBack}
            >
              <Fontawesome name={faLongArrowAltLeft} className={styles.backIcon} />
              Back
            </div>
          </div>
          <div className={styles.messageContainer}>
            <div className={styles.heading}>
              <Fontawesome name={faExclamationTriangle} className={styles.errorIcon} />
              Your Spekit instance needs additional licenses.
            </div>
            <div className={styles.description}>
              Please contact your admin to upgrade your plan.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LicenseExceeded;
