import {Plugin} from '@ckeditor/ckeditor5-core';
import {isWidget, WidgetToolbarRepository} from '@ckeditor/ckeditor5-widget';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import aspectRatioIcon from '../icons/aspect-ratio-line.svg';
import Editor from '@ckeditor/ckeditor5-core/src/editor/editor';
import {Writer} from '@ckeditor/ckeditor5-engine';

export const toggleAspectRatio = (editor: Editor, writer: Writer) => {
  // get the selected element
  const element = editor.model.document.selection.getSelectedElement();

  if (element) {
    // get the attributes and position of the selected element
    const src = element.getAttribute('data-src');
    const options = element.getAttribute('data-options') as string;
    const position = writer.createPositionBefore(element);

    // update the options with the new aspect ratio
    const updatedOptions = {
      // 16 / 9 aspect ratio is written as 1.7 in the iframe options
      // 4 / 5 aspect ratio is default and may not be present in the options
      aspectRatio: options.includes('1.7') ? 4 / 5 : 16 / 9,
    };

    // remove the existing iframe
    writer.remove(element);

    // use the embedIframe command to insert the new iframe
    editor.execute('embedIframe', src, position, JSON.stringify(updatedOptions));
  }
};

export const buttonAction = (editor: Editor) => {
  editor.model.change((writer) => toggleAspectRatio(editor, writer));
};

export const setupButton = (editor: Editor) => {
  // create a toolbar button
  const button = new ButtonView();

  // set button options
  button.set({
    label: 'Change aspect ratio',
    icon: aspectRatioIcon,
    tooltip: true,
  });

  button.on('execute', () => buttonAction(editor));

  return button;
};

export default class IframeToolbar extends Plugin {
  static get requires() {
    return [WidgetToolbarRepository];
  }

  static get pluginName() {
    return 'IframeToolbar';
  }

  init() {
    const editor = this.editor;
    editor.ui.componentFactory.add('changeAspectRatio', () => setupButton(editor));
  }

  afterInit() {
    const editor = this.editor;

    const translate = editor.t;
    const widgetToolbarRepository = editor.plugins.get(WidgetToolbarRepository);
    widgetToolbarRepository.register('IframeToolbar', {
      ariaLabel: translate('Iframe toolbar'),
      // Toolbar Buttons
      items: ['changeAspectRatio'],
      // If a related element is returned the toolbar is attached
      getRelatedElement: (selection) => {
        const viewElement = selection.getSelectedElement();

        if (
          viewElement &&
          isWidget(viewElement) &&
          !!viewElement.getCustomProperty('iframe-embed')
        ) {
          return viewElement;
        }

        return null;
      },
    });
  }
}
