const initialState = {
  busy: false,
};
function uploadReducer(state = initialState, action) {
  switch (action.type) {
    case 'SETBUSY':
      return {
        busy: true,
      };
    case 'UNSETBUSY':
      return {
        busy: false,
      };
    default:
      return state;
  }
}

export default uploadReducer;
