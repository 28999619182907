/* 
  Make sure to always name your image files like 
  "Graphic<graphic-number>.svg|png|jpeg" inside 
  the /images/gallery folder 
*/

// To import all the modules using webpack
function importAll(r: any) {
  return r.keys().map(r);
}

//Getting all the images from /images/gallery folder dynamically
export function getImages() {
  const images: Array<string> = importAll(
    require.context('../images/gallery', false, /\.(png|jpe?g|svg)$/)
  );

  return images;
}

//Sorting strings of images
export function getSortedImages() {
  const images = getImages();
  const sortedImages = images
    .map((i: any) => i.default)
    .sort((a: string, b: string) => {
      return a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
  return sortedImages;
}

//All the gallery images inside spotlights and flows
export function getLibraryItems() {
  const sortedImages = getSortedImages();
  return sortedImages.map((sI, index) => ({
    src: sI,
    text: `Graphic${index + 1}`,
  }));
}

//Getting image by host or whether its an upload, embed or gallery item
export const getImage = (state: any, host?: string) => {
  const sortedImages = getSortedImages();
  const systemImages = sortedImages.reduce((acc, curr, index) => {
    acc[`Graphic${index + 1}`] = curr;
    return acc;
  }, {});

  let image = state.system_image_id;
  if (state.image) {
    const isValidImage = state.image.includes('/media/');
    const image = isValidImage ? state.image : `/api/media/${state.image}/view`;
    return host ? `${host}${image}` : `${image}`;
  } else if (state.embed_url) {
    return state.embed_url;
  }
  return host ? `${host}${systemImages[image]}` : `${systemImages[image]}`;
};

//Mapping the image back to a graphic
export const imageToGraphic = (image: string) => {
  const sortedImages = getSortedImages();
  const graphic = sortedImages.find((sI) => sI.includes(image));

  return graphic;
};
