const initialState = {
  seismic: {},
};
function integrationsReducer(
  state = initialState,
  action: {type?: string; payload?: {seismic: object}} = {}
) {
  switch (action.type) {
    case 'SET_SEISMIC_STATUS':
      return {
        ...state,
        seismic: action.payload?.seismic,
      };
    default:
      return state;
  }
}

export default integrationsReducer;
