const initialState = {
  fieldTypes: [],
  hasLoaded: false,
};
function fieldTypeSelectReducer(state = initialState, action) {
  switch (action.type) {
    case 'SETFIELDTYPES':
      return {
        ...state,
        hasLoaded: true,
        fieldTypes: action.fieldTypes,
      };
    default:
      return state;
  }
}

export default fieldTypeSelectReducer;
