import React from 'react';
import {DSTooltip as Tooltip, Icon, DSButton as Button, Box} from 'spekit-ui';
import {RiNotification2Line} from 'react-icons/ri';
import {utils} from 'spekit-datalayer';

interface Props {
  notificationCount: number;
  handleNavigation: (item: string) => void;
}

export const NotificationBell = (props: Props) => {
  const {notificationCount, handleNavigation} = props;

  return (
    <Tooltip shouldWrapChildren label='Notifications'>
      <Box cursor='pointer' fontSize={16} position='relative'>
        <Button
          onClick={() => handleNavigation('Notifications')}
          colorScheme='transparent'
          variant='icon'
          size='medium'
          icon={<Icon h='16px' w='16px' as={RiNotification2Line} />}
          aria-label={`${notificationCount} notifications`}
          data-testid={`bell-button${
            utils.getEnvironment() === 'webapp' ? '' : '-chrome'
          }`}
        />

        {notificationCount > 0 ? (
          <Box
            as='span'
            position='absolute'
            borderRadius={8}
            fontSize={10}
            fontWeight='semibold'
            color='neutral.0'
            backgroundColor='error.600'
            w='22px'
            h='14px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            data-testid={`bell-count${
              utils.getEnvironment() === 'webapp' ? '' : '-chrome'
            }`}
            top={0}
            right='-5px'
            aria-disabled
          >
            {notificationCount}
          </Box>
        ) : null}
      </Box>
    </Tooltip>
  );
};
