type TAction =
  | {
      type: 'SETEXPORTJOBID';
      exportJobId: string;
    }
  | {
      type: 'SETIMPORTJOBID';
      importJobId: string;
    };

type TState = {exportJobId: null | string; importJobId: null | string};

const initialState: TState = {
  exportJobId: null,
  importJobId: null,
};

function BulkUploadReducer(state = initialState, action: TAction) {
  switch (action.type) {
    case 'SETEXPORTJOBID':
      return {
        ...state,
        exportJobId: action.exportJobId,
      };
    case 'SETIMPORTJOBID':
      return {
        ...state,
        importJobId: action.importJobId,
      };
    default:
      return state;
  }
}

export default BulkUploadReducer;
