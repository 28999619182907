const initialState = {
  storedState: {},
};
function wikiStoredStateReducer(state = initialState, action) {
  switch (action.type) {
    case 'WIKISTOREDSTATEUPDATE':
      return {
        storedState: action.storedState,
      };
    default:
      return state;
  }
}

export default wikiStoredStateReducer;
