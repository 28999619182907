import {
  ICreateIntelligenceCompletionPayload,
  ICreateIntelligenceCompletionResponse,
} from 'spekit-types';
import {create} from '../utils/APIHelpers';
import {SPEKIT_AI_API} from '../api';

export const createIntelligenceCompletion = async (
  payload: ICreateIntelligenceCompletionPayload
) => {
  const response: ICreateIntelligenceCompletionResponse = await create(
    `${SPEKIT_AI_API}`,
    payload
  );
  return response;
};
