import React from 'react';
import {
  Box,
  DSButton as Button,
  Flex,
  Icon,
  Input,
  Text,
  DSTooltip as Tooltip,
} from 'spekit-ui';
import {
  PathRules as TargetingPathRule,
  PathRulesProps,
  Select as SelectProps,
} from '../types';
import PathSelector from './PathSelector';
import {RiDeleteBin6Line, RiAddCircleLine} from 'react-icons/ri';
const PathRules = (props: PathRulesProps) => {
  const {
    target,
    index,
    onPathSelect,
    onAddNewPath,
    onDomainPathChange,
    removePathHandler,
    disabled,
  } = props;

  return (
    <>
      <Flex mt={12} gap={12} mx={42} direction='column'>
        {target.path_rules!.map((path: TargetingPathRule, pathIndex: number) => (
          <Flex gap={6} alignItems='center' width='85%'>
            <Box width='10%'>
              <Text variant='body2' fontWeight='normal'>
                {pathIndex === 0 ? 'And ' : 'Or '}path
              </Text>
            </Box>

            <Box width='30%' data-testid='path-selector'>
              <PathSelector
                onSelect={(path: SelectProps) => {
                  onPathSelect(path, index, pathIndex);
                }}
                value={path.comparison}
                disabled={disabled}
              />
            </Box>
            <Box width='40%' data-testid='path-input'>
              <Input
                value={path.value || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onDomainPathChange(e, index, pathIndex)
                }
                placeholder='Enter path'
                disabled={disabled}
              />
            </Box>

            {!disabled && (
              <Tooltip label='Delete path' placement='top'>
                <Button
                  icon={<Icon as={RiDeleteBin6Line} />}
                  data-testid='delete-path-button'
                  aria-label={'Delete Path Rule Button'}
                  colorScheme='white'
                  variant='icon'
                  // @ts-ignore
                  size='large'
                  onClick={(e: React.MouseEvent) =>
                    removePathHandler(e, index, pathIndex)
                  }
                />
              </Tooltip>
            )}
          </Flex>
        ))}
        <Box>
          <Button
            colorScheme='primary'
            leftIcon={<Icon as={RiAddCircleLine} />}
            size='medium'
            variant='ghost'
            onClick={(e: React.MouseEvent) => onAddNewPath(e, index)}
            disabled={disabled}
            data-testid='add-path-button'
          >
            Add path
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default PathRules;
