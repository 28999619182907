import React from 'react';
import {Select} from 'spekit-ui';
import {Select as SelectProps} from '../types';

const options = [
  {
    label: 'Is',
    value: 'exactly',
  },
  {
    label: 'Is not ',
    value: 'not-exactly',
  },
  {
    label: 'Contains',
    value: 'contains',
  },
  {
    label: 'Does not contain',
    value: 'not-contains',
  },
];

interface IPathSelectorProps {
  onSelect: (value: SelectProps) => void;
  value: string;
  disabled: boolean;
}
const PathSelector = (props: IPathSelectorProps) => {
  const {onSelect, value, disabled} = props;

  const handleChange = (newValue: any) => {
    onSelect({label: newValue.label, value: newValue.value});
  };
  return (
    <>
      <Select
        isClearable={false}
        options={options}
        isDisabled={disabled}
        value={options.filter((option) => option.value === value)[0]}
        onChange={handleChange}
        placeholder='Select where the Spotlight will display'
      />
    </>
  );
};

export default PathSelector;
