import {FONT_FAMILY} from '../Typography';

export const colorSchemes = {
  neutral: {
    backgroundColor: 'neutral.50',
    color: 'neutral.800',
  },
  success: {
    backgroundColor: 'success.50',
    color: 'success.700',
  },
  warning: {
    backgroundColor: 'warning.50',
    color: 'warning.700',
  },
  error: {
    backgroundColor: 'error.50',
    color: 'error.700',
  },
  primary: {
    backgroundColor: 'default.primary.50',
    color: 'default.primary.700',
  },
  pink: {
    backgroundColor: 'pink.50',
    color: 'pink.700',
  },
  filter: {
    backgroundColor: 'default.primary.50',
    color: 'default.primary.700',
  },
  plum: {
    backgroundColor: 'plum.25',
    color: 'plum.500',
  },
  turq: {
    backgroundColor: 'turq.25',
    color: 'turq.500',
  },
  taffy: {
    backgroundColor: 'taffy.25',
    color: 'taffy.500',
  },
  orchid: {
    backgroundColor: 'orchid.25',
    color: 'orchid.500',
  },
  royal: {
    backgroundColor: 'royal.25',
    color: 'royal.500',
  },
  pdf: {
    backgroundColor: 'pdf.25',
    color: 'pdf.500',
  },
  image: {
    backgroundColor: 'image.25',
    color: 'image.500',
  },
  presentation: {
    backgroundColor: 'presentation.25',
    color: 'presentation.500',
  },
  document: {
    backgroundColor: 'document.25',
    color: 'document.500',
  },
  spreadsheet: {
    backgroundColor: 'spreadsheet.25',
    color: 'spreadsheet.500',
  },
  misc: {
    backgroundColor: 'misc.25',
    color: 'misc.500',
  },
  seismic: {
    backgroundColor: 'seismic.25',
    color: 'seismic.500',
  },
};

export const filterVariantConfig = {};

Object.entries(colorSchemes).forEach(([key, value]) => {
  filterVariantConfig[key] = {
    _checked: {
      backgroundColor: value.backgroundColor,
      color: value.color,
      borderColor: value.color,
    },
    _hover: {
      backgroundColor: value.backgroundColor,
      color: value.color,
    },
  };
});

export const sizeConfig = {
  filter: {
    sm: {
      container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        h: '24px',
        py: '5.5px',
        px: '8px',
        borderRadius: '6',
        w: 'fit-content',
      },
      label: {
        fontSize: 'tiny',
      },
      closeButton: {
        h: '12px',
        w: '12px',
        ml: '10',
        fontSize: '16',
      },
    },
    md: {
      container: {
        maxH: '32px',
        h: '32px',
        py: '5.5px',
        px: '8',
        borderRadius: '8',
        w: 'fit-content',
      },
      label: {
        fontSize: 'small',
      },
      closeButton: {
        h: '12px',
        w: '12px',
        ml: '10',
        fontSize: '16',
      },
    },
  },

  status: {
    sm: {
      container: {
        py: '2',
        px: '8',
        borderRadius: '16',
        minWidth: 'revert',
        h: '24px',
      },
      label: {
        fontSize: 'tiny',

        fontFamily: FONT_FAMILY,
      },
      closeButton: {
        h: '12px',
        w: '12px',
      },
    },
    md: {
      container: {
        maxH: '25px',
        h: '25px',
        py: '2',
        px: '8',
        borderRadius: '16',
        minWidth: 'revert',
      },
      label: {
        fontSize: 'small',

        fontFamily: FONT_FAMILY,
      },
      closeButton: {
        h: '16px',
        w: '16px',
      },
    },
  },

  solid: {
    sm: {
      container: {
        py: '6',
        px: '8',
        h: '6',
        borderRadius: '6',
      },
      label: {
        fontSize: 'tiny',
      },
      closeButton: {
        h: '4',
        w: '4',
      },
    },
    md: {
      container: {
        h: '8',
        py: '6',
        px: '8',
        borderRadius: '8',
      },
      label: {
        fontSize: 'small',
      },
      closeButton: {
        h: '4',
        w: '4',
      },
    },
  },
};
