import {checkTaskStatus} from '../task/checkStatusTask';
import {retrieve, create, modify, createForm} from '../utils/APIHelpers';
import {
  ProgressResponse,
  ConnectionResponse,
  LoginResponse,
  ExcelImportExportResponse,
  SSOSyncResponse,
} from './sso.types';

export const checkProgress = async (): Promise<ProgressResponse> => {
  return await retrieve(`/workos/connectivity_status/`);
};

export const connectSSO = async (domain: string): Promise<ConnectionResponse> => {
  return await create(`/workos/sso/connect/`, {domain});
};

export const connectDsync = async (): Promise<ConnectionResponse> => {
  return await retrieve(`/workos/dsync/connect/`);
};

export const updateSessionShown = async (
  sessionId: string,
  shown: boolean
): Promise<unknown> => {
  return await modify(`/workos/connectivity_sessions/${sessionId}/`, {shown});
};

export const loginWithSSO = async (
  domain: string,
  redir: string
): Promise<LoginResponse> => {
  return retrieve(`/workos/v1/auth/initiate/?domain=${domain}${redir.replace('?', '&')}`);
};

export const downloadExcelFile = async (): Promise<ExcelImportExportResponse> => {
  const response: any = await retrieve(`/workos/sso/mapping/export/`);
  await checkTaskStatus(response.task_id);
  return response;
};

export const uploadExcelFile = async (
  formData: FormData
): Promise<ExcelImportExportResponse> => {
  const response: any = await createForm(`/workos/sso/mapping/import/`, formData);
  await checkTaskStatus(response.task_id);
  return response;
};

export const syncNow = async (): Promise<SSOSyncResponse> => {
  const response: any = await retrieve(`/workos/v1/dsync/resync/`);
  await checkTaskStatus(response.task_id);
  return response;
};
