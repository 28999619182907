import React from 'react';
import {Heading as ChakraHeading, HeadingProps, forwardRef} from '@chakra-ui/react';
import {FONT_FAMILY} from '../typography';

export type THeading = Omit<HeadingProps, 'as'> & {
  as?: 'h1' | 'h2' | 'h3' | 'h4';
};

export const HeadingConfig = {
  baseStyle: {
    color: 'neutral.800',
    fontWeight: 'normal',
    fontFamily: FONT_FAMILY,
  },
};

const headingFontSizes = {
  h1: 'massive',
  h2: 'jumbo',
  h3: 'xl',
  h4: 'large',
};

export const Heading = forwardRef((props: THeading, ref) => {
  const {as = 'h3', ...restProps} = props;
  return (
    <ChakraHeading
      m={0}
      as={as}
      fontSize={headingFontSizes[as]}
      ref={ref}
      {...restProps}
    />
  );
});
