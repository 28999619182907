import React from 'react';
import {capitalizeFirstAndLowercaseRest} from 'spekit-datalayer/src/utils/commonUtils';
import {TComputedValue, TCustomField} from 'spekit-types';
import {Input} from 'spekit-ui';

interface IStringField {
  value: string;
  field: TCustomField;
  handleUpdate: (id: string, values: TComputedValue) => void;
}

export const StringField = ({field, value, handleUpdate}: IStringField) => {
  const {id, name} = field;
  return (
    <Input
      data-testid='custom-field-input'
      value={value}
      onChange={(e) => handleUpdate(id, e.target.value)}
      placeholder={`Enter ${capitalizeFirstAndLowercaseRest(name)}`}
    />
  );
};
