import React from 'react';
import {FormHelperText as ChakraFormHelperText, HelpTextProps} from '@chakra-ui/react';
import {FONT_FAMILY} from '../../Typography';

export type TFormHelperTextProps = HelpTextProps;

export const FormHelperText = (props: TFormHelperTextProps) => (
  <ChakraFormHelperText
    fontSize='tiny'
    fontWeight='normal'
    fontFamily={FONT_FAMILY}
    color='neutral.800'
    my={6}
    {...props}
  />
);
