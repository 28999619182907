import {stringifyQs} from '../utils/commonUtils';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';

export const fetchStatsByType = async (query: any) => {
  const _query = {
    type: query.type,
    team_id: query.teamId,
  };

  const querystring = stringifyQs(_query, {
    indices: false,
    arrayFormat: 'comma',
    skipNulls: true,
    encode: true,
  });

  const response = await fetch(`/api/v1/stats/?${querystring}`, {});
  const result = response.json();
  return result;
};
