/* eslint-disable no-param-reassign */
import {set} from 'js-cookie';
import {IS_IMPERSONATING, BUILD_VERSION} from './constants';
import {ResultType, setJWTRuleType} from './fetchMiddlewareTypes';
import {
  jwtDecodeToken,
  getItem,
  setItem,
  getTokenKey,
  tokenStorage,
} from './jwtUtilsHelpers';
import {getRefreshToken} from './jwtUtilsRefresh';

export const isEmptyCheck = (x: string | undefined | null) => {
  // eslint-disable-next-line
  if (typeof x !== 'string' || x === 'undefined' || x.trim() === '') {
    return true;
  }
  return false;
};

export const extractTokenFromHeaders = (headers: Record<string, string>) => {
  if (
    !isEmptyCheck(headers.Authorization) &&
    typeof headers.Authorization === 'string' &&
    headers.Authorization.match(/^JWT (?!undefined|null)\w/)
  ) {
    return headers.Authorization.replace(/^(JWT )/, '');
  }
  return undefined;
};

/**
 * Checks if the jwt token is expired.
 *
 * @param token {string}
 * @returns {boolean}
 */
export function isJwtTokenExpired(token: string): boolean {
  const decodedToken: any = jwtDecodeToken(token);
  let result: boolean = true;

  if (decodedToken && decodedToken.exp) {
    const expirationDate: number = new Date(0).setUTCSeconds(decodedToken.exp);
    result = expirationDate.valueOf() < new Date().valueOf();
  }
  return result;
}

/**
 * Calls generate token api to fetch the new token.
 *
 * @param xClient {string}
 * @param host {string}
 * @param token {string}
 * @returns {string}
 */
export const generateTokenAPI = async (
  xClient: string,
  host: string,
  token: string
): Promise<string | false> => {
  const tokenKey = getTokenKey(xClient);
  try {
    const resp = await fetch(host + '/api/auth/generate', {
      method: 'GET',
      headers: {
        credentials: 'include',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CLIENT': xClient,
        Authorization: `JWT ${token}`,
      },
    });
    const results = (await resp.json()) as ResultType;
    if (resp.status !== 200 || results.success !== true) {
      throw new Error('JWT Refresh token unsuccessful.');
    }
    const newToken = results[tokenKey];
    await tokenStorage.setToken(tokenKey, newToken);
    return newToken;
  } catch (err) {
    console.error(err);
  }

  return false;
};

let _isLeLegacyTokenCheckPerformed = false;

/**
 * Handle Legacy Token.
 *
 * This script will helps us in rollout jwt to obtain new jwt token.
 * If the chrome ext already has a jwt token, we check its payload to check if
 * its an old jwt token, we call the generateTokenAPI to obtain the new token.
 *
 * Once we perform the check, we set `_isLeLegacyTokenCheckPerformed` to true
 * in app memory context to prevent token check on every request.
 *
 * @param xClientName {string}
 * @param host {string}
 * @returns {string | boolean}
 */
const handleLegacyToken = async (token: string, xClientName: string, host: string) => {
  if (_isLeLegacyTokenCheckPerformed) {
    return false;
  }
  _isLeLegacyTokenCheckPerformed = true;
  const xClient = xClientName || 'app';
  const decodedToken = jwtDecodeToken(token);

  if (decodedToken?.kind === undefined) {
    return generateTokenAPI(xClientName, host, token);
  }

  return false;
};

let pendingRefreshRequest: Promise<string | undefined> | undefined = undefined;

/**
 * Helper method to get the jwt token. If its not available, it will call
 * refresh token api to generate new one.
 *
 * @param xClientName {string}
 * @param host {string}
 * @returns {string}
 */
export const getJWTToken = async (
  xClientName: string,
  setJWTRule: setJWTRuleType,
  host: string = '',
  url: string = ''
) => {
  const xClient = xClientName || 'app';
  const tokenKey = getTokenKey(xClient);
  let refreshPromise = undefined;

  let token: string | undefined = await tokenStorage.getToken(tokenKey);
  if (!token) {
    if (pendingRefreshRequest) {
      token = await pendingRefreshRequest;
      if (token && xClient === 'ext') {
        setJWTRule();
      }
    } else {
      // if we're in the extension, we call refresh only it hasn't failed
      // with a 401 previously.
      if (xClient === 'ext') {
        const spekitBlockRefresh = await getItem('spekit_block_refresh');
        if (spekitBlockRefresh !== 'true')
          refreshPromise = getRefreshToken(xClientName, host, url);
      } else {
        refreshPromise = getRefreshToken(xClientName, host, url);
      }

      pendingRefreshRequest = refreshPromise;

      try {
        token = await refreshPromise;
        if (token && xClient === 'ext') {
          setJWTRule();
        }
      } finally {
        pendingRefreshRequest = undefined;
      }
    }
  }

  return token;
};

/**
 * If the user is in impersonating mode, we do not send jwt token
 * in the headers. Impersonating mode is determined by the key
 * set in the localStorage.
 *
 * @param options {any}
 * @param token {string}
 * @returns {any}
 */
const addJWTHeader = async (
  headers: Record<string, string>,
  token: string | undefined
) => {
  let isImpersonating = null;
  if (headers['X-CLIENT'] === 'ext') {
    isImpersonating = await getItem(IS_IMPERSONATING);
  } else {
    isImpersonating = localStorage.getItem(IS_IMPERSONATING);
  }

  if (token && (!isImpersonating || isImpersonating !== 'yes')) {
    headers.Authorization = `JWT ${token}`;
  }
  return headers;
};

/**
 * JWT Interceptor method.
 *
 * @param _options {any}
 * @param commsSendToBackground {any}
 * @param host {string}
 * @returns {any}
 */
export const jwtHandler = async (
  options: RequestInit,
  commsSendToBackground: any,
  setJWTRule: any,
  url: string,
  outlook: boolean,
  host = ''
) => {
  if (!options.headers) options.headers = {};

  options.headers['X-CLIENT'] = 'app';

  if (!!commsSendToBackground) {
    options.headers['X-CLIENT'] = 'ext';
  }

  if (outlook) {
    options.headers['X-CLIENT'] = 'outlook';
  }

  options.headers['X-VERSION'] = BUILD_VERSION;

  host = host || '';

  const origin = location?.origin;
  if (options.headers['X-CLIENT'] === 'ext' && !origin.includes('chrome-extension://')) {
    // this is because marker script tries to send the api call directly from the webpage.
    // These api are handles via fetch middleware and then redirect to the fetch middleware
    // for chrome. This condition ensures that the api calls init from the marker script
    // directly landed to fetchMiddleware without any processing.
    return options;
  }

  let token = null;

  if (
    options.headers['X-CLIENT'] === 'ext' &&
    extractTokenFromHeaders(options.headers as Record<string, string>)
  ) {
    token = extractTokenFromHeaders(options.headers as Record<string, string>);
  } else {
    token = await getJWTToken(options.headers['X-CLIENT'], setJWTRule, host, url);
  }
  // we have a good token, no need to block refreshes
  if (token && options?.headers?.['X-CLIENT'] === 'ext') {
    await setItem('spekit_block_refresh', 'false');
  }

  // this is temporary check until jwt rollout is complete.
  if (token && !isEmptyCheck(token) && options.headers['X-CLIENT'] === 'ext') {
    const updatedToken = await handleLegacyToken(
      token,
      options.headers['X-CLIENT'] as string,
      host
    );
    if (updatedToken !== false) {
      token = updatedToken;
      setJWTRule();
    }
  }

  if (token && !isEmptyCheck(token) && isJwtTokenExpired(token)) {
    token = await getRefreshToken(options.headers['X-CLIENT'], host, url);
    // we have a new token -- need to update the extension declarativeNetRequest rule
    if (token && options.headers['X-CLIENT'] === 'ext') {
      setJWTRule();
    }
  }

  await addJWTHeader(options.headers as Record<string, string>, token);
  return options;
};
