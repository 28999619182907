import {Image, Flex, Icon} from 'spekit-ui';

interface ISBProps {
  icon: any;
  iconName: string;
  faIcon: boolean;
  onClick: (icon: any, iconName: string, faIcon: boolean) => void;
}

export default function IconSelectorButton({icon, faIcon, iconName, onClick}: ISBProps) {
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick(icon, iconName, faIcon);
    }
  };

  return (
    <Flex
      justify='center'
      align='center'
      onClick={handleClick}
      _hover={{cursor: 'pointer'}}
    >
      {faIcon ? (
        <Icon as={icon} w={8} h={8} p={8} color='primary.500' data-testid={iconName} />
      ) : (
        <Image src={icon} w={8} h={8} p={8} data-testid={`image-${iconName}`} />
      )}
    </Flex>
  );
}
