const initialState = null;
function featureFlagReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_FEATURES':
      return action.features;
    default:
      return state;
  }
}

export default featureFlagReducer;
