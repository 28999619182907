import DFAdd from 'date-fns/add';
import DFSub from 'date-fns/sub';
import DFParse from 'date-fns/parse';
import DFFormat from 'date-fns/format';
import DFSubDays from 'date-fns/subDays';
import DFIsValid from 'date-fns/isValid';
import DFIsEqual from 'date-fns/isEqual';
import DFIsAfter from 'date-fns/isAfter';
import DFIsBefore from 'date-fns/isBefore';
import DFParseISO from 'date-fns/parseISO';
import DFFormatDistance from 'date-fns/formatDistance';
import DFFormatDuration from 'date-fns/formatDuration';
import DFIntervalToDuration from 'date-fns/intervalToDuration';
import DFSecondsToHours from 'date-fns/secondsToHours';
import DFSecondsToMinutes from 'date-fns/secondsToMinutes';
import DFFormatDistanceStrict from 'date-fns/formatDistanceStrict';
import DFDifferenceInDays from 'date-fns/differenceInDays';

type TDate = Date | string | number;

export const parse = (...params: Parameters<typeof DFParse>) => DFParse(...params);

export const parseISO = (...params: Parameters<typeof DFParseISO>) =>
  DFParseISO(...params);

export const formatDistance = (...params: Parameters<typeof DFFormatDistance>) =>
  DFFormatDistance(...params);

export const formatDistanceStrict = (
  ...params: Parameters<typeof DFFormatDistanceStrict>
) => DFFormatDistanceStrict(...params);

export const add = (...params: Parameters<typeof DFAdd>) => DFAdd(...params);

export const sub = (...params: Parameters<typeof DFSub>) => DFSub(...params);

export const subDays = (...params: Parameters<typeof DFSubDays>) => DFSubDays(...params);

export const format = (...params: Parameters<typeof DFFormat>) => DFFormat(...params);

export const formatDuration = (...params: Parameters<typeof DFFormatDuration>) =>
  DFFormatDuration(...params);

export const intervalToDuration = (...params: Parameters<typeof DFIntervalToDuration>) =>
  DFIntervalToDuration(...params);

export const formatDurationFromSeconds = (duration: number) => {
  if (!duration || duration < 1) return '0 seconds';

  const interval = DFIntervalToDuration({start: 0, end: duration * 1000});

  return DFFormatDuration(interval);
};
export const formatSecondsToHours = (seconds: number) => {
  return DFSecondsToHours(seconds);
};
export const formatSecondsToMinutes = (seconds: number) => {
  return DFSecondsToMinutes(seconds);
};

export const fromNow = (
  date: TDate,
  options?: {addSuffix?: boolean; includeSeconds?: boolean}
) => {
  let parsedDate;
  if (typeof date === 'string') parsedDate = parseISO(date);
  else parsedDate = date;

  return formatDistance(parsedDate, Date.now(), options);
};

export const fromNowStrict = (
  date: TDate,
  options?: {addSuffix?: boolean; includeSeconds?: boolean}
) => {
  let parsedDate;
  if (typeof date === 'string') parsedDate = parseISO(date);
  else parsedDate = date;

  let formattedDate = formatDistanceStrict(parsedDate, Date.now(), options);
  const [count, duration] = formattedDate.split(/\s/);
  return `${count}${duration.slice(0, duration.includes('month') ? 2 : 1)}`;
};

export const isValid = (...params: Parameters<typeof DFIsValid>) => {
  return DFIsValid(...params);
};
export const isEqual = (...params: Parameters<typeof DFIsEqual>) => {
  return DFIsEqual(...params);
};
export const isAfter = (...params: Parameters<typeof DFIsAfter>) => {
  return DFIsAfter(...params);
};
export const isBefore = (...params: Parameters<typeof DFIsBefore>) => {
  return DFIsBefore(...params);
};

export const isSameOrAfter = (date1: Date | number, date2: Date | number) => {
  return isEqual(date1, date2) || isAfter(date1, date2);
};

export const isSameOrBefore = (date1: Date | number, date2: Date | number) => {
  return isEqual(date1, date2) || isBefore(date1, date2);
};

export const getTinyDate = (date: TDate) => {
  let parsedDate = date;
  if (typeof parsedDate === 'string') parsedDate = parseISO(parsedDate);

  const {seconds, minutes, hours, days, months, years} = intervalToDuration({
    start: parsedDate,
    end: Date.now(),
  });

  if (years) return `${years}y`;
  if (months) return `${months}mo`;
  if (days && days > 6 && days < 31) return `${Math.trunc(days / 7)}w`;
  if (days) return `${days}d`;
  if (hours) return `${hours}h`;
  if (minutes) return `${minutes}m`;

  return `${seconds}s`;
};

export const isGreaterThanAWeek = (date: TDate) => {
  let parsedDate = date;
  let currentDate = new Date();
  if (typeof parsedDate === 'string') parsedDate = parseISO(parsedDate);

  const difference = DFDifferenceInDays(currentDate, parsedDate);
  return difference > 7;
};

export const formatTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const remainingMinutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  let result = '';

  if (hours > 0) {
    result += `${hours}h `;
  }
  if (remainingMinutes > 0 || hours > 0) {
    result += `${remainingMinutes}m `;
  }
  if (remainingSeconds > 0 || (hours === 0 && remainingMinutes === 0)) {
    result += `${remainingSeconds}s`;
  }

  return result.trim();
};

export const DATE_FORMAT = 'MMMM d, yyyy';

export const SHORTENED_DATE_FORMAT = 'MMM dd, yyyy';

export const SHORTENED_DATE_FORMAT_NO_ZERO = 'MMM d, yyyy';

export const HOUR_FORMAT_PERIOD = 'h:mma';
