import React from 'react';

import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

import Button from '../../button';
import styles from './discard.module.css';
import Fontawesome from '../../fontawesome';

export interface Props {
  saveAsPublished: () => void;
  saveAsDraft: () => void;
  handleDiscard: () => void;
  handleCancel: () => void;
  isPublished: boolean;
}

const Discard: React.FC<Props> = ({
  saveAsPublished,
  saveAsDraft,
  isPublished,
  handleDiscard,
  handleCancel,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <Fontawesome name={faExclamationTriangle} className={styles.icon} />
        <span>Discard Changes?</span>
      </div>
      <div className={styles.subHeading}>You have unsaved changes.</div>
      <div className={styles.btnContainer}>
        <Button className={styles.btn} onClick={handleDiscard} noHover>
          Discard
        </Button>
        {isPublished ? (
          <Button
            className={styles.btnSecondary}
            secondary
            onClick={saveAsPublished}
            noHover
          >
            Publish Change
          </Button>
        ) : (
          <Button className={styles.btnSecondary} secondary onClick={saveAsDraft} noHover>
            Save as draft
          </Button>
        )}
      </div>
      <div className={styles.cancel} onClick={handleCancel}>
        Cancel, keep editing
      </div>
    </div>
  );
};

export default Discard;
