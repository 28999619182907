import {
  ISearchQuery,
  ISearchResponse,
  ISearchCountsResponse,
  IRecentlyViewedItem,
} from 'spekit-types';
import {retrieve} from '../utils/APIHelpers';
import {stringifyQs} from '../utils/commonUtils';

export const getSearchResults = (queryString: ISearchQuery) => {
  const query = stringifyQs(queryString, {
    arrayFormat: 'comma',
    skipNulls: true,
    encode: false,
    addQueryPrefix: true,
  });
  return retrieve<ISearchResponse>(`/api/v2.0/search/${query}`);
};

export const getCounts = (queryString: ISearchQuery) => {
  const query = stringifyQs(queryString, {
    arrayFormat: 'comma',
    skipNulls: true,
    encode: false,
    addQueryPrefix: true,
  });
  return retrieve<ISearchCountsResponse>(`/api/v2.0/search/count/${query}`);
};

export const getRecentlyViewed = () => {
  return retrieve<IRecentlyViewedItem[]>(`/api/v1/recently-viewed/`);
};
