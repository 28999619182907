import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {INotificationState} from 'spekit-types';

export const defaultValues: INotificationState = {
  teams: [],
  message: '',
  notifyByEmail: false,
  createSpotlight: false,
  notifyInApp: false,
};

const selectable = yup.object().shape({label: yup.string(), value: yup.string()});

const schema = yup.object({
  teams: yup.array(selectable).min(1, 'Select at least one Team'),
  message: yup
    .string()
    .required('Enter message')
    .max(255, 'Message must be less than 255 characters'),
  notifyByEmail: yup.boolean(),
  createSpotlight: yup.boolean(),
});

export const resolver = yupResolver(schema);
