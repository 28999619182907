/**
 * SSOLoginPage
 *
 * Handles the SSO login page
 *
 * This page is rendered when the user selects login with sso from the 'view all login options' landing page
 */

import {useState} from 'react';
import LoginLayout from '../../components/LoginLayout';
import {
  Heading,
  Text,
  Flex,
  Input,
  FormControl,
  FormLabel,
  DSTooltip as Tooltip,
  Icon,
  DSButton as Button,
  InputRightElement,
  InputGroup,
  FormErrorMessage,
  Loader,
} from 'spekit-ui';
import {RiQuestionLine, RiArrowLeftLine} from 'react-icons/ri';
import {SSO} from 'spekit-datalayer';
import {useHistory} from 'react-router-dom';
import {ReactComponent as SSOIcon} from '../../../../images/SSO.svg';

const SSOPage = () => {
  const [domain, setDomain] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const domainInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    const {value} = e.target;
    if (value.length > 53) {
      return;
    }
    const domainValue = value.replace(/[^a-z0-9]/gi, '').toLowerCase();
    setDomain(domainValue);
  };

  function getCorrectDomain() {
    return domain.includes('spekit.co') ? domain : `${domain}.spekit.co`;
  }

  const ssoLoginHandler = async () => {
    if (domain) {
      setIsLoading(true);
      setError('');
      try {
        const spekitDomain = getCorrectDomain();
        const response = await SSO.loginWithSSO(spekitDomain, history.location.search);
        window.location.href = response.authorization_url;
      } catch (e) {
        setError((e as Error).message);
      } finally {
        setIsLoading(false);
      }
    } else setError('A workspace domain is required to login.');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      ssoLoginHandler();
    }
  };

  return (
    <>
      <LoginLayout>
        <Flex direction='column' px={144} w={384} pt={176}>
          <Flex alignItems='flex-start'>
            <Button
              data-testid='back-btn'
              size='small'
              variant='ghost'
              colorScheme='primary'
              leftIcon={<Icon as={RiArrowLeftLine} />}
              onClick={() =>
                history.push({
                  pathname: '/login-all-options',
                  search: history.location.search,
                  state: {loginFlow: true},
                })
              }
            >
              Back to login options
            </Button>
          </Flex>
          <Heading
            as='h3'
            fontWeight='semibold'
            fontSize='20px'
            textAlign='center'
            mt={20}
          >
            Login to Spekit
          </Heading>
          <Flex gap={10} alignItems='center' justifyContent='center'>
            <Icon as={SSOIcon} w='22px' h='21px' />
            <Text variant='body2' fontWeight='normal' textAlign='center' mt={44} mb={44}>
              Login with Single Sign-On (SSO)
            </Text>
          </Flex>

          <FormControl isInvalid={!!error} mb={20}>
            <Flex align='center'>
              <FormLabel mr={4}>Workspace domain</FormLabel>
              <Tooltip
                label='You can find your workspace domain in the original invitation email you received from Spekit.'
                shouldWrapChildren
                placement='top'
              >
                <Icon as={RiQuestionLine} mt={4} />
              </Tooltip>
            </Flex>
            <InputGroup size='md'>
              <Input
                data-testid='workspace-domain'
                value={domain}
                isInvalid={!!error}
                placeholder='Enter workspace domain'
                pr={84}
                onChange={domainInputHandler}
                onKeyDown={handleKeyDown}
                autoFocus
              />
              {!error && (
                <InputRightElement pr={24}>
                  <Text>.spekit.co</Text>
                </InputRightElement>
              )}
            </InputGroup>
            {error && (
              <FormErrorMessage data-testid='login-error'>{error}</FormErrorMessage>
            )}
          </FormControl>
          {isLoading ? (
            <Flex justify='center' align='center' mt={8} data-testid='loader'>
              <Loader size={8} />
            </Flex>
          ) : (
            <Button
              data-testid='login-btn'
              onClick={ssoLoginHandler}
              size='large'
              variant='contained'
              colorScheme='primary'
            >
              Login
            </Button>
          )}
        </Flex>
      </LoginLayout>
    </>
  );
};

export default SSOPage;
