import React from 'react';
import Select from 'react-select/async';
import {Dropdown} from 'spekit-ui';
import debounce from 'debounce-promise';
import {Props} from './audienceTeamSelector.types';
const EnhancedDropDown: any = Dropdown(Select);
import {getTeams} from './audienceUtils';

const AudienceTeamSelector: React.FC<Props> = ({
  monitorScrollElement,
  value,
  onSelect,
  isDisabled,
  error,
}) => {
  const handleChange = (newValue: any, actionMeta: any) => {
    let teams = value ? [...value] : [];
    let {action, option, removedValue} = actionMeta;
    if (action === 'select-option') {
      teams.push({value: option.value, label: option.label, topics: option.topics});
      onSelect(teams);
    }
    if (action === 'remove-value' || action === 'pop-value') {
      let filteredTeams = teams.filter((team) => team !== removedValue.value);
      onSelect(filteredTeams);
    }
    if (action === 'deselect-option') {
      let mapedTeams = newValue.map((v: any) => ({
        value: v.value,
        label: v.label,
        topics: v.topics,
      }));
      onSelect(mapedTeams);
    }
    if (action === 'clear') {
      onSelect([]);
    }
  };
  const getDebouncedTeams = debounce(getTeams, 200, {leading: false});
  return (
    <div>
      <EnhancedDropDown
        isClearable={false}
        hideSelectedOptions={false}
        defaultOptions
        isMulti
        filterLabel='Team'
        noOptionMessage='No teams found'
        placeholder='Select a team'
        menuPosition='fixed'
        isDisabled={isDisabled}
        error={error}
        teamStyles
        menuPortalTarget={document.querySelector('body')}
        closeMenuOnScroll={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (
            monitorScrollElement &&
            monitorScrollElement.current &&
            event.target.isSameNode(monitorScrollElement.current)
          ) {
            return true;
          }
          return false;
        }}
        handleChange={handleChange}
        loadOptions={getDebouncedTeams}
        value={value}
      />
    </div>
  );
};

export default AudienceTeamSelector;
