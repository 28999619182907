import React from 'react';
import {version as uuidVersion, validate as uuidValidate} from 'uuid';
import {PDFViewer, Box} from 'spekit-ui';
import {useLocation} from 'react-router-dom';
import {utils} from 'spekit-datalayer';

export default function ApryseEmbed() {
  const location = useLocation();
  const queryString = location.search;
  const contentUrl = utils.parseQs(queryString) as unknown as {id: string; nonce: string};
  let nonce = Date.now().toString();

  const isValidUUIDV4 = (uuid: string) => uuidValidate(uuid) && uuidVersion(uuid) === 4;

  if (!contentUrl.id) {
    return null;
  }

  if (!isValidUUIDV4(contentUrl.id)) {
    return null;
  }

  if (contentUrl.nonce) {
    nonce = contentUrl.nonce;
  }
  return (
    <Box height='100vh'>
      <PDFViewer
        url={`/api/v1/topic_assets/${contentUrl.id}/asset_view/?nonce=${nonce}`}
      />
    </Box>
  );
}
