export function generateCopyableContent(definition: string, host?: string) {
  const textHtml = getSpekHTML(definition, host);
  return {textHtml, textPlain: htmlToText(textHtml)};
}

export function getSpekHTML(htmlString: string, host = '') {
  // Create a new DOM Parser
  const parser = new DOMParser();

  // Parse the HTML string
  const doc = parser.parseFromString(htmlString, 'text/html');

  // replace iframes with links - we use a custom section schema in CKE
  const iframeRegex =
    /<section\s+class="iframe-embed"\s+data-src="([^"]+)"[^>]*><\/section>/g;
  let updatedInnerHtml = doc.body.innerHTML.replace(
    iframeRegex,
    '<a href="$1" target="_blank" rel="noopener noreferrer">Click here to view</a>'
  );

  // <section class="upload-embed" data-file="{&quot;filepath&quot;:&quot;/api/v1/media/b3215d70-b942-47c9-8239-753b1e453a16/view/?download=true&quot;,&quot;filename&quot;:&quot;some great pdf.pdf&quot;,&quot;filetype&quot;:&quot;application/pdf&quot;}"></section>
  // find all upload embeds
  const fileUploads = doc.body.querySelectorAll('.upload-embed');

  // for each upload embed, parse the data-file attribute and replace the section with an anchor tag
  fileUploads.forEach((fileUpload) => {
    const dataFile = fileUpload.getAttribute('data-file');
    if (dataFile) {
      const parsedDataFile = JSON.parse(dataFile);
      const {filepath, filename} = parsedDataFile;
      const anchorTag = `<a href="${
        host + filepath
      }" target="_blank" rel="noopener noreferrer">${filename}</a>`;
      updatedInnerHtml = updatedInnerHtml.replace(fileUpload.outerHTML, anchorTag);
    } else {
      // remove the file upload if the file is missing
      updatedInnerHtml = updatedInnerHtml.replace(fileUpload.outerHTML, '');
    }
  });

  return updatedInnerHtml;
}

export function htmlToText(html: string) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  let text = '';

  function parseNode(node: HTMLElement, indent = '', index = 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      text += node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const el = node;
      const parentTagName = el.parentElement
        ? el.parentElement.tagName.toLowerCase()
        : '';
      switch (el.tagName.toLowerCase()) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'p':
        case 'pre':
        case 'blockquote':
          if (text.length > 0 && text[text.length - 1] !== '\n') {
            text += '\n';
          }
          text += indent;
          break;
        case 'li':
          text += '\n' + indent;
          const prefix = parentTagName === 'ol' ? `${index + 1}. ` : '- ';
          text += prefix;
          break;
        case 'a':
          text += el.getAttribute('href') + '\n';
          break;
        case 'hr':
          text += '\n-----\n';
          break;
        case 'img':
          // text += '\n' + el.getAttribute('src') + '\n';
          text += el.getAttribute('src');
          break;
        case 'table':
          // We just skip the table and do not pass it to parseNode
          return;
        default:
          break;
      }

      let childIndex = 0;
      for (const child of Array.from(node.childNodes)) {
        parseNode(
          child as HTMLElement,
          el.tagName.toLowerCase() === 'ul' || el.tagName.toLowerCase() === 'ol'
            ? indent + '    '
            : indent,
          childIndex
        );
        if (child.nodeType === Node.ELEMENT_NODE) {
          childIndex++;
        }
      }

      if (
        ['h1', 'h2', 'h3', 'h4', 'p', 'pre', 'blockquote'].includes(
          el.tagName.toLowerCase()
        )
      ) {
        text += '\n';
      }
    }
  }

  parseNode(doc.body);

  return text.trim();
}
