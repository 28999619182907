import {FiltersResponse} from './filters.types';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {UserApiError} from '../user/UserApiError';

export async function get(): Promise<FiltersResponse> {
  return new Promise((res, rej) => {
    fetch('/api/generic/users_filters_data/', {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((r: any) => r.json())
      .then((result: any) => {
        if (!result) {
          throw new UserApiError('unexpected response from user api');
        } else {
          res(result);
        }
      })
      .catch((e: any) => rej(e));
  });
}
