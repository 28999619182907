import Command from '@ckeditor/ckeditor5-core/src/command';
import Position from '@ckeditor/ckeditor5-engine/src/model/position';

export default class EmbedCommand extends Command {
  execute(file: string | null, position: Position | undefined | null) {
    return this.editor.model.change((writer) => {
      // Insert <embeddedContent data-file="..."></embeddedContent> at the current selection position
      // the schema definition will render the correct view for embeddedContent models

      // create the embed element
      const element = writer.createElement('embeddedContent', {'data-file': file});

      // insert the embed element at the provided position
      this.editor.model.insertObject(element, position);

      // return the element
      return element;
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition() as Position,
      'embeddedContent'
    );

    this.isEnabled = allowedIn !== null;
  }
}
