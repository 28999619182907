import React from 'react';
import {FaArrowLeft, FaArrowRight} from 'react-icons/fa';
import {Button} from '../Button';
import {HStack, Flex, Box} from '../Layout';
import {Icon} from '../Icon';
import {Text} from '../Typography';
import {generatePages} from './helpers';
import {Button as ChakraButton, forwardRef, ButtonProps} from '@chakra-ui/react';

export type IPaginationProps = {
  currentPage: number;
  totalPages: number;
  onChange: (pageNumber: number) => void;
  dataTestId?: string;
  isCompact?: boolean;
};

const PagingButton = forwardRef((props: ButtonProps, ref) => {
  return (
    <ChakraButton
      borderRadius='lg'
      colorScheme='primary'
      h='40px'
      size='md'
      iconSpacing={8}
      variant='unstyled'
      fontSize='sm'
      ref={ref}
      {...props}
      borderColor={'transparent'}
      background='none'
      color='neutral.800'
      _active={{
        color: 'neutral.900',
        background: 'primary.25',
        borderColor: 'primary.500',
        borderWidth: 1,
      }}
      _disabled={{
        color: 'neutral.300',
        background: 'none',
        opacity: 1,
      }}
      _focusVisible={{
        color: 'neutral.900',
        background: 'primary.25',
        borderColor: 'primary.500',
        borderStyle: 'solid',
        borderWidth: 1,
        boxShadow: '0 0 0 4px var(--spekit-colors-primary-100)',
      }}
      _hover={{
        color: 'neutral.900',
        background: 'neutral.50',
        _disabled: {
          color: 'neutral.300',
          background: 'none',
          opacity: 1,
        },
      }}
    />
  );
});

export const ModernPagination = (props: IPaginationProps) => {
  const {totalPages, currentPage, onChange, dataTestId, isCompact} = props;

  const handleChange = (index: number) => () => {
    if (index === currentPage) return;
    onChange(index);
  };

  const handleNext = () => {
    onChange(currentPage + 1);
  };

  const handlePrevious = () => {
    onChange(currentPage - 1);
  };

  let pages = generatePages(totalPages, currentPage);

  const leftIcon = isCompact
    ? {icon: <Icon as={FaArrowLeft} />}
    : {leftIcon: <Icon as={FaArrowLeft} />};

  const rightIcon = isCompact
    ? {icon: <Icon as={FaArrowRight} />}
    : {rightIcon: <Icon as={FaArrowRight} />};

  const buttonVariant = isCompact ? 'icon' : 'ghost';

  return (
    <Flex
      justifyContent='space-between'
      data-testid={dataTestId}
      gap={isCompact ? '10' : 'revert'}
    >
      <Button
        variant={buttonVariant}
        colorScheme='white'
        h='40px'
        size='medium'
        isDisabled={currentPage <= 1}
        onClick={handlePrevious}
        aria-label='Previous Page'
        {...leftIcon}
      >
        {isCompact ? '' : 'Previous'}
      </Button>
      <HStack spacing={4}>
        {pages.map((page) => {
          if (page === -1 || page === 0)
            return (
              <Box key={page} h='40px' w='40px' textAlign='center' lineHeight='40px'>
                <Text fontWeight='normal' color='neutral.500'>
                  ...
                </Text>
              </Box>
            );
          return (
            <PagingButton
              aria-label={`Page ${page}`}
              isActive={page === currentPage}
              key={page}
              onClick={handleChange(page)}
              data-testid='pagination-page-item'
            >
              {page}
            </PagingButton>
          );
        })}
      </HStack>
      <Button
        variant={buttonVariant}
        colorScheme='white'
        h='40px'
        size='medium'
        aria-label='Next Page'
        isDisabled={currentPage >= totalPages}
        onClick={handleNext}
        {...rightIcon}
      >
        {isCompact ? '' : 'Next'}
      </Button>
    </Flex>
  );
};
