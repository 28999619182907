import React from 'react';
import {Flex, Image, Heading, Text} from 'spekit-ui';
import Logo from '../images/spekit-logo.svg';

interface IErrorViewProps {
  isPrivate: boolean;
}

export default function ErrorView({isPrivate}: IErrorViewProps) {
  return (
    <Flex h='100vh' position='relative' alignItems='center' justifyContent='center'>
      <Image src={Logo} w={36} position='absolute' top={32} left={32} />
      <Flex alignItems='center' direction='column' textAlign='center' w='500px'>
        {isPrivate ? (
          <>
            <Heading as='h4' fontWeight='semibold'>
              This content cannot be accessed
            </Heading>
            <Text variant='body2' mt={16} mb={32}>
              Login to your Spekit account or reach out to your admin for help.
            </Text>
          </>
        ) : (
          <>
            <Heading as='h1' fontWeight='semibold'>
              Oops!
            </Heading>
            <Heading as='h3' fontWeight='semibold' mt={32}>
              It looks like this content has been deleted.
            </Heading>
          </>
        )}
      </Flex>
    </Flex>
  );
}
