import React from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {IPerson, ISpekFromState, IWikiResult, TClonedTerm} from 'spekit-types';
import {getFormValues, resolver} from './form';

export interface ISpekFormProvider {
  termToClone?: TClonedTerm;
  termToEdit?: IWikiResult;
  user?: IPerson;
  children: React.ReactElement;
}

export const SpekFormProvider = ({
  termToClone,
  termToEdit,
  user,
  children,
}: ISpekFormProvider) => {
  const methods = useForm<ISpekFromState>({
    defaultValues: getFormValues({termToEdit, termToClone, user}),
    resolver,
    mode: 'onChange',
  });
  return <FormProvider {...methods}>{children}</FormProvider>;
};
