/**
 * A collection of utilities for the editor and plugins
 */
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';

/** FILE TYPE CHECKING */
interface FileInterface {
  type: string;
  name: string;
}

export const checkForFileType = (file: FileInterface): boolean => {
  const allowedTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text',
    'application/rtf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.oasis.opendocument.spreadsheet',
    'text/csv',
  ];

  const allowedExtensions = [
    'jpg',
    'jpeg',
    'png',
    'bmp',
    'gif',
    'tif',
    'tiff',
    'svg',
    'pdf',
    'ai',
    'txt',
    'docx',
    'doc',
    'odt',
    'rtf',
    'pptx',
    'ppt',
    'odp',
    'xlsx',
    'xls',
    'ods',
    'csv',
  ];

  if (allowedTypes.includes(file.type)) {
    return true;
  }

  const fileExtension = file.name.split('.').pop()?.toLowerCase();
  if (fileExtension && allowedExtensions.includes(fileExtension)) {
    return true;
  }

  return false;
};

export const getImageSource = (source: string, host: string | null = null) => {
  if (host == null) {
    return source;
  }

  if (source.includes('data:image')) {
    return source;
  }

  if (source.includes('http')) {
    return source;
  }

  return `${host}${source}`;
};

export const createInputElement = (fileTypes: string) => {
  const inputElement = document.createElement('input');
  inputElement.type = 'file';

  // limit file types
  inputElement.accept = fileTypes;

  return inputElement;
};

export const supportedFileInputs = [
  'image/*',
  'application/pdf',
  '.docx',
  '.doc',
  '.rtf',
  '.odt',
  '.pptx',
  '.ppt',
  '.odp',
  '.xlsx',
  '.xls',
  '.ods',
  '.csv',
  '.txt',
];

export const getSelectedContent = (editor: ClassicEditor) => {
  // get selected range
  const selection = editor.model.document.selection;
  // extract html string from the range
  const content = editor.data.stringify(editor.model.getSelectedContent(selection));

  return content;
};
