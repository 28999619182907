import React from 'react';
import {fileLookup} from 'spekit-datalayer';
import {IOptionType} from 'spekit-types';
import {AsyncSelect} from 'spekit-ui';
import {Term} from '../types';

interface IFileInput {
  teams: string[];
  value: Term;
  onSelect: (file: Term) => void;
  isDisabled?: boolean;
}

export const FileInput = ({teams, value, onSelect, isDisabled}: IFileInput) => {
  const handleChange = (value: IOptionType) => onSelect({...value, term_type: 'file'});
  return (
    <AsyncSelect
      key={JSON.stringify(teams)}
      value={value.value ? value : undefined}
      loadOptions={(inputValue: string) => fileLookup({q: inputValue, teams})}
      onChange={handleChange}
      cacheOptions
      defaultOptions
      placeholder='Select file'
      isDisabled={isDisabled}
      blurInputOnSelect
    />
  );
};
