import {IUpdateCompanySharingSettingResponse} from 'spekit-types';
import {create, retrieve} from '../utils/APIHelpers';
import {COMPANY_PRIVACY_SETTINGS_API} from '../api';
import {fetchMiddleWare as fetch} from '../fetchMiddleWare';

export const getCompanySharingSetting = async () => {
  const response: IUpdateCompanySharingSettingResponse = await retrieve(
    `${COMPANY_PRIVACY_SETTINGS_API}`
  );
  return response;
};

export const toggleCompanySharingSetting = async () => {
  const response: IUpdateCompanySharingSettingResponse = await create(
    `${COMPANY_PRIVACY_SETTINGS_API}`,
    {}
  );
  return response;
};

export const fetchAsset = async (url: string) => {
  // we can call .blob() on the response when used in the webapp, but it fails in the extension. They both work this way, but the types are wrong.
  // It may be wise for us to rework our fetch handlers with files in mind with the addition of the Spekit Share functionality.
  const response = await fetch(url);
  return response;
};
