import React from 'react';
import {DSUtilityBar as UtilityBar, Flex, Text, Divider} from 'spekit-ui';
import {RiCloseFill} from 'react-icons/ri';
import SpotlightActionButtons from '../SpotlightActionButtons/SpotlightActionButtons';
import SpotlightStatusIndicator from '../SpotlightStatusIndicator/SpotlightStatusIndicator';
import SpotlightConfirmationPopup from '../SpotlightConfirmationPopup/SpotlightConfirmationPopup';

interface ISpotlightUtilityBarProps {
  isPublished: boolean;
  readable: boolean;
  editable: boolean;
  isNew: boolean;
  isSaving: boolean;
  isExpired: boolean;
  isCreate: boolean;
  unpublish: () => void;
  saveDraft: () => void;
  handleEdit: () => void;
  handleDelete: () => void;
  handleClone: () => void;
  onClose?: (evt: React.MouseEvent) => void;
  saveAsDraft: () => Promise<void>;
  saveAsPublished: () => Promise<void>;
  handleDiscard: () => void;
}

const SpotlightUtilityBar = ({
  onClose,
  isPublished,
  unpublish,
  saveDraft,
  readable,
  handleEdit,
  handleDelete,
  editable,
  isNew,
  handleClone,
  isSaving,
  isExpired,
  isCreate,
  saveAsDraft,
  handleDiscard,
  saveAsPublished,
}: ISpotlightUtilityBarProps) => {
  const status = () => {
    if (isSaving) return 'saving';
    if (isPublished) return 'published';
    if (isExpired) return 'expired';
    return 'draft';
  };

  return (
    <UtilityBar
      variant='light'
      data-testid='spotlight-utilitybar'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <Flex gap={12}>
        <Text variant='body1' fontWeight='semibold'>
          {isCreate ? 'Create Spotlight' : 'Edit Spotlight'}
        </Text>
        {!isNew && <SpotlightStatusIndicator status={status()} />}
      </Flex>
      <Flex alignItems='center'>
        <SpotlightActionButtons
          isPublished={isPublished}
          readable={readable}
          editable={editable}
          isNew={isNew}
          isSaving={isSaving}
          unpublish={unpublish}
          saveDraft={saveDraft}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleClone={handleClone}
          saveAsPublished={saveAsPublished}
        />
        <Divider orientation='vertical' h='30px' ml={16} mr={16} />

        {onClose && (
          <>
            <SpotlightConfirmationPopup
              primaryAction={handleDiscard}
              secondaryAction={saveAsDraft}
              primaryActionText='Yes, close'
              secondaryActionText='Save as draft'
              header='Close this Spotlight'
              message='Are you sure? Closing this Spotlight will cause your progress to be deleted.'
              icon={RiCloseFill}
              actionTooltip='Close'
              skipConfirmation={isNew || !editable || readable}
              primaryButtonTestId='dicard-button'
              secondaryButtonTestId='save-as-draft-button'
            />
          </>
        )}
      </Flex>
    </UtilityBar>
  );
};

export default SpotlightUtilityBar;
