import {IInviteUserResponse} from 'spekit-types';

export const checkInvite = async (id: string): Promise<IInviteUserResponse> => {
  try {
    let response = await fetch(`/api/auth/check_invite/${id}`, {
      credentials: 'include',
      method: 'GET',
    });
    const result = await response.json();
    return result;
  } catch (e) {
    throw new Error('Something went wrong. Please try again later.');
  }
};

export const acceptInvite = async (
  id: string,
  password: string
): Promise<IInviteUserResponse> => {
  try {
    let response = await fetch(`/api/auth/accept_invite/${id}`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...(password && {body: JSON.stringify({password})}),
    });
    const result = await response.json();
    return result;
  } catch (e) {
    throw new Error('Something went wrong. Please try again later.');
  }
};
