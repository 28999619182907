import React from 'react';
import {Icon, TIconProps} from '../design-system/Icon';

export const SeismicIcon = (props: TIconProps) => (
  <Icon {...props} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.98077 3.66377L15.25 1.869V8.4402L5.98077 6.65257V3.66377ZM2.75 16.1394V9.57573L12.0192 11.3634V14.3518L2.75 16.1394Z'
      stroke='#FE5000'
      strokeWidth='1.5'
    />
  </Icon>
);
