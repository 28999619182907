import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  DSButton as Button,
} from 'spekit-ui';

interface Props {
  setValidatedUser(value: boolean): void;
}

const COMPAT_OTP = '773549';
const COMPAT_ADD_OTP = '773550';

const AppCompatibilityOTP: React.FC<Props> = ({setValidatedUser}) => {
  const history = useHistory();
  const [otp, setOTP] = useState('');
  const [error, setError] = useState('');

  const pinHandler = () => {
    if (!otp) {
      setError('A verification code is required to login.');
    } else if (
      otp === COMPAT_OTP &&
      typeof setValidatedUser === 'function' &&
      window.location.href.includes('/appscompat-status')
    ) {
      setValidatedUser(true);
      history.replace('/appscompat-status', {appsCompatUser: true});
    } else if (
      otp === COMPAT_ADD_OTP &&
      typeof setValidatedUser === 'function' &&
      window.location.href.includes('/appscompat-add')
    ) {
      setValidatedUser(true);
      history.replace('/appscompat-add', {appsCompatUser: true});
    } else setError('Invalid Verification code');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setOTP(event.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      pinHandler();
    }
  };

  return (
    <Flex
      direction='column'
      boxShadow='0px 0px 32px 0px rgba(0, 0, 0, 0.2)'
      borderRadius='15px'
      width='25%'
      height='fit-content'
      background='white'
      boxSizing='border-box'
      margin='15vh auto 90px'
      color='#637280'
      textAlign='center'
    >
      <Flex direction='column' px={144} p={50}>
        <FormControl mb={20} isInvalid={!!error}>
          <FormLabel>Verification code</FormLabel>

          <InputGroup size='md'>
            <Input
              type='password'
              placeholder='Enter verification code'
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              isInvalid={!!error}
            />
          </InputGroup>
          <FormErrorMessage data-testid='otp-error'>{error}</FormErrorMessage>
        </FormControl>
        <Button
          size='large'
          variant='contained'
          colorScheme='primary'
          data-testid='otp-verify-btn'
          onClick={pinHandler}
        >
          Confirm and verify
        </Button>
      </Flex>
    </Flex>
  );
};
export default AppCompatibilityOTP;
