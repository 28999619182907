import {hasSomeOf} from '../../utils/permissions';

export const invitePermissions = [
  'teams.create',
  'teams.clone',
  'teams.list_all',
  'teams.delete',
  'teams.update_all',
];
export const teamsPermissions = [...invitePermissions, 'teams.update_own'];
export const adminToolsPermissions = ['customcolumns.manage_custom_columns'];
export const planPermissions = ['subscriptiondetails.view_own'];

function useSettings() {
  return {
    invitePermissions: hasSomeOf(invitePermissions),
    teamsPermissions: hasSomeOf(teamsPermissions),
    adminToolsPermissions: hasSomeOf(adminToolsPermissions),
    planPermissions: hasSomeOf(planPermissions),
  };
}

export default useSettings;
