import {useFormContext} from 'react-hook-form';
import {Link, useHistory} from 'react-router-dom';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
  Text,
  TInputProps,
  Link as DSLink,
} from 'spekit-ui';

interface IUsernameInput extends TInputProps {
  label?: string;
  requiredmsg?: string;
  hideforgotlink?: boolean;
}

const UsernameInput = (props: IUsernameInput) => {
  const {
    register,
    formState: {errors},
  } = useFormContext();
  const history = useHistory();

  return (
    <FormControl isInvalid={!!errors.username}>
      <Flex justifyContent='space-between' alignItems='center'>
        <FormLabel>{props.label || 'Email address or username'}</FormLabel>
        {!props.hideforgotlink && (
          <Text fontWeight='normal' fontSize='small'>
            <DSLink
              data-testid='forgot-username'
              as={Link}
              to={{
                pathname: '/retrieve-user',
                search: history.location.search,
              }}
            >
              Forgot username?
            </DSLink>
          </Text>
        )}
      </Flex>

      <Input
        data-testid='email-username'
        autoComplete='off'
        isInvalid={!!errors.username}
        placeholder='Enter email address or username'
        {...register('username', {
          required:
            props.requiredmsg || 'An email address or username is required to login.',
        })}
        {...props}
      />
      {errors.username && (
        <FormErrorMessage data-testid='username-error' colorScheme={props.colorScheme}>
          {errors.username.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default UsernameInput;
