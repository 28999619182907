import {fetchMiddleWare as fetch} from '../fetchMiddleWare';
import {stringifyQs} from '../utils/commonUtils';
export interface Query {
  q: string;
  permissions: string;
}

interface Team {
  label: string;
  value: string;
  topics: Array<string>;
}
export interface Response {
  results: Array<Team>;
}

export const teamLookup = async (query: Query): Promise<Response> => {
  const querystring = stringifyQs(query);
  const response = await fetch(`/api/lookups/teams?${querystring}`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  });
  const result = await response.json();
  return result;
};
