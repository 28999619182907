import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {IFlagState} from 'spekit-types';

interface IProtectedRouteProps {
  children: React.ReactNode;
  path: string;
}

interface IState {
  flag: IFlagState;
}

function ProtectedSearchRoute(props: IProtectedRouteProps) {
  const {children, path} = props;
  const hasNewSearchFlag = useSelector((state: IState) => state.flag.hasNewSearchFlag);

  if (!hasNewSearchFlag) {
    return <Redirect to='/app/notfound' />;
  }

  return (
    <Route path={path} exact>
      {children}
    </Route>
  );
}

export default ProtectedSearchRoute;
