import React from 'react';
import {Link as ChakraLink, LinkProps, forwardRef} from '@chakra-ui/react';

interface ILinkInterface {
  pathname: string;
  search?: string;
  hash?: string;
  state?: object;
}

export type TLinkProps = LinkProps & {to?: string | ILinkInterface};

export const LinkConfig = {
  baseStyle: {
    color: 'primary.500',
    textDecoration: 'none',
    _hover: {
      textDecoration: 'none',
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  variants: {},
  sizes: {},
  defaultProps: {},
};
export const Link = forwardRef((props: TLinkProps, ref) => (
  <ChakraLink ref={ref} {...props} />
));
