import {Component} from 'react';
import {logging} from 'spekit-datalayer';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error, errorInfo) {
    logging.capture(error, {errorInfo});
    this.setState({hasError: true});
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  me: state.layout.me,
});

export default withRouter(connect(mapStateToProps)(ErrorBoundary));
