/* istanbul ignore file */
// CKEditor TS support is coming https://github.com/ckeditor/ckeditor5/issues/11704

import Command from '@ckeditor/ckeditor5-core/src/command';

export default class InsertIframe extends Command {
  execute(src, position, options) {
    return this.editor.model.change((writer) => {
      // Insert <embeddedIframe data-src="...">*</embeddedIframe> at the current selection position
      // the schema definition will render the correct view for embeddedIframe models

      const element = writer.createElement('embeddedIframe', {
        'data-src': src,
        'data-options': options,
      });

      // insert the embed element in the model and move the cursor after it
      // this.editor.model.insertObject(element, null, null, {setSelection: 'after'});
      this.editor.model.insertObject(element, position || null);

      // return the element
      return element;
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      'embeddedIframe'
    );

    this.isEnabled = allowedIn !== null;
  }
}
