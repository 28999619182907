interface IPayload {
  content_id: string;
  content_link: string;
  name: string;
  created_on: string;
  last_viewed: string;
}

interface IAction {
  type: 'SET_SHARE_LINK_DATA';
  payload: IPayload;
}
const initialState = {
  content_id: '',
  content_link: '',
  name: '',
  created_on: '',
  last_viewed: '',
};

export default function shareLinkAnalyticsReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case 'SET_SHARE_LINK_DATA':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
