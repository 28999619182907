import {IGroup} from 'spekit-types';
import {retrieve} from './utils/APIHelpers';

interface IGroupResponse {
  groups: IGroup[];
  success: boolean;
}

export const getRoles = async () => {
  const result = await retrieve<IGroupResponse>('/api/groups/');

  if (result.success) {
    return result;
  }

  throw new Error(JSON.stringify(result));
};
