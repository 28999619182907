/**
 * The Embiggener displays a full size (max of 80% w/h) image over a curtain when clicked.
 * This and the backdrop component will be combined into a single component in the future.
 */
import React, {HTMLAttributes} from 'react';

// styles for the curtain, image, and close button
import '../styles/embiggener.css';

interface EmbiggenerProps {
  imgAttribs: HTMLAttributes<HTMLImageElement>;
  imgSrc: string;
  onImageZoom?: (url: string) => void;
}

// time for the curtain and image to fade in/out
// defined here and used in a few places
const animationTime = 300;

export const Embiggener: React.FC<EmbiggenerProps> = ({
  imgAttribs,
  imgSrc,
  onImageZoom,
}) => {
  return (
    <img
      alt=''
      {...imgAttribs}
      src={imgSrc}
      onClick={() => {
        if (typeof onImageZoom === 'function') {
          onImageZoom(imgSrc);
          return;
        }
        // a curtain to prevent interaction with the page
        const curtain = document.createElement('div');
        curtain.classList.add('embiggener__curtain');

        // append the curtain to the body
        document.body.appendChild(curtain);

        // fade in the curtain, but wait 0ms so that the curtain is added to the DOM before fading in
        setTimeout(() => {
          curtain.style.transition = `opacity ${animationTime}ms ease-in-out`;
          curtain.style.opacity = '1';
        }, 0);

        // create an image element
        const img = document.createElement('img');
        img.src = imgSrc;
        img.classList.add('embiggener__image');

        // append the img to the curtain
        curtain.appendChild(img);

        // add a button with an x symbol that closes the curtain
        const button = document.createElement('button');
        button.classList.add('embiggener__close-button');

        // add the button to the curtain
        curtain.appendChild(button);

        // function that removes the curtain after fade out
        const removeCurtain = () => {
          curtain.style.opacity = '0';
          setTimeout(() => {
            curtain.remove();
          }, animationTime);
        };

        // add removal handlers
        curtain.onclick = removeCurtain;
        img.onclick = removeCurtain;
        button.onclick = removeCurtain;
      }}
    />
  );
};
