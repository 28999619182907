import {ISeismicResponse, ISearchResult, TSearchAPIContentType} from 'spekit-types';

export function transformSeismicToSearch(seismicData?: ISeismicResponse[]) {
  const searchResults: ISearchResult[] = [];
  seismicData?.forEach((seismicResponse) => {
    const documents = seismicResponse?.documents ?? [];
    const transformedDocuments = documents.map((document) => ({
      id: document.id,
      label: document.name,
      truncated_definition: document?.description ?? '',
      type: 'seismic',
      document_type: 'seismic' as TSearchAPIContentType,
      updated_by: {
        id: '',
        first_name: '',
        last_name: '',
      },
      can_edit: true,
      parent: null,
      updated_on: document.modifiedDate,
      last_edited: document.modifiedDate,
      seismicFormat: document.format,
      seismicType: document.type,
      seismicUrl: document.applicationUrls[0]?.url,
    }));

    searchResults.push(...transformedDocuments);
  });

  return searchResults;
}
