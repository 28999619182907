import React from 'react';
import Popover from './popover';
import {PropagateLoader} from 'react-spinners';

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      btn: null,
      loaderHeight: '0px',
      hovered: false,
      getLoaderHeight: this.getLoaderHeight.bind(this),
    };
    this.setRef = this.setRef.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.busy) {
      return {
        loaderHeight: state.getLoaderHeight(state.btn),
      };
    }
    return null;
  }

  setRef(btn) {
    if (btn) {
      this.setState({btn});
    }
  }
  getLoaderHeight(btn) {
    if (btn) {
      let cs = window.getComputedStyle(btn);
      let loaderHeight = btn.clientHeight;
      loaderHeight -=
        window.parseFloat(cs.paddingTop) + window.parseFloat(cs.paddingBottom);
      return loaderHeight;
    }
  }
  handleMouseEnter(evt) {
    if (!this.props.noHover) {
      this.setState({hovered: true});
      if (typeof this.props.onMouseEnter === 'function') {
        this.props.onMouseEnter(evt);
      }
    }
  }
  handleMouseLeave(evt) {
    if (!this.props.noHover) {
      this.setState({hovered: false});
      if (typeof this.props.onMouseLeave === 'function') {
        this.props.onMouseLeave(evt);
      }
    }
  }
  render() {
    let styles = {
      main: {
        background: this.props.secondary
          ? '#9FA9BA'
          : this.props.disabled
          ? '#96B9F1'
          : '#3677E6',
        filter:
          this.state.hovered && !this.props.noHover
            ? 'brightness(80%)'
            : 'brightness(100%)',
        color: this.props.loaderColor ? this.props.loaderColor : '#fff',
        borderRadius: '4px',
        border: 'none',
        outline: 'none',
        cursor: this.props.disabled ? 'not-allowed' : 'pointer',
        padding: '10px 11px',
        fontSize: '14px',
        overflow: 'hidden',
        transition: 'all 300ms ease-in-out',
      },
      loaderContainer: {
        width: '0px',
        margin: this.props.loaderMargin ? this.props.loaderMargin : 'auto',
        height: this.state.loaderHeight,
      },
      popover: {
        backgroundColor: '#04254A',
        color: '#FFF',
        padding: '20px',
        fontSize: '12px',
        maxWidth: '150px',
      },
    };
    styles.main = Object.assign({}, styles.main, this.props.style);
    return (
      <React.Fragment>
        <button
          onClick={(evt) => {
            if (typeof this.props.onClick === 'function' && !this.props.disabled) {
              this.props.onClick(evt);
            }
          }}
          className={this.props.className}
          title={this.props.title}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          disabled={(this.props.disabled || this.props.busy) && !this.props.tooltip}
          ref={this.setRef}
          style={styles.main}
          data-testid={this.props['data-testid']}
        >
          {this.props.busy ? (
            <div style={styles.loaderContainer} data-testid={this.props.loadingTestId}>
              <PropagateLoader
                loading={this.props.busy}
                size={this.state.loaderHeight}
                color={styles.main.color}
              />
            </div>
          ) : (
            this.props.children
          )}
        </button>
        {!!this.props.tooltip ? (
          <Popover
            open={this.state.hovered && !!this.props.tooltip}
            style={styles.popover}
            anchorEl={this.state.btn}
          >
            {this.props.tooltip}
          </Popover>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Button;
