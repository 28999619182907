import React, {useState, useRef, useEffect} from 'react';
import styles from './accordionItem.module.css';
import Fontawesome from '../fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

export interface Props {
  title: string;
  expanded?: boolean;
  onToggle?: (expanded: boolean | undefined, title: string) => void;
}

const AccordionItem: React.FC<Props> = ({children, title, expanded, onToggle}) => {
  const [opened, setOpened] = useState<boolean | undefined>(expanded);
  const [setHeight, setHeightState] = useState<string>('0px');
  const contentRef = useRef<any>(null);
  const containerRef = useRef<any>(null);

  useEffect(() => {
    if (!contentRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (contentRef.current && opened) {
        setHeightState(`${contentRef.current.scrollHeight}px`);
      }
    });

    resizeObserver.observe(contentRef.current);

    return () => resizeObserver.disconnect();
  });

  const toggleAccordion = (e: React.MouseEvent) => {
    e.currentTarget.scrollIntoView({behavior: 'smooth'});
    if (contentRef.current) {
      setHeightState(opened ? '0px' : `${contentRef.current.scrollHeight}px`);
    }
    setOpened((prevState) => {
      if (onToggle instanceof Function) {
        onToggle(prevState, title);
      }

      if (containerRef.current) {
        containerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
      return !prevState;
    });
  };

  return (
    <div
      className={styles.container}
      ref={containerRef}
      data-testid={`accordion-item-${title}`}
    >
      <div
        className={opened ? styles.titleActive : styles.title}
        onClick={toggleAccordion}
      >
        <div>{title}</div>
        <Fontawesome
          name={opened ? faChevronUp : faChevronDown}
          className={styles.chevron}
        />
      </div>
      <div className={styles.content} style={{maxHeight: `${setHeight}`}}>
        <div ref={contentRef}>{opened ? children : null}</div>
      </div>
    </div>
  );
};

export default AccordionItem;
