import React from 'react';
import styles from './audience.module.css';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import AudienceTeamSelector from '../../audienceTeamSelector/audienceTeamSelector';
import SpotlightAudienceTeamSelector from '../../audienceTeamSelector/SpotlightAudienceTeamSelector';
import {Fontawesome, Text, DSTooltip as Tooltip} from 'spekit-ui';
import {State, Dispatch} from '../../spotlightModal/types';

interface Props {
  containerRef: any;
  state: State;
  dispatch: Dispatch;
  onChange: (teams: any) => void;
  isSpotlight?: boolean;
  label?: string | undefined;
}

const Audience: React.FC<Props> = ({
  containerRef,
  state,
  onChange,
  isSpotlight,
  dispatch,
  label,
}) => {
  const hasSpotlightsRevampFlag = state.flag?.hasSpotlightsRevampFlag;
  return (
    <>
      {hasSpotlightsRevampFlag && isSpotlight ? (
        <>
          <div className={styles.spotlightAudienceSelector}>
            <Text mt={16} fontSize='medium' fontWeight='bold'>
              Audience
            </Text>
            <SpotlightAudienceTeamSelector
              state={state}
              dispatch={dispatch}
              onSelect={onChange}
              value={state.teams}
              isDisabled={state.read_mode}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.audienceSelector}>
            <div className={styles.headerContainer}>
              <div className={styles.header}>Audience</div>
              <Tooltip shouldWrapChildren label='Who should see this?'>
                <Fontawesome name={faInfoCircle} className={styles.infoCircle} />
              </Tooltip>
            </div>
            <div className={styles.subHeading}>{label ? label : 'Teams to send to'}</div>
            <AudienceTeamSelector
              monitorScrollElement={containerRef}
              onSelect={onChange}
              value={state.teams}
              isDisabled={state.read_mode}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Audience;
