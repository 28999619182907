import React from 'react';
import {FormControl, FormLabel} from '@chakra-ui/form-control';
import {FormErrorMessage, FormHelperText, Input} from 'spekit-ui';
import {URLRulesProps} from '../types';

const URLInput = (props: URLRulesProps) => {
  const {target, onDomainChange, index, handleDomainBlur, disabled} = props;

  return (
    <>
      <FormControl isRequired isInvalid={target.error} data-testid='url-input'>
        <FormLabel fontSize='sm'> URL </FormLabel>
        <Input
          isRequired
          placeholder='https://'
          isDisabled={disabled}
          value={target.domain_value || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onDomainChange(e, index)}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleDomainBlur(e, index)}
        />

        {!target.error ? (
          <FormHelperText>URL must begin with “http://” or ‘”https://”.</FormHelperText>
        ) : (
          <FormErrorMessage data-testid='url-error-message'>
            {target.errorText}
          </FormErrorMessage>
        )}
      </FormControl>
    </>
  );
};

export default URLInput;
