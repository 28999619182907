import ReactDOM from 'react-dom';
import React, {FocusEvent, MouseEvent, CSSProperties} from 'react';

interface PopoverProps {
  open?: boolean;
  anchorEl: HTMLElement | null;
  autoCloseOnBlur?: boolean;
  onRequestClose?: (e?: any) => void;
  openInView?: boolean;
  centered?: boolean;
  mouseLeave?: () => void;
  attachRight?: boolean;
  matchWidth?: boolean;
  verticalOffset?: number;
  horizontalOffset?: number;
  style?: any;
  closeOnClick?: () => void;
  dataTestId?: string;
}

interface PopoverState {
  menu: HTMLElement | null;
  shift: number;
  activeReposition: boolean | null;
}

function isDetached(el: HTMLElement): boolean {
  if (el.parentElement) {
    if (el.parentElement.tagName === 'BODY') {
      return false;
    } else {
      return isDetached(el.parentElement);
    }
  } else {
    return true;
  }
}

class Popover extends React.Component<PopoverProps, PopoverState> {
  constructor(props: PopoverProps) {
    super(props);
    this.state = {
      menu: null,
      shift: 0,
      activeReposition: null,
    };
    this.setRef = this.setRef.bind(this);
    this.isInView = this.isInView.bind(this);
    this.reposition = this.reposition.bind(this);
    this.wasUnfocused = this.wasUnfocused.bind(this);
    this.setCenterShift = this.setCenterShift.bind(this);
  }
  setRef(ref: HTMLDivElement | null) {
    this.setState({menu: ref});
  }
  componentDidMount() {
    window.addEventListener('scroll', this.reposition);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.reposition);
  }
  componentDidUpdate() {
    if (this.props.open) {
      if (this.props.anchorEl) {
        this.props.anchorEl.tabIndex = -1;
      }
      if (this.props.autoCloseOnBlur && this.state.menu !== null) {
        this.state.menu.focus();
      }
      if (!this.isInView() && this.props.onRequestClose) {
        this.props.onRequestClose();
      }
    }
    if (this.props.openInView && this.props.open) {
      if (!this.state.activeReposition) {
        this.setState({activeReposition: true}, this.decideOpenDirection);
      }
    }
  }
  isInView() {
    if (this.props.anchorEl) {
      let anchor = this.props.anchorEl.getBoundingClientRect();
      if (anchor.x < 0 || anchor.x > window.innerWidth) {
        return false;
      }
      if (anchor.y < 0 || anchor.y > window.innerHeight) {
        return false;
      }
      return true;
    }
    return false;
  }
  reposition() {
    if (this.props.anchorEl && this.props.open && !this.props.openInView) {
      this.forceUpdate();
    }
  }
  wasUnfocused(evt: FocusEvent) {
    if (this.props.autoCloseOnBlur && evt.relatedTarget !== this.props.anchorEl) {
      evt.persist();
      window.setTimeout(() => {
        if (this.props.onRequestClose !== undefined) {
          this.props.onRequestClose(evt);
        }
      }, 100);
    }
    if (
      evt.relatedTarget === this.props.anchorEl &&
      this.props.autoCloseOnBlur &&
      this.state.menu
    ) {
      this.state.menu.focus();
    }
  }
  setCenterShift() {
    let shift = 0;
    if (this.props.centered && this.state.menu) {
      shift = this.state.menu.getBoundingClientRect().width / 2;
    }
    this.setState({shift: shift});
  }
  decideOpenDirection = () => {
    if (
      this.state.menu &&
      !isDetached(this.state.menu) &&
      this.props.anchorEl &&
      this.props.open &&
      this.props.openInView
    ) {
      let menu = this.state.menu.getBoundingClientRect();
      let anchor = this.props.anchorEl.getBoundingClientRect();
      let menuEl = this.state.menu;
      if (window.innerWidth - Math.ceil(anchor.left + anchor.width) < menu.width) {
        menuEl.style.right = '3px';
        menuEl.style.left = null as unknown as string;
      } else {
        menuEl.style.left = Math.ceil(anchor.left) + 'px';
        menuEl.style.right = null as unknown as string;
      }
      if (window.innerHeight - Math.ceil(anchor.top + anchor.height) < menu.height) {
        menuEl.style.bottom = '3px';
        if (menuEl.style.left) {
          menuEl.style.left = Math.ceil(anchor.left + anchor.width) + 'px';
        }
        if (menuEl.style.right) {
          menuEl.style.right =
            Math.ceil(window.innerWidth - anchor.left - anchor.width) + 'px';
        }
        menuEl.style.top = null as unknown as string;
      } else {
        menuEl.style.top = Math.ceil(anchor.top + anchor.height) + 'px';
        menuEl.style.bottom = null as unknown as string;
      }
      window.requestAnimationFrame(this.decideOpenDirection);
    } else {
      this.setState({activeReposition: null});
    }
  };
  mouseLeave = (e: MouseEvent<HTMLDivElement>) => {
    if (typeof this.props.mouseLeave === 'function') {
      this.props.mouseLeave();
    }
  };
  render() {
    let topPos = 0;
    let leftPos = 0;
    let rightPos = 0;
    let width = null;
    if (this.props.centered && this.props.anchorEl) {
      setTimeout(this.setCenterShift, 20);
    }
    if (this.props.attachRight && this.props.anchorEl) {
      rightPos = Math.round(
        document.documentElement.clientWidth -
          this.props.anchorEl.getBoundingClientRect().right
      );
    }
    if (!this.props.attachRight && this.props.anchorEl) {
      if (this.props.centered) {
        leftPos = Math.round(
          this.props.anchorEl.getBoundingClientRect().left - this.state.shift
        );
      } else {
        leftPos = Math.round(this.props.anchorEl.getBoundingClientRect().left);
      }
    }
    if (this.props.matchWidth && this.props.anchorEl) {
      width = this.props.anchorEl.getBoundingClientRect().width;
    }
    var styles = {
      main: {
        position: 'fixed' as 'fixed',
        ...(this.state.activeReposition === null
          ? {
              top:
                this.props.anchorEl && this.props.open
                  ? Math.round(
                      this.props.anchorEl.getBoundingClientRect().bottom +
                        (this.props.verticalOffset || 0) +
                        topPos
                    ) + 'px'
                  : 0,
              left: this.props.attachRight
                ? null
                : this.props.anchorEl && this.props.open
                ? (this.props.horizontalOffset || 0) + leftPos + 'px'
                : 0,
              right: this.props.attachRight
                ? this.props.anchorEl && this.props.open
                  ? rightPos + 'px'
                  : 0
                : null,
            }
          : {}),
        width: width + 'px',
        display: this.props.open ? 'block' : 'none',
        backgroundColor: '#fff',
        zIndex: 2147483647,
        border: '1px solid #C0C5D2',
        borderRadius: '4px',
        boxShadow: '0px -4px 20px 0 rgba(159,169,186,0.8)',
        outline: 'none',
        overflow: 'hidden',
        ...this.props.style,
      } as CSSProperties,
    };
    let thePortal = document.getElementById('popup_portal');
    if (!thePortal) {
      thePortal = document.createElement('div');
      thePortal.id = 'popup_portal';
      document.body.appendChild(thePortal);
    }
    return ReactDOM.createPortal(
      <div
        tabIndex={-1}
        ref={this.setRef}
        onBlur={this.wasUnfocused}
        onMouseLeave={this.mouseLeave}
        onMouseDown={(e) => {
          if (!this.props.closeOnClick) {
            e.preventDefault();
          }
        }}
        style={styles.main}
        data-testid={this.props.dataTestId}
      >
        {this.props.children}
      </div>,
      thePortal
    );
  }
}

export default Popover;
